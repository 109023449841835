import React, { FC } from 'react';
import './style.scss';

const SpinningCircle: FC = () => {
  return (
    <div>
      <div className="spinning-circle"></div>
      <span>Loading...</span>
    </div>
  );
};

export default SpinningCircle;
