import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  DetailedHTMLProps,
  TextareaHTMLAttributes,
} from 'react';
import DOMPurify from 'dompurify';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import convertFromHTML from 'html-to-draftjs';
import PropTypes from 'prop-types';
import TextArea from '../Input/TextArea';
import { WrapperProps } from '../Input/Wrapper';

interface RichTextInputProps
  extends DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    WrapperProps {
  parseToHTMLCallback?: (value: string) => void;
  initialValue?: string;
  name: string;
}

export interface RichTextInputMethods {
  reset: () => void;
}

const RichTextInput = forwardRef<RichTextInputMethods, RichTextInputProps>(
  ({ parseToHTMLCallback, initialValue, ...textAreaProps }, ref) => {
    const richTextState = () => {
      if (initialValue) {
        const blocksFromHtml = convertFromHTML(initialValue);
        const initialContentState: ContentState = ContentState.createFromBlockArray(
          blocksFromHtml.contentBlocks,
          blocksFromHtml.entityMap,
        );
        return EditorState.createWithContent(initialContentState);
      } else {
        return EditorState.createEmpty();
      }
    };

    const [editorState, setEditorState] = useState(() => richTextState());
    const [previousHtml, setPreviousHtml] = useState<string>('');

    const checkIfAllPtagsAreEmpty = (content: string) => {
      // Extract all matches of <p> tags from the string
      const matches = content.match(/<p>(.*?)<\/p>/gi);

      // Check if all <p> tags contain only &nbsp; as their content
      return matches?.every(match => {
        // Remove <p> tags and any whitespace from the match
        const content = match.replace(/<\/?p>/g, '').trim();
        // Check if the content consists of only &nbsp; characters
        return /^(&nbsp;|\s)*$/.test(content);
      });
    };

    const checkIfOnlyBrTags = (content: string) => content.replace(/<br\s*\/?>/g, '').trim().length === 0;

    const replaceEmptyPTagsWithBr = (content: string) => content.replace(/<p>\s*<\/p>/g, '<br>');

    useEffect(() => {
      const emptyContent = (content: string) => checkIfAllPtagsAreEmpty(content) || checkIfOnlyBrTags(content);

      const sanitize = (content: string) => DOMPurify.sanitize(replaceEmptyPTagsWithBr(content.trim()));

      const asHTML = () => {
        const contentState = editorState.getCurrentContent();
        const rawContentState = convertToRaw(contentState);
        const sanitizedContent = sanitize(draftToHtml(rawContentState));
        return emptyContent(sanitizedContent) ? '' : sanitizedContent;
      };

      const htmlFragment = asHTML();

      if (parseToHTMLCallback && htmlFragment !== previousHtml) {
        parseToHTMLCallback(htmlFragment);
        setPreviousHtml(htmlFragment);
      }
    }, [editorState, parseToHTMLCallback, previousHtml]);

    const reset = () => {
      setEditorState(richTextState());
    };

    useImperativeHandle(ref, () => ({
      reset: () => reset(),
    }));

    const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const newContentState = ContentState.createFromText(event.target.value);
      const newEditorState = EditorState.push(editorState, newContentState, 'insert-characters');
      setEditorState(newEditorState);
    };

    return (
      <TextArea
        {...textAreaProps}
        value={editorState.getCurrentContent().getPlainText()}
        onChange={handleInputChange}
      />
    );
  },
);

RichTextInput.displayName = 'RichTextInput';

RichTextInput.propTypes = {
  parseToHTMLCallback: PropTypes.func,
  initialValue: PropTypes.string,
};

export default RichTextInput;
