import React, { FC, ReactNode, MouseEvent } from 'react';
import clsx from 'clsx';
import ICONS from 'components/Icons';

type IconButtonProps = {
  icon: string;
  onClick?: (ev: MouseEvent<HTMLElement>) => void;
  iconSide?: 'LEFT' | 'RIGHT';
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  label?: string;
  htmlId?: string;
};

const IconButton: FC<IconButtonProps> = ({
  onClick,
  className,
  icon,
  children,
  disabled = false,
  iconSide = 'LEFT',
  label,
  htmlId,
}: IconButtonProps) => {
  const classNames = clsx('icon-button', className);

  return (
    <button
      id={htmlId}
      type="button"
      className={classNames}
      onClick={onClick}
      aria-label={icon || label}
      disabled={disabled}>
      {iconSide === 'LEFT' && ICONS[icon]}
      {children}
      {iconSide === 'RIGHT' && ICONS[icon]}
    </button>
  );
};

export default IconButton;
