import React, { FC } from 'react';
import { numberCentsFormatter, showPlural } from 'utils';
import { Goal } from './types';
import { BlockState } from 'api/data/pages/blocks/types';
import { DonationBlock } from '../DonationBlock/types';
import { PaymentBlock } from '../PaymentBlock/types';
import './style.scss';

interface GoalBarProps {
  goal: Goal;
}

const GoalBar: FC<GoalBarProps> = ({ goal }) => {
  const progress = ((goal.collectedAmount / goal.target) * 100).toString() + '%';

  return (
    <div className="goal-bar">
      <div className="progress-bar">
        <span style={{ width: progress }}></span>
      </div>
      <div className="labels">
        <div>
          <p className="collected-amount">${numberCentsFormatter(goal.collectedAmount).toLocaleString()}</p>
          <p className="supporters">
            {' '}
            raised • {goal.submissionsCount} supporter{showPlural(goal.submissionsCount)}{' '}
          </p>
        </div>
        <p className="target-amount">${numberCentsFormatter(goal.target).toLocaleString()} </p>
      </div>
    </div>
  );
};

export const getTarget = (blocks: BlockState[]) => {
  const blocksFiltered = blocks.filter(block => (block as PaymentBlock).goal?.id || block.type === 'PAYMENT') as
    | DonationBlock[]
    | PaymentBlock[];

  const totalAmount = blocksFiltered.reduce((prev, curr) => {
    if (curr.type === 'PAYMENT') {
      curr.variants.forEach(variant => {
        if (variant.blocks) {
          variant.blocks.forEach(block => {
            if (block.type === 'PAYMENT') prev += block.goal?.amountCents || 0;
          });
        }
      });
    }

    prev += curr.goal?.amountCents || 0;
    return prev;
  }, 0);

  return totalAmount;
};

export default GoalBar;
