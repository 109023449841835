import React, { ReactElement, useEffect } from 'react';
import { RouteProps, DefaultParams, useLocation, Route } from 'wouter';
import useUserSession from 'hooks/useUserSession';
import { chooseManagedSpace, isProduction } from 'utils';

function LoginRoute<T extends DefaultParams>({ path, component }: RouteProps<T>): ReactElement | null {
  const { data, loading, error } = useUserSession();
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (!loading && !error && data?.session.currentUser) {
      if (data?.session.currentUser?.signUpData?.signup_step === 'password') {
        setLocation('/signup');
      } else {
        const firstSpace = chooseManagedSpace(data?.session.managedOrganizations);
        const path = firstSpace ? `/${firstSpace.slug}/pages` : '/member';
        setLocation(path);
      }
    }
  }, [error, loading, data, setLocation]);

  if (isProduction() && error && location === '/') {
    window.location.assign('https://www.omella.com/');
    return null;
  }

  if (loading) return <div>Loading... </div>;

  if (error || !data?.session.currentUser) return <Route path={path} component={component} />;

  return null;
}

export default LoginRoute;
