import React, { FC, ChangeEvent } from 'react';
import clsx from 'clsx';
import { defaultBlocksTitle } from 'defaults/page';
import { findSupportMessage, updateSupportMessage } from 'api/data/response';
import Input from 'components/Input';
import RichTextInput from '../RichTextInput';
import SupporterList from './SupporterList';
import { SupporterFeedPayerViewProps } from './types';

const PayerView: FC<SupporterFeedPayerViewProps> = ({ readOnly, data, isRequired, pageId, participantId }) => {
  return (
    <div
      id={`block-${data.id}`}
      className={clsx('block payer-view supporter-feed', {
        readOnly: readOnly,
        'required-block': isRequired,
      })}>
      {pageId}
      <div className="block-content">
        <div className="default-question">
          <h4 className="block-title" data-id={`question_title_${data.id}`}>
            {data.title || defaultBlocksTitle[data.type]}
          </h4>
        </div>
        <SupporterList blockId={data.id} participantId={participantId} readOnly={readOnly} />
        <SupportMessage payerView id={data.id} />
      </div>
    </div>
  );
};

type SupportMessageProps = {
  payerView: boolean;
  id: string;
};

export const SupportMessage: FC<SupportMessageProps> = ({ payerView, id }) => {
  const supportMessageResponse = findSupportMessage(id);

  const handleMessageChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    updateSupportMessage(id, {
      ...supportMessageResponse,
      [target.name]: target.value,
    });
  };

  return (
    <div className="supporter-message">
      <h4 className="block-title">Add a public message</h4>
      <Input
        name="name"
        value={supportMessageResponse.name}
        placeholder={`${payerView ? 'N' : 'Supporter n'}ame or blank for anonymous`}
        disabled={!payerView}
        onChange={handleMessageChange}
        onBlur={event =>
          handleMessageChange({ ...event, target: { ...event.target, value: event.target.value.trim(), name: 'name' } })
        }
        aria-label="name"
      />
      <RichTextInput
        name="message"
        initialValue={supportMessageResponse.message}
        placeholder={payerView ? 'Your message here...' : 'Supporters can add an optional message here'}
        disabled={!payerView}
        parseToHTMLCallback={(message: string) => {
          updateSupportMessage(id, {
            ...supportMessageResponse,
            message: message,
          });
        }}
        aria-label="message"
      />
    </div>
  );
};

export default PayerView;
