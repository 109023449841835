import { useState, useEffect } from 'react';

const SIDE_BAR_SIZE = 210;
const SCREEN_MD_MIN = 768;

export default function useWindowSize() {
  const [clientWidth, setClientWidth] = useState<number>(window.innerWidth);
  const isMobile = clientWidth <= SCREEN_MD_MIN;

  useEffect(() => {
    const width = isMobile ? window.innerWidth : window.innerWidth - SIDE_BAR_SIZE;
    setClientWidth(width);
  }, [isMobile]);

  return { clientWidth };
}
