import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useQuery } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import Header from 'components/Header';
import NewPasswordForm from './NewPasswordForm';
import { PageParams, PasswordRecoveryResponse, UserResponse } from './types';
import './style.scss';

const Welcome: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { id, token } = params;
  const [, setLocation] = useLocation();

  const { data, loading: loadingPasswordRecovery } = useQuery<PasswordRecoveryResponse>(AuthQuery.PasswordRecovery, {
    variables: { id, token },
  });

  const { data: userData, loading: loadingUserData } = useQuery<UserResponse>(AuthQuery.UserByPasswordResetToken, {
    variables: { id, token },
  });

  useEffect(() => {
    if (data && !data?.passwordRecoveryValidation && !loadingPasswordRecovery) {
      setLocation('/password-recover?invalid-link');
    }
  }, [data, loadingPasswordRecovery, setLocation]);

  const outsideRef = useRef<HTMLElement>(null);

  if (loadingPasswordRecovery || loadingUserData) return <div>Loading... </div>;
  return (
    <section ref={outsideRef} className="request-link password-recover welcome">
      <Header refOutside={outsideRef} showSideBar={false} />
      <div className="content">
        <h1>
          <span role="img" aria-label="hand waving">
            &#x1F44b;
          </span>{' '}
          Hi {userData?.userByPasswordResetToken.fullName}!
        </h1>
        <h2>Welcome to Omella. Please set your password.</h2>
        <NewPasswordForm params={params} buttonLabel="Create Password" />
      </div>
    </section>
  );
};

export default Welcome;
