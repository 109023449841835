import React, { FC, useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';
import { participantAsSubmission } from 'utils';
import SubmissionDetails from './SubmissionDetails';
import IconButton from 'components/Button/IconButton';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs';
import { Transactions } from 'components/ResponseDetail/Transactions';
import { Subscriptions } from 'components/ResponseDetail/Subscriptions';
import { SubmissionDetail, ResponseDetailType } from 'api/data/response/types';
import { SubmissionDetails as SubmissionDetailsType } from 'api/data/response/types';
import { Subscriptions as SubscriptionsType } from 'api/data/subscription/types';
import { Transactions as TransactionsType } from 'api/data/transaction/types';
import './style.scss';

const ResponseDetail: FC<ResponseDetailType> = ({ data, setResponseId, pageId, pageTitle }) => {
  const { data: transactionsData } = useQuery<TransactionsType>(TransactionQuery.GetTransactions, {
    variables: { id: data.id },
  });
  const { data: subscriptionsData } = useQuery<SubscriptionsType>(SubscriptionQuery.GetSubscriptions, {
    variables: { id: data.id },
  });
  const { data: submissionData } = useQuery<SubmissionDetailsType>(ResponseQuery.SubmissionDetails, {
    variables: { id: data.id },
  });
  const [tabContentKey, setTabContentKey] = useState('');
  const isFirstRender = useRef(true);

  const participantAnswer: SubmissionDetail[] = data.participantMembership
    ? [participantAsSubmission(data.participantMembership)]
    : [];

  const items = participantAnswer.concat(submissionData?.submissionDetails || []);

  const onTabChange = (tabKey: string) => {
    setTabContentKey(tabKey);
  };

  useEffect(() => {
    if (transactionsData && isFirstRender.current) {
      isFirstRender.current = false;

      if (transactionsData.transactions.length === 0) {
        setTabContentKey('details');
      } else {
        setTabContentKey('payments');
      }
    }
  }, [transactionsData]);

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    payments: (
      <Transactions
        pageResponse={data}
        transactions={transactionsData?.transactions || []}
        pageId={pageId}
        pageTitle={pageTitle}
      />
    ),
    subscriptions: (
      <Subscriptions responseId={data.id} subscriptions={subscriptionsData?.subscriptions || []} pageId={pageId} />
    ),
    details: <SubmissionDetails respondentFullName={data.respondent.fullName} items={items} />,
  };

  return (
    <tr className="response-detail">
      <th colSpan={pageId ? 3 : 4}>
        <div className="one-column">
          <div className="response-container">
            <div className="detail-container">
              <div className="header">
                {tabContentKey && (
                  <Tabs activeKey={tabContentKey}>
                    <Tab label="Payments" tabKey="payments" onClick={onTabChange}></Tab>
                    <Tab label="Subscriptions" tabKey="subscriptions" onClick={onTabChange}></Tab>
                    <Tab label="Details" tabKey="details" onClick={onTabChange}></Tab>
                  </Tabs>
                )}
                <div>
                  <IconButton className="close-button" icon="close" onClick={() => setResponseId(undefined)} />
                </div>
              </div>
              {tabContent[tabContentKey]}
            </div>
          </div>
        </div>
      </th>
    </tr>
  );
};

export default ResponseDetail;
