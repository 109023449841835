import EXTENSIONS_ICONS, { Icons } from 'components/Icons/ExtensionsIcons';

export type MapExtensionType = {
  [key: string]: Icons;
};

const mapExtension: MapExtensionType = {
  jpg: 'image',
  jpeg: 'image',
  png: 'image',
  svg: 'image',
  gif: 'image',
  webp: 'image',
  heic: 'image',
  mov: 'video',
  avi: 'video',
  mp4: 'video',
  wmv: 'video',
  flv: 'video',
  '3gp': 'video',
  '7z': 'zip',
  webm: 'zip',
  zip: 'zip',
  rar: 'zip',
  xml: 'text',
  txt: 'text',
  html: 'text',
  mp3: 'music',
  midi: 'music',
  aac: 'music',
  csv: 'excel',
  xls: 'excel',
  xlsx: 'excel',
  numbers: 'excel',
  ppt: 'ppt',
  pptx: 'ppt',
  key: 'ppt',
  pdf: 'pdf',
  doc: 'word',
  pages: 'word',
  docx: 'word',
};

const getIconByExtension = (extension: string) => {
  const icon = mapExtension[extension.toLowerCase()] || 'undefined';

  return EXTENSIONS_ICONS[icon];
};

export default getIconByExtension;
