import React, { FC, useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'wouter';
import { useQuery } from '@apollo/client';
import { default as queryString } from 'query-string';
import * as MemberQuery from 'graphql/member.graphql';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFirstOrganization from 'hooks/useFirstOrganization';
import useTransactions from 'hooks/useTransactions';
import useSubscriptions from 'hooks/useSubscriptions';
import UserRolePerspective from 'contexts/UserRolePerspective';
import Header from './Header';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import Transactions from './Transactions';
import Subscriptions from './Subscriptions';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { GetMember, GetMemberByOrg } from 'api/data/user/types';
import './style.scss';

type MemberParams = { userId?: string };

const MemberProfile: FC<RouteComponentProps<MemberParams>> = ({ params }) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const [tabContentKey, setTabContentKey] = useState('payments');
  const { currentOrg } = useCurrentOrganization();
  const { firstOrganizationData } = useFirstOrganization();
  const { transactions } = useTransactions(params?.userId);
  const { subscriptions, refetch: refetchSubscription } = useSubscriptions(params?.userId);
  const { data: memberByUser } = useQuery<GetMember>(MemberQuery.GetMember, {
    skip: !isPayerView,
  });
  const { data: memberByOrg } = useQuery<GetMemberByOrg>(MemberQuery.GetMemberByOrg, {
    variables: { id: params.userId },
    skip: isPayerView,
  });

  const organization = currentOrg || firstOrganizationData;
  const memberData = memberByUser?.user || memberByOrg?.userByOrg;
  const payer = memberData;
  const spaceSlug = currentSpaceSlug() || '';

  const paymentTransactions = transactions?.filter(transaction => transaction.type === 'PAYMENT');
  const refundTransactions = transactions?.filter(transaction => transaction.type === 'REFUND');

  useEffect(() => {
    const cancelationRequestFor = queryString.parse(window.location.search).cancelationRequestFor;
    if (cancelationRequestFor) {
      setTabContentKey('subscriptions');
    }
  }, [setTabContentKey]);

  return (
    <LoggedPageContainer
      className="member-profile-page"
      headerChildren={
        <Header
          pageTitle={`${payer?.fullName || ''}'s Profile`}
          pageSubtitle={`This list contains all payments and subscriptions made from ${payer?.email || ''}`}
          backLocation={organization ? `/${spaceSlug}/pages` : undefined}
          payer={payer}
        />
      }
      organizationStatus={organization?.status}
      fixedHeaderContent={
        <Tabs activeKey={tabContentKey}>
          <Tab label={`${paymentTransactions?.length || 0} Payments`} tabKey="payments" onClick={setTabContentKey} />
          <Tab
            label={`${subscriptions?.length || 0} Subscriptions`}
            tabKey="subscriptions"
            onClick={setTabContentKey}
          />
        </Tabs>
      }>
      {tabContentKey === 'payments' ? (
        <Transactions
          transactionsByUser={paymentTransactions}
          refundTransactions={refundTransactions}
          userId={params?.userId}
        />
      ) : (
        <Subscriptions
          subscriptions={subscriptions}
          onSubscriptionsChanged={refetchSubscription}
          transactionsByUser={paymentTransactions}
          userId={params?.userId}
        />
      )}
    </LoggedPageContainer>
  );
};

export default MemberProfile;
