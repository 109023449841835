import { NumberFormatValues } from 'react-number-format';

const MIN_VAL = 1;

const isValidAmount = (inputObj: NumberFormatValues): boolean => {
  const floatValue = inputObj.floatValue || 0;

  return floatValue === 0 || floatValue >= MIN_VAL;
};

export default isValidAmount;
