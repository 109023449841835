import React, { FC } from 'react';
import { currencyFormatter, hasPaymentPlanInstallments } from 'utils';
import ICONS from 'components/Icons';
import PaymentPlanTooltip from 'components/PaymentBlock/PaymentPlanTooltip';
import { OrderItem } from 'api/data/response/types';

interface OrderItemType {
  orderItem: OrderItem;
  occurrence?: number;
}

export const OrderItemDetail: FC<OrderItemType> = ({ orderItem, occurrence }) => {
  const hasPaymentPlan =
    orderItem.recurring !== 'ONCE' && hasPaymentPlanInstallments(orderItem.variant.paymentPlanInstallments);

  return (
    <div className="order-item">
      <div>
        <div>
          <span>{orderItem.blockTitle}</span>
          <span className="order-item-title">{orderItem.variant.title}</span>
        </div>
        {orderItem.variant.quantity > 1 ? (
          <div className="order-item-variant-quantity">{`Qty ${orderItem.variant.quantity}`}</div>
        ) : null}
      </div>
      <div className="amount-recurring-box">
        <div>
          {orderItem.recurring !== 'ONCE' && (
            <span className="event-recurring">
              {hasPaymentPlan ? <PaymentPlanTooltip /> : ICONS['recurring']}
              {orderItem.variant.paymentPlanInstallments === -1 ? orderItem.recurring : ''}
            </span>
          )}
          <span className="event-amount">{currencyFormatter(orderItem?.amount || 0)}</span>
        </div>
        {hasPaymentPlan && !!occurrence && (
          <div>
            Payment {occurrence} of {orderItem.variant.paymentPlanInstallments}
          </div>
        )}
      </div>
    </div>
  );
};
