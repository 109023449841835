import { ApolloLink, Operation, ReactiveVar, makeVar } from '@apollo/client';
import { OperationDefinitionNode, Kind } from 'graphql';

// This is where we'll store currentSpaceSlug in the app.
// Every code that needs, should use it from here.
// if you need organization details, refer to useCurrentOrganization() hook
export const currentSpaceSlug: ReactiveVar<string | null> = makeVar<string | null>(null);

// This middleware adds the currentSpace variable to all queries that have it defined
// if the currentSpaceSlug is available in the reactive var.
// It's to be in the middleware chain of ApolloClient.
export const currentSpaceMiddleware = new ApolloLink((operation, forward) => {
  const currentSpace = shouldAddCurrentSpace(operation);
  if (currentSpace) operation.variables.currentSpace = currentSpace;

  // this is so useful for debugging that I'm leaving it here as a suggestion
  // console.log('>>>', operation);

  return forward(operation).map(data => {
    // data is what the server returned. It can be useful for debugging.
    // console.log('<<<', data);

    if (currentSpace) {
      // if we added the currentSpace variable, then we remove it back
      // to avoid confusing apollo regarding cache. Otherwise apollo will cache
      // results regarding the variable, and will not identify the correctly
      // when refetching.
      //
      // The motivating symptom here were that copyPage mutation was causing PageQuery
      // to be refetched, but the page didn't update, once apollo thought the page
      // did the query without the var, but what returned here was with the var.
      //
      // If it causes problems again, maybe it's worth to try other solutions on
      // cache configuration: https://www.apollographql.com/docs/react/caching/cache-configuration/
      delete operation.variables['currentSpace'];
    }
    return data;
  });
});

const shouldAddCurrentSpace = (operation: Operation) => {
  const opDefinition = operation.query.definitions.find(
    definition => definition.kind === Kind.OPERATION_DEFINITION,
  ) as OperationDefinitionNode;
  const currentSpaceDefinition = opDefinition?.variableDefinitions?.find(
    definition => definition.variable.name.value === 'currentSpace',
  );
  return !!currentSpaceDefinition && !operation.variables.currentSpace && currentSpaceSlug();
};
