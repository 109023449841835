import React, { FC, useState, useEffect } from 'react';
import { useLocation, useParams } from 'wouter';
import { useMutation, useQuery } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import clsx from 'clsx';
import queryString from 'query-string';
import * as PageQuery from 'graphql/page.graphql';
import {
  defaultDonationBlock,
  defaultSupporterFeedBlock,
  defaultPageTitle,
  defaultTextBlock,
  emptyPage,
} from 'defaults/page';
import createEditorStateWithText from '~/components/RichTextEditor/CustomEditor/utils/createEditorStateWithText';
import { validateDates } from 'components/Editor/AddDatesModal';
import { buildBlockItem, mapBlocks, mapPage } from 'api/data/pages';
import Steps from 'components/Steps';
import DatesGoal from './DatesGoal';
import Editor from './Editor';
import IconButton from 'components/Button/IconButton';
import Setup from './Setup';
import { DonationBlock } from 'components/DonationBlock/types';
import { PageDataDraftBlock, PageView } from 'api/data/pages/types';
import { ValueParam } from './types';
import './style.scss';

const steps = [{ title: '' }, { title: '' }, { title: '' }];

export const updateFundraiserSpec = (page: PageDataDraftBlock) => {
  const hasProgramName = Boolean(page.p2pProgramName?.trim());
  const defaultText = `${page.p2pOrganizationName?.trim()}${hasProgramName ? ` ${page.p2pProgramName?.trim()}` : ''}`;
  const blocks = page.blocks.map(item => {
    const block = buildBlockItem(item);
    if ((item.type === 'DONATION' || item.type === 'PAYMENT') && item.goal?.amountCents) {
      return {
        ...block,
        goal: null,
      };
    }

    return block;
  });
  const donationIdx = blocks.findIndex(block => block.type === 'DONATION');

  if (!blocks.some(block => block.type === 'SUPPORTER_FEED')) {
    const supportersFeed = defaultSupporterFeedBlock();
    blocks.splice(donationIdx + 1, 0, supportersFeed);
  }

  const goal = page.goal?.target ? { id: uuidv4(), amountCents: page.goal.target } : null;
  if (donationIdx > -1) {
    const donationBlock = blocks[donationIdx] as DonationBlock;
    blocks[donationIdx] = {
      ...donationBlock,
      goal,
    };
  } else {
    const donationBlock: DonationBlock = {
      ...defaultDonationBlock(),
      goal,
    };
    blocks.unshift(donationBlock);
  }

  const textBlock = defaultTextBlock(
    createEditorStateWithText(
      `Thank you for your generous contribution to${hasProgramName ? ' the' : ''} ${defaultText}!`,
    ),
  );

  blocks.unshift(textBlock);

  const newPage = {
    ...page,
    blocks,
    p2pEnabled: true,
    title: page.title !== defaultPageTitle ? page.title : `${defaultText} fundraiser`,
  };

  return newPage;
};

const EnableTeam: FC = () => {
  const { id, currentSpace } = useParams();
  const [location, setLocation] = useLocation();
  const [page, setPage] = useState<PageDataDraftBlock>(emptyPage(id || ''));
  const { data, loading: loadingPage } = useQuery<PageView>(PageQuery.GetPage, {
    variables: { id },
    fetchPolicy: 'network-only',
  });
  const [updatePage, { loading: savePageLoading }] = useMutation(PageQuery.SavePage, {
    refetchQueries: [PageQuery.GetPage],
  });
  const validDates = validateDates(page.startDate, page.endDate);

  const [currentStep, setCurrentStep] = useState(0);

  useEffect(() => {
    if (!loadingPage && data) {
      const { page } = data;
      setPage({
        ...page,
        startDate: page.startDate ? new Date(page.startDate) : null,
        endDate: page.endDate ? new Date(page.endDate) : null,
      });

      if (page.p2pEnabled) setLocation(location.replace('enable', ''));
    }
  }, [loadingPage, data, setLocation, location]);

  const handleOnFieldChange = (field: string, value: ValueParam) => {
    setPage(prev => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleSteps = async () => {
    switch (currentStep) {
      case 1:
        setPage(updateFundraiserSpec({ ...page, blocks: data?.page.blocks || page.blocks }));
        setCurrentStep(currentStep + 1);
        break;

      case 2: {
        const updatedPage = {
          ...mapPage(page),
          blocks: page.blocks.map(mapBlocks),
          id: page.id,
          p2pEnabled: true,
          p2pOrganizationName: page.p2pOrganizationName,
          p2pProgramName: page.p2pProgramName,
          p2pReferToMeAs: page.p2pReferToMeAs,
        };
        await updatePage({ variables: { page: updatedPage } });
        setLocation(`/${currentSpace}/pages/${id}/team/invite`);
        break;
      }

      default:
        setCurrentStep(currentStep + 1);
    }
  };

  const goBack = () => {
    const fromTemplate = queryString.parse(window.location.search).fromTemplate;
    currentStep === 0
      ? setLocation(`/${currentSpace}/pages${fromTemplate ? '' : `/${id}/team`}`)
      : setCurrentStep(currentStep - 1);
  };

  const componentsSteps = [
    <Setup key={0} handleOnFieldChange={handleOnFieldChange} {...page} />,
    <DatesGoal key={1} handleOnFieldChange={handleOnFieldChange} {...page} />,
    <Editor key={3} page={page} setPage={setPage} />,
  ];

  if (loadingPage || data?.page.p2pEnabled) return <div>Loading...</div>;

  return (
    <form
      onSubmit={ev => {
        ev.preventDefault();
        void handleSteps();
      }}
      className="page-default enable-team">
      <header className="row space-between">
        <div className="row align-center">
          <IconButton icon="arrow_left" className="back-button tertiary" onClick={goBack}>
            Back
          </IconButton>
          <p className="header-title subtitle-small">Enable Team Fundraising</p>
        </div>
        <Steps current={currentStep} items={steps}></Steps>
        <div className="btn-container">
          <button
            type="submit"
            disabled={
              savePageLoading || !page.p2pReferToMeAs?.trim() || !page.p2pOrganizationName?.trim() || !validDates
            }>
            {currentStep < 2 ? 'Next' : 'Finish'}
          </button>
        </div>
      </header>
      <div className={clsx('content', { editor: currentStep === 2 })}>{componentsSteps[currentStep]}</div>
    </form>
  );
};

export default EnableTeam;
