import React, { FC, useState, useRef, ReactNode } from 'react';
import uploadFile from 'api/uploadClient';
import { onImageInputClick } from 'utils';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import UploadImageWrapper from 'components/UploadImage';
import './style.scss';

type ImageInBlockProps = {
  imageUrl?: string;
  handleOnChange: (value: string | string[], field: string) => void;
  children: ReactNode;
};

const ImageInBlock: FC<ImageInBlockProps> = ({ imageUrl, handleOnChange, children }) => {
  const [imageUploading, setImageUploading] = useState('');

  const refInside = useRef<HTMLElement>(null);
  const imageInput = useRef<HTMLInputElement>(null);
  const refImages = useRef<HTMLDivElement>(null);

  const removeImage = () => {
    handleOnChange('', 'image');
  };

  const handleOnUploadImage = async (file: File, attr: string) => {
    const uploadResponse = await uploadFile(file, 'IMAGE', attr);
    handleOnChange([uploadResponse.file_handle, uploadResponse.url], attr);
  };

  return (
    <div className="input-block-image-container">
      <div className="title-container">
        {children}
        <section ref={refInside} className="container image-button-container">
          {!imageUrl && !imageUploading && (
            <IconButton icon="image_upload_block" onClick={() => onImageInputClick(imageInput)} />
          )}
          {!!imageUploading && <span className="loading"> {ICONS['spinner']} Uploading </span>}
        </section>
      </div>
      <div ref={refImages} className="upload-wrapper">
        <UploadImageWrapper
          image={{
            uploadIcon: 'image_upload_block',
            name: 'image',
            label: 'image',
            file: imageUrl || '',
            ref: imageInput,
          }}
          imageUploading={imageUploading}
          handleOnRemoveImage={removeImage}
          handleOnUploadImage={handleOnUploadImage}
          setImageUploading={setImageUploading}
        />
      </div>
    </div>
  );
};

export default ImageInBlock;
