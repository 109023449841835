import React, { FC, useState, useEffect, useCallback, useRef } from 'react';
import { useLocation } from 'wouter';
import clsx from 'clsx';
import QRCodeStyling from 'qr-code-styling';
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import * as ReportsQuery from 'graphql/reports.graphql';
import qrCodeDefault from 'defaults/qrCode';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { downloadFile, extractQueryStringFromParams } from 'utils';
import ICONS from '../Icons';
import IconButton from 'components/Button/IconButton';
import Message, { MessageType } from 'components/Message';
import ParticipantCard from './ParticipantCard';
import TeamEmptyState from 'components/Team/EmptyState';
import Tooltip from 'components/Tooltip';
import { PageDataDraftBlock, Participant } from 'api/data/pages/types';
import { ParticipantsReportDownload } from '~/api/data/response/types';
import './style.scss';

type TeamProps = {
  page?: PageDataDraftBlock;
};

type ParticipantState = { [key: string]: Participant[] };

const TIER_MAP = [
  { id: 'INVITED', title: 'Invited', className: 'invited', tooltip: `Click "Add Participants" to invite more` },
  {
    id: 'REGISTERED',
    title: 'Registered',
    className: 'registered',
    tooltip: 'Encourage these folks to invite donors!',
  },
  { id: 'PARTIAL_EFFORT', title: 'Partial Effort', className: 'partial-effort', tooltip: 'Invited at least one donor' },
  { id: 'TOP_PERFORMER', title: 'Top Performer', className: 'top-performer', tooltip: 'Invited 20+ donors!' },
];

const qrCode = new QRCodeStyling({
  ...qrCodeDefault,
  width: 111,
  height: 111,
  type: 'svg',
  margin: 0,
  imageOptions: { ...qrCodeDefault.imageOptions, margin: 2 },
  backgroundOptions: { color: '#FAFAFA' },
});

const Team: FC<TeamProps> = ({ page }) => {
  const [toastContent, setToastContent] = useState({
    show: false,
    message: '',
    type: 'succeeded' as MessageType,
  });
  const [participantsByTier, setParticipantByTier] = useState<ParticipantState>({});
  const [maxCardsQty, setMaxCardsQty] = useState(0);
  const [location, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug() || '';

  const firstRender = useRef(true);
  const {
    data: participantsData,
    loading,
    refetch,
  } = useQuery<{ participants: Participant[] }>(PageQuery.GetParticipantsWithoutGoal, {
    variables: { pageId: page?.slug, perspective: 'organizer' },
    skip: !page?.slug,
  });

  const [downloadReportQuery, { loading: loadingDownload }] = useLazyQuery<ParticipantsReportDownload>(
    ReportsQuery.ParticipantsReportDownload,
    {
      onCompleted: data => {
        downloadFile('report.csv', data.participantsReportDownload.content);
      },
      fetchPolicy: 'network-only',
    },
  );

  const downloadReport = () => {
    void downloadReportQuery({
      variables: { pageId: page?.id, currentSpace: spaceSlug },
    });
  };

  useSubscription<{ participantStatsChanged: Participant[] }>(PageQuery.ParticipantStatsChanged, {
    variables: { pageId: page?.id, currentSpace: spaceSlug },
    skip: !page?.id,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const participantsChanged = subscriptionData.data?.participantStatsChanged;
      if (participantsChanged) {
        const mergedMap = [...(participantsData?.participants || []), ...participantsChanged].reduce(
          (acc, item) => {
            acc[item.id] = item;
            return acc;
          },
          {} as { [key: string]: Participant },
        );

        const mergedArray = Array.from(Object.values(mergedMap));
        client.writeQuery({
          query: PageQuery.GetParticipantsWithoutGoal,
          data: {
            participants: mergedArray,
          },
          variables: { pageId: page?.slug, perspective: 'organizer' },
        });
      }
    },
  });

  const qrCodeRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (node !== null) {
        qrCode.update({ data: `${window.location.origin}/${page?.slug}/p/signup` });
        qrCode.append(node);
      }
    },
    [page?.slug],
  );

  useEffect(() => {
    if (participantsData?.participants) {
      let counter = 0;
      const participantMap = participantsData?.participants.reduce(
        (acc, participant) => {
          acc[participant.computedEffortStage].push(participant);
          counter = Math.max(acc[participant.computedEffortStage].length, counter);

          return acc;
        },
        { INVITED: [], REGISTERED: [], PARTIAL_EFFORT: [], TOP_PERFORMER: [] } as ParticipantState,
      );

      setMaxCardsQty(counter);
      setParticipantByTier(participantMap);
    }
  }, [participantsData?.participants]);

  useEffect(() => {
    const error = extractQueryStringFromParams('error');
    const success = extractQueryStringFromParams('success');

    if (error) {
      setToastContent({
        show: true,
        message: 'Something went wrong. Please try reloading this page and try again.',
        type: 'failed',
      });
      setLocation(location);
    }
    if (success) {
      setToastContent({ show: true, message: 'Member has been removed!', type: 'succeeded' });
      setLocation(location);
    }
  }, [location, setLocation, setToastContent]);

  useEffect(() => {
    if (firstRender.current && page?.id) {
      void refetch();
      firstRender.current = false;
    }
  }, [firstRender, page?.id, refetch]);

  if (loading || !page || !participantsData) {
    return <>Loading...</>;
  }

  const { participants } = participantsData;

  const csvProps = {
    icon: loadingDownload ? 'spinner' : 'download_file',
    disabled: loadingDownload,
    onClick: () => void downloadReport(),
  };

  return (
    <div className="team-members">
      {page.p2pEnabled ? (
        <>
          <Message
            className="team-members-toast"
            type={toastContent.type}
            showMessage={toastContent.show}
            setShowMessage={visible => setToastContent({ ...toastContent, show: visible })}>
            {toastContent.message}
          </Message>
          <div className="row space-between participants-header">
            <div className="participants-info">
              <p className="subtitle-xx-small">{page?.p2pOrganizationName}</p>
              <p className="subtitle-x-small">{page?.p2pProgramName}</p>
            </div>
            <div className="row direction-column align-center qr-code">
              <div>
                <div ref={qrCodeRef} />
              </div>
              <span className="size-xxxxs">Join the team!</span>
            </div>
          </div>
          <div className="row space-between align-center">
            <h1>{`Participants (${participants?.length || ''})`}</h1>
            <div className="row">
              <Tooltip title="Download CSV" color="dark">
                <IconButton {...csvProps} className={loadingDownload ? 'loading' : ''} />
              </Tooltip>
              <button
                className="button-size-ml"
                onClick={() => setLocation(`/${spaceSlug}/pages/${page?.slug}/team/invite`)}>
                Add Participants
              </button>
            </div>
          </div>
          <div className="board">
            {TIER_MAP.map(tier => {
              const participantsByColumn = participants?.filter(
                participant => participant.computedEffortStage === tier.id,
              );
              const participantsLength = participantsByColumn?.length || 0;
              const lastItemBoard = maxCardsQty === participantsLength;

              return (
                <div key={tier.id}>
                  <div className="row subtitle-x-small">
                    {tier.title} ({participantsLength})
                    <Tooltip color="dark" className="row align-center" title={tier.tooltip} ariaLabel={tier.tooltip}>
                      {ICONS['solid_help']}
                    </Tooltip>
                  </div>
                  <div className="column">
                    <div className={clsx(tier.className, { 'has-last-item': lastItemBoard })}>
                      {participantsByTier[tier.id]?.map((participant, index) => {
                        const lastItem = index === participantsLength - 1;

                        return (
                          <ParticipantCard
                            key={participant.id}
                            participant={participant}
                            classNames={clsx({
                              'last-item': lastItem,
                              'first-item': index === 0,
                              'last-max-item': lastItem && lastItemBoard,
                            })}
                            link={`/${spaceSlug}/pages/${page?.slug}/team/${participant.slug}`}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      ) : (
        <TeamEmptyState p2pEnabled={page?.p2pEnabled} />
      )}
    </div>
  );
};

export default Team;
