import React, { FC, TextareaHTMLAttributes, DetailedHTMLProps } from 'react';
import clsx from 'clsx';
import Wrapper from './Wrapper';
import { WrapperProps } from './Wrapper';
import './style.scss';

type TextAreaProps = WrapperProps & DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>;

const TextArea: FC<TextAreaProps> = ({
  name,
  label,
  className,
  classNameLabel,
  hint,
  rules,
  labelTooltip,
  ariaLabelTooltip,
  ...inputProps
}) => {
  return (
    <Wrapper
      label={label}
      name={name}
      className={clsx(className, 'textarea')}
      classNameLabel={classNameLabel}
      hint={hint}
      rules={rules}
      labelTooltip={labelTooltip}
      ariaLabelTooltip={ariaLabelTooltip}>
      <textarea {...inputProps} className="default" name={name} id={name} />
    </Wrapper>
  );
};

export default TextArea;
