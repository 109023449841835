import React, { FC, SyntheticEvent, useState } from 'react';
import { Frames, CardNumber, ExpiryDate, Cvv } from 'frames-react';
import { APP_CONFIG } from 'api/config';
import { createSetupIntent } from '~/api/data/payment';
import ZipInput from './ZipInput';
import { CardProps } from './types';
import { PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import './style.scss';

const CARD_OPTIONS = {
  publicKey: APP_CONFIG.CHECKOUT,
  localization: {
    cardNumberPlaceholder: '1234 1234 1234 1234',
    expiryMonthPlaceholder: 'MM',
    expiryYearPlaceholder: 'YY',
    cvvPlaceholder: 'CVC',
  },
  style: {
    base: {
      fontSize: '14px',
      color: '#555',
      height: 'auto',
      letterSpacing: 'normal',
      fontFamily: 'Arial',
    },
    placeholder: {
      base: {
        color: '#bfbfbf',
        fontFamily: 'Arial',
      },
    },
  },
};

const Checkout: FC<CardProps> = ({
  response,
  handleResponseUser,
  setLoading,
  setErrorMessage,
  onSubmit,
  showButton,
  buttonText,
  loading,
  client,
  handleOnBlur,
}) => {
  const [token, setToken] = useState('');

  const submitCardGetToken = async (event: SyntheticEvent) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      let localToken = token;

      if (!token) {
        Frames.enableSubmitForm();
        const { token: checkoutToken } = await Frames.submitCard();
        setToken(checkoutToken);
        localToken = checkoutToken;
      }

      const { paymentMethodId } = await createSetupIntent(
        {
          email: response?.user?.email || '',
          responseId: response.id || '',
          token: localToken,
        },
        client,
      );

      await onSubmit({
        paymentMethodId,
        paymentMethodType: PaymentMethodType.CARD,
        response,
      });

      setLoading(false);
    } catch (error) {
      if (typeof error === 'string') setErrorMessage(error);
      else {
        setErrorMessage((error as PaymentErrorType).message);
        setToken('');
      }
      setLoading(false);
    }
  };

  const props = {
    className: 'input-default',
  };

  return (
    <form onSubmit={event => void submitCardGetToken(event)} className="payment-method" aria-label="credit card form">
      <Frames
        config={{
          ...CARD_OPTIONS,
          cardholder: {
            name: response.user?.fullName,
          },
        }}>
        <span>Card Number</span>
        <CardNumber {...props} />
        <div className="row credit-card-elements">
          <div className="col-4">
            <span>Expiry</span>
            <ExpiryDate {...props} />
          </div>
          <div className="col-4">
            <span>CVC</span>
            <Cvv {...props} />
          </div>
          <div className="col-4">
            <ZipInput
              handleResponseUser={handleResponseUser}
              zipcode={response.user?.zipcode}
              handleOnBlur={handleOnBlur}
            />
          </div>
        </div>
      </Frames>
      <button type="submit" disabled={showButton}>
        {loading ? 'Processing...' : buttonText}
      </button>
    </form>
  );
};

export default Checkout;
