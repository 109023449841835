import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import useCurrentOrganization from './useCurrentOrganization';

export default function useSpacesFromCurrentOrg() {
  const spaceSlug = currentSpaceSlug();
  const result = useCurrentOrganization({ fetchPolicy: 'network-only' });

  const spaces = result.data?.organization?.spaces || result.previousData?.organization?.spaces || [];
  const currentSpace = spaces?.find(space => space.slug === spaceSlug);

  return { ...result, spaces: spaces, currentSpace: currentSpace };
}
