import React, { FC } from 'react';
import CopyButton from 'components/Button/CopyButton';
import SocialSharing, { ShareType } from 'components/SocialSharing';
import goToIcon from 'assets/go-to.svg';
import './style.scss';

type ShareByLinkProps = {
  params: string;
  title: string;
  shareType: ShareType;
  message?: string;
  emailBcc?: string;
  p2pReferToMeAs?: string;
};

const ShareByLink: FC<ShareByLinkProps> = ({ params, title, shareType, message, emailBcc, p2pReferToMeAs }) => {
  const url = `${window.location.origin}/${params}`;
  const messageWithUrl = message ? `${message}${url}` : undefined;

  return (
    <div className="share-by-link">
      <div className="row align-center link">
        <a href={`/${params}`} className="paragraph-x-small" target="_blank" rel="noreferrer">
          {`${window.location.host}/${params}`}
          <img src={goToIcon} alt="go to page" />
        </a>
        <CopyButton link={messageWithUrl || url} className="copy-button secondary" buttonText="Copy" />
      </div>
      <SocialSharing
        url={url}
        title={title}
        shareType={shareType}
        message={message}
        emailBcc={emailBcc}
        p2pReferToMeAs={p2pReferToMeAs}
      />
    </div>
  );
};

export default ShareByLink;
