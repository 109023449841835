import { useState, useCallback } from 'react';

export default function useToggle(initialState = false): [boolean, (nextValue?: boolean) => void] {
  const [value, setValue] = useState(initialState);

  const toggle = useCallback((nextValue?: boolean) => {
    if (typeof nextValue === 'boolean') {
      setValue(nextValue);
    } else {
      setValue(v => !v);
    }
  }, []);

  return [value, toggle];
}
