import React, { FC, useState, useRef } from 'react';
import { getRecurringName, hasPaymentPlanInstallments, numberCentsFormatter } from 'utils';
import { recurringOptionsArray } from 'defaults/page';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import { RecurringValue } from 'defaults/page';
import { PaymentOption } from '../types';
import './style.scss';

interface CustomFrequencyModalProps {
  handleOnChange: (value: Partial<PaymentOption>) => void;
  setShowModal: (show: boolean) => void;
  variantAmount?: number;
  paymentRecurring?: RecurringValue;
  variantInstallments: number;
}

const MAX_NUMBER_OF_PAYMENTS = 18;
const paymentPlanOptions = Array.from({ length: MAX_NUMBER_OF_PAYMENTS - 1 }, (_, i) => i + 2);
const DEFAULT_PAYMENT_PLAN = 4;

const CustomFrequencyModal: FC<CustomFrequencyModalProps> = ({
  setShowModal,
  handleOnChange,
  paymentRecurring,
  variantAmount,
  variantInstallments,
}: CustomFrequencyModalProps) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const hasInstallments = hasPaymentPlanInstallments(variantInstallments);
  const [showPaymentPlan, setShowPaymentPlan] = useState(hasInstallments);
  const [recurring, setRecurring] = useState<RecurringValue>(paymentRecurring || 'ONCE');

  const calculateTotal = (amountCents: number, installments: number) =>
    numberCentsFormatter(amountCents * installments);

  const [paymentPlanInstallments, setPaymentPlanInstallments] = useState(variantInstallments);

  const handleSave = () => {
    const paymentOption = {
      recurring,
      paymentPlanInstallments: recurring === 'ONCE' ? -1 : paymentPlanInstallments,
    };

    handleOnChange(paymentOption);
    setShowModal(false);
  };

  const handleOnClickInstallments = (installments: number) => {
    setPaymentPlanInstallments(installments);
  };

  const handleTogglePaymentPlan = () => {
    setShowPaymentPlan(!showPaymentPlan);
    if (!showPaymentPlan) {
      setPaymentPlanInstallments(DEFAULT_PAYMENT_PLAN);
    }
  };

  const paymentPlanInstallmentsTotal = (installments: number) => {
    return (
      !!variantAmount && variantAmount > 0 && <span>Total: $ {calculateTotal(variantAmount || 0, installments)}</span>
    );
  };

  return (
    <Modal
      modalRef={modalRef}
      handleOnCancel={() => setShowModal(false)}
      handleOnConfirm={handleSave}
      header="Custom frequency"
      headerIcon="recurring"
      confirmlabel="Save change"
      denyLabel="Cancel"
      visible
      className="custom-frequency-modal"
      fullScreenMobile>
      <div className="modal-body">
        <p>Frequency</p>
        <DropDown
          outsideRef={modalRef}
          openedIcon="arrow_up"
          closedIcon="arrow_down"
          buttonChildren={getRecurringName(recurring)}
          iconSide="RIGHT">
          {recurringOptionsArray.map(recurringOption => (
            <DropDownItem
              key={recurringOption.id}
              onClick={() => {
                setRecurring(recurringOption.id);
              }}
              isSelected={recurring === recurringOption.id}>
              {recurringOption.noun}
            </DropDownItem>
          ))}
        </DropDown>
        {recurring !== 'ONCE' && (
          <div className="payment-plan">
            <ToggleSwitch
              label="Ending after"
              labelPosition="after"
              toggleValue={showPaymentPlan}
              handleOnChange={handleTogglePaymentPlan}
            />
            {showPaymentPlan && (
              <div className="payment-dropdown">
                <DropDown
                  outsideRef={modalRef}
                  openedIcon="arrow_up"
                  closedIcon="arrow_down"
                  buttonChildren={`${paymentPlanInstallments} payments`}
                  iconSide="RIGHT">
                  {paymentPlanOptions.map(installments => (
                    <DropDownItem
                      key={installments}
                      onClick={() => handleOnClickInstallments(installments)}
                      isSelected={paymentPlanInstallments === installments}>
                      <div className="payment-plan-item">
                        <span>{installments} payments</span>
                        {paymentPlanInstallmentsTotal(installments)}
                      </div>
                    </DropDownItem>
                  ))}
                </DropDown>
                {paymentPlanInstallmentsTotal(paymentPlanInstallments)}
              </div>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CustomFrequencyModal;
