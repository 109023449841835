import React, { FC, ReactNode } from 'react';
import { Link } from 'wouter';
import clsx from 'clsx';
import { TooltipEllipsis } from 'components/Tooltip';
import { User } from 'api/data/user/types';
import arrowLeft from 'assets/arrow_left.svg';

interface HeaderProps {
  pageTitle: string;
  pageSubtitle: string;
  backLocation?: string;
  payer?: User;
  children?: ReactNode;
}

const Header: FC<HeaderProps> = ({ pageTitle, pageSubtitle, backLocation, payer }) => {
  return (
    <div className="row space-between second-header">
      <div className="page-title">
        <div className="page-actions">
          {backLocation && (
            <Link href={backLocation} className="link-back">
              <img src={arrowLeft} alt="back icon" />
            </Link>
          )}
          {payer && <TooltipEllipsis title={pageTitle} className="profile-page-title" />}
        </div>
        {payer && <span className={clsx({ 'without-back-location': !backLocation })}>{pageSubtitle}</span>}
      </div>
    </div>
  );
};

export default Header;
