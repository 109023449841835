import { SetupIntentResult } from '@stripe/stripe-js';
import { Card, Response } from '../response/types';

export type PaymentErrorType = {
  message: string;
};

export interface ProcessPayment {
  processPayment: {
    status:
      | 'CANCELED'
      | 'PROCESSING'
      | 'REQUIRES_ACTION'
      | 'REQUIRES_CAPTURE'
      | 'REQUIRES_CONFIRMATION'
      | 'REQUIRES_PAYMENT_METHOD'
      | 'SUCCEEDED';
    response: Response;
    failureMessage?: string;
  };
}

export type ProcessPaymentData = {
  paymentMethodId?: string;
  setupIntentResult?: SetupIntentResult;
  customerId?: string;
  paymentMethodType: PaymentMethodType;
  financialConnectionsAccountId?: string;
  response: Response;
};

export const enum PaymentMethodType {
  ACH = 'ACH',
  CARD = 'CARD',
  WALLET = 'WALLET',
  OFFLINE = 'OFFLINE',
}

export type OnSubmitFunction = (processPaymentData: ProcessPaymentData) => Promise<void>;

export type PaymentErrorProps = {
  title: string;
};

export interface BankAccount {
  bankName: string;
  last4: string;
  accountType: string;
}

export interface SetupIntent {
  createSetupIntent: {
    clientSecret: string;
    customerId: string;
    paymentMethodId: string;
    card: Card;
  };
}
export interface SetupIntentInput {
  email: string;
  responseId: string;
  token?: string;
  card?: {
    last4?: string;
    brand?: string;
    exp_month?: number;
    exp_year?: number;
    holder?: string;
  };
}
