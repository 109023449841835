import React, { FC, ReactNode } from 'react';
import { getRecurringName, hasPaymentPlanInstallments, numberCentsFormatter } from 'utils';
import ICONS from 'components/Icons';
import { OrderItem } from 'api/data/response/types';
import { DonationBlock } from 'components/DonationBlock/types';
import { PaymentBlock } from 'components/PaymentBlock/types';
import { BlockState } from 'api/data/pages/blocks/types';
import './style.scss';

interface OrderItemDetailsProps {
  block?: BlockState;
  orderItem: OrderItem;
  showRecurring?: boolean;
  children?: ReactNode;
}

const OrderItemDetails: FC<OrderItemDetailsProps> = ({
  block,
  orderItem,
  showRecurring = true,
  children,
}: OrderItemDetailsProps) => {
  const blockType = orderItem.blockType?.toUpperCase();
  const blockIcon = blockType === 'DONATION' ? ICONS['heart_money'] : ICONS['money'];
  const blockImage = (block as PaymentBlock | DonationBlock)?.imageUrl || orderItem.imageUrl;
  const title = blockType === 'DONATION' ? 'Donation' : 'Payment';

  return (
    <div className="order-item-details">
      {blockImage ? <img className="block-image" src={blockImage} alt={`${blockType} block`} /> : blockIcon}
      <div className="description-container">
        <div className="row space-between sub-title">
          <span className="overflow-wrap-anywhere">
            <span>{orderItem.blockTitle || orderItem.block.title || title}</span>
            <span className="text">{orderItem.variantTitle}</span>
            <br />
            {children || <span className="text">Qty {orderItem.variant.quantity}</span>}
          </span>
          <span className="amount-container">
            <strong>${numberCentsFormatter(orderItem.amount || 0)}</strong>
            {showRecurring && (
              <>
                <span className="recurring-name">
                  {orderItem.recurring !== 'ONCE' && `/${getRecurringName(orderItem.recurring)}`}
                </span>
                <br />
                {hasPaymentPlanInstallments(orderItem.variant.paymentPlanInstallments) && (
                  <span className="text">for {orderItem.variant.paymentPlanInstallments} payments</span>
                )}
              </>
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrderItemDetails;
