import React, { FC } from 'react';
import { FetchResult, useMutation } from '@apollo/client';
import * as RequestQuery from 'graphql/request.graphql';
import clsx from 'clsx';
import { showPlural } from 'utils';
import { SendRequests } from 'api/data/request/types';
import './style.scss';

interface SendRequestsButtonProps {
  pageId: string;
  disabled: boolean;
  filledRequestsQty: number;
  buttonText: string;
  onClick: (callback: (requestIds: string[]) => Promise<FetchResult<SendRequests>>) => Promise<void>;
  className: string;
}

const SendRequestsButton: FC<SendRequestsButtonProps> = ({
  pageId,
  disabled,
  onClick,
  filledRequestsQty,
  buttonText,
  className,
}) => {
  const [sendRequests, { loading: loadingSendRequests }] = useMutation<SendRequests>(RequestQuery.SendRequests);

  const handleSendRequests = async (requestIds: string[]) => {
    const requestsParam = {
      pageId,
      requests: requestIds,
    };

    return sendRequests({ variables: requestsParam });
  };

  return (
    <button
      className={clsx('request-button', className)}
      disabled={loadingSendRequests || disabled}
      onClick={() => void onClick(handleSendRequests)}>
      {loadingSendRequests ? `Sending request${showPlural(filledRequestsQty)}` : buttonText}
    </button>
  );
};

export default SendRequestsButton;
