import React, { FC } from 'react';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import PriceSettingsContent from 'components/PricingSettings/PriceSettingsContent';
import UploadImages from './UploadImages';
import { SettingsProps } from '..';
import './style.scss';

const PageSettings: FC<SettingsProps> = ({ handleSaveOrg, loading }) => {
  const { currentOrg, loading: loadingOrg } = useCurrentOrganization();

  if (loadingOrg) return <div>Loading...</div>;

  return (
    <div className="page-settings">
      <div className="content">
        <h2>Branding</h2>
        <p className="title-description">
          The logo and cover image here will apply to all newly created pages. The recommended size for the logo is 180
          x 180 and for the cover is 1440 x 312.
        </p>
        <UploadImages handleSaveOrganization={changes => void handleSaveOrg(changes)} loading={loading} />
      </div>
      <div className="content">
        <h2>Price Settings</h2>
        <p className="title-description">
          These settings will apply to all newly created pages. Learn more about pricing{' '}
          <a
            className="button tertiary button-size-m"
            href="https://www.omella.com/blog/pricing"
            target="_blank"
            rel="noreferrer">
            here.
          </a>
        </p>
        <div className="price-settings">
          <PriceSettingsContent
            page={{
              processingFeePayer: currentOrg?.processingFeePayer,
              tipsEnabled: currentOrg?.tipsEnabled,
            }}
            onSave={changes => void handleSaveOrg(changes)}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default PageSettings;
