import React, { FC } from 'react';
import clsx from 'clsx';
import { validateEmail } from 'utils';
import IconButton from 'components/Button/IconButton';
import Input from 'components/Input';
import OrganizationRole from '../OrganizationRole';
import SpaceMemberships from '../SpaceMemberships';
import { SpacesType, UserInviteMember } from './index';
import './style.scss';

interface MemberRowProps extends UserInviteMember {
  index: number;
  loading: boolean;
  handleOnChange: (value: string, attr: string, index: number) => void;
  removeMember: (index: number) => void;
  handleOnAddSpaceMembership: (value: SpacesType, index: number) => void;
  handleOnRemoveSpaceMembership: (value: string, index: number) => void;
}

const MemberRow: FC<MemberRowProps> = ({
  index,
  fullName,
  email,
  role,
  handleOnChange,
  removeMember,
  loading,
  handleOnAddSpaceMembership,
  handleOnRemoveSpaceMembership,
}) => {
  const isEmailInvalid = !!email && !validateEmail(email);
  return (
    <div className="member-row">
      <Input
        name="email"
        placeholder="e.g. my@email.com"
        onChange={({ target }) => handleOnChange(target.value, 'email', index)}
        value={email}
        disabled={loading}
        rules={{
          errorMessage: 'Please enter a valid email',
          hasError: isEmailInvalid,
        }}
      />
      <Input
        name="name"
        placeholder="Full name"
        onChange={({ target }) => handleOnChange(target.value, 'fullName', index)}
        value={fullName || ''}
        disabled={loading}
      />
      <OrganizationRole value={role} onChange={role => handleOnChange(role, 'role', index)} />
      {role && role === 'SPACE' ? (
        <SpaceMemberships
          onChange={space => handleOnAddSpaceMembership(space, index)}
          onRemove={spaceId => handleOnRemoveSpaceMembership(spaceId, index)}
        />
      ) : (
        <div className="selector"></div>
      )}
      <IconButton
        className={clsx({ 'delete-button': !isEmailInvalid })}
        disabled={loading}
        icon="delete"
        onClick={() => removeMember(index)}
      />
    </div>
  );
};

export default MemberRow;
