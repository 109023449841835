import React, { FC } from 'react';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { CSSTransition } from 'react-transition-group';
import { currencyFormatter } from 'utils';
import ResponseDetail from './ResponseDetail';
import UserLink from '~/components/UserLink';
import { ResponseRow as ResponseRowType } from 'api/data/response/types';
import './style.scss';

const ResponseRow: FC<ResponseRowType> = ({ data, setResponseId, showDetail, page, showPageTitle }) => {
  return (
    <>
      <tr onClick={() => setResponseId(data.id)} className={clsx({ 'response-selected': showDetail })}>
        <th>
          <UserLink fullName={data.respondent.fullName} email={data.respondent.email} userId={data.respondent.id} />
        </th>
        {showPageTitle && <td>{data.pageTitle}</td>}
        <td className="short-column">{dayjs(data.updatedAt).fromNow()}</td>
        <td className="number-column">{currencyFormatter(data.totalCents)}</td>
      </tr>
      <CSSTransition timeout={300} in={showDetail} classNames="animation" unmountOnExit exit={false}>
        <ResponseDetail data={data} setResponseId={setResponseId} pageId={page?.id} pageTitle={page?.title || ''} />
      </CSSTransition>
    </>
  );
};

export default ResponseRow;
