import React, { FC } from 'react';
import { Link } from 'wouter';
import './style.scss';

type ParticipantShareBannerProps = {
  slug: string;
  fullName: string;
  pageSlug: string;
};

const ParticipantShareBanner: FC<ParticipantShareBannerProps> = ({ pageSlug, slug, fullName }) => {
  const firstName = fullName.split(' ')[0];

  return (
    <div className="participant-share-banner">
      <p className="paragraph-x-small">
        👋 <b>{firstName}</b>,{' '}
        <Link className="neutral" href={`/${pageSlug}/p/${slug}/share-participant`}>
          share
        </Link>{' '}
        your page!
      </p>
    </div>
  );
};

export default ParticipantShareBanner;
