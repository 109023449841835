import React, { FC } from 'react';
import QrCode from 'components/QrCode';

type AddByQrCodeProps = {
  slug: string;
  title: string;
};

const AddByQrCode: FC<AddByQrCodeProps> = ({ slug, title }) => {
  const url = `${window.location.origin}/${slug}/p/signup`;

  return (
    <QrCode title={title} titleUrl={`${window.location.host}/${slug}/p/signup`} validation={!!slug} qrCodeUrl={url} />
  );
};

export default AddByQrCode;
