import { Variant } from 'components/PaymentBlock/types';

const formatParentAnswer = (parent?: Variant) => {
  if (parent)
    return {
      id: parent.id,
      blockId: parent.blockId ?? '',
      title: parent.label ?? '',
      type: 'VARIANT',
    };

  return undefined;
};

export default formatParentAnswer;
