import { KeyboardEvent } from 'react';
import {
  ContentBlock,
  BlockMap,
  ContentState,
  EditorState,
  getDefaultKeyBinding,
  Modifier,
  RawDraftEntity,
  SelectionState,
} from 'draft-js';

type EntityMap = { [key: string]: RawDraftEntity };

export const customStyleMap = {
  STRIKETHROUGH: {
    textDecoration: 'line-through',
  },
};

export const getCustomKeyBinding = (e: KeyboardEvent, editorState: EditorState): string | null => {
  const selectionState = editorState.getSelection();
  const type = editorState.getCurrentContent().getBlockForKey(selectionState.getStartKey()).getType();
  if (type === 'unordered-list-item' || type === 'ordered-list-item') {
    if (e.key === 'Tab') {
      return `editor-tab${e.shiftKey ? '-shift' : ''}`;
    }
    return getDefaultKeyBinding(e);
  }

  const currentContent = editorState.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(selectionState.getAnchorKey());
  const currentContentBlockLength = currentContentBlock.getLength();
  const blockKey = currentContentBlock.getKey();
  const blockMap = currentContent.getBlockMap();

  if (
    (e.code === 'ArrowUp' &&
      selectionState.getAnchorOffset() === 0 &&
      (currentContent.getBlocksAsArray().length === 1 || blockKey === blockMap.first().getKey())) ||
    (e.code === 'ArrowDown' &&
      selectionState.getAnchorOffset() === currentContentBlockLength &&
      (currentContent.getBlocksAsArray().length === 1 || blockKey === blockMap.last().getKey()))
  ) {
    return `editor-${e.code.toLowerCase().replace('w', 'w-')}`;
  }

  if (e.code === 'Enter' || e.code === 'NumpadEnter') {
    return 'editor-new-line';
  }

  if (
    e.code === 'Backspace' &&
    selectionState.getAnchorOffset() === 0 &&
    (currentContent.getBlocksAsArray().length === 1 || blockKey === blockMap.first().getKey())
  ) {
    return 'editor-delete-block';
  }

  if (
    e.code === 'Delete' &&
    selectionState.getAnchorOffset() === currentContentBlockLength &&
    (currentContent.getBlocksAsArray().length === 1 || blockKey === blockMap.last().getKey())
  ) {
    return 'editor-delete-next-block';
  }

  return getDefaultKeyBinding(e);
};

const isYoutubeEmbeded = (editorState: EditorState): boolean => {
  const blockStartKey = editorState.getSelection().getStartKey();
  const block = editorState.getCurrentContent().getBlockMap().get(blockStartKey);
  return block.getType() === 'atomic';
};

export const isEmpty = (editorState: EditorState | undefined, removeBlankSpaces = false): boolean => {
  if (!editorState) return false;
  if (isYoutubeEmbeded(editorState)) return false;

  const text = editorState.getCurrentContent().getPlainText();
  return removeBlankSpaces ? text.trim().length === 0 : text.length === 0;
};

export const myBlockStyleFn = (contentBlock: ContentBlock): string => {
  const type = contentBlock.getType();

  switch (type) {
    case 'block-center-alignment':
    case 'block-right-alignment':
      return type;

    default:
      return 'block-left-alignment';
  }
};

export function adjustBlockDepthForContentState(
  editorState: EditorState,
  shifted: boolean,
  maxDepth: number,
): ContentState {
  const adjustment = shifted ? -1 : 1;
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  let blockMap: BlockMap = contentState.getBlockMap();
  const blocks = blockMap
    .toSeq()
    .skipUntil(function (_, k) {
      return k === startKey;
    })
    .takeUntil(function (_, k) {
      return k === endKey;
    })
    .concat([[endKey, blockMap.get(endKey)]])
    .map(function (block) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      let depth = block.getDepth() + adjustment;
      depth = Math.max(0, Math.min(depth, maxDepth));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return block.set('depth', depth);
    });

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  blockMap = blockMap.merge(blocks);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return contentState.merge({
    blockMap: blockMap,
    selectionBefore: selectionState,
    selectionAfter: selectionState,
  });
}

export function moveFocus(editorState: EditorState, offset: number, end?: boolean): EditorState {
  const selectionState = editorState.getSelection();
  const blockLength = editorState.getCurrentContent().getBlockForKey(selectionState.getAnchorKey()).getLength();

  if (end || blockLength <= offset)
    return EditorState.forceSelection(
      editorState,
      selectionState.merge({
        anchorOffset: blockLength,
        focusOffset: blockLength,
      }),
    );

  return EditorState.forceSelection(
    editorState,
    selectionState.merge({
      anchorOffset: offset,
      focusOffset: offset,
    }),
  );
}

export function splitBlock(textBlock: EditorState): [EditorState, EditorState] {
  const selectionState = textBlock.getSelection();
  const currentContent = textBlock.getCurrentContent();
  const currentContentBlock = currentContent.getBlockForKey(selectionState.getAnchorKey());
  const start = selectionState.getStartOffset();
  const end = selectionState.getEndOffset();
  const blockKey = currentContentBlock.getKey();
  const blockMap = currentContent.getBlockMap();

  const blockSelection = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: currentContentBlock.getLength(),
  });

  const contentState = Modifier.removeRange(currentContent, blockSelection, 'forward');
  const removedEditorState = EditorState.push(textBlock, contentState, 'change-block-data');

  if (currentContent.getBlocksAsArray().length === 1) {
    const secondBlockSelection = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: 0,
      focusOffset: end,
    });
    const secondContentState = Modifier.removeRange(currentContent, secondBlockSelection, 'forward');
    const secondEditorState = EditorState.push(textBlock, secondContentState, 'change-block-data');
    return [removedEditorState, secondEditorState];
  }

  const removeBlocksAfterEnter = new SelectionState({
    anchorKey: blockKey,
    anchorOffset: start,
    focusKey: blockMap.last().getKey(),
    focusOffset: blockMap.last().getText().length,
  });

  const removeBlocksBeforeEnter = new SelectionState({
    anchorKey: blockMap.first().getKey(),
    anchorOffset: 0,
    focusKey: blockKey,
    focusOffset: end,
  });

  const firstContentState = Modifier.removeRange(currentContent, removeBlocksAfterEnter, 'forward');
  const secondContentState = Modifier.removeRange(currentContent, removeBlocksBeforeEnter, 'forward');

  const newFirstEditorState = EditorState.push(textBlock, firstContentState, 'change-block-data');
  const newSecondEditorState = EditorState.push(textBlock, secondContentState, 'change-block-data');

  return [newFirstEditorState, newSecondEditorState];
}

export function splitAllBlocks(editorState: EditorState) {
  const contentState = editorState.getCurrentContent();
  const blocks = contentState.getBlocksAsArray();
  const entityMap = contentState.getEntityMap() as EntityMap;

  return blocks.map((block: ContentBlock) => {
    const contentStateForBlock = ContentState.createFromBlockArray([block], entityMap);
    return EditorState.createWithContent(contentStateForBlock);
  });
}

export function appendTextBlocks(firstBlock: EditorState, secondBlock: EditorState): EditorState {
  const previousBlockState = EditorState.moveFocusToEnd(firstBlock);
  const previousBlockSelection = previousBlockState.getSelection();
  const secondBlockAsArray = secondBlock.getCurrentContent().getBlocksAsArray();
  const newContentState = Modifier.replaceWithFragment(
    firstBlock.getCurrentContent(),
    firstBlock.getSelection(),
    ContentState.createFromBlockArray(secondBlockAsArray).getBlockMap(),
  );

  const newEditorState = EditorState.createWithContent(newContentState);

  const state = EditorState.forceSelection(newEditorState, previousBlockSelection);
  return state;
}
