import { APP_CONFIG } from '~/api/config';
import { Organization, User } from '~/api/data/user/types';

const intercomInit = (currentUser: User | null | undefined, currentOrg: Organization | null | undefined) => {
  let bootObj: object = {
    api_base: 'https://api-iam.intercom.io',
    app_id: APP_CONFIG.INTERCOM_APP_ID,
  };

  if (currentUser) {
    bootObj = {
      name: currentUser.fullName,
      email: currentUser.email,
      created_at: currentUser.insertedAt ? Date.parse(currentUser.insertedAt) / 1000 : undefined,
      user_id: currentUser.id,
      ...bootObj,
    };

    if (currentOrg) {
      bootObj = {
        company: {
          id: currentOrg.id,
          name: currentOrg.name,
        },
        ...bootObj,
      };
    }
  } else {
    window.Intercom('shutdown', {});
  }

  window.Intercom('boot', bootObj);
};

export default intercomInit;
