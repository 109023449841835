import React, { FC, useEffect, useRef, useState } from 'react';
import Filter from 'components/Filter';
import DropDownItem from 'components/Menu/DropDownItem';
import { TableFilterProps } from '../../types';
import '../style.scss';

const CheckboxFilter: FC<TableFilterProps> = ({
  filterName,
  filterLabel,
  dropdownOptions = {},
  filters,
  setFilters,
}) => {
  const refOutside = useRef<HTMLElement>(null);
  const [selectedIds, setSelectedIds] = useState(filters[filterName]);

  useEffect(() => setSelectedIds(filters[filterName]), [filters, filterName]);

  const filterChanged = (checked: boolean, id: string) => {
    setSelectedIds(selectedIds => {
      if (checked) {
        return [...selectedIds, id];
      } else {
        if (selectedIds === undefined) {
          selectedIds = Object.keys(dropdownOptions);
        }
        return selectedIds?.filter(item => item !== id);
      }
    });
  };

  const filterApplied = () => {
    if (selectedIds) {
      setFilters({ ...filters, [filterName]: selectedIds });
    } else {
      delete filters[filterName];
      setFilters({ ...filters });
    }
  };

  const sortedOptions = Object.entries(dropdownOptions).sort((a, b) => a[1].localeCompare(b[1]));

  const buttonTextLabel = Object.entries(dropdownOptions)
    .filter(([id]) => selectedIds?.includes(id))
    .map(([, label]) => label)
    .sort()
    .join(', ');

  return (
    <Filter
      buttonName={filterLabel}
      filterName={filterLabel}
      refOutside={refOutside}
      handleOnApply={filterApplied}
      handleOnClear={() => setSelectedIds([])}
      outsideClick={() => setSelectedIds(filters[filterName])}
      itemsFiltered={buttonTextLabel}>
      {sortedOptions.map(([id, item]) => (
        <DropDownItem key={id} onClick={() => null}>
          <label htmlFor={id}>
            <input
              type="checkbox"
              id={id}
              checked={selectedIds === undefined || selectedIds.includes(id)}
              onChange={({ target }) => filterChanged(target.checked, id)}
            />
            <span className="label">{item}</span>
          </label>
        </DropDownItem>
      ))}
    </Filter>
  );
};

export default CheckboxFilter;
