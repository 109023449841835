import React, { FC } from 'react';
import { currencyFormatter, feeFormatter } from 'utils';
import ICONS from 'components/Icons';
import { PaymentMethodType } from 'api/data/payment/types';
import './style.scss';

interface TransactionSummaryProps {
  subtotalAmountCents?: number;
  tipAmountCents?: number;
  platformFeeAmountCents?: number;
  processingFee?: number;
  isPayerView: boolean;
  recurringFeeAmountCents?: number;
  totalAmountCents?: number;
  paymentMethodType?: PaymentMethodType | string;
  bankName?: string;
  last4?: string;
  cardBrand?: string;
  showPaymentMethod: boolean;
}

interface SummaryItemProps {
  label: string;
  amountFormatted?: string;
}

export const SummaryItem: FC<SummaryItemProps> = ({ label, amountFormatted }) => {
  return (
    <div className="row flex-wrap space-between">
      <span>{label}</span>
      <b>{amountFormatted}</b>
    </div>
  );
};

const TransactionSummary: FC<TransactionSummaryProps> = ({
  subtotalAmountCents,
  tipAmountCents,
  platformFeeAmountCents,
  processingFee = 0,
  isPayerView,
  recurringFeeAmountCents = 0,
  paymentMethodType = '',
  last4,
  bankName,
  cardBrand,
  totalAmountCents,
  showPaymentMethod,
}) => {
  const paymentMethod = {
    ach: <>{`${bankName?.toUpperCase() || ''} •••• ${last4 || ''} `}</>,
    card: (
      <>
        {ICONS[cardBrand?.toLocaleLowerCase() || '']}
        {`•••• ${last4 || ''} `}
      </>
    ),
    offline: <>{ICONS['cash_or_check']} Cash or Check</>,
  };

  return (
    <div className="transaction-summary">
      <SummaryItem label="Subtotal" amountFormatted={currencyFormatter(subtotalAmountCents)} />
      {isPayerView && <SummaryItem label="Tips" amountFormatted={currencyFormatter(tipAmountCents)} />}
      {!isPayerView && (
        <SummaryItem label="Platform fee" amountFormatted={feeFormatter(platformFeeAmountCents, isPayerView)} />
      )}
      <SummaryItem label="Processing fee" amountFormatted={feeFormatter(processingFee, isPayerView)} />
      {!isPayerView && recurringFeeAmountCents > 0 && (
        <SummaryItem label="Recurring fee" amountFormatted={feeFormatter(recurringFeeAmountCents, isPayerView)} />
      )}
      <div className="horizontal-line"></div>
      <div className="row flex-wrap space-between align-center total-info">
        <b>Total</b>
        {showPaymentMethod && (
          <b className="row align-center last-4">{paymentMethod[paymentMethodType as keyof typeof paymentMethod]}</b>
        )}
        <b className="total-amount">{currencyFormatter(totalAmountCents)}</b>
      </div>
    </div>
  );
};

export default TransactionSummary;
