import React, { FC, Fragment } from 'react';
import clsx from 'clsx';
import { RecurringValue } from 'defaults/page';
import './style.scss';

interface RecurringBlockHeaderProps {
  recurringOptions: RecurringValue[];
  readOnly?: boolean;
  selected: string;
  blockId: string;
  handleOnChange: (value: RecurringValue) => void;
}

const RecurringBlockHeader: FC<RecurringBlockHeaderProps> = ({
  recurringOptions,
  readOnly,
  selected,
  handleOnChange,
  blockId,
}: RecurringBlockHeaderProps) => {
  const className = clsx('recurring-block-header row justify-center', { 'payer-view': !readOnly });
  return (
    <div className={className}>
      <div className="recurring-block-header-buttons row align-center">
        {recurringOptions.map((option, i) => (
          <Fragment key={option}>
            <input
              type="radio"
              id={`${option}-${blockId}`}
              name={`recurring-${blockId}`}
              value={option}
              defaultChecked={selected ? option === selected : i === 0}
              onChange={() => {
                handleOnChange(option);
              }}
              className="hide"
            />
            <label htmlFor={`${option}-${blockId}`}>{option}</label>
          </Fragment>
        ))}
      </div>
    </div>
  );
};

export default RecurringBlockHeader;
