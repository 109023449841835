import React, { FC, ReactNode } from 'react';
import clsx from 'clsx';
import ICONS from 'components/Icons';
import Tooltip from '../Tooltip';
import './style.scss';

export type WrapperProps = {
  label?: string;
  name: string;
  className?: string;
  classNameLabel?: string;
  hint?: string;
  rules?: {
    required?: boolean;
    hasError?: boolean;
    errorMessage?: string;
    useTooltip?: boolean;
    hasWarning?: boolean;
    warningMessage?: string;
  };
  labelTooltip?: string;
  ariaLabel?: string;
  ariaLabelTooltip?: string;
  children?: ReactNode;
};

const Wrapper: FC<WrapperProps> = ({
  label,
  name,
  className,
  classNameLabel,
  hint,
  rules,
  labelTooltip,
  ariaLabelTooltip,
  children,
}) => {
  return (
    <div
      className={clsx('input-wrapper', className, { 'has-error': rules?.hasError, 'has-warning': rules?.hasWarning })}>
      <div className="label-wrapper">
        <div>
          {!!label && (
            <label htmlFor={name} className={classNameLabel}>
              {label}{' '}
            </label>
          )}
          {!!labelTooltip && (
            <Tooltip color="dark" title={labelTooltip} ariaLabel={ariaLabelTooltip || ''}>
              {ICONS['solid_help']}
            </Tooltip>
          )}
        </div>
        {rules?.required && <span>Required*</span>}
      </div>
      <span className="affix-wrapper">
        {children}
        {rules?.hasError && (
          <Tooltip color="dark" title={rules?.useTooltip ? rules?.errorMessage || '' : ''}>
            {ICONS['warning_circle']}
          </Tooltip>
        )}
        {rules?.hasWarning && (
          <Tooltip color="dark" title={rules?.useTooltip ? rules?.warningMessage || '' : ''}>
            {ICONS['warning']}
          </Tooltip>
        )}
      </span>
      {rules?.hasError && !rules?.useTooltip && <span className="size-xxxs">{rules.errorMessage}</span>}
      {hint && !rules?.hasError && <span className="size-xxxs">{hint}</span>}
    </div>
  );
};

export default Wrapper;
