import React, { FC, useRef } from 'react';
import { RouteComponentProps } from 'wouter';
import clsx from 'clsx';
import { useEditor } from 'hooks/useEditor';
import useUserSession from '~/hooks/useUserSession';
import Header from 'components/Editor/Header';
import Editor from 'components/Editor';
import ShareButton from 'components/ShareButton';

export type PageParams = { id: string };

const EditorPage: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [page, setPage, isLoading, savePageError] = useEditor(params.id, undefined, false);
  const { data: userSession } = useUserSession();
  const refOutside = useRef<HTMLElement>(null);

  return (
    <Editor page={page} setPage={setPage} savePageError={savePageError}>
      <Header isLoading={isLoading} title={page.title} refOutside={refOutside} showSaveState={!!page.slug} />
      <nav
        className={clsx('actions', {
          'unlogged-user': !userSession?.session.currentUser,
        })}>
        {!!page.id && (
          <div className="float-actions">
            <ShareButton organizationId={page.organizationId} organizationStatus={page.organization?.status} />
          </div>
        )}
      </nav>
    </Editor>
  );
};

export default EditorPage;
