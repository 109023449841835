import React, { FC, SyntheticEvent, useRef, useState } from 'react';
import { useLocation } from 'wouter';
import { useMutation, ApolloError } from '@apollo/client';
import { chooseManagedSpace } from '~/utils';
import * as AuthQuery from 'graphql/auth.graphql';
import { updateUserCache } from 'api/data/user';
import { regexCheckPassword, errorParser } from 'defaults/user';
import PasswordInput, { PasswordRules } from 'components/PasswordInput';
import { LoginQuery } from 'api/data/user/types';
import { PageParams, SaveNewPasswordResponse } from './types';

type NewPasswordFormProps = {
  params: PageParams;
  buttonLabel: string;
};

const NewPasswordForm: FC<NewPasswordFormProps> = ({ params, buttonLabel }) => {
  const { id, token } = params;
  const [, setLocation] = useLocation();
  const [password, setPassword] = useState({
    value: '',
    errorMessage: '',
  });

  const [saveNewPassword, { loading }] = useMutation<SaveNewPasswordResponse>(AuthQuery.NewPassword);
  const [userLogin, { loading: loginLoading }] = useMutation<LoginQuery>(AuthQuery.Login, {
    update: updateUserCache,
  });
  const outsideRef = useRef<HTMLElement>(null);

  const handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();

    if (!regexCheckPassword.test(password.value)) {
      setPassword({ ...password, errorMessage: 'Password criteria not met' });
      return;
    }

    saveNewPassword({ variables: { input: { id, token, password: password.value } } })
      .then(({ data }) => {
        void userLogin({ variables: { email: data?.saveNewPassword.email, password: password.value } }).then(
          ({ data }) => {
            if (data?.login?.currentUser) {
              const space = chooseManagedSpace(data?.login.managedOrganizations);
              const path = space ? `/${space.slug}/pages` : '/member';
              setLocation(path);
            }
          },
        );
      })
      .catch((error: ApolloError) => {
        if (error?.message === 'Invalid token') setLocation('/password-recover?invalid-link');
        const regex = /encrypted_password:/i;
        if (regex.test(error?.message)) {
          setPassword({ ...password, errorMessage: errorParser(error?.message) });
        }
      });
  };

  return (
    <section ref={outsideRef} className="password-input-container">
      <form onSubmit={handleSubmit} method="post">
        <PasswordInput
          password={password}
          setPassword={param => {
            setPassword(param);
          }}
          label="New password"
        />
        <PasswordRules password={password.value} hasError={!!password.errorMessage} />
      </form>
      <div className="footer">
        <button type="submit" disabled={!password.value} onClick={handleSubmit}>
          {loading || loginLoading ? 'Loading...' : buttonLabel}
        </button>
      </div>
    </section>
  );
};

export default NewPasswordForm;
