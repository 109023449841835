import React, { useState } from 'react';
import clsx from 'clsx';
import { getCookie, isKYCd, setCookie } from 'utils';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useUserSession from 'hooks/useUserSession';
import ICONS from '../Icons';
import ReferModal from './Modal';
import referralImg from 'assets/referral_image.png';
import './style.scss';

const COOKIE_NAME = 'hideRefer';
const ImageButton = () => {
  const { data } = useUserSession();
  const { currentOrg } = useCurrentOrganization();
  const hideReferButton = getCookie(COOKIE_NAME);
  const [hideRefer, setHideRefer] = useState(!!hideReferButton);
  const [showModal, setShowModal] = useState(false);

  const handleCloseRefer = () => {
    setCookie(COOKIE_NAME, 'true', 60);
    setHideRefer(true);
  };

  if (hideRefer) return null;

  return (
    <div className={clsx('refer-image-container', { 'not-kycd': !!currentOrg && !isKYCd(currentOrg.status) })}>
      <ReferModal
        setShowModal={setShowModal}
        showModal={showModal}
        userEmail={data?.session?.currentUser?.email || ''}
      />
      <button className="refer-image-button" onClick={() => setShowModal(true)}>
        <img src={referralImg} alt="Referral Earn $50" />
      </button>
      <button className="button-link close-button" aria-label="close referral button" onClick={handleCloseRefer}>
        {ICONS['close']}
      </button>
    </div>
  );
};

export default ImageButton;
