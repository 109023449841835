import React, { FC, useState, isValidElement, cloneElement, Children, ReactNode, useEffect } from 'react';
import clsx from 'clsx';
import './style.scss';

export interface TabsProps {
  activeKey: string;
  children: ReactNode;
  className?: string;
}

const Tabs: FC<TabsProps> = ({ activeKey, children, className }: TabsProps) => {
  const [tabActive, setTabActive] = useState(activeKey);

  useEffect(() => {
    setTabActive(activeKey);
  }, [activeKey]);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      const props = { tabActive };
      return cloneElement(child, props);
    }
  });

  return <div className={clsx('tabs', className)}>{childrenWithProps}</div>;
};

export default Tabs;
