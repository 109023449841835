import React, { FC } from 'react';
import clsx from 'clsx';
import { TooltipEllipsis } from 'components/Tooltip';
import './style.scss';

interface ViewProps {
  label: string;
  viewKey: string;
  onClick?: (viewKey: string) => void;
  viewActive?: string;
}

const View: FC<ViewProps> = ({ viewKey, onClick, label, viewActive }) => {
  return (
    <button
      className={clsx('button-clear view-button', { active: viewKey === viewActive })}
      onClick={() => onClick && onClick(viewKey)}>
      <TooltipEllipsis title={label} />
    </button>
  );
};

export default View;
