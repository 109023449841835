import { ReactElement } from 'react';

export type Item = ReactElement;

export enum SlideDirection {
  Right = -1,
  Left = 1,
}

export interface CarouselProps {
  children: Item[];
  show: number;
  slide: number;
  transition?: number;
  responsive?: boolean;
  infinite?: boolean;
  className?: string;
  hideArrows?: boolean;
}
