import React, { FC, RefObject } from 'react';
import HeaderComponent from 'components/Header';

interface HeaderProps {
  isLoading: boolean;
  title: string;
  showTitle?: boolean;
  refOutside: RefObject<HTMLElement>;
  showSaveState?: boolean;
}

const Header: FC<HeaderProps> = ({
  isLoading,
  title,
  showTitle = true,
  refOutside,
  showSaveState = true,
}: HeaderProps) => {
  const saveState = isLoading ? '(saving)' : '(saved)';

  return (
    <HeaderComponent
      refOutside={refOutside}
      showSideBar={false}
      additionalChildren={
        showTitle &&
        title && (
          <>
            <span className="vertical-line"></span>
            <span className="size-xxxs text-disabled">{`${title} ${showSaveState ? saveState : ''}`}</span>
          </>
        )
      }
    />
  );
};

export default Header;
