import React, { FC, useState } from 'react';
import IconButton from '../IconButton';
import Message from 'components/Message';
import './style.scss';

interface CopyButtonProps {
  link: string;
  className?: string;
  buttonText?: string;
  copyCallback?: () => void;
}

const CopyButton: FC<CopyButtonProps> = ({ link, className, copyCallback, buttonText }) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const copy = (text: string) => {
    void navigator.clipboard.writeText(text);
    setShowCopyMessage(true);
    copyCallback && copyCallback();
  };

  return (
    <div className="copy-container">
      <Message
        className="row align-center justify-center default-message"
        showMessage={showCopyMessage}
        setShowMessage={setShowCopyMessage}>
        Copied!
      </Message>
      <IconButton
        className={className || 'button-link copy-button'}
        icon="copy"
        onClick={() => copy(decodeURIComponent(link))}>
        {buttonText || 'Copy link'}
      </IconButton>
    </div>
  );
};

export default CopyButton;
