const removeEmptyLines = (value = '') => {
  const formatedData = value
    .split('\n')
    .filter(line => line.trim() !== '')
    .join('\n');

  return formatedData;
};

export default removeEmptyLines;
