import React, { FC, SyntheticEvent, useState, useEffect } from 'react';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as PaymentQuery from 'graphql/payment.graphql';
import Modal from 'components/Modal';
import PaymentForm from '../PaymentForm';
import { PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import { User } from 'api/data/response/types';
import { CreateMeldConnection, RetrieveBankAccount, MeldConnectionData, MeldError } from './types';
import { ProviderProps } from '../types';
import './style.scss';

const Meld: FC<ProviderProps> = ({ setLoading, onSubmit, response, ...props }) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [showBankModal, setShowBankModal] = useState(false);
  const [connectionData, setConnectionData] = useState<MeldConnectionData>();
  const [createMeldConnection] = useMutation<CreateMeldConnection>(PaymentQuery.CreateMeldConnection);
  const [retrieveBankAccount, { data: bankAccountData, error, loading: loadingBankAccount }] =
    useLazyQuery<RetrieveBankAccount>(PaymentQuery.RetrieveBankAccount);

  useEffect(() => {
    const handleEvent = ({ data }: MessageEvent) => {
      const prefix = '[meld-connect]';

      if (typeof data === 'string') {
        setErrorMessage('');

        if (data === `${prefix}cancel`) {
          setShowBankModal(false);
          setLoading(false);
        }

        if (data?.startsWith(`${prefix}error`)) {
          const target = `${prefix}error?`;
          const errorFormatted = JSON.parse(data.slice(target.length)) as MeldError;
          setErrorMessage(errorFormatted.reason);
        }

        if (data === `${prefix}handover`) {
          void retrieveBankAccount({ variables: { id: connectionData?.id } });
          setShowBankModal(false);
        }
      }
    };

    window.addEventListener('message', handleEvent, false);

    return function cleanup() {
      window.removeEventListener('message', handleEvent, false);
    };
  }, [setLoading, retrieveBankAccount, connectionData?.id]);

  useEffect(() => {
    setLoading(loadingBankAccount);
  }, [loadingBankAccount, setLoading]);

  const connectBankAccount = async (user: User) => {
    const { data } = await createMeldConnection({ variables: { email: user.email } });

    setConnectionData(data?.createMeldConnection);
    setShowBankModal(true);
  };

  const handleSubmit = async (event: SyntheticEvent, user: User) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);
    try {
      if (!bankAccount) {
        await connectBankAccount(user);
      } else {
        await onSubmit({
          paymentMethodId: bankAccountData?.retrieveBankAccount.accountId || '',
          paymentMethodType: PaymentMethodType.ACH,
          response: { ...response, user },
        });
      }
    } catch (error) {
      if ((error as PaymentErrorType)?.message) setErrorMessage((error as PaymentErrorType).message);
      setLoading(false);
    }
  };

  const bankAccount = bankAccountData?.retrieveBankAccount
    ? {
        bankName: bankAccountData?.retrieveBankAccount.bankName,
        last4: bankAccountData?.retrieveBankAccount.accountLast4,
        accountType: bankAccountData?.retrieveBankAccount.accountType,
      }
    : undefined;

  return (
    <>
      <PaymentForm
        {...props}
        connectBankAccount={connectBankAccount}
        handleSubmit={handleSubmit}
        setLoading={setLoading}
        errorMessage={errorMessage || (error as PaymentErrorType)?.message}
        setErrorMessage={setErrorMessage}
        bankAccount={bankAccount}
        response={response}
      />
      <Modal visible={showBankModal} className="meld-bank-modal">
        <iframe
          data-testid="meld-iframe"
          title="connect-bank-meld"
          src={connectionData?.widgetUrl}
          height="640"></iframe>
        <p className="error-message">{errorMessage}</p>
      </Modal>
    </>
  );
};

export default Meld;
