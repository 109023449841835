import React, { FC, useState, FormEvent, useRef } from 'react';
import { useLocation } from 'wouter';
import { useMutation, useQuery } from '@apollo/client';
import CreatableSelect from 'react-select/creatable';
import clsx from 'clsx';
import * as organizationQuery from 'graphql/organization.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { chooseManagedSpace, extractQueryStringFromParams } from 'utils';
import { useSavePageRedirect } from '../Template/AddPageModal';
import useUserSession from 'hooks/useUserSession';
import Input from 'components/Input';
import { SessionQuery } from 'api/data/user/types';
import './style.scss';

type BusinessTypesDataType = {
  businessTypes: { id: string; name: string }[];
};

type OnChangeParams = {
  value: string;
  label: string;
  __isNew__?: boolean;
} | null;

type AnnualCollection = {
  label: string;
  est_annual_gmv_lower: number;
  est_annual_gmv_upper: number;
};

const GMV_OPTIONS: AnnualCollection[] = [
  { label: 'Under $50K', est_annual_gmv_lower: 0, est_annual_gmv_upper: 50000 },
  { label: '$50 - $100K', est_annual_gmv_lower: 50000, est_annual_gmv_upper: 100000 },
  { label: '$100 - $500K', est_annual_gmv_lower: 100000, est_annual_gmv_upper: 500000 },
  { label: '$500K - $1M', est_annual_gmv_lower: 500000, est_annual_gmv_upper: 1000000 },
  { label: '$1M - $2M', est_annual_gmv_lower: 1000000, est_annual_gmv_upper: 2000000 },
  { label: '$2M+', est_annual_gmv_lower: 2000000, est_annual_gmv_upper: 2000000 },
];

const DEFAULT_ORGANIZATION = {
  name: '',
  contactEmail: '',
  contactName: '',
  timezone: '',
  tipsEnabled: true,
  processingFeePayer: '',
  businessType: '',
  businessSubType: '',
  estAnnualGmvLower: null,
  estAnnualGmvUpper: null,
};

const Organization: FC = () => {
  const isFirstRender = useRef(true);
  const [, setLocation] = useLocation();
  const { data, refetch: refetchUser } = useUserSession({
    onCompleted: (data: SessionQuery | undefined) => {
      if (data?.session?.currentUser) {
        setOrganization({
          ...organization,
          contactEmail: data.session.currentUser.email,
          contactName: data.session.currentUser.fullName || '',
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          tipsEnabled: true,
          processingFeePayer: 'PAYER',
        });

        const spaceSlug = currentSpaceSlug() || chooseManagedSpace(data?.session.managedOrganizations)?.slug || '';

        if (isFirstRender.current && spaceSlug) setLocation(`/${spaceSlug}/pages`);

        isFirstRender.current = false;
      }
    },
  });
  const [organization, setOrganization] = useState(DEFAULT_ORGANIZATION);
  const [annualCollection, setAnnualCollection] = useState<AnnualCollection | undefined>();
  const { data: btData } = useQuery<BusinessTypesDataType>(organizationQuery.GetBusinessTypes);
  const [createOrganization, { loading }] = useMutation(organizationQuery.CreateOrganization);
  const [savePageAndRedirect] = useSavePageRedirect();

  const selectedBusinessTypeLabel = organization.businessType || 'Select...';
  const businessTypes = btData?.businessTypes.filter(type => type.name !== 'Other');

  const handleSaveOrganization = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const sanitizedOrg = {
      ...organization,
      name: organization.name.trim(),
      est_annual_gmv_lower: annualCollection?.est_annual_gmv_lower,
      est_annual_gmv_upper: annualCollection?.est_annual_gmv_upper,
    };
    setOrganization(sanitizedOrg);

    await createOrganization({ variables: { input: sanitizedOrg } }).then(async () => {
      const userResponse = await refetchUser();
      const firstSpace = chooseManagedSpace(userResponse?.data?.session.managedOrganizations);

      if (firstSpace?.slug)
        if (extractQueryStringFromParams('redirectTo') === 'editor') {
          void savePageAndRedirect(firstSpace.slug);
        } else {
          setLocation(`/${firstSpace?.slug || ''}/pages`);
        }
    });
  };

  return (
    <form onSubmit={event => void handleSaveOrganization(event)} aria-label="create organization form">
      <span role="img" aria-label="hand waving" className="user-name">
        &#x1F44b; Nice to meet you, {data?.session?.currentUser?.fullName}!
      </span>
      <h1>Tell us about your organization</h1>
      <div>
        <Input
          label="Organization name"
          placeholder="Organization name"
          onChange={({ target }) => setOrganization({ ...organization, name: target.value.trimStart() })}
          onBlur={({ target }) => setOrganization({ ...organization, name: target.value.trim() })}
          value={organization.name}
          name="input organization"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <div className="business-type-container">
          <label htmlFor="organization-type">Organization type</label>
          <CreatableSelect
            id="organization-type"
            className="react-select"
            classNamePrefix="react-select"
            isSearchable
            isClearable
            name="business-type"
            value={{
              label: selectedBusinessTypeLabel === 'Other' ? organization.businessSubType : selectedBusinessTypeLabel,
              value: organization.businessType || '',
            }}
            options={businessTypes?.map(type => ({
              label: type.name,
              value: type.name,
            }))}
            onChange={(option: OnChangeParams) => {
              const optionValue = option?.value || '';
              setOrganization({
                ...organization,
                businessType: option?.__isNew__ ? 'Other' : optionValue,
                businessSubType: option?.__isNew__ ? optionValue : '',
              });
            }}
            formatCreateLabel={value => `Other: ${value}`}
            aria-labelledby="organization-type"
          />
        </div>
        {organization.name && organization.businessType && (
          <div className="gmv-container">
            <label htmlFor="annual-collection-container">How much does {organization.name} collect annually?</label>
            <div id="annual-collection-container">
              {GMV_OPTIONS.map(option => {
                const label = option.label;
                return (
                  <div key={label}>
                    <label
                      htmlFor={`business_type_${label}`}
                      className={clsx({ 'focus-item': label === annualCollection?.label })}>
                      {label}
                    </label>
                    <input
                      key={label}
                      type="radio"
                      id={`business_type_${label}`}
                      name={label}
                      checked={label === annualCollection?.label}
                      onChange={() => setAnnualCollection(option)}
                      className="hide"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
      <div className="footer">
        <button disabled={!organization.name || !organization.businessType || loading}>
          {loading ? 'Loading...' : 'Show me Omella!'}
        </button>
      </div>
    </form>
  );
};

export default Organization;
