import React, { FC } from 'react';
import { Link } from 'wouter';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useUserSession from 'hooks/useUserSession';
import omellaLogo from 'assets/logo_omella.svg';
import oLogo from 'assets/o_logo.svg';

type LogoLinkProps = {
  redirect?: boolean;
};

const LogoLink: FC<LogoLinkProps> = ({ redirect = true }) => {
  const { currentOrg } = useCurrentOrganization();
  const { data } = useUserSession();

  const currentOrgSlug = currentOrg?.slug ? `/${currentOrg?.slug}` : '';
  const location = data ? `${currentOrgSlug}/pages` : '/login';

  const Logo = (
    <picture className="row align-center logo-link">
      <source srcSet={omellaLogo} media="(min-width: 992px)" />
      <img src={oLogo} alt="Omella logo" />
    </picture>
  );

  if (redirect) return <Link to={location}>{Logo}</Link>;

  return Logo;
};

export default LogoLink;
