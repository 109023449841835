import React, { FC, useState } from 'react';
import { validateEmail } from 'utils';
import Email from '../Input/Email';
import Input from '../Input';
import PaymentError from '../Error/PaymentError';
import { PaymentFormProps } from './types';
import { User } from 'api/data/response/types';

const PaymentForm: FC<PaymentFormProps> = ({
  onResponseChanged,
  buttonText,
  pageId,
  paymentError,
  setLoading,
  loading,
  connectBankAccount,
  handleSubmit,
  buttonDisabled,
  setErrorMessage,
  errorMessage,
  bankAccount,
  setBankAccount,
  disableInputEmail,
  response,
}) => {
  const [user, setUser] = useState<User>(response.user ? response.user : { email: '', zipcode: '', fullName: '' });

  const handleSwitchBankAccount = async () => {
    setBankAccount && setBankAccount(undefined);
    setErrorMessage('');
    setLoading(true);
    await connectBankAccount(user);
  };

  const handleOnBlur = () => {
    onResponseChanged && onResponseChanged({ ...response, user });
  };

  const handleResponseUpdated = (value: string, field: string) => {
    setUser({ ...user, [field]: value });
  };

  const isEmailInvalid = !!user?.email && !validateEmail(user?.email);
  const newButtonText = bankAccount ? buttonText : `Connect your bank`;

  return (
    <div className="ach-container">
      {!loading && errorMessage && <PaymentError title={paymentError?.title} errorMessage={errorMessage} />}
      {!bankAccount && (
        <Email
          onChange={({ target }) => handleResponseUpdated(target.value, 'email')}
          value={user?.email || ''}
          name="input-mail"
          disabled={disableInputEmail}
          onBlur={({ target }) => {
            handleResponseUpdated(target.value, 'email');
            handleOnBlur();
          }}
          rules={{
            hasError: isEmailInvalid,
            errorMessage: 'Please enter a valid email.',
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      )}
      {validateEmail(user?.email || '') && (
        <div>
          <form onSubmit={event => void handleSubmit(event, user)} className="payment-method" aria-label="ach form">
            {bankAccount ? (
              <>
                <span>
                  <b>{bankAccount.bankName}</b>: {bankAccount.accountType} •••• {bankAccount.last4}
                </span>
                <span>{user?.fullName}</span>
                <span>{user?.email}</span>
              </>
            ) : (
              <Input
                label="Full name"
                placeholder="Full name"
                onChange={({ target }) => handleResponseUpdated(target.value, 'fullName')}
                onBlur={() => handleOnBlur()}
                value={user?.fullName}
                name="full-name"
                type="text"
              />
            )}
            <button
              type="submit"
              disabled={
                buttonDisabled ||
                loading ||
                !user ||
                !user.fullName.trim() ||
                (!!pageId && response.order.totalCents === 0)
              }>
              {loading ? 'Processing...' : newButtonText}
            </button>
          </form>
          {!!bankAccount && (
            <div className="switch-container">
              <button className="tertiary" type="button" onClick={() => void handleSwitchBankAccount()}>
                Switch bank account
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PaymentForm;
