import React, { useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import { useMutation } from '@apollo/client';
import * as MemberQuery from 'graphql/member.graphql';
import * as SpaceQuery from 'graphql/space.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useSpaceMessage from './useSpaceMessage';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import LoggedPageContainer from '~/components/LoggedPageContainer';
import Message from '~/components/Message';
import SpaceHeader from '~/components/SpaceHeader';
import SpaceMembers from '~/components/SpaceMembers';
import SpaceName from '~/components/SpaceName';
import Tabs from '~/components/Tabs';
import Tab from '~/components/Tabs/Tab';
import { Space, SpaceMembership } from '~/api/data/user/types';
import './style.scss';

export default function SpaceSettings() {
  const [, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug();
  const [saveSpace] = useMutation(SpaceQuery.SaveSpace);
  const [inviteSpaceMember] = useMutation(MemberQuery.InviteSpaceMember, { refetchQueries: ['GetOrganization'] });
  const [removeSpaceMember] = useMutation(MemberQuery.RemoveSpaceMember, { refetchQueries: ['GetOrganization'] });
  const [promoteToSpaceOwner] = useMutation(MemberQuery.PromoteToSpaceOwner, { refetchQueries: ['GetOrganization'] });
  const [cancelPendingSpaceOwnership] = useMutation(MemberQuery.CancelPendingSpaceOwnership, {
    refetchQueries: ['GetOrganization'],
  });
  const [, params] = useRoute(`/:currentSpace/space-settings/:tab`);
  const { currentOrg } = useCurrentOrganization();
  const { loading, currentSpace } = useSpacesFromCurrentOrg();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  useSpaceMessage({ setShowMessage, setMessage, loading: loading || !currentSpace });

  if (loading || !currentSpace) return <div>Loading...</div>;

  const activeTab = params?.tab || 'general';

  const setTab = (tab: string) => {
    if (spaceSlug) {
      setLocation(`/${spaceSlug}/space-settings/${tab}`);
    }
  };

  const handleChangeSpaceName = async (space: Space) => {
    await saveSpace({
      variables: { space: { id: space.id, name: space.name } },
      optimisticResponse: {
        saveSpace: { ...currentSpace, ...space },
      },
    });
    setShowMessage(true);
    setMessage('Space name has been updated.');
  };

  const handleInviteSpaceMember = async (member: { email: string; name: string }) => {
    await inviteSpaceMember({
      variables: {
        invites: [
          {
            email: member.email,
            fullName: member.name,
            role: 'SPACE',
          },
        ],
      },
    });

    setShowMessage(true);
    setMessage('Member successfully added');
  };

  const handleRemoveSpaceMember = async (member: SpaceMembership) => {
    await removeSpaceMember({ variables: { id: member.id } });

    setShowMessage(true);
    setMessage(`${member.user.email} has been removed.`);
  };

  const handlePromoteToOwner = async (member: SpaceMembership) => {
    await promoteToSpaceOwner({ variables: { id: member.id } });

    setShowMessage(true);
    setMessage(`${member.user.email} has been promoted to owner.`);
  };

  const onCancelPendingMembership = async (member: SpaceMembership) => {
    await cancelPendingSpaceOwnership({ variables: { id: member.id } });

    setShowMessage(true);
    setMessage('Pending ownership canceled.');
  };

  const content: {
    [key: string]: JSX.Element;
  } = {
    general: <SpaceName space={currentSpace} onSubmit={handleChangeSpaceName} />,
    members: (
      <SpaceMembers
        onSubmit={handleInviteSpaceMember}
        onRemove={handleRemoveSpaceMember}
        onPromoteToOwner={handlePromoteToOwner}
        onCancelPendingMembership={onCancelPendingMembership}
      />
    ),
  };

  return (
    <>
      <Message
        className="space-settings-succeeded-message"
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        type="succeeded">
        {message}
      </Message>
      <LoggedPageContainer
        className="space-settings"
        headerChildren={
          <div className="second-header">
            <SpaceHeader renderSpaceSettings={false} />
            <Tabs activeKey={activeTab}>
              <Tab label="General" tabKey="general" onClick={setTab} />
              <Tab label="Members" tabKey="members" onClick={setTab} />
            </Tabs>
          </div>
        }
        organizationStatus={currentOrg?.status}>
        {content[activeTab]}
      </LoggedPageContainer>
    </>
  );
}
