import { makeVar } from '@apollo/client';
import { EditorState, convertToRaw } from 'draft-js';
import { defaultBlocksTitle, defaultTextBlock, emptyPage } from 'defaults/page';
import { PaymentBlock } from 'components/PaymentBlock/types';
import { QuestionBlock } from 'components/QuestionBlock/types';
import { PageDataDraftBlock } from 'api/data/pages/types';
import { BlockState, BlockStateRaw, BlockTextState } from './blocks/types';

export const pageVar = makeVar<Partial<PageDataDraftBlock>>(emptyPage(''));

export const generateEditorState = (): BlockTextState => {
  const newEditor = EditorState.moveFocusToEnd(EditorState.createEmpty());
  return { ...defaultTextBlock(newEditor), __typename: 'TextBlock' };
};

export function buildBlockItem(item: BlockState, variantId?: string): BlockState {
  if (item.type === 'PAYMENT') {
    return {
      id: item.id,
      order: item.order,
      type: item.type,
      description: item.description,
      image: item.image,
      imageUrl: item.imageUrl,
      required: item.required,
      multipleChoice: item.multipleChoice,
      goal: item.goal
        ? {
            id: item.goal.id,
            amountCents: item.goal?.amountCents,
          }
        : null,
      variants: item.variants.map(variant => {
        return {
          id: variant.id,
          image: variant.image,
          label: variant.label || '',
          order: variant.order,
          maxQuantity: variant.maxQuantity,
          inventory: {
            id: variant.inventory?.id,
            availableQuantity: variant.inventory?.availableQuantity,
            unlimited: variant.inventory?.unlimited,
          },
          paymentOptions: variant.paymentOptions.map(option => ({
            amountCents: option.amountCents === null ? undefined : option.amountCents,
            recurring: option.recurring,
            allowAnyAmount: option.allowAnyAmount,
            paymentPlanInstallments: option.paymentPlanInstallments,
            order: option.order,
            id: option.id,
          })),
          blocks: variant.blocks?.map(item => buildBlockItem(item, variant.id) as PaymentBlock | QuestionBlock),
        };
      }),
      parentVariantId: variantId,
    };
  }

  if (item.type === 'DONATION') {
    return {
      id: item.id,
      order: item.order,
      type: item.type,
      description: item.description || '',
      required: item.required,
      recurring: item.recurring || [],
      image: item.image,
      imageUrl: item.imageUrl,
      goal: item.goal
        ? {
            id: item.goal.id,
            amountCents: item.goal.amountCents,
          }
        : null,
      variants: item.variants.map(variant => {
        return {
          id: variant.id,
          amountCents: variant.amountCents === null ? undefined : variant.amountCents,
          label: variant.label || '',
          allowAnyAmount: variant.allowAnyAmount,
          order: variant.order,
        };
      }),
    };
  }

  if (item.type === 'QUESTION') {
    return {
      id: item.id,
      order: item.order,
      type: item.type,
      required: item.required,
      questionType: item.questionType,
      questionPrompt: item.questionPrompt,
      description: item.description,
      allowOther: item.allowOther,
      hasValidationList: item.hasValidationList,
      choices: item.choices.map(choice => {
        return {
          id: choice.id,
          order: choice.order,
          text: choice.text,
        };
      }),
    };
  }

  if (item.type === 'GROUP') {
    return {
      id: item.id,
      order: item.order,
      type: item.type,
      blocks: item.blocks.map(block => {
        return {
          id: block.id,
          order: block.order,
          type: block.type,
          required: block.required,
          questionType: block.questionType,
          questionPrompt: block.questionPrompt,
          description: block.description,
          allowOther: block.allowOther,
          hasValidationList: block.hasValidationList,
          choices: block.choices.map(choice => {
            return {
              id: choice.id,
              order: choice.order,
              text: choice.text,
            };
          }),
        };
      }),
    };
  }

  if (item.type === 'SUPPORTER_FEED') {
    return {
      id: item.id,
      order: item.order,
      type: item.type,
      required: item.required,
      title: item.title,
    };
  }

  return item;
}

export const mapBlocks = (item: BlockState, index: number): BlockStateRaw => {
  if (item.type === 'TEXT' && item.data) {
    return {
      id: item.id,
      type: item.type,
      data: JSON.stringify(convertToRaw(item.data.getCurrentContent())),
      order: index,
    };
  }

  if (item.type === 'GROUP' && item.blocks) {
    const newBlocks = item.blocks.map(block =>
      block.questionPrompt && block.questionPrompt.trim()
        ? block
        : { ...block, questionPrompt: defaultBlocksTitle[block.questionType] },
    );
    return {
      id: item.id,
      type: item.type,
      order: index,
      blocks: newBlocks,
    };
  }

  if (item.type === 'DONATION' && (!item.description || item.description.trim() === '')) {
    return {
      ...item,
      order: index,
      description: defaultBlocksTitle[item.type],
      goal: item.goal
        ? {
            id: item.goal?.id,
            amountCents: item.goal?.amountCents,
          }
        : null,
    };
  }

  if (item.type === 'PAYMENT') {
    return {
      ...item,
      order: index,
      description:
        !item.description || item.description.trim() === '' ? defaultBlocksTitle[item.type] : item.description,
      goal: item.goal
        ? {
            id: item.goal?.id,
            amountCents: item.goal?.amountCents,
          }
        : null,
      variants: item.variants.map(variant => {
        return {
          ...variant,
          blocks: variant.blocks?.map(mapBlocks) as PaymentBlock[] | QuestionBlock[],
        };
      }),
    };
  }

  if (item.type === 'QUESTION' && (!item.questionPrompt || !item.questionPrompt.trim())) {
    return {
      ...item,
      order: index,
      questionPrompt: defaultBlocksTitle[item.questionType],
    };
  }

  if (item.type === 'SUPPORTER_FEED' && !item.title.trim()) {
    return {
      ...item,
      order: index,
      title: defaultBlocksTitle[item.type],
    };
  }

  return { ...item, order: index } as BlockStateRaw;
};

export const mapPage = (page: PageDataDraftBlock) => {
  return {
    title: page.title,
    coverPicture: page.coverPicture,
    logo: page.logo,
    tipsEnabled: page.tipsEnabled,
    processingFeePayer: page.processingFeePayer,
    customBodyReceiptContent: page.customBodyReceiptContent,
    customFooterReceiptContent: page.customFooterReceiptContent,
    showCustomReceiptContent: page.showCustomReceiptContent,
    startDate: page.startDate,
    endDate: page.endDate,
  };
};
