import React, { FC, RefObject } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { defaultDonationBlock, defaultPaymentVariant, defaultSupporterFeedBlock } from 'defaults/page';
import { generateOrder } from 'utils';
import { generateEditorState } from 'api/data/pages';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import { DonationBlock as DonationBlockType } from 'components/DonationBlock/types';
import { GroupBlock } from '~/components/GroupBlock/types';
import { PaymentBlock as PaymentBlockType, Variant } from 'components/PaymentBlock/types';
import { QuestionBlock, QuestionType } from 'components/QuestionBlock/types';
import { SupporterFeedBlock } from 'components/SupporterFeedBlock/types';
import { BlockState, BlockTextState } from 'api/data/pages/blocks/types';

import './style.scss';

type SideMenuProps = {
  blocks: BlockState[];
  setBlocks: (blocks: BlockState[]) => void;
  outsideRef: RefObject<HTMLElement>;
  setFocusedElement: (idx: number) => void;
  blockIndex: number;
  groupIndex?: number;
  isNested?: boolean;
};

const SideMenu: FC<SideMenuProps> = ({
  blocks,
  setBlocks,
  outsideRef,
  setFocusedElement,
  blockIndex,
  groupIndex,
  isNested = false,
}: SideMenuProps) => {
  const addTextBlock = () => {
    const newBlocks = [...blocks];
    const textBlock: BlockTextState = generateEditorState();
    newBlocks.splice(blockIndex + 1, 0, textBlock);
    setFocusedElement(blockIndex + 1);
    setBlocks(newBlocks);
  };

  const addPaymentBlock = () => {
    const newBlocks = [...blocks];
    const paymentBlock: PaymentBlockType = {
      id: uuidv4(),
      order: generateOrder(blockIndex, newBlocks),
      description: '',
      type: 'PAYMENT',
      required: true,
      multipleChoice: false,
      variants: [defaultPaymentVariant('PAYMENT') as Variant],
    };
    newBlocks.splice(blockIndex + 1, 0, paymentBlock);
    setFocusedElement(blockIndex + 1);
    setBlocks(newBlocks);
  };

  const addDonationBlock = () => {
    const newBlocks = [...blocks];
    const donationBlock: DonationBlockType = {
      ...defaultDonationBlock(),
      order: generateOrder(blockIndex, newBlocks),
    };

    newBlocks.splice(blockIndex + 1, 0, donationBlock);
    setFocusedElement(blockIndex + 1);
    setBlocks(newBlocks);
  };

  const addSupporterFeedBlock = () => {
    const newBlocks = [...blocks];
    const supporterFeedBlock: SupporterFeedBlock = {
      ...defaultSupporterFeedBlock(),
      order: generateOrder(blockIndex, newBlocks),
    };

    newBlocks.splice(blockIndex + 1, 0, supporterFeedBlock);
    setFocusedElement(blockIndex + 1);
    setBlocks(newBlocks);
  };

  const addQuestionBlock = (question_type: QuestionType) => {
    const newBlocks = [...blocks];

    const choices =
      question_type === 'SINGLE_CHOICE' || question_type === 'MULTIPLE_CHOICE'
        ? [{ id: uuidv4(), order: 0, text: '' }]
        : [];
    if (groupIndex) {
      const group = newBlocks[groupIndex] as GroupBlock;
      const questionBlock: QuestionBlock = {
        id: uuidv4(),
        order: generateOrder(blockIndex, group.blocks),
        type: 'QUESTION',
        required: true,
        questionPrompt: '',
        description: '',
        questionType: question_type,
        allowOther: false,
        hasValidationList: false,
        choices: choices,
      };

      group.blocks.splice(blockIndex + 1, 0, questionBlock);
      newBlocks[groupIndex] = group;
      setFocusedElement(blockIndex + 1);
      setBlocks(newBlocks);
      return;
    }

    const questionBlock: QuestionBlock = {
      id: uuidv4(),
      order: generateOrder(blockIndex, newBlocks),
      type: 'QUESTION',
      required: true,
      questionPrompt: '',
      description: '',
      questionType: question_type,
      allowOther: false,
      hasValidationList: false,
      choices: choices,
    };
    newBlocks.splice(blockIndex + 1, 0, questionBlock);
    setFocusedElement(blockIndex + 1);
    setBlocks(newBlocks);
  };

  const menuItems = [
    { icon: 'money', label: 'Payment', onClick: addPaymentBlock, condition: !groupIndex },
    { icon: 'heart_money', label: 'Donation', onClick: addDonationBlock, condition: !groupIndex && !isNested },
    {
      icon: 'supporter_feed',
      label: 'Supporter feed',
      onClick: addSupporterFeedBlock,
      condition: !groupIndex && !isNested,
    },
    { icon: 'short_answer', label: 'Short Answer', onClick: () => addQuestionBlock('SHORT_ANSWER') },
    { icon: 'long_answer', label: 'Long Answer', onClick: () => addQuestionBlock('LONG_ANSWER') },
    { icon: 'single_choice_answer', label: 'Single Choice', onClick: () => addQuestionBlock('SINGLE_CHOICE') },
    {
      icon: 'multiple_choice_answer',
      label: 'Multiple Choice',
      onClick: () => addQuestionBlock('MULTIPLE_CHOICE'),
      condition: !groupIndex,
      className: 'multiple-choice',
    },
    {
      icon: 'file_upload',
      label: 'File Upload',
      onClick: () => addQuestionBlock('FILE_UPLOAD'),
      condition: !groupIndex,
    },
    { icon: 'signature', label: 'Signature', onClick: () => addQuestionBlock('SIGNATURE'), condition: !groupIndex },
    { icon: 'text', label: 'Text', onClick: addTextBlock, condition: !groupIndex && !isNested },
  ];

  return (
    <DropDown
      closedIcon="plus_menu"
      openedIcon="close_menu"
      outsideRef={outsideRef}
      className="plus-menu"
      dynamicPosition>
      {menuItems.map(
        (item, index) =>
          item.condition !== false && (
            <DropDownItem key={index} icon={item.icon} onClick={item.onClick} className={item.className}>
              {item.label}
            </DropDownItem>
          ),
      )}
    </DropDown>
  );
};

export default SideMenu;
