import React, { FC, useContext, useMemo } from 'react';
import { RouteComponentProps } from 'wouter';
import { getLastTransaction } from 'api/data/subscription';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useSubscriptions from 'hooks/useSubscriptions';
import useTransactions from 'hooks/useTransactions';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { TransactionByUser } from 'api/data/transaction/types';
import UserRolePerspective from 'contexts/UserRolePerspective';
import Header from '../Header';
import LoggedPageContainer from 'components/LoggedPageContainer';
import SubscriptionDetailComponent from 'components/SubscriptionDetail';

type SubscriptionDetailParams = { userId?: string; subscriptionId: string };

const SubscriptionDetail: FC<RouteComponentProps<SubscriptionDetailParams>> = ({ params }) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const { userId, subscriptionId } = params;
  const { currentOrg } = useCurrentOrganization();
  const { transactions } = useTransactions(userId);
  const { subscriptions, loading, refetch: refetchSubscription } = useSubscriptions(userId, subscriptionId);

  const subscription = useMemo(
    () => subscriptions?.find(subscription => subscription.id === subscriptionId),
    [subscriptions, subscriptionId],
  );

  const lastTransaction = useMemo(
    () => getLastTransaction(subscriptionId, transactions),
    [transactions, subscriptionId],
  ) as TransactionByUser;

  const spaceSlug = currentSpaceSlug() || '';

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <LoggedPageContainer
      className="member-profile-page"
      headerChildren={
        <Header
          pageTitle={`${subscription?.user?.fullName || ''}'s subscription detail for ${
            subscription?.page?.title || ''
          }`}
          pageSubtitle="View subscription information below"
          backLocation={isPayerView ? '/member' : `/${spaceSlug}/member/${userId || ''}`}
          payer={subscription?.user}
        />
      }
      organizationStatus={currentOrg?.status}>
      <section>
        <SubscriptionDetailComponent
          subscription={subscription}
          payer={subscription?.user}
          onSubscriptionsChanged={refetchSubscription}
          lastTransaction={lastTransaction}
        />
      </section>
    </LoggedPageContainer>
  );
};

export default SubscriptionDetail;
