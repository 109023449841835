import React, { FC, useState, useEffect, FormEvent } from 'react';
import { useMutation, ApolloError } from '@apollo/client';
import { Link } from 'wouter';
import { default as queryString } from 'query-string';
import * as AuthQuery from 'graphql/auth.graphql';
import { updateUserCache } from 'api/data/user';
import { regexCheckPassword, errorParser } from 'defaults/user';
import Input from 'components/Input';
import PasswordInput, { PasswordRules } from 'components/PasswordInput';
import TermsPrivacy from 'components/TermsPrivacy';
import { LoginQuery } from 'api/data/user/types';
import './style.scss';

type SignupPasswordProps = {
  setStep: (step: string) => void;
  userEmail: string;
};

const SignupPassword: FC<SignupPasswordProps> = ({ setStep, userEmail }) => {
  const searchParams = queryString.parse(window.location.search);
  const [fullName, setFullName] = useState(((searchParams.name || searchParams.Name) as string) || '');
  const [createUser, { loading }] = useMutation(AuthQuery.CreateUser);
  const [password, setPassword] = useState({
    value: '',
    errorMessage: '',
  });
  const [userLogin, { loading: loginLoading }] = useMutation<LoginQuery>(AuthQuery.Login, {
    update: updateUserCache,
  });
  const [userExists, setUserExists] = useState(false);

  const handleCreateAccount = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!regexCheckPassword.test(password.value)) {
      setPassword({ ...password, errorMessage: 'Password criteria not met' });
      return;
    }

    setUserExists(false);
    createUser({
      variables: {
        input: {
          fullName,
          email: userEmail,
          password: password.value,
          signUpData: {
            signupStep: 'password',
            tracking: {
              url: window.location.href,
              userAgent: window.navigator.userAgent,
              referrer: document.referrer,
            },
          },
        },
      },
    })
      .then(() => {
        void userLogin({ variables: { email: userEmail, password: password.value } }).then(({ data }) => {
          if (data?.login?.currentUser) {
            setStep('ORGANIZATION');
          }
        });
      })
      .catch((error: ApolloError) => {
        if (/Email already exists/i.test(error?.message)) setUserExists(true);
        else if (/encrypted_password/i.test(error?.message)) {
          setPassword({ ...password, errorMessage: errorParser(error?.message) });
        }
      });
  };

  useEffect(() => {
    if (!userEmail) {
      setStep('EMAIL');
    }
  }, [userEmail, setStep]);

  return (
    <form onSubmit={handleCreateAccount} aria-label="create account form">
      <h1>Let&apos;s start with the basics</h1>
      <div>
        <Input
          label="Full name"
          placeholder="Full Name"
          value={fullName}
          onChange={({ target }) => setFullName(target.value.trimStart())}
          onBlur={({ target }) => setFullName(target.value.trim())}
          name="input full name"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
        <PasswordInput password={password} setPassword={setPassword} label="Password" />
        <PasswordRules hasError={!!password.errorMessage} password={password.value} />
        {userExists && <p className="login-message">Email is invalid or already taken.</p>}
      </div>
      <div className="footer">
        <button disabled={!password.value || !fullName || loading || loginLoading}>
          {loading || loginLoading ? 'Loading...' : 'Create my free account'}
        </button>
        <p>
          Already have an account? <Link href={`/login${window.location.search}`}>Log in</Link>
        </p>
      </div>
      <TermsPrivacy type="SIGNUP" />
    </form>
  );
};

export default SignupPassword;
