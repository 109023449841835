import { PaymentRequestPaymentMethodEvent, Stripe, StripeElements } from '@stripe/stripe-js';
import { ApolloClient, ApolloError, NormalizedCacheObject } from '@apollo/client';
import { updateResponse } from 'api/data/response';
import { apolloErrorFormatter, pickKeys } from 'utils';
import { processCardWalletSetupIntent, setupIntentDataVar } from '../stripe';
import { createSetupIntentMutation, processPaymentMutation } from './mutation';
import * as ResponseQuery from 'graphql/response.graphql';
import { Response } from 'api/data/response/types';
import { PaymentErrorType, PaymentMethodType, ProcessPaymentData, SetupIntentInput } from './types';

const API = {
  processPayment: async (
    {
      paymentMethodId,
      setupIntentResult,
      customerId,
      paymentMethodType,
      financialConnectionsAccountId,
      response,
    }: ProcessPaymentData,
    client: ApolloClient<NormalizedCacheObject>,
  ) => {
    if (
      paymentMethodType === PaymentMethodType.OFFLINE ||
      paymentMethodId ||
      (setupIntentResult && setupIntentResult.setupIntent && setupIntentResult.setupIntent?.status === 'succeeded') ||
      setupIntentResult?.error
    ) {
      try {
        const processPaymentInput = {
          responseId: response.id || '',
          customerId: customerId,
          paymentMethodId: paymentMethodId || null,
          paymentMethodType: paymentMethodType,
          financialConnectionsAccountId: financialConnectionsAccountId || null,
          error: setupIntentResult?.error?.type
            ? {
                type: setupIntentResult?.error?.type,
                code: setupIntentResult?.error?.code,
                message: setupIntentResult?.error?.message,
              }
            : null,
        };

        const { data } = await processPaymentMutation(processPaymentInput, client);

        const responseStatus = data?.processPayment.response.status;
        const orderStatus = data?.processPayment.response.order.status;

        updateResponse({
          status: responseStatus,
          order: { ...response.order, status: orderStatus },
        });

        if (setupIntentResult?.error && setupIntentResult.error.message) {
          throw {
            message: setupIntentResult.error.message,
          };
        }

        if (data?.processPayment.failureMessage) {
          throw {
            message: data?.processPayment.failureMessage,
          };
        }

        const paymentStatus = data?.processPayment.status;
        return { succeeded: paymentStatus === 'SUCCEEDED' };
      } catch (error) {
        throw { message: apolloErrorFormatter(error as ApolloError) };
      }
    } else {
      throw { message: 'Something went wrong, try again later' };
    }
  },
};

export const saveResponseAndProcessPayment = async (
  client: ApolloClient<NormalizedCacheObject>,
  response: Response,
  processPaymentData: ProcessPaymentData,
) => {
  try {
    response.status !== 'PROCESSED' &&
      (await client.mutate({
        mutation: ResponseQuery.SaveResponse,
        variables: {
          response: {
            ...pickKeys(response, 'id', 'pageId'),
            status: 'SUBMITTED',
            paymentMethodType: processPaymentData.paymentMethodType,
            user: response.user && pickKeys(response.user, 'fullName', 'email'),
          },
        },
      }));
    return await API.processPayment(processPaymentData, client);
  } catch (error) {
    throw { message: apolloErrorFormatter(error as ApolloError) };
  }
};

export const fetchOrProcessSetupIntentData = async (
  method: PaymentMethodType,
  stripe: Stripe | null,
  client: ApolloClient<NormalizedCacheObject>,
  response: Response,
  cardPaymentMethod: StripeElements | PaymentRequestPaymentMethodEvent | null,
) => {
  if (response.status !== 'PROCESSED' && response.pageId)
    await client.mutate({
      mutation: ResponseQuery.SaveResponse,
      variables: {
        response: {
          ...pickKeys(response, 'id', 'pageId'),
          status: 'SUBMITTED',
          user: response.user && pickKeys(response.user, 'fullName', 'email'),
        },
      },
    });

  if (method === PaymentMethodType.ACH) {
    const { paymentMethodDetails, setupIntentResult: setupIntentResultVar } = setupIntentDataVar();
    return {
      paymentMethodId: paymentMethodDetails.paymentMethodId,
      setupIntentResult: setupIntentResultVar,
      customerId: paymentMethodDetails.customerId,
      financialConnectionsAccountId: paymentMethodDetails.financialConnectionsAccountId,
      paymentMethodType: method,
      response,
    };
  }

  if (method === PaymentMethodType.OFFLINE) {
    return {
      paymentMethodType: PaymentMethodType.OFFLINE,
      response,
    };
  }

  const { setupIntentResult, paymentMethodId, customerId } = await processCardWalletSetupIntent(
    method,
    stripe,
    client,
    cardPaymentMethod,
    response.user,
    response.id,
  );
  return {
    paymentMethodId,
    setupIntentResult,
    customerId,
    paymentMethodType: PaymentMethodType.CARD,
    response,
  };
};

export const processPayment = async (
  method: PaymentMethodType,
  stripe: Stripe | null,
  client: ApolloClient<NormalizedCacheObject>,
  response: Response,
  cardPaymentMethod: StripeElements | PaymentRequestPaymentMethodEvent | null,
) => {
  try {
    const processPaymentData = await fetchOrProcessSetupIntentData(method, stripe, client, response, cardPaymentMethod);
    return await saveResponseAndProcessPayment(client, response, processPaymentData);
  } catch (error) {
    throw { message: (error as PaymentErrorType).message };
  }
};

export const createSetupIntent = async (
  setupIntentInput: SetupIntentInput,
  client: ApolloClient<NormalizedCacheObject>,
) => {
  try {
    const { data } = await createSetupIntentMutation(setupIntentInput, client);
    const paymentMethodId = data?.createSetupIntent?.paymentMethodId || '';
    const clientSecret = data?.createSetupIntent?.clientSecret || '';
    const customerId = data?.createSetupIntent.customerId || '';

    return { paymentMethodId, customerId, clientSecret };
  } catch (error) {
    throw { message: apolloErrorFormatter(error as ApolloError) };
  }
};
