import React from 'react';
import createBlockStyleButton from './createBlockStyleButton';
import createInlineStyleButton from './createInLineStyleButton';

const StrikeThroughButton = createInlineStyleButton({
  style: 'STRIKETHROUGH',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M15.6414 14.944C15.6414 15.9573 15.2734 16.7467 14.5374 17.312C13.8014 17.8773 12.8094 18.16 11.5614 18.16C10.9214 18.16 10.3294 18.112 9.78538 18.016C9.24138 17.92 8.78804 17.7867 8.42538 17.616V16.24C8.80938 16.4107 9.28404 16.5653 9.84938 16.704C10.4254 16.8427 11.0174 16.912 11.6254 16.912C12.4787 16.912 13.1187 16.7467 13.5454 16.416C13.9827 16.0853 14.2014 15.6373 14.2014 15.072C14.2014 14.6987 14.1214 14.384 13.9614 14.128C13.8014 13.872 13.524 13.6373 13.1294 13.424C12.7454 13.2 12.2067 12.9653 11.5134 12.72C10.5427 12.368 9.80671 11.936 9.30538 11.424C8.81471 10.912 8.56938 10.2133 8.56938 9.328C8.56938 8.72 8.72404 8.20267 9.03338 7.776C9.34271 7.33867 9.76938 7.00267 10.3134 6.768C10.868 6.53333 11.5027 6.416 12.2174 6.416C12.8467 6.416 13.4227 6.47467 13.9454 6.592C14.468 6.70933 14.9427 6.864 15.3694 7.056L14.9214 8.288C14.5267 8.11733 14.0947 7.97333 13.6254 7.856C13.1667 7.73867 12.6867 7.68 12.1854 7.68C11.4707 7.68 10.932 7.83467 10.5694 8.144C10.2067 8.44267 10.0254 8.84267 10.0254 9.344C10.0254 9.728 10.1054 10.048 10.2654 10.304C10.4254 10.56 10.6867 10.7893 11.0494 10.992C11.412 11.1947 11.9027 11.4133 12.5214 11.648C13.1934 11.8933 13.7587 12.16 14.2174 12.448C14.6867 12.7253 15.0387 13.0613 15.2734 13.456C15.5187 13.8507 15.6414 14.3467 15.6414 14.944Z" />
      <path d="M7.60938 12.848H16.3934V13.648H7.60938V12.848Z" />
    </svg>
  ),
  testId: 'strike-through-button',
});

const ItalicButton = createInlineStyleButton({
  style: 'ITALIC',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M9.08625 18L9.24625 17.216L10.6063 16.896L12.5583 7.68L11.3423 7.376L11.5023 6.576H15.5023L15.3423 7.376L13.9663 7.68L12.0143 16.896L13.2463 17.216L13.0863 18H9.08625Z" />
    </svg>
  ),
  testId: 'italic-button',
});

const BoldButton = createInlineStyleButton({
  style: 'BOLD',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M11.617 6.576C13.1423 6.576 14.2943 6.78933 15.073 7.216C15.8517 7.64267 16.241 8.39467 16.241 9.472C16.241 10.1227 16.0863 10.672 15.777 11.12C15.4783 11.568 15.0463 11.84 14.481 11.936V12.016C14.865 12.0907 15.2117 12.224 15.521 12.416C15.8303 12.608 16.0757 12.8853 16.257 13.248C16.4383 13.6107 16.529 14.0907 16.529 14.688C16.529 15.7227 16.1557 16.5333 15.409 17.12C14.6623 17.7067 13.6383 18 12.337 18H8.065V6.576H11.617ZM11.889 11.104C12.593 11.104 13.0783 10.992 13.345 10.768C13.6223 10.544 13.761 10.2187 13.761 9.792C13.761 9.35467 13.601 9.04 13.281 8.848C12.961 8.656 12.4543 8.56 11.761 8.56H10.481V11.104H11.889ZM10.481 13.024V16H12.065C12.7903 16 13.297 15.8613 13.585 15.584C13.873 15.296 14.017 14.9173 14.017 14.448C14.017 14.0213 13.8677 13.68 13.569 13.424C13.281 13.1573 12.753 13.024 11.985 13.024H10.481Z" />
    </svg>
  ),
  testId: 'bold-button',
});

const UnderlineButton = createInlineStyleButton({
  style: 'UNDERLINE',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M16.3884 13.968C16.3884 14.7573 16.2284 15.472 15.9084 16.112C15.5884 16.7413 15.0978 17.2427 14.4364 17.616C13.7858 17.9787 12.9538 18.16 11.9404 18.16C10.5218 18.16 9.4391 17.776 8.69244 17.008C7.95644 16.2293 7.58844 15.2053 7.58844 13.936V6.576H9.02844V13.984C9.02844 14.912 9.27377 15.632 9.76444 16.144C10.2658 16.656 11.0178 16.912 12.0204 16.912C13.0551 16.912 13.8018 16.64 14.2604 16.096C14.7298 15.5413 14.9644 14.832 14.9644 13.968V6.576H16.3884V13.968Z" />
      <path d="M6.14844 20H17.8444V20.8H6.14844V20Z" />
    </svg>
  ),
  testId: 'underline-button',
});

const OrderedListButton = createBlockStyleButton({
  blockType: 'ordered-list-item',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <rect x="7" y="8" width="12" height="1" rx="0.5" />
      <rect x="7" y="12" width="12" height="1" rx="0.5" />
      <rect x="7" y="16" width="12" height="1" rx="0.5" />
      <path d="M4.108 7.2V6.813H4.831V9H4.399V7.2H4.108ZM5.47991 9.021C5.40391 9.021 5.34091 8.998 5.29091 8.952C5.24291 8.904 5.21891 8.845 5.21891 8.775C5.21891 8.705 5.24291 8.647 5.29091 8.601C5.34091 8.553 5.40391 8.529 5.47991 8.529C5.55391 8.529 5.61491 8.553 5.66291 8.601C5.71091 8.647 5.73491 8.705 5.73491 8.775C5.73491 8.845 5.71091 8.904 5.66291 8.952C5.61491 8.998 5.55391 9.021 5.47991 9.021Z" />
      <path d="M4.291 12.526C4.483 12.366 4.636 12.233 4.75 12.127C4.864 12.019 4.959 11.907 5.035 11.791C5.111 11.675 5.149 11.561 5.149 11.449C5.149 11.347 5.125 11.267 5.077 11.209C5.029 11.151 4.955 11.122 4.855 11.122C4.755 11.122 4.678 11.156 4.624 11.224C4.57 11.29 4.542 11.381 4.54 11.497H4.132C4.14 11.257 4.211 11.075 4.345 10.951C4.481 10.827 4.653 10.765 4.861 10.765C5.089 10.765 5.264 10.826 5.386 10.948C5.508 11.068 5.569 11.227 5.569 11.425C5.569 11.581 5.527 11.73 5.443 11.872C5.359 12.014 5.263 12.138 5.155 12.244C5.047 12.348 4.906 12.474 4.732 12.622H5.617V12.97H4.135V12.658L4.291 12.526ZM6.11566 13.021C6.03966 13.021 5.97666 12.998 5.92666 12.952C5.87866 12.904 5.85466 12.845 5.85466 12.775C5.85466 12.705 5.87866 12.647 5.92666 12.601C5.97666 12.553 6.03966 12.529 6.11566 12.529C6.18966 12.529 6.25066 12.553 6.29866 12.601C6.34666 12.647 6.37066 12.705 6.37066 12.775C6.37066 12.845 6.34666 12.904 6.29866 12.952C6.25066 12.998 6.18966 13.021 6.11566 13.021Z" />
      <path d="M4.165 15.389C4.175 15.189 4.245 15.035 4.375 14.927C4.507 14.817 4.68 14.762 4.894 14.762C5.04 14.762 5.165 14.788 5.269 14.84C5.373 14.89 5.451 14.959 5.503 15.047C5.557 15.133 5.584 15.231 5.584 15.341C5.584 15.467 5.551 15.574 5.485 15.662C5.421 15.748 5.344 15.806 5.254 15.836V15.848C5.37 15.884 5.46 15.948 5.524 16.04C5.59 16.132 5.623 16.25 5.623 16.394C5.623 16.514 5.595 16.621 5.539 16.715C5.485 16.809 5.404 16.883 5.296 16.937C5.19 16.989 5.062 17.015 4.912 17.015C4.686 17.015 4.502 16.958 4.36 16.844C4.218 16.73 4.143 16.562 4.135 16.34H4.543C4.547 16.438 4.58 16.517 4.642 16.577C4.706 16.635 4.793 16.664 4.903 16.664C5.005 16.664 5.083 16.636 5.137 16.58C5.193 16.522 5.221 16.448 5.221 16.358C5.221 16.238 5.183 16.152 5.107 16.1C5.031 16.048 4.913 16.022 4.753 16.022H4.666V15.677H4.753C5.037 15.677 5.179 15.582 5.179 15.392C5.179 15.306 5.153 15.239 5.101 15.191C5.051 15.143 4.978 15.119 4.882 15.119C4.788 15.119 4.715 15.145 4.663 15.197C4.613 15.247 4.584 15.311 4.576 15.389H4.165ZM6.1889 17.021C6.1129 17.021 6.0499 16.998 5.9999 16.952C5.9519 16.904 5.9279 16.845 5.9279 16.775C5.9279 16.705 5.9519 16.647 5.9999 16.601C6.0499 16.553 6.1129 16.529 6.1889 16.529C6.2629 16.529 6.3239 16.553 6.3719 16.601C6.4199 16.647 6.4439 16.705 6.4439 16.775C6.4439 16.845 6.4199 16.904 6.3719 16.952C6.3239 16.998 6.2629 17.021 6.1889 17.021Z" />
    </svg>
  ),
  testId: 'ordered-list-button',
});

const UnorderedListButton = createBlockStyleButton({
  blockType: 'unordered-list-item',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <circle cx="5.5" cy="8.5" r="0.5" />
      <circle cx="5.5" cy="12.5" r="0.5" />
      <circle cx="5.5" cy="16.5" r="0.5" />
      <rect x="7" y="8" width="12" height="1" rx="0.5" />
      <rect x="7" y="12" width="12" height="1" rx="0.5" />
      <rect x="7" y="16" width="12" height="1" rx="0.5" />
    </svg>
  ),
  testId: 'unordered-list-button',
});

const AlignLeftButton = createBlockStyleButton({
  blockType: 'block-left-alignment',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M19.1999 7.73317H4.79993C4.5066 7.73317 4.2666 7.49317 4.2666 7.19984C4.2666 6.9065 4.5066 6.6665 4.79993 6.6665H19.1999C19.4933 6.6665 19.7333 6.9065 19.7333 7.19984C19.7333 7.49317 19.4933 7.73317 19.1999 7.73317Z" />
      <path d="M14.3999 10.9334H4.79993C4.5066 10.9334 4.2666 10.6934 4.2666 10.4C4.2666 10.1067 4.5066 9.8667 4.79993 9.8667H14.3999C14.6933 9.8667 14.9333 10.1067 14.9333 10.4C14.9333 10.6934 14.6933 10.9334 14.3999 10.9334Z" />
      <path d="M19.1999 14.1336H4.79993C4.5066 14.1336 4.2666 13.8936 4.2666 13.6002C4.2666 13.3069 4.5066 13.0669 4.79993 13.0669H19.1999C19.4933 13.0669 19.7333 13.3069 19.7333 13.6002C19.7333 13.8936 19.4933 14.1336 19.1999 14.1336Z" />
      <path d="M14.3999 17.3333H4.79993C4.5066 17.3333 4.2666 17.0933 4.2666 16.7999C4.2666 16.5066 4.5066 16.2666 4.79993 16.2666H14.3999C14.6933 16.2666 14.9333 16.5066 14.9333 16.7999C14.9333 17.0933 14.6933 17.3333 14.3999 17.3333Z" />
    </svg>
  ),
  testId: 'left-alignment-button',
});

const AlignCenterButton = createBlockStyleButton({
  blockType: 'block-center-alignment',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M19.1999 7.73317H4.79993C4.5066 7.73317 4.2666 7.49317 4.2666 7.19984C4.2666 6.9065 4.5066 6.6665 4.79993 6.6665H19.1999C19.4933 6.6665 19.7333 6.9065 19.7333 7.19984C19.7333 7.49317 19.4933 7.73317 19.1999 7.73317Z" />
      <path d="M16.8003 10.9334H7.20033C6.90699 10.9334 6.66699 10.6934 6.66699 10.4C6.66699 10.1067 6.90699 9.8667 7.20033 9.8667H16.8003C17.0937 9.8667 17.3337 10.1067 17.3337 10.4C17.3337 10.6934 17.0937 10.9334 16.8003 10.9334Z" />
      <path d="M19.1999 14.1336H4.79993C4.5066 14.1336 4.2666 13.8936 4.2666 13.6002C4.2666 13.3069 4.5066 13.0669 4.79993 13.0669H19.1999C19.4933 13.0669 19.7333 13.3069 19.7333 13.6002C19.7333 13.8936 19.4933 14.1336 19.1999 14.1336Z" />
      <path d="M16.8003 17.3333H7.20033C6.90699 17.3333 6.66699 17.0933 6.66699 16.7999C6.66699 16.5066 6.90699 16.2666 7.20033 16.2666H16.8003C17.0937 16.2666 17.3337 16.5066 17.3337 16.7999C17.3337 17.0933 17.0937 17.3333 16.8003 17.3333Z" />
    </svg>
  ),
  testId: 'center-alignment-button',
});

const AlignRightButton = createBlockStyleButton({
  blockType: 'block-right-alignment',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M19.1999 7.73317H4.79993C4.5066 7.73317 4.2666 7.49317 4.2666 7.19984C4.2666 6.9065 4.5066 6.6665 4.79993 6.6665H19.1999C19.4933 6.6665 19.7333 6.9065 19.7333 7.19984C19.7333 7.49317 19.4933 7.73317 19.1999 7.73317Z" />
      <path d="M19.1997 10.9334H9.59974C9.30641 10.9334 9.06641 10.6934 9.06641 10.4C9.06641 10.1067 9.30641 9.8667 9.59974 9.8667H19.1997C19.4931 9.8667 19.7331 10.1067 19.7331 10.4C19.7331 10.6934 19.4931 10.9334 19.1997 10.9334Z" />
      <path d="M19.1999 14.1336H4.79993C4.5066 14.1336 4.2666 13.8936 4.2666 13.6002C4.2666 13.3069 4.5066 13.0669 4.79993 13.0669H19.1999C19.4933 13.0669 19.7333 13.3069 19.7333 13.6002C19.7333 13.8936 19.4933 14.1336 19.1999 14.1336Z" />
      <path d="M19.1997 17.3333H9.59974C9.30641 17.3333 9.06641 17.0933 9.06641 16.7999C9.06641 16.5066 9.30641 16.2666 9.59974 16.2666H19.1997C19.4931 16.2666 19.7331 16.5066 19.7331 16.7999C19.7331 17.0933 19.4931 17.3333 19.1997 17.3333Z" />
    </svg>
  ),
  testId: 'right-alignment-button',
});

const HeadlineOneButton = createBlockStyleButton({
  blockType: 'header-one',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M11.8532 19H9.90919V13.132H3.77119V19H1.82719V6.148H3.77119V11.476H9.90919V6.148H11.8532V19ZM20.278 19H18.388V10.468C18.388 10.024 18.394 9.616 18.406 9.244C18.418 8.872 18.436 8.512 18.46 8.164C18.304 8.32 18.142 8.476 17.974 8.632C17.806 8.776 17.614 8.938 17.398 9.118L15.994 10.252L15.022 9.028L18.694 6.148H20.278V19Z" />
    </svg>
  ),
  testId: 'header-one-button',
});

const HeadlineTwoButton = createBlockStyleButton({
  blockType: 'header-two',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M11.8077 17H10.5477V12.338H5.42366V17H4.16366V7.004H5.42366V11.232H10.5477V7.004H11.8077V17ZM20.4626 17H13.8546V15.978L16.4726 13.332C16.9766 12.828 17.4013 12.38 17.7466 11.988C18.092 11.596 18.3533 11.2133 18.5306 10.84C18.708 10.4573 18.7966 10.042 18.7966 9.594C18.7966 9.04333 18.6333 8.628 18.3066 8.348C17.98 8.05867 17.5553 7.914 17.0326 7.914C16.5473 7.914 16.118 7.998 15.7446 8.166C15.3806 8.334 15.0073 8.56733 14.6246 8.866L13.9666 8.04C14.3586 7.71333 14.8066 7.438 15.3106 7.214C15.824 6.98067 16.398 6.864 17.0326 6.864C17.966 6.864 18.7033 7.102 19.2446 7.578C19.786 8.04467 20.0566 8.69333 20.0566 9.524C20.0566 10.0467 19.9493 10.5367 19.7346 10.994C19.52 11.4513 19.2213 11.904 18.8386 12.352C18.456 12.7907 18.008 13.262 17.4946 13.766L15.4086 15.824V15.88H20.4626V17Z" />
    </svg>
  ),
  testId: 'header-two-button',
});

const HeadlineThreeButton = createBlockStyleButton({
  blockType: 'header-three',
  component: (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="1" fill="white" />
      <path d="M11.8351 17H10.7551V13.004H6.36314V17H5.28314V8.432H6.36314V12.056H10.7551V8.432H11.8351V17ZM18.9297 10.436C18.9297 11.012 18.7697 11.472 18.4497 11.816C18.1297 12.152 17.6977 12.376 17.1537 12.488V12.536C17.8417 12.616 18.3537 12.832 18.6897 13.184C19.0257 13.536 19.1937 13.996 19.1937 14.564C19.1937 15.06 19.0777 15.504 18.8457 15.896C18.6137 16.28 18.2537 16.58 17.7657 16.796C17.2857 17.012 16.6657 17.12 15.9057 17.12C15.4577 17.12 15.0417 17.084 14.6577 17.012C14.2737 16.948 13.9057 16.828 13.5537 16.652V15.668C13.9137 15.844 14.3017 15.984 14.7177 16.088C15.1337 16.184 15.5337 16.232 15.9177 16.232C16.6857 16.232 17.2377 16.084 17.5737 15.788C17.9177 15.484 18.0897 15.068 18.0897 14.54C18.0897 14.004 17.8777 13.62 17.4537 13.388C17.0377 13.148 16.4497 13.028 15.6897 13.028H14.8617V12.128H15.7017C16.4057 12.128 16.9377 11.98 17.2977 11.684C17.6657 11.388 17.8497 10.996 17.8497 10.508C17.8497 10.092 17.7097 9.772 17.4297 9.548C17.1497 9.316 16.7697 9.2 16.2897 9.2C15.8257 9.2 15.4297 9.268 15.1017 9.404C14.7737 9.54 14.4497 9.712 14.1297 9.92L13.6017 9.2C13.9057 8.96 14.2817 8.752 14.7297 8.576C15.1857 8.4 15.7017 8.312 16.2777 8.312C17.1737 8.312 17.8377 8.512 18.2697 8.912C18.7097 9.312 18.9297 9.82 18.9297 10.436Z" />
    </svg>
  ),
  testId: 'header-three-button',
});

export const StyleButtons = [
  BoldButton,
  ItalicButton,
  UnderlineButton,
  StrikeThroughButton,
  HeadlineOneButton,
  HeadlineTwoButton,
  HeadlineThreeButton,
  UnorderedListButton,
  OrderedListButton,
  AlignLeftButton,
  AlignCenterButton,
  AlignRightButton,
];
