import React, { FC, useState, useEffect } from 'react';
import { useLocation, useRoute } from 'wouter';
import { default as queryString } from 'query-string';
import { showPlural } from 'utils';
import Message from 'components/Message';
import './style.scss';

export type RequestSentMessageProps = { showMessage: boolean };
const RequestSentMessage: FC<RequestSentMessageProps> = ({ showMessage }) => {
  const [location, setLocation] = useLocation();
  const [matchSharePage] = useRoute('/:currentSpace/pages/:id/share');
  const [requestSentData, setRequestSentData] = useState({ showModal: false, requestsQty: 0, requestsFailed: 0 });

  useEffect(() => {
    const searchParams = queryString.parse(window.location.search);
    const requestsSent = searchParams.requestSent as string;
    const requestsFailed = searchParams.requestFailed as string;

    if (showMessage && (requestsSent || requestsFailed)) {
      setRequestSentData({
        showModal: true,
        requestsQty: parseInt(requestsSent),
        requestsFailed: parseInt(requestsFailed),
      });
      setLocation(location);
    }
  }, [setRequestSentData, location, setLocation, showMessage]);

  const message = matchSharePage
    ? '1 request sent!'
    : `${requestSentData.requestsQty} new request${showPlural(requestSentData.requestsQty)}! `;

  const failMessage = `${requestSentData.requestsFailed} request${showPlural(
    requestSentData.requestsFailed,
  )} could not be sent!`;

  return (
    <Message
      className="request-sent"
      type={requestSentData.requestsFailed > 0 ? 'failed' : 'succeeded'}
      setShowMessage={showMessage => {
        setRequestSentData({ ...requestSentData, showModal: showMessage });
      }}
      showMessage={requestSentData.showModal}>
      {requestSentData.requestsQty > 0 && message}
      {requestSentData.requestsFailed > 0 && failMessage}
    </Message>
  );
};

export default RequestSentMessage;
