import React, { FC } from 'react';
import './style.scss';

interface BadgeProps {
  label?: string;
  size?: 'small' | 'medium';
}

const Badge: FC<BadgeProps> = ({ label, size = 'medium' }) => {
  return <span className={`badge ${size}`}>{label}</span>;
};

export default Badge;
