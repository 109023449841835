import React, { FC } from 'react';
import { Link } from 'wouter';
import useUserSession from 'hooks/useUserSession';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import './style.scss';

type ErrorType = { code: string };

const errorMap: {
  [key: string]: JSX.Element;
} = {
  PAGE_NOT_FOUND: (
    <>
      <h1>Oops!</h1>
      <div className="not-found">
        <p>This page was not found</p>
      </div>
    </>
  ),
  CANCELED_REQUEST: (
    <div className="canceled-request">
      <h1>
        Uh oh{' '}
        <span role="img" aria-label="confused">
          😕
        </span>
      </h1>
      <p>It looks like this request has been canceled. No worries! Just reach out to the sender with questions.</p>
    </div>
  ),
  DEFAULT: (
    <>
      <h1>Oops!</h1>
      <p className="default">Something went wrong, please try again later.</p>
    </>
  ),
};

const Error: FC<ErrorType> = ({ code }: ErrorType) => {
  const { data } = useUserSession();
  const { currentOrg } = useCurrentOrganization();
  const spaceSlug = currentSpaceSlug() || '';

  return (
    <div className="error">
      {errorMap[code] ? errorMap[code] : errorMap.DEFAULT}
      {data?.session?.currentUser && currentOrg ? (
        <Link to={`/${spaceSlug}/pages`} className="button">
          Go to my pages
        </Link>
      ) : null}
    </div>
  );
};

export default Error;
