import React, { FC } from 'react';
import clsx from 'clsx';
import './style.scss';

interface BannerProps {
  className?: string;
  type?: 'default' | 'warning';
}

const Banner: FC<BannerProps> = ({ children, className, type = 'default' }) => {
  return <div className={clsx('banner', className, type)}>{children}</div>;
};

export default Banner;
