import React, { FC } from 'react';
import emptyState from 'assets/requests_empty_state.svg';
import './style.scss';

interface BlankStateProps {
  title: string;
  message: string;
}

const BlankState: FC<BlankStateProps> = ({ title, message }) => {
  return (
    <div className="empty-row">
      <img src={emptyState} alt="Empty state" />
      <h2>{title}</h2>
      <p>{message}</p>
    </div>
  );
};

export default BlankState;
