import React, { FC } from 'react';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import { ModalProps } from './types';
import './style.scss';

const Modal: FC<ModalProps> = ({
  children,
  visible,
  confirmlabel,
  denyLabel,
  handleOnConfirm,
  handleOnCancel,
  handleOnClose,
  header,
  headerIcon,
  className,
  confirmButtonProps,
  denyButtonProps,
  position,
  modalRef,
  fullScreenMobile = false,
}: ModalProps) => {
  const classNameHeader = clsx('row space-between align-center modal-header', { 'header-line': !!header });
  const classNameOverlay = clsx('overlay', position, className, { 'full-screen-mobile': fullScreenMobile });

  const content = (
    <CSSTransition in={visible} timeout={400} classNames="animation" unmountOnExit>
      <div className={classNameOverlay} ref={modalRef} onClick={ev => ev.stopPropagation()} role="presentation">
        <div className="modal">
          <div className={classNameHeader}>
            <span className="row align-center">
              {headerIcon && ICONS[headerIcon]} {header}
            </span>
            <IconButton icon="close" onClick={handleOnClose || handleOnCancel} className="modal-close" />
          </div>
          {children && (
            <>
              <div className="modal-content">{children}</div>
              <div className="horizontal-line" />
            </>
          )}
          {Boolean(denyLabel || confirmlabel) && (
            <div className="modal-footer">
              {denyLabel && (
                <button type="button" className="secondary" onClick={handleOnCancel} {...denyButtonProps}>
                  {denyLabel}
                </button>
              )}
              {confirmlabel && (
                <button type="button" className="primary" onClick={handleOnConfirm} {...confirmButtonProps}>
                  {confirmlabel}
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </CSSTransition>
  );

  return createPortal(content, document.body);
};

export default Modal;
