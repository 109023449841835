import { recurringOptions } from '../defaults/page';

const getRecurringName = (recurringType = ''): string => {
  return recurringOptions[recurringType]?.noun || 'Once';
};

export const getRecurringLabel = (recurringType = ''): string => {
  return recurringOptions[recurringType]?.label || 'Once';
};

export default getRecurringName;
