import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import UserRolePerspective from 'contexts/UserRolePerspective';
import { SubscriptionById, SubscriptionsByUser, SubscriptionsByUserAndOrg } from 'api/data/subscription/types';

export default function useSubscriptions(userId?: string, subscriptionId?: string) {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const subscriptionIdPresent = !!subscriptionId;

  const {
    data: subscriptionById,
    loading: loadingById,
    refetch: refetchById,
  } = useQuery<SubscriptionById>(SubscriptionQuery.GetSubscriptionById, {
    variables: { id: subscriptionId },
    skip: !isPayerView || !subscriptionIdPresent,
  });

  const {
    data: subscriptionByUser,
    loading: loadingByUser,
    refetch: refetchByUser,
  } = useQuery<SubscriptionsByUser>(SubscriptionQuery.GetSubscriptionsFromCurrentUser, {
    skip: !isPayerView || subscriptionIdPresent,
  });

  const {
    data: subscriptionByUserAndOrg,
    loading: loadingByOrg,
    refetch: refetchByUserAndOrg,
  } = useQuery<SubscriptionsByUserAndOrg>(SubscriptionQuery.GetSubscriptionsByUserAndOrg, {
    variables: { userId, currentSpace: currentSpaceSlug() },
    skip: isPayerView,
    fetchPolicy: 'network-only',
  });

  return {
    subscriptions:
      (subscriptionById && [subscriptionById.subscription]) ||
      subscriptionByUser?.subscriptionsFromCurrentUser ||
      subscriptionByUserAndOrg?.subscriptionsByUserAndOrg,
    loading: loadingById || loadingByUser || loadingByOrg,
    refetch: refetchById || refetchByUser || refetchByUserAndOrg,
  };
}
