import React, { FC, RefObject } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import { useLocation } from 'wouter';
import * as AuthQuery from 'graphql/auth.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from '../Menu/DropDownItem';
import DropDownItemDivider from '../Menu/DropDownItemDivider';
import ICONS from '../Icons';
import { SessionQuery } from 'api/data/user/types';

interface ProfileMenuProps {
  data: SessionQuery;
  refOutside: RefObject<HTMLElement>;
}

const ProfileMenu: FC<ProfileMenuProps> = ({ data, refOutside }) => {
  const client = useApolloClient();
  const [, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug();

  const [userLogout] = useMutation(AuthQuery.Logout, {
    onCompleted: () => {
      void client.clearStore();
      currentSpaceSlug(null);
    },
  });

  const logout = async () => {
    await userLogout();
    setLocation('/login');
  };

  return (
    <DropDown
      openedIcon="profile_outline"
      closedIcon="profile_outline"
      outsideRef={refOutside}
      iconSide="RIGHT"
      className="profile-menu">
      <DropDownItem
        icon=""
        onClick={() => setLocation(spaceSlug ? `/${spaceSlug}/my-profile` : '/member')}
        className="user-menu-item">
        <span className="user-icon">{ICONS['profile_outline']}</span>
        <span className="breakword-anywhere item-text">
          {data.session?.currentUser?.email}
          <b>View profile</b>
        </span>
      </DropDownItem>
      <DropDownItemDivider />
      <DropDownItem icon="logout" onClick={() => void logout()}>
        <span className="item-text">Log out</span>
      </DropDownItem>
    </DropDown>
  );
};

export default ProfileMenu;
