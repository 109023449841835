import { RefundReason } from '~/api/data/transaction/types';
import { CardFunding } from 'api/data/transaction/types';
import { TagType } from '~/components/Tag';

export const cardFundingTranslator: Record<CardFunding, string> = {
  CREDIT: 'Credit card',
  DEBIT: 'Debit card',
  PREPAID: 'Prepaid card',
  UNKNOWN: 'Unknown',
};

export const refundReasonTranslator: Record<RefundReason, string> = {
  REQUESTED_BY_CUSTOMER: 'due to customer request',
  FRAUDULENT: 'Refund initiated due to fraudulent charge',
  EXPIRED_UNCAPTURED_CHARGE: 'Refund initiated due to expired authorization without capturing the amount',
  DUPLICATE: 'Refund initiated due to duplicated charge',
  DISPUTE_LOST: 'Refund initiated due to lost dispute',
};

type StatusType = {
  [key: string]: TagType;
};

export const mapStatusClass: StatusType = {
  SUCCEEDED: 'positive',
  PENDING: 'pending',
  CANCELED: 'neutral',
  FAILED: 'negative',
};
