import React, { FC, useEffect, useState } from 'react';
import { findAnswer } from 'api/data/response';
import Link from './Link';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import Upload from './Upload';
import { Answer, Group } from 'api/data/response/types';
import { QuestionBlock } from '../types';
import warningCircleIcon from 'assets/warning_circle.svg';
import warningIcon from 'assets/warning_orange.svg';
import { Variant } from 'components/PaymentBlock/types';
import './style.scss';

interface FileUploadProps {
  data: QuestionBlock;
  placeholder: 'Recipient will upload a file here' | 'Browse';
  readOnly?: boolean;
  pageId?: string;
  group?: Group;
  groupedAnswer?: Answer;
  saveGroupedAnswer?: (value: string) => void;
  parent?: Variant;
}

const FileUpload: FC<FileUploadProps> = ({
  readOnly = false,
  pageId,
  placeholder,
  groupedAnswer,
  data,
  group,
  saveGroupedAnswer,
  parent,
}) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'FILE_UPLOAD');
  const defaultActiveKeyTab = !answer.value || answer.file ? 'upload' : 'link';
  const [tabContentKey, setTabContentKey] = useState(defaultActiveKeyTab);
  const [uploadError, setUploadError] = useState('');
  const [showWarningMessage, setShowWarningMessage] = useState(false);

  const warningMessage = answer.file
    ? 'If you add a link your file will be deleted'
    : 'If you add a file your link will be deleted';

  useEffect(() => {
    setShowWarningMessage(
      (tabContentKey === 'link' && !!answer.file) || (tabContentKey === 'upload' && !answer.file && !!answer.value),
    );

    if (uploadError) setShowWarningMessage(false);

    if (tabContentKey === 'link') setUploadError('');
  }, [answer.file, answer.value, tabContentKey, uploadError]);

  return (
    <div className="file-upload">
      <Tabs activeKey={tabContentKey}>
        <Tab label="Upload" tabKey="upload" onClick={setTabContentKey}></Tab>
        <Tab label="Link" tabKey="link" onClick={setTabContentKey}></Tab>
      </Tabs>
      <div className="box">
        {tabContentKey === 'upload' ? (
          <Upload
            data={data}
            placeholder={placeholder}
            readOnly={readOnly}
            setUploadError={setUploadError}
            group={group}
            groupedAnswer={groupedAnswer}
            pageId={pageId}
            saveGroupedAnswer={saveGroupedAnswer}
            parent={parent}
          />
        ) : (
          <Link
            data={data}
            answerValue={answer.file ? '' : answer?.value}
            readOnly={readOnly}
            group={group}
            saveGroupedAnswer={saveGroupedAnswer}
            parent={parent}
          />
        )}
      </div>
      {showWarningMessage && (
        <p>
          <img src={warningIcon} alt="warning icon" />
          {warningMessage}
        </p>
      )}
      {uploadError && tabContentKey === 'upload' && (
        <p>
          <img src={warningCircleIcon} alt="Upload Icon" /> {uploadError}
        </p>
      )}
    </div>
  );
};

export default FileUpload;
