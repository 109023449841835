import React, { FC, useState } from 'react';
import iframeCode from 'defaults/iframeCode';
import CopyButton from 'components/Button/CopyButton';
import Message from 'components/Message';
import QrCode from 'components/QrCode';
import ResizableTextArea from 'components/ResizableTextArea';
import Requests from './Requests';
import SocialSharing from 'components/SocialSharing';
import { PageDataDraftBlock } from 'api/data/pages/types';
import goToIcon from 'assets/go-to.svg';
import './style.scss';

interface TabContentInterface {
  page: PageDataDraftBlock;
  tabContentKey: string;
  onShowPreview: (index: number) => void;
  selectedIndex?: number;
}

const TabContent: FC<TabContentInterface> = ({ page, tabContentKey, onShowPreview, selectedIndex }) => {
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const payerUrl = `${window.location.origin}/${page.slug}`;

  const copy = (text: string) => {
    void navigator.clipboard.writeText(text);
    setShowCopyMessage(true);
  };

  const pageTitle = page.title?.trim();

  const iframePayerPage = iframeCode(page.slug);
  const tabElement: {
    [key: string]: JSX.Element;
  } = {
    requests: (
      <Requests pageId={page.id} slug={page.slug} onShowPreview={onShowPreview} selectedIndex={selectedIndex} />
    ),
    link: (
      <>
        <div className="share-container">
          <div className="row align-center share-link">
            <a href={`/${page.slug}`} target="_blank" rel="noreferrer" className="paragraph-x-small">
              {`${window.location.host}/${page.slug}`}
              <img src={goToIcon} alt="go to page" />
            </a>
            <CopyButton
              buttonText="Copy"
              link={`${window.location.origin}/${page.slug || ''}`}
              className="copy-button secondary"
            />
          </div>
        </div>
        <SocialSharing url={payerUrl} title={pageTitle} shareType="page" />
      </>
    ),
    qrCode: (
      <>
        <div className="share-container">
          <QrCode
            title={page.title}
            titleUrl={`${window.location.host}/${page.slug}`}
            validation={page && tabContentKey === 'qrCode'}
            qrCodeUrl={payerUrl}
          />
        </div>
      </>
    ),
    embed: (
      <>
        <Message
          className="row align-center justify-center default-message"
          showMessage={showCopyMessage}
          setShowMessage={setShowCopyMessage}>
          HTML copied!
        </Message>
        <div className="share-container">
          <div className="embed-code">
            <p>Place this code in your websites&apos;s HTML where you want your page to appear.</p>
            <div
              className="row direction-column align-center space-between copy-html"
              role="presentation"
              onClick={() => copy(iframePayerPage)}>
              <ResizableTextArea disabled value={iframePayerPage} className="paragraph-medium" />
              <button className="button-outline" type="button">
                Copy HTML
              </button>
            </div>
          </div>
        </div>
      </>
    ),
  };
  return tabElement[tabContentKey];
};

export default TabContent;
