import React, { FC, useState, FormEvent } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation, useRoute, Link } from 'wouter';
import * as AuthQuery from 'graphql/auth.graphql';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { updateUserCache } from 'api/data/user';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { extractQueryStringFromParams, validateEmail, chooseManagedSpace } from 'utils';
import useErrorMessage from 'hooks/useErrorMessage';
import { useSavePageRedirect } from '../Template/AddPageModal';
import Email from '~/components/Input/Email';
import { LoginQuery } from 'api/data/user/types';
import logoWhite from 'assets/logo_omella_white.svg';
import oLogoBg from 'assets/o_circle_logo.svg';
import warningIcon from 'assets/warning_icon.svg';
import './style.scss';

const Login: FC = () => {
  const [login, setLogin] = useState<Record<string, string>>({
    email: extractQueryStringFromParams('email'),
    password: '',
  });
  const [location, setLocation] = useLocation();
  const [match] = useRoute('/login');
  const spaceSlug = currentSpaceSlug();
  const [userLogin, { error, loading }] = useMutation<LoginQuery>(AuthQuery.Login, {
    update: updateUserCache,
    refetchQueries: spaceSlug
      ? [
          {
            query: OrganizationQuery.GetOrganization,
            variables: { currentSpace: spaceSlug },
          },
        ]
      : [],
  });
  const [savePageAndRedirect] = useSavePageRedirect();
  const errorMessage = useErrorMessage(error);

  const handleOnChange = (value: string, name: string) => {
    setLogin({
      ...login,
      [name]: value,
    });
  };

  const handleLogin = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (login.email && login.password) {
      await userLogin({ variables: { ...login } })
        .then(({ data }) => {
          if (data?.login?.currentUser) {
            if (match) {
              const firstSpace = chooseManagedSpace(data?.login.managedOrganizations);

              if (extractQueryStringFromParams('redirectTo') === 'editor' && firstSpace?.slug) {
                void savePageAndRedirect(firstSpace.slug);
              } else {
                const path = firstSpace ? `/${firstSpace.slug}/pages` : '/member';
                setLocation(path);
              }
            } else {
              setLocation(location);
            }
          }
        })
        .catch(() => null);
    }
  };

  return (
    <main className="login">
      <section className="fields-container">
        <img src={logoWhite} alt="omella logo" className="omella-logo" />
        <img src={oLogoBg} alt="omella logo background" className="omella-logo-background" />

        <form onSubmit={event => void handleLogin(event)} aria-label="login form">
          {errorMessage && (
            <div className="error">
              <h4>
                <img src={warningIcon} alt="warning icon" />
                Your email or password is incorrect.
              </h4>
            </div>
          )}
          <Email
            rawInput
            placeholder="Email"
            value={login.email}
            onChange={({ target }) => handleOnChange(target.value, 'email')}
            name="email"
            aria-label="email"
            onBlur={({ target }) => handleOnChange(target.value, 'email')}
          />
          <input
            type="password"
            placeholder="Password"
            autoComplete="off"
            value={login.password}
            onChange={({ target }) => handleOnChange(target.value, target.name)}
            name="password"
            aria-label="password"
          />
          <div className="link forgot-password">
            <Link href="/password-recover">Forgot password?</Link>
          </div>
          <button className="login-button" disabled={!validateEmail(login.email) || !login.password}>
            {loading ? 'Loading...' : 'Login'}
          </button>
          <div className="link">
            <Link href="/signup">Sign up</Link>
          </div>
        </form>
      </section>
    </main>
  );
};

export default Login;
