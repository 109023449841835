import { RequestData } from '~/api/data/request/types';
import dayjs from 'dayjs';

const mapStatus: {
  [key: string]: string;
} = {
  created: 'sending',
};

const translateStatus = ({ status, dueDate }: RequestData) => {
  const translatedStatus =
    status === 'SENT' && dayjs(dueDate).isBefore(dayjs(), 'day') ? 'overdue' : status.toLocaleLowerCase();

  return mapStatus[translatedStatus] || translatedStatus;
};

export default translateStatus;
