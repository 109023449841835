import React, { FC, useRef, useState, useEffect } from 'react';
import { useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import { isSpaceNameTaken } from 'utils';
import * as SpaceQuery from 'graphql/space.graphql';
import Branding from './Branding';
import Naming from './Naming';
import Header from 'components/Header';
import Steps from 'components/Steps';
import { SpaceData, CreateSpace } from './types';
import './style.scss';

const steps = [{ title: 'Naming' }, { title: 'Branding' }];
const NewSpace: FC = () => {
  const [, setLocation] = useLocation();
  const { currentOrg } = useCurrentOrganization();
  const { spaces } = useSpacesFromCurrentOrg();

  const [createSpace, { loading }] = useMutation<CreateSpace>(SpaceQuery.CreateSpace);

  const [currentStep, setCurrentStep] = useState(0);
  const [spaceData, setSpaceData] = useState<SpaceData>({
    name: '',
    coverPicture: '',
    coverPictureUrl: '',
    logoUrl: '',
    logo: '',
  });
  const refOutside = useRef<HTMLElement>(null);

  const invalidSpaceName = isSpaceNameTaken(spaces, spaceData.name);
  const setNextStep = () => setCurrentStep(currentStep + 1);

  const componentsSteps = [
    <Naming
      spaceData={spaceData}
      setSpaceData={setSpaceData}
      key={0}
      invalidSpaceName={invalidSpaceName}
      setNextStep={setNextStep}
    />,
    <Branding spaceData={spaceData} setSpaceData={setSpaceData} key={1} setNextStep={setNextStep} />,
  ];

  useEffect(() => {
    if (currentOrg) {
      setSpaceData(space => ({
        name: space.name,
        coverPicture: currentOrg.coverPicture || '',
        coverPictureUrl: currentOrg.coverPictureUrl || '',
        logoUrl: currentOrg.logoUrl || '',
        logo: currentOrg.logo || '',
      }));
    }
  }, [currentOrg, setSpaceData]);

  const handleFinish = async () => {
    const { data } = await createSpace({
      variables: {
        space: {
          name: spaceData.name,
          logo: spaceData.logo,
          coverPicture: spaceData.coverPicture,
        },
      },
    });
    setLocation(`/${data?.createSpace.slug || ''}/space-settings/members?spaceCreated=true`);
  };

  return (
    <main className="new-space">
      <Header refOutside={refOutside} showSideBar={false} displayRightElements={false} />
      <Steps current={currentStep} items={steps}></Steps>
      <div className="content">
        <div>{componentsSteps[currentStep]}</div>
        <footer>
          {currentStep > 0 && (
            <button onClick={() => setCurrentStep(currentStep - 1)} className="button-outline button-size-ml">
              Back
            </button>
          )}
          {currentStep < steps.length - 1 && (
            <button disabled={!spaceData.name || invalidSpaceName} onClick={setNextStep} className="button-size-ml">
              Next
            </button>
          )}
          {currentStep === steps.length - 1 && (
            <button onClick={() => void handleFinish()} disabled={loading} className="button-size-ml">
              {loading ? 'Saving' : 'Finish'}
            </button>
          )}
        </footer>
      </div>
    </main>
  );
};

export default NewSpace;
