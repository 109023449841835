import React, { FC } from 'react';

interface CircularProgressBarProps {
  score: number;
}

const CircularProgressBar: FC<CircularProgressBarProps> = ({ score }) => {
  const commonCircleProps = {
    cx: '50',
    cy: '50',
    r: '30',
    strokeWidth: '15',
    fill: 'none',
  };

  return (
    <svg width="24" height="24" viewBox="0 0 100 100">
      <circle {...commonCircleProps} stroke="#E1F7FF" />
      <circle
        {...commonCircleProps}
        stroke="#1B9DE2"
        transform="rotate(0 50 50)"
        strokeDasharray="190"
        strokeDashoffset={score > 100 ? 0 : 190 - (190 * score) / 100}
      />
    </svg>
  );
};

export default CircularProgressBar;
