import React, { FC, SyntheticEvent, useState, useEffect } from 'react';
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { useStripe } from '@stripe/react-stripe-js';
import { fetchOrProcessSetupIntentData } from 'api/data/payment';
import { processACHSetupIntent } from 'api/data/stripe';
import PaymentForm from './PaymentForm';
import { BankAccount, PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import { ProviderProps } from './types';
import { User } from 'api/data/response/types';

const Stripe: FC<ProviderProps> = ({ onSubmit, setLoading, response, ...props }) => {
  const stripe = useStripe();
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [usBankAccount, setUsBankAccount] = useState<BankAccount>();
  const [errorMessage, setErrorMessage] = useState<string>();

  useEffect(() => {
    return function cleanup() {
      setLoading(false);
    };
  }, [setLoading]);

  const connectBankAccount = async (user: User) => {
    try {
      const { bankAccount } = await processACHSetupIntent(stripe, client, user, response.id);
      setUsBankAccount({
        accountType: bankAccount.account_type,
        bankName: bankAccount.bank_name,
        last4: bankAccount.last4,
      });
    } catch (error) {
      setErrorMessage((error as PaymentErrorType).message);
    }
    setLoading(false);
  };

  const handleSubmit = async (event: SyntheticEvent, user: User) => {
    event.preventDefault();
    setErrorMessage('');
    setLoading(true);

    if (usBankAccount) {
      const stripe = null;
      const cardPaymentMethod = null;

      try {
        const processPaymentData = await fetchOrProcessSetupIntentData(
          PaymentMethodType.ACH,
          stripe,
          client,
          { ...response, user },
          cardPaymentMethod,
        );

        await onSubmit(processPaymentData);
      } catch (error) {
        setErrorMessage((error as PaymentErrorType).message);
        setLoading(false);
      }
    } else {
      await connectBankAccount(user);
    }
  };

  return (
    <PaymentForm
      {...props}
      buttonDisabled={!stripe}
      connectBankAccount={connectBankAccount}
      handleSubmit={handleSubmit}
      setLoading={setLoading}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      bankAccount={usBankAccount}
      setBankAccount={setUsBankAccount}
      response={response}
    />
  );
};

export default Stripe;
