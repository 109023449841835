import React, { FC } from 'react';
import Banner from 'components/Banner';
import ShareButton from 'components/ShareButton';
import brettPhoto from 'assets/brett_photo.svg';
import { Organization } from 'api/data/user/types';
import './style.scss';

interface KYCBannerProps {
  currentOrganization: Organization;
}

function bannerContent(status: string, id: string) {
  switch (status) {
    case 'PENDING':
      return (
        <>
          Ready to accept payments?
          <ShareButton organizationId={id} className="button-link" showButton buttonText="Set up payouts" />
        </>
      );
    case 'VERIFYING':
      return <>Your account is being verified, which only takes a few minutes. Please start setting up your pages!</>;
    case 'PENDING_APPROVAL':
      return (
        <>Your account is being verified, which usually takes less than 2 days. Please start setting up your pages!</>
      );
  }
}

const KYCBanner: FC<KYCBannerProps> = ({ currentOrganization: { id, status } }) => {
  return (
    <>
      <div className="banner-fixed" />
      <Banner className="kyc-banner">
        <img src={brettPhoto} alt="Brett" />
        {bannerContent(status, id)}
      </Banner>
    </>
  );
};

export default KYCBanner;
