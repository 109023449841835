import React from 'react';
import Tooltip from '../Tooltip';
import pieChartIcon from 'assets/pie_chart.svg';
import './style.scss';

const PaymentPlanTooltip = () => {
  return (
    <Tooltip title="Payment plan" color="dark" className="tooltip-payment-plan">
      <img src={pieChartIcon} alt="payment plan icon" />
    </Tooltip>
  );
};

export default PaymentPlanTooltip;
