import { Space } from 'api/data/user/types';

const GENERAL_SPACE = 'general';

const sortSpaceByName = (spaceList: Space[]) => {
  return [...spaceList].sort((a, b) => {
    const nameA = a.name.toLowerCase();
    const nameB = b.name.toLowerCase();

    if (nameA === GENERAL_SPACE && nameB !== GENERAL_SPACE) {
      return -1;
    }

    if (nameA !== GENERAL_SPACE && nameB === GENERAL_SPACE) {
      return 1;
    }
    return nameA.localeCompare(nameB);
  });
};

export default sortSpaceByName;
