import { useEffect } from 'react';
import { useLocation } from 'wouter';
import { default as queryString } from 'query-string';

interface useSpaceMessageProps {
  setShowMessage: (show: boolean) => void;
  setMessage: (message: string) => void;
  loading: boolean;
}

const useSpaceMessage = ({ setShowMessage, setMessage, loading }: useSpaceMessageProps) => {
  const [location, setLocation] = useLocation();

  useEffect(() => {
    const searchParams = queryString.parse(window.location.search);
    const spaceCreated = searchParams.spaceCreated as string;

    if (!loading && spaceCreated) {
      setMessage('Space created! Add members below.');
      setShowMessage(true);
      setLocation(location);
    }
  }, [setShowMessage, location, setLocation, setMessage, loading]);
};

export default useSpaceMessage;
