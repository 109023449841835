import React, { FC } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import * as AuthQuery from 'graphql/auth.graphql';
import { extractQueryStringFromParams } from 'utils';
import useUserSession from '~/hooks/useUserSession';
import SignupParticipant, { Participant } from '.';

type PageParams = {
  id: string;
  participantSlug: string;
};

const InvitedParticipant: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [, setLocation] = useLocation();

  // we need to ensure this query is called so it can be refetched after the mutation
  useUserSession();

  const [saveParticipantMutation, { loading: loadingSaveParticipant }] = useMutation(PageQuery.SaveParticipant, {
    refetchQueries: [AuthQuery.Session],
    awaitRefetchQueries: true,
  });

  const saveParticipant = async (participant: Participant) => {
    await saveParticipantMutation({
      variables: {
        participant: {
          fullName: participant.fullName,
          birthDate: participant.birthDate,
          id: extractQueryStringFromParams('id'),
        },
        pageId: params.id,
      },
    });

    setLocation(`/${params.id}/p/${params.participantSlug}/edit?signup=true`);
  };

  return (
    <SignupParticipant
      handleSubmit={saveParticipant}
      showFields
      loading={loadingSaveParticipant}
      email={extractQueryStringFromParams('email')}
    />
  );
};

export default InvitedParticipant;
