import React, { FC } from 'react';
import ICONS from '../Icons';
import './style.scss';

type AlertProps = {
  type: 'informative' | 'negative' | 'warning' | 'new' | 'positive' | 'success' | 'bold-negative';
  icon: string;
  content: string;
};

const Alert: FC<AlertProps> = ({ type, icon, content }) => {
  return (
    <span className={`alert ${type}`}>
      {ICONS[icon]} {content}
    </span>
  );
};

export default Alert;
