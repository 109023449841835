import React, { FC, useRef, useState } from 'react';
import { Link } from 'wouter';
import { useMutation } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import { validateEmail } from 'utils';
import Email from '~/components/Input/Email';
import Header from 'components/Header';

const PasswordRecover: FC = () => {
  const [email, setEmail] = useState('');
  const [resetPasswordSent, setResetPasswordSent] = useState(false);
  const [forgotPassword, { loading }] = useMutation(AuthQuery.ForgotPassword);
  const outsideRef = useRef<HTMLElement>(null);

  const handleResetPassword = () => {
    void forgotPassword({ variables: { email: email } }).then(() => setResetPasswordSent(true));
  };

  return (
    <section ref={outsideRef} className="request-link">
      <Header refOutside={outsideRef} showSideBar={false} />
      <div className="content">
        {resetPasswordSent ? (
          <>
            <div>
              <h1>Recover password</h1>
              <p>
                {`If an account exists for ${email}, you will get an email with instructions to reset your password.`}
              </p>
              <p>If not, please check your spam folder.</p>
            </div>
            <div className="footer">
              <Link href="/login">
                <button type="button" className="button-outline">
                  Ok
                </button>
              </Link>
            </div>
          </>
        ) : (
          <>
            <div>
              {location.search === '?invalid-link' ? (
                <>
                  <h1>The link you tried to access has expired or is invalid.</h1>
                  <h2>Please enter your email below to receive a new link.</h2>
                </>
              ) : (
                <h1>Enter your email to reset your password</h1>
              )}
              <Email
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                id="email-field"
                name="input email"
                onBlur={({ target }) => setEmail(target.value)}
              />
            </div>
            <div className="footer">
              <button disabled={!validateEmail(email)} onClick={handleResetPassword}>
                {loading ? 'Loading...' : 'Reset password'}
              </button>
              <Link href="/login">
                <button type="button" className="button-cancel">
                  Cancel
                </button>
              </Link>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default PasswordRecover;
