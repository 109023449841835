import React, { FC, useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useLocation } from 'wouter';
import * as AuthQuery from 'graphql/auth.graphql';
import { validateEmail, removeSpaces } from 'utils';
import { pageVar } from 'api/data/pages';
import { mapPage } from 'api/data/pages';
import { mapBlockItem } from './index';
import Modal from 'components/Modal';
import Input from 'components/Input';
import { CheckUserType } from 'api/data/user/types';
import { PageDataDraftBlock } from 'api/data/pages/types';

interface SavePageModalProps {
  page: PageDataDraftBlock;
}

const SavePageModal: FC<SavePageModalProps> = ({ page }) => {
  const [showSavePageModal, setShowSavePageModal] = useState(false);
  const [email, setEmail] = useState('');
  const [, setLocation] = useLocation();
  const [checkUser, { loading, data }] = useLazyQuery<CheckUserType>(AuthQuery.CheckUser);

  useEffect(() => {
    if (data) {
      const url = `?email=${email}&redirectTo=editor`;

      if (data.checkUserExists) {
        setLocation(`/login${url}`);
      } else {
        setLocation(`/signup${url}`);
      }
    }
  }, [data, setLocation, email]);

  const onSaveUnloggedUser = () => {
    const newPage = {
      ...mapPage(page),
      blocks: mapBlockItem(page.blocks),
      id: '',
      sourcePageId: page.id,
    } as PageDataDraftBlock;

    pageVar(newPage);

    void checkUser({
      variables: {
        email,
        tracking: {
          url: window.location.href,
          userAgent: window.navigator.userAgent,
          referrer: document.referrer,
        },
      },
    });
  };

  return (
    <>
      <Modal
        visible={showSavePageModal}
        handleOnCancel={() => setShowSavePageModal(false)}
        handleOnConfirm={onSaveUnloggedUser}
        header="Save this page"
        headerIcon="document"
        confirmButtonProps={{ disabled: !validateEmail(email) || loading }}
        confirmlabel="Save"
        denyLabel="Cancel">
        <Input
          label="Enter your email to save this page"
          placeholder="email@email.com"
          onChange={({ target }) => setEmail(removeSpaces(target.value))}
          value={email}
          name="input email"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        />
      </Modal>
      <button onClick={() => setShowSavePageModal(true)}>Save</button>
    </>
  );
};

export default SavePageModal;
