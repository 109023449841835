import { useEffect } from 'react';
import { useLocation } from 'wouter';
import useUserSession from './useUserSession';
import useCurrentOrganization from './useCurrentOrganization';

function useCioUserIdentify(): void {
  const { data } = useUserSession({ fetchPolicy: 'network-only' });
  const { currentOrg } = useCurrentOrganization();
  const [location] = useLocation();

  useEffect(() => {
    if (data?.session?.currentUser && _cio && _cio.identify) {
      const { currentUser } = data.session;

      _cio.identify({
        id: currentUser.email.toLowerCase(),
        email: currentUser.email.toLowerCase(),
        full_name: currentUser.fullName || '',
        org_id: currentOrg?.id,
        has_kyc: currentOrg?.status === 'VERIFIED',
        kyc_modal_path: `${location}#kyc`,
      });
    }
  }, [data?.session, currentOrg, location]);
}

export default useCioUserIdentify;
