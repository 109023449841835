import React, { FC, SyntheticEvent } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { fetchOrProcessSetupIntentData } from 'api/data/payment';
import ZipInput from './ZipInput';
import { CardProps } from './types';
import { PaymentErrorType, PaymentMethodType } from 'api/data/payment/types';
import './style.scss';

const CARD_OPTIONS = {
  style: {
    base: {
      fontSize: '14px',
      color: '#555',
      fontFamily: "'Poppins', sans-serif",
      '::placeholder': {
        color: '#bfbfbf',
      },
    },
    invalid: {
      color: '#555',
    },
  },
};

const StripeCard: FC<CardProps> = ({
  response,
  handleResponseUser,
  setLoading,
  setErrorMessage,
  client,
  onSubmit,
  showButton,
  buttonText,
  loading,
  handleOnBlur,
}) => {
  const elements = useElements();
  const stripe = useStripe();

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    setLoading(true);
    setErrorMessage('');

    try {
      const processPaymentData = await fetchOrProcessSetupIntentData(
        PaymentMethodType.CARD,
        stripe,
        client,
        response,
        elements,
      );

      await onSubmit(processPaymentData);
    } catch (error) {
      setErrorMessage((error as PaymentErrorType).message);
      setLoading(false);
    }
  };

  const props = {
    options: CARD_OPTIONS,
  };

  return (
    <form onSubmit={event => void handleSubmit(event)} className="payment-method" aria-label="credit card form">
      <span>Card Number</span>
      <CardNumberElement {...props} />
      <div className="row credit-card-elements">
        <div className="col-4">
          <span>Expiry</span>
          <CardExpiryElement {...props} />
        </div>
        <div className="col-4">
          <span>CVC</span>
          <CardCvcElement {...props} />
        </div>
        <div className="col-4">
          <ZipInput
            handleResponseUser={handleResponseUser}
            zipcode={response.user?.zipcode}
            handleOnBlur={handleOnBlur}
          />
        </div>
      </div>
      <button type="submit" disabled={!stripe || showButton}>
        {loading ? 'Processing...' : buttonText}
      </button>
    </form>
  );
};

export default StripeCard;
