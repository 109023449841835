import { Order, Response, ResponseInput } from 'api/data/response/types';
import pickKeys from './pickKeys';

export const formatOrder = (order: Order) => ({
  ...pickKeys(order, 'id', 'subtotalCents', 'totalCents'),
  tip: pickKeys(order.tip, 'id', 'amountCents', 'percentage'),
  orderItems: order.orderItems.map(item => ({
    ...pickKeys(item, 'amount', 'recurring', 'unitPriceCents', 'image', 'blockId', 'blockTitle', 'blockType'),
    variant: pickKeys(item.variant, 'id', 'amount', 'quantity', 'paymentPlanInstallments', 'title', 'paymentOptionId'),
    parent: item.parent && pickKeys(item.parent, 'id', 'blockId', 'title', 'type'),
  })),
});

const mapToResponseInput = (response: Response): ResponseInput => ({
  ...pickKeys(response, 'id', 'tracking', 'requestId', 'pageId', 'email', 'fullName'),
  order: formatOrder(response.order),
  answers: response.answers?.map(answer => ({
    ...pickKeys(answer, 'id', 'value', 'groupKey'),
    question: pickKeys(answer.question, 'id', 'title', 'type'),
    file: answer.file && pickKeys(answer.file, 'id', 'fileName', 'url'),
    choice: answer.choice && pickKeys(answer.choice, 'id', 'title'),
    parent: answer.parent && pickKeys(answer.parent, 'id', 'blockId', 'title', 'type'),
    group: answer.group && pickKeys(answer.group, 'id'),
  })),
  supportMessages: response.supportMessages?.map(supportMessage =>
    pickKeys(supportMessage, 'id', 'name', 'message', 'blockId'),
  ),
});

export default mapToResponseInput;
