import { default as queryString } from 'query-string';

type TrackingParams = {
  om_landing_page_document_referrer: string;
  om_landing_page_user_agent: string;
  om_landing_page_url: string;
};

const getBaseTrackingParams = () => {
  const baseTrackingParams = {
    om_landing_page_document_referrer: document.referrer,
    om_landing_page_user_agent: window.navigator.userAgent,
    om_landing_page_url: window.location.href,
  };

  return { ...queryString.parse(window.location.search), ...baseTrackingParams };
};

const generateTracking = (): string => JSON.stringify(getBaseTrackingParams());

export const generateTrackingParams = (params?: string): string => {
  const trackingParams = params ? (JSON.parse(params) as TrackingParams) : getBaseTrackingParams();

  return Object.entries(trackingParams)
    .filter(([key, value]) => key !== 'om_landing_page_user_agent' && value !== '')
    .map(value => value.join('='))
    .join('&');
};

export default generateTracking;
