import React from 'react';
import SafeHtmlRenderer from '../SafeHtmlRenderer';
import TransactionSummary from 'components/Footer/TransactionSummary';
import { Organization } from 'api/data/user/types';
import { PageDataDraftBlock } from 'api/data/pages/types';
import omellaLogo from 'assets/logo_omella.svg';
import './style.scss';

interface PreviewProps {
  customBodyPreview: string;
  customFooterPreview: string;
  subject?: Organization | PageDataDraftBlock;
}

export default function Preview({ customBodyPreview, customFooterPreview, subject }: PreviewProps) {
  const image = getImage(subject);

  return (
    <div className="preview-container">
      <img src={image.resource} alt={image.alt} />
      <h3>Hi [Recipient name],</h3>
      {customBodyPreview && <SafeHtmlRenderer className="custom-content-container" content={customBodyPreview} />}
      <div className="receipt-info-container">
        <span className="header">Description</span>
        <span className="header result-text">Amount</span>
        <div className="horizontal-line row-span" />
        <span>Event ticket</span>
        <span className="result-text">$25.00</span>
        <div className="horizontal-line row-span" />
      </div>
      <TransactionSummary
        subtotalAmountCents={2500}
        totalAmountCents={2500}
        isPayerView
        processingFee={0}
        showPaymentMethod
        paymentMethodType="card"
        last4="4242"
        cardBrand="visa"
      />
      {customFooterPreview && <SafeHtmlRenderer className="custom-content-container" content={customFooterPreview} />}
    </div>
  );
}

function getImage(organization?: Organization | PageDataDraftBlock) {
  if (organization?.logoUrl)
    return {
      resource: organization.logoUrl,
      alt: 'logo',
    };

  if (organization?.coverPictureUrl)
    return {
      resource: organization.coverPictureUrl,
      alt: 'cover',
    };

  return {
    resource: omellaLogo,
    alt: 'omella default logo',
  };
}
