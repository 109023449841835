import React, { FC, useState, useRef } from 'react';
import { updateQuestionBlock } from 'api/data/pages/blocks';
import useOutsideClick from 'hooks/useOutsideClick';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import ICONS from 'components/Icons';
import Input from 'components/Input';
import Modal from 'components/Modal';
import SupporterFeedPayerView, { SupportMessage } from './PayerView';
import SupporterList from './SupporterList';
import { SupporterFeedBlockProps } from './types';
import './style.scss';

const SupporterFeed: FC<SupporterFeedBlockProps> = ({ data, isFocused, refOutside, handleDelete, updateBlock }) => {
  const [isEditing, setIsEditing] = useState<boolean>(isFocused);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const supporterFeedRef = useRef<HTMLElement>(null);

  useOutsideClick(supporterFeedRef, refOutside, () => {
    if (isEditing) {
      setIsEditing(false);
    }
  });

  const handleOnChange = (value: string, field: string) => {
    const block = updateQuestionBlock(data, value, field);
    updateBlock(block);
  };

  return (
    <section
      className="block-container"
      role="presentation"
      ref={supporterFeedRef}
      onClick={ev => {
        ev.stopPropagation();
        if (!isEditing) {
          setIsEditing(true);
        }
      }}>
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={handleDelete}
        confirmlabel="Delete"
        denyLabel="Cancel"
        visible={showDeleteModal}
        header="Delete this Supporter feed Block?"
      />
      {isEditing ? (
        <div className="block donation">
          <div className="block-header row space-between">
            <div className="row align-center">
              {ICONS['supporter_feed']}
              <span className="size-xxs">Supporter feed</span>
            </div>
            <div className="row align-center">
              <DropDown outsideRef={supporterFeedRef}>
                <DropDownItem icon="delete" onClick={() => setShowDeleteModal(true)}>
                  Delete
                </DropDownItem>
              </DropDown>
            </div>
          </div>
          <div className="block-content">
            <Input
              placeholder="Top supporters"
              onChange={({ target }) => handleOnChange(target.value, 'title')}
              value={data.title || ''}
              maxLength={1000}
              className="block-title"
              name="title"
              aria-label="question prompt"
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus
            />
            <SupporterList readOnly isEditing />
            <SupportMessage payerView={false} id={data.id} />
          </div>
        </div>
      ) : (
        <SupporterFeedPayerView data={data} readOnly />
      )}
    </section>
  );
};

export default SupporterFeed;
