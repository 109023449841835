import React, { FC, ReactNode, RefObject } from 'react';
import DropDown from '~/components/Menu/DropDown';
import DropDownItem from '~/components/Menu/DropDownItem';
import DropDownItemDivider from '~/components/Menu/DropDownItemDivider';
import ICON from 'components/Icons';
import './style.scss';

type UploadMenuProps = {
  outsideRef: RefObject<HTMLElement>;
  inputRef: RefObject<HTMLInputElement>;
  deletePhoto: () => void;
  children?: ReactNode;
};

const UploadMenu: FC<UploadMenuProps> = ({ outsideRef, inputRef, deletePhoto, children }: UploadMenuProps) => {
  const uploadPhoto = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const closeMenu = () => {
    if (outsideRef && outsideRef.current) {
      outsideRef.current.click();
    }
  };

  return (
    <DropDown
      outsideRef={outsideRef}
      className="upload-menu"
      classNameOverlay="upload-menu-overlay"
      openedIcon=""
      closedIcon=""
      buttonChildren={
        <>
          {ICON['image_upload']}
          <span className="button-text">{children}</span>
        </>
      }>
      <DropDownItem icon="close" onClick={closeMenu} />
      <DropDownItem icon="upload" onClick={uploadPhoto}>
        Upload photo
      </DropDownItem>
      <DropDownItemDivider />
      <DropDownItem icon="delete" onClick={deletePhoto}>
        Delete photo
      </DropDownItem>
    </DropDown>
  );
};

export default UploadMenu;
