import React, { FC } from 'react';
import { currencyFormatter, hasPaymentPlanInstallments } from 'utils';
import ICONS from 'components/Icons';
import PaymentPlanTooltip from 'components/PaymentBlock/PaymentPlanTooltip';
import { OrderItem as OrdemItemType } from 'api/data/response/types';
import { RecurringValue } from 'defaults/page';
import './style.scss';

interface OrderItemType {
  orderItem: OrdemItemType;
  occurrence?: number;
  recurring?: RecurringValue;
}

export const OrderItem: FC<OrderItemType> = ({ orderItem, occurrence, recurring }) => {
  const hasPaymentPlan = hasPaymentPlanInstallments(orderItem.variant.paymentPlanInstallments);

  return (
    <div className="order-item">
      <div>
        <span className="variant">{orderItem.blockTitle}</span>
        <span className="title-description variant">{orderItem.variant.title}</span>
        {orderItem.variant.quantity > 1 ? (
          <span className="quantity">{`Qty (${orderItem.variant.quantity}x ${currencyFormatter(
            orderItem.variant.amount,
          )})`}</span>
        ) : null}
      </div>
      <div className="recurring-content">
        <span className="variant">{currencyFormatter(orderItem?.amount || 0)}</span>
        {hasPaymentPlan && !!occurrence && (
          <span className="title-description">
            <PaymentPlanTooltip />
            Payment {occurrence} of {orderItem.variant.paymentPlanInstallments}
          </span>
        )}
        {!hasPaymentPlan && (
          <span className="title-description recurring">
            {ICONS['recurring']} {recurring}
          </span>
        )}
      </div>
    </div>
  );
};
