import { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { PageResponse } from 'api/data/response/types';
import { RequestData } from 'api/data/request/types';

function useActiveTab(
  setTabContentKey: Dispatch<SetStateAction<string>>,
  requests?: RequestData[],
  submissions?: PageResponse[],
  tabParam?: string,
) {
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (requests && submissions && isFirstRender.current) {
      isFirstRender.current = false;

      if (tabParam) return setTabContentKey(tabParam);

      const hasRequests = requests.length > 0;
      if (!hasRequests) setTabContentKey('submissions');
      else setTabContentKey('requests');
    }
  }, [requests, submissions, setTabContentKey, tabParam]);
}

export default useActiveTab;
