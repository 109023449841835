import React, { FC, useRef } from 'react';
import { RouteComponentProps } from 'wouter';
import clsx from 'clsx';
import { useEditor } from 'hooks/useEditor';
import useUserSession from 'hooks/useUserSession';
import AddPageModal from './AddPageModal';
import Alert from 'components/Alert';
import Editor from 'components/Editor';
import Header from 'components/Header';
import './style.scss';

type PageParams = {
  id: string;
};

const Template: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [page, setPage, , savePageError] = useEditor(params.id, undefined, false);
  const { data: userSession } = useUserSession();

  const refOutside = useRef<HTMLElement>(null);
  return (
    <Editor page={page} setPage={setPage} savePageError={savePageError}>
      <Header refOutside={refOutside} showSideBar={false} />
      <nav
        className={clsx('actions', 'save-template', {
          'unlogged-user': !userSession?.session.currentUser,
        })}>
        <div className="float-actions">
          <AddPageModal page={page} />
        </div>
      </nav>
      <Alert type="warning" icon="warning_solid" content={`Click “Save” to add this page to your account`} />
    </Editor>
  );
};

export default Template;
