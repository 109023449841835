import { ApolloError } from '@apollo/client';
import { errorCodesRegex } from 'defaults/response';

const apolloErrorFormatter = (error?: ApolloError): string => {
  if (!error || !error.message) return '';

  const hasGraphQLErrors = error.graphQLErrors && error.graphQLErrors.length;

  if (error.networkError?.message) {
    return error.networkError.message;
  } else if (hasGraphQLErrors) {
    return error.graphQLErrors.map(({ message }) => message.replace(errorCodesRegex, '')).join(', ');
  } else {
    return error.message;
  }
};

export default apolloErrorFormatter;
