import React, { FC } from 'react';
import { useLocation } from 'wouter';
import clsx from 'clsx';
import { numberCentsFormatter, showPlural } from 'utils';
import CircularProgressBar from 'components/CircularProgressBar';
import DropDownList, { DropDownListItem } from 'components/Menu/DropDownList';
import ICONS from 'components/Icons';
import { hasParticipantJoined } from 'pages/Participant';
import { Participant } from 'api/data/pages/types';
import profilePicture from 'assets/empty-profile-picture.png';
import './style.scss';

interface ParticipantCardProps {
  participant: Participant;
  link: string;
  classNames: string;
}

const showParentIcon = (email: string | null, secondEmail: string | null) => {
  if (email && secondEmail)
    return (
      <span aria-label="parents icon" className="size-xxxxs">
        {ICONS['users_solid']}
      </span>
    );
  if (email || secondEmail)
    return (
      <span aria-label="parent icon" className="size-xxxxs">
        {ICONS['user_solid']}
      </span>
    );
  return null;
};

const ParticipantCard: FC<ParticipantCardProps> = ({ participant, classNames, link }) => {
  const [, setLocation] = useLocation();
  const participantName =
    (hasParticipantJoined(participant.computedEffortStage) ? participant.fullName : '') || participant.user.email;

  let parentsAdded = participant.parentEmail || participant.secondParentEmail ? 1 : 0;
  if (participant.parentEmail && participant.secondParentEmail) {
    parentsAdded = 2;
  }

  const { computedEffortPoints, computedInvitedOrDonatedCount, computedRaisedAmountCents } = participant;
  const hasGrayStyle = (item: boolean) => (item ? 'disabled' : '');

  return (
    <div
      className={`row align-center space-between participant-card ${classNames}`}
      role="presentation"
      onClick={() => setLocation(link)}>
      <div className="row align-center gap-s participant-wrapper">
        <img src={participant.pictureUrl || profilePicture} alt={participantName} />
        <div>
          <span className="subtitle-xx-small ellipsis-text">{participantName}</span>
          <div className="row align-center gap-s participant-data">
            <span className="size-xxxxs">{`$${numberCentsFormatter(computedRaisedAmountCents)}`}</span>
            <span className="row align-center size-xxxxs">
              {ICONS['envelope_solid']}
              {`${computedInvitedOrDonatedCount}/20`}
            </span>
            {showParentIcon(participant.parentEmail, participant.secondParentEmail)}
          </div>
        </div>
      </div>
      <DropDownList
        label={computedEffortPoints.toString()}
        progressIcon={<CircularProgressBar score={computedEffortPoints} />}
        className="row justify-center subtitle-xx-small score">
        <DropDownListItem
          icon="envelope"
          className={clsx('donors-invited', hasGrayStyle(computedInvitedOrDonatedCount === 0))}>
          {computedInvitedOrDonatedCount} donor{showPlural(computedInvitedOrDonatedCount)} invited
        </DropDownListItem>
        <DropDownListItem icon="profile_outline" className={clsx('parents-added', hasGrayStyle(parentsAdded === 0))}>
          {parentsAdded} parent{showPlural(parentsAdded)} added
        </DropDownListItem>
        <DropDownListItem
          icon="image_upload_block"
          className={clsx('profile-picture', hasGrayStyle(!participant.pictureUrl))}>
          {participant.pictureUrl ? 'Profile picture added' : 'No profile picture added'}
        </DropDownListItem>
      </DropDownList>
    </div>
  );
};

export default ParticipantCard;
