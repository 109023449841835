import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { CSSTransition } from 'react-transition-group';
import { useQuery } from '@apollo/client';
import * as TransactionQuery from 'graphql/transaction.graphql';
import { getLastTransaction } from 'api/data/subscription';
import { statusTranslator } from 'defaults/subscription';
import { currencyFormatter, hasPaymentPlanInstallments, subscriptionDatesByStatus, subscriptionEnded } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import { OrderItemDetail } from './OrderItemDetail';
import CancelSubscriptionModal, { showCancelationButton } from 'components/CancelSubscriptionModal';
import ICONS from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import Modal from 'components/Modal';
import PaymentPlanTooltip from 'components/PaymentBlock/PaymentPlanTooltip';
import ProtectedComponent from 'components/ProtectedComponent';
import ReviewRequestModal from 'components/RefundModal/ReviewRequestModal';
import TransactionSummary from 'components/Footer/TransactionSummary';
import { Subscription } from 'api/data/subscription/types';
import { Transaction, Transactions } from 'api/data/transaction/types';
import './style.scss';

interface SubscriptionType {
  subscriptions: Subscription[] | [];
  responseId?: string;
  pageId?: string;
  pageSpaceSlug?: string;
}
interface SubscriptionDetailType {
  subscription?: Subscription;
  setShowDetail: React.Dispatch<React.SetStateAction<boolean>>;
  lastTransaction?: Transaction;
  responseId?: string;
  pageId?: string;
}

export const Subscriptions: FC<SubscriptionType> = ({ subscriptions, responseId, pageId, pageSpaceSlug }) => {
  const { data: transactionsData } = useQuery<Transactions>(TransactionQuery.GetTransactions, {
    variables: { id: responseId, currentSpace: pageSpaceSlug },
  });
  const [showSubscriptionDetail, setShowSubscriptionDetail] = useState(false);
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [lastTransaction, setLastTransaction] = useState<Transaction>();

  useEffect(() => {
    if (subscriptions) {
      const newSelectedSubscription = subscriptions.find(subscription => subscription.id === selectedSubscription?.id);

      setSelectedSubscription(newSelectedSubscription);
    }
  }, [subscriptions, selectedSubscription?.id]);

  if (subscriptions.length === 0) {
    return null;
  }

  const handleOnSelectSubscription = (subscription: Subscription) => {
    setSelectedSubscription(subscription);
    setShowSubscriptionDetail(true);
    const transaction = getLastTransaction(subscription.id, transactionsData?.transactions);
    setLastTransaction(transaction);
  };

  return (
    <div className="response-detail-wrapper">
      <div className="subscriptions">
        <div className="event">
          {subscriptions.map(subscription => {
            const hasPaymentPlan = subscription.orderItems.some(orderItem =>
              hasPaymentPlanInstallments(orderItem.variant.paymentPlanInstallments),
            );
            return (
              <div
                role="presentation"
                className={clsx('event-item', {
                  'selected-event': subscription.id === selectedSubscription?.id,
                })}
                key={subscription.id}
                onClick={() => handleOnSelectSubscription(subscription)}>
                <div>
                  <p className="first-capitalize">{subscription.orderItems[0]?.blockTitle}</p>
                  {subscriptionDatesByStatus(
                    subscription,
                    getLastTransaction(subscription.id, transactionsData?.transactions),
                  )}
                </div>
                <div className="amount-status">
                  <div className="amount-recurring-box">
                    <span className="event-recurring">
                      {hasPaymentPlan ? (
                        <PaymentPlanTooltip />
                      ) : (
                        <>
                          {ICONS['recurring']}
                          {subscription.recurring}
                        </>
                      )}
                    </span>
                    <span className="event-amount">{currencyFormatter(subscription.netAmountCents)}</span>
                  </div>
                  <p>{statusTranslator[subscription.status]}</p>
                </div>
              </div>
            );
          })}
        </div>
        <div className="details-wrapper">
          <CSSTransition
            timeout={200}
            in={showSubscriptionDetail}
            classNames="animation"
            unmountOnExit
            onExited={() => setSelectedSubscription(undefined)}>
            <SubscriptionDetail
              setShowDetail={setShowSubscriptionDetail}
              subscription={selectedSubscription}
              lastTransaction={lastTransaction}
              pageId={pageId}
              responseId={responseId}
            />
          </CSSTransition>
        </div>
        <Modal
          header={
            <div>
              <span>Subscription</span>
              <div className="subscription-actions">
                <span>Created {dayjs(selectedSubscription?.insertedAt).format('MMM D, YYYY')}</span>
              </div>
            </div>
          }
          className="response-detail-modal"
          handleOnCancel={() => setShowSubscriptionDetail(false)}
          visible={showSubscriptionDetail}
          fullScreenMobile>
          <SubscriptionDetail
            setShowDetail={setShowSubscriptionDetail}
            subscription={selectedSubscription}
            lastTransaction={lastTransaction}
            pageId={pageId}
            responseId={responseId}
          />
        </Modal>
      </div>
    </div>
  );
};

export const SubscriptionDetail: FC<SubscriptionDetailType> = ({
  subscription,
  setShowDetail,
  lastTransaction,
  responseId,
  pageId,
}) => {
  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  const nextOccurrence =
    subscription?.currentInvoiceOccurrence && subscription?.currentInvoiceOccurrence > 0
      ? subscription?.currentInvoiceOccurrence + 1
      : undefined;

  return (
    <div className="details">
      <div className="header">
        <span>Subscription</span>
        <div className="subscription-actions">
          <p>Created {dayjs(subscription?.insertedAt).format('MMM D, YYYY')}</p>
          <IconButton
            icon="close"
            onClick={() => {
              setShowDetail(false);
            }}
          />
        </div>
      </div>
      <div className="actions">
        <h3>{currencyFormatter(subscription?.netAmountCents)}</h3>
        <div className="next-charge-content">
          {subscriptionDatesByStatus(subscription, lastTransaction)}
          <ProtectedComponent
            action="CANCEL_SUBSCRIPTION"
            currentUserOrganizationRole={currentOrg?.currentUserRole}
            currentUserSpaceRole={currentSpace?.currentUserRole}>
            <CancelSubscriptionModal
              subscription={subscription}
              showButton={!!subscription && showCancelationButton(subscription)}
              lastTransaction={lastTransaction}
              pageId={pageId}
              responseId={responseId}
            />
          </ProtectedComponent>
          <ReviewRequestModal
            pageTitle={subscription?.page?.title}
            requesterName={subscription?.user?.fullName}
            amountCents={subscription?.pendingPayerRequest?.amountCents}
            subscriptionId={subscription?.id}
            showButton={!!subscription?.hasPendingPayerRequest}
            responseId={responseId}
            pageId={pageId}
            recurring={subscription?.recurring}
            cancelationReason={subscription?.pendingPayerRequest?.cancelationReason}
          />
        </div>
      </div>
      <div className="horizontal-line"></div>
      <div className="order-detail">
        {subscription?.orderItems.map((orderItem, index) => (
          <OrderItemDetail
            key={index}
            orderItem={orderItem}
            occurrence={subscriptionEnded(subscription) ? undefined : nextOccurrence}
          />
        ))}
        <div className="horizontal-line"></div>
        <TransactionSummary
          subtotalAmountCents={subscription?.subtotalAmountCents}
          totalAmountCents={subscription?.netAmountCents}
          isPayerView={false}
          processingFee={
            subscription?.processingFeePayer === 'organization' ? subscription?.processingFeeAmountCents : 0
          }
          showPaymentMethod
          platformFeeAmountCents={subscription?.platformFeeAmountCents}
          recurringFeeAmountCents={subscription?.recurringFeeAmountCents}
          paymentMethodType={subscription?.paymentMethodType.toLowerCase()}
          bankName={subscription?.bankName}
          last4={subscription?.last4}
          cardBrand={subscription?.cardBrand}
        />
      </div>
    </div>
  );
};
