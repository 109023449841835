import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import { useEditor } from 'hooks/useEditor';
import PageAddedMessage from 'pages/Template/AddPageModal/PageAddedMessage';
import Editor from 'components/Editor';

type EditProps = {
  setPageTitle?: (title: string) => void;
  pageId: string;
  setTabContentKey: Dispatch<SetStateAction<string>>;
};

const Edit: FC<EditProps> = ({ pageId, setPageTitle, setTabContentKey }) => {
  const [page, setPage, , savePageError] = useEditor(pageId);

  useEffect(() => {
    if (page.status === 'archived') setTabContentKey('activity');
  }, [page.status, page.slug, setTabContentKey]);

  return (
    <Editor page={page} setPage={setPage} savePageError={savePageError} setPageTitle={setPageTitle}>
      <PageAddedMessage />
    </Editor>
  );
};

export default Edit;
