import React, { SyntheticEvent, useRef, useState } from 'react';
import clsx from 'clsx';
import useToggle from 'hooks/useToggle';
import Accordion from 'components/Accordion';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import Preview from './Preview';
import ResponseActivityTypeModal from 'components/ResponseActivityTypeModal';
import RichTextInput, { RichTextInputMethods } from '../RichTextInput';
import ToggleSwitch from '../ToggleSwitch';
import Tooltip, { RichTooltip } from '../Tooltip';
import { PageDataDraftBlock } from 'api/data/pages/types';
import { ResponseActivityType } from 'api/data/response/types';
import './style.scss';

interface PageReceiptProps {
  page: PageDataDraftBlock;
  loading: boolean;
  onSave: (receiptBody: string, receiptFooter: string, extraProps?: Record<string, unknown>) => void;
  unsavedChanges?: Partial<PageDataDraftBlock>;
  setUnsavedChanges?: (unsavedChanges?: Partial<PageDataDraftBlock>) => void;
}

interface ReadOnlyInputProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  name: string;
  value?: string;
}

export default function PageReceipt({ page, loading, onSave, unsavedChanges, setUnsavedChanges }: PageReceiptProps) {
  const [pageReceiptBody, setPageReceiptBody] = useState(page?.customBodyReceiptContent || '');
  const [pageReceiptFooter, setPageReceiptFooter] = useState(page?.customFooterReceiptContent || '');
  const [activityType, setActivityType] = useState<Partial<ResponseActivityType>>();
  const [allActivityTypes, setAllActivityTypes] = useState<Partial<ResponseActivityType>[]>(
    page.responseActivityTypes || [],
  );

  const [toggle, setToggle] = useToggle(page?.showCustomReceiptContent);

  const richTextBodyInputRef = useRef<RichTextInputMethods>(null);
  const richTextFooterInputRef = useRef<RichTextInputMethods>(null);

  const newActivityType = { name: '', public: false };
  const unsavedActivityTypes = unsavedChanges?.responseActivityTypes || [];

  const handleResetClick = () => {
    if (richTextBodyInputRef.current) {
      richTextBodyInputRef.current.reset();
    }

    if (richTextFooterInputRef.current) {
      richTextFooterInputRef.current.reset();
    }

    setActivityType(undefined);
    setUnsavedChanges && setUnsavedChanges(undefined);
    setAllActivityTypes(page.responseActivityTypes || []);
    setToggle(page?.showCustomReceiptContent);
  };

  const onActionButtonChange = (newActivityType: Partial<ResponseActivityType>) => {
    const newActivityListToShow = [...allActivityTypes];

    if (newActivityType.id) {
      const index = allActivityTypes.findIndex(actType => actType?.id === newActivityType.id);
      newActivityListToShow[index] = { ...allActivityTypes[index], ...newActivityType };
    } else {
      const newAct = newActivityType;
      newActivityListToShow?.push(newAct);
    }

    handleUnsavedActivityType(newActivityListToShow as ResponseActivityType[]);
    setAllActivityTypes(newActivityListToShow);
  };

  const handleUnsavedChanges = (label: string, pageValue: string, inputValue: string) => {
    if (pageValue !== inputValue) {
      setUnsavedChanges && setUnsavedChanges({ ...unsavedChanges, [label]: inputValue });
    }
  };

  const handleUnsavedActivityType = (newValue: ResponseActivityType[]) => {
    const newActivityTypeList = unsavedActivityTypes || page.responseActivityTypes;
    setUnsavedChanges && setUnsavedChanges({ ...newActivityTypeList, responseActivityTypes: newValue });
  };

  const handleShowModal = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.preventDefault();
    setActivityType(newActivityType);
  };

  const { bodyPreview, footerPreview } = getPreviewContent();

  function getPreviewContent() {
    if (!toggle) {
      return {
        bodyPreview: page.organization?.customBodyReceiptContent || '',
        footerPreview: page.organization?.customFooterReceiptContent || '',
      };
    }

    return {
      bodyPreview: pageReceiptBody,
      footerPreview: pageReceiptFooter,
    };
  }

  const customBodyInputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
    className: clsx('default', { 'has-error': !pageReceiptBody }),
    id: 'body',
    placeholder: 'Please keep this receipt for your records.',
  };

  const customFooterInputProps: React.TextareaHTMLAttributes<HTMLTextAreaElement> = {
    className: 'default',
    id: 'footer',
    placeholder: 'E.g. [org name] is a registered 501(c)(3) nonprofit organization, EIN: 12-2346789',
  };

  function ReadOnlyInput({ value, ...textAreaProps }: ReadOnlyInputProps) {
    return <RichTextInput {...textAreaProps} disabled initialValue={value} />;
  }

  return (
    <>
      {!!activityType && (
        <ResponseActivityTypeModal
          pageId={page?.id || ''}
          activityType={activityType}
          visible={!!activityType}
          onClose={() => setActivityType(undefined)}
          spaceSlug={page?.space?.slug}
          onSave={onActionButtonChange}
        />
      )}
      <form
        className="receipt-settings"
        onSubmit={(event: SyntheticEvent) => {
          event.preventDefault();
          onSave(pageReceiptBody, pageReceiptFooter, {
            showCustomReceiptContent: toggle,
            responseActivityTypes: unsavedActivityTypes,
          });
        }}
        aria-label="receipt form">
        <div className="content">
          <div>
            <div className="header-container">
              <div className="row">
                <h2 className="subtitle-small">Customize this page&apos;s receipt</h2>
                <Tooltip
                  title="If you would like to update your organization's default receipt content, just head over to organization settings."
                  color="dark"
                  ariaLabel="receipt-info">
                  {ICONS['solid_help']}
                </Tooltip>
              </div>
              <ToggleSwitch
                toggleValue={toggle}
                handleOnChange={setToggle}
                label={toggle ? 'On' : 'Off'}
                labelPosition="after"
              />
            </div>
            {toggle ? (
              <p className="title-description">
                Turn this <strong>off</strong> if you would like to use your organization&apos;s default receipt
                content.
              </p>
            ) : (
              <p className="title-description">
                Turn this <strong>on</strong> if you would like to override your organization&apos;s default receipt
                content (shown below).
              </p>
            )}
            <div className="input first">
              {toggle ? (
                <RichTextInput
                  label="Body"
                  rules={{
                    required: true,
                    hasError: !pageReceiptBody,
                    errorMessage: 'This field is required',
                  }}
                  ref={richTextBodyInputRef}
                  {...customBodyInputProps}
                  parseToHTMLCallback={(htmlFragment: string) => {
                    handleUnsavedChanges('customBodyReceiptContent', page.customBodyReceiptContent, htmlFragment);
                    setPageReceiptBody(htmlFragment);
                  }}
                  initialValue={page.customBodyReceiptContent || ''}
                  name="body"
                />
              ) : (
                <ReadOnlyInput
                  {...customBodyInputProps}
                  name="body"
                  label="Body"
                  value={page.organization?.customBodyReceiptContent}
                />
              )}
            </div>
            <div className="input">
              {toggle ? (
                <RichTextInput
                  ref={richTextFooterInputRef}
                  {...customFooterInputProps}
                  parseToHTMLCallback={(htmlFragment: string) => {
                    handleUnsavedChanges('customFooterInputProps', page.customFooterReceiptContent || '', htmlFragment);
                    setPageReceiptFooter(htmlFragment);
                  }}
                  initialValue={page.customFooterReceiptContent || ''}
                  name="footer"
                  label="Footer"
                />
              ) : (
                <ReadOnlyInput
                  {...customFooterInputProps}
                  value={page.organization?.customFooterReceiptContent}
                  name="footer"
                  label="Footer"
                />
              )}
            </div>
          </div>

          <Accordion title="Preview receipt">
            <Preview subject={page} customBodyPreview={bodyPreview} customFooterPreview={footerPreview} />
          </Accordion>

          <div className="separator" />
          <div className="activity-action-buttons-container">
            <div className="row align-center space-between">
              <span className="subtitle-small">
                Action Buttons{' '}
                <RichTooltip
                  title={
                    <p className="paragraph-xx-small">
                      What actions would you like to perform after scanning a receipts QR code? Scan to &quot;Check
                      in&quot; may be useful for ticketed events, while scan to &quot;Fulfill&quot; can help track
                      concession sales.{' '}
                      <a
                        className="neutral-100"
                        href="https://omella.com/help/actions"
                        target="_blank"
                        rel="noreferrer">
                        Learn more...
                      </a>
                    </p>
                  }
                  ariaLabel="action buttons info">
                  {ICONS['solid_help']}
                </RichTooltip>
              </span>
              <button className="button-link secondary" onClick={handleShowModal}>
                Add
              </button>
            </div>

            <div className="row direction-column gap-s">
              {allActivityTypes?.map(
                (aType, index) =>
                  !aType?.deletedAt && (
                    <div key={`response-activity-${index}`} className="row">
                      <button
                        className="cta-group full-screen"
                        onClick={event => {
                          event?.preventDefault();
                          setActivityType(aType);
                        }}>
                        {aType?.name}
                      </button>
                      <IconButton
                        className="primary cta-group button-size-m"
                        icon="edit"
                        iconSide="RIGHT"
                        onClick={() => setActivityType(aType)}
                      />
                    </div>
                  ),
              )}
            </div>
          </div>
        </div>

        <div className="actions-container">
          <button disabled={loading} type="button" className="button-outline" onClick={handleResetClick}>
            Not now
          </button>
          <button type="submit" className="button primary" disabled={loading || !pageReceiptBody}>
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </form>
    </>
  );
}
