import { ReactiveVar, makeVar } from '@apollo/client';
import { defaultResponse, defaultOrderItem } from 'defaults/response';
import { formatParentAnswer } from 'utils';
import {
  Answer,
  Group,
  GroupedAnswers,
  Order,
  OrderItem,
  Response,
  SupportMessageInput,
} from 'api/data/response/types';
import { QuestionType } from 'components/QuestionBlock/types';
import { PaymentOption, Variant } from 'components/PaymentBlock/types';

export const responseVar: ReactiveVar<Response> = makeVar<Response>(defaultResponse);

export const findOrCreateOrderItem = (blockId: string): OrderItem => {
  return responseVar().order.orderItems.find(item => item.blockId === blockId) || newOrderItem({ blockId });
};

export const getOrderItems = (blockId: string): OrderItem[] => {
  const orderItems = responseVar().order.orderItems.filter(item => item.blockId === blockId);
  return orderItems.length > 0 ? orderItems : [newOrderItem({ blockId })];
};

export const findOrderItemByVariant = (blockId: string, variantId: string): OrderItem => {
  return responseVar().order.orderItems.find(item => item.variant.id === variantId) || newOrderItem({ blockId });
};

export const addOrderItem = (orderItem: OrderItem): void => {
  const response = responseVar();
  updateOrderItems(response, response.order.orderItems.concat(orderItem));
};

export const removeOrderItem = (id: string): void => {
  const response = responseVar();
  const orderItems = response.order.orderItems.filter((orderItem: OrderItem) => id !== orderItem.blockId);
  updateOrderItems(response, orderItems);
  removeOrderItemsByParentBlock(id);
};

export const removeOrderItemByVariant = (variantId: string): void => {
  const response = responseVar();
  const orderItems = response.order.orderItems.filter((orderItem: OrderItem) => variantId !== orderItem.variant.id);
  updateOrderItems(response, orderItems);
};

const getOrderItemsVariantIdsByBlockId = (id: string): (string | undefined)[] => {
  const response = responseVar();
  return response.order.orderItems.map((orderItem: OrderItem) => {
    if (id === orderItem.blockId) {
      return orderItem.variant.id;
    }
  });
};

export const removeOrderItemsByParentBlock = (parentBlockId: string): void => {
  const response = responseVar();
  const parentItemsVariantIds = getOrderItemsVariantIdsByBlockId(parentBlockId);

  const shouldBeRemoved = (orderItem: OrderItem) => {
    return orderItem.parent?.blockId === parentBlockId && !parentItemsVariantIds.includes(orderItem.parent.id);
  };

  const orderItems = response.order.orderItems.filter((orderItem: OrderItem) => !shouldBeRemoved(orderItem));
  updateOrderItems(response, orderItems);
};

const updateOrderItemInReponse = (response: Response, newOrderItem: OrderItem, orderItemIndex: number) => {
  const orderItems = [...response.order.orderItems];

  if (orderItemIndex === -1) {
    orderItems.push(newOrderItem);
  } else {
    orderItems[orderItemIndex] = newOrderItem;
  }
  updateOrderItems(response, orderItems);
};

export const updateOrderItemPaymentOption = (
  orderItem: OrderItem,
  variant: Variant,
  paymentOption: PaymentOption,
  description: string | undefined,
  amountCents: number | null | undefined,
  multipleChoice: boolean,
  parent?: Variant,
) => {
  const newOrderItem = {
    ...orderItem,
    blockId: orderItem.blockId,
    blockTitle: description,
    blockType: 'PAYMENT',
    recurring: paymentOption.recurring || 'ONCE',
    amount: amountCents,
    unitPriceCents: amountCents,
    variant: {
      id: variant.id,
      title: variant.label,
      amount: paymentOption.amountCents || 0,
      quantity: 1,
      paymentPlanInstallments: paymentOption.paymentPlanInstallments,
      paymentOptionId: paymentOption.id,
    },
    parent: formatParentAnswer(parent),
  };

  if (multipleChoice) {
    updateOrderItemByVariant(orderItem.blockId, variant.id, newOrderItem);
  } else {
    updateOrderItem(orderItem.blockId, newOrderItem);
  }
};

export const updateOrderItem = (id: string, attrs: Partial<OrderItem>): void => {
  const response = responseVar();
  const newOrderItem = { ...findOrCreateOrderItem(id), ...attrs };
  const orderItemIndex = response.order.orderItems.findIndex(orderItem => orderItem.blockId === id);

  updateOrderItemInReponse(response, newOrderItem, orderItemIndex);
};

export const updateOrderItemByVariant = (blockId: string, variantId: string, attrs: Partial<OrderItem>): void => {
  const response = responseVar();
  const newOrderItem = { ...findOrderItemByVariant(blockId, variantId), ...attrs };
  const orderItemIndex = response.order.orderItems.findIndex(orderItem => orderItem.variant.id === variantId);

  updateOrderItemInReponse(response, newOrderItem, orderItemIndex);
};

export const findGroupAnswers = (id: string): GroupedAnswers => {
  const groupedAnswers: GroupedAnswers = {};
  responseVar()
    .answers?.filter(answer => answer.group && answer.group.id === id)
    .forEach(answer => {
      const { group, groupKey } = answer;
      if (group && groupKey != null)
        groupedAnswers[groupKey] = Object.prototype.hasOwnProperty.call(groupedAnswers, groupKey)
          ? groupedAnswers[groupKey].concat(answer)
          : [answer];
    });
  return groupedAnswers;
};

export const findAnswer = (id: string, type: QuestionType, groupKey = null, value = '', title = ''): Answer => {
  const found = responseVar().answers?.find(
    answer => answer.question.id === id && (!groupKey || answer.groupKey === groupKey),
  );

  return (
    found || {
      value: value,
      question: {
        id: id,
        title: title,
        type: type,
      },
    }
  );
};

export const findMultipleChoiceAnswers = (id: string, groupKey = null): { [key: string]: Answer } => {
  const found =
    responseVar().answers?.filter(answer => answer.question.id === id && (!groupKey || answer.groupKey === groupKey)) ||
    [];

  return found.reduce((previous, next) => {
    return {
      ...previous,
      [next.choice?.id || next.choice?.title || '']: next,
    };
  }, {});
};

export const findGroupedAnswer = (
  id: string,
  type: QuestionType,
  group: Group,
  groupKey?: number,
  value = '',
  title = '',
): Answer => {
  const found = responseVar().answers?.find(
    answer => answer.question.id === id && answer.group?.id === group.id && answer.groupKey === groupKey,
  );

  return (
    found || {
      value: value,
      question: {
        id: id,
        title: title,
        type: type,
      },
      group: { id: group.id },
      groupKey: groupKey,
    }
  );
};

export const removeAnswers = (ids: string[]): void => {
  const response = responseVar();
  const answers = response.answers?.filter((answer: Answer) => !ids.includes(answer.question.id));
  responseVar({ ...response, answers });
};

export const removeAnswerChoice = (id: string): void => {
  const response = responseVar();
  const answers = response.answers?.filter((answer: Answer) => answer.choice?.id !== id);
  responseVar({ ...response, answers });
};

export const removeAnswerOtherChoice = (answer?: Answer): void => {
  if (answer) {
    const response = responseVar();
    if (response.answers) {
      const index = response.answers.indexOf(answer);
      response.answers.splice(index, 1);
      responseVar({ ...response });
    }
  }
};

export const updateMultipleChoiceAnswer = (id: string, newAnswer: Answer): void => {
  const response = responseVar();
  const answer = {
    ...findMultipleChoiceAnswers(id)[newAnswer.choice?.id || newAnswer.choice?.title || ''],
    ...newAnswer,
  };

  let answers = response.answers?.concat(answer);
  if (!answer.choice?.id && answer.choice?.title === 'Other') {
    const index =
      response.answers?.findIndex(answer => answer.choice?.title === 'Other' && answer.question.id === id) ?? -1;
    if (index > -1) {
      response.answers?.splice(index, 1, answer);
      answers = response.answers;
    }
  }

  responseVar({ ...response, answers });
};

export const updateAnswer = (id: string, newAnswer: Answer, group?: Group): void => {
  const response = responseVar();
  let answer, answers;
  if (group) {
    answer = { ...findGroupedAnswer(id, newAnswer.question.type, group, newAnswer.groupKey), ...newAnswer };
    answers = response.answers?.concat(answer);
  } else {
    answer = { ...findAnswer(id, newAnswer.question.type), ...newAnswer };
    answers = response.answers?.filter(previous => id !== previous.question.id).concat(answer);
  }
  responseVar({ ...response, answers });
};

export const findSupportMessage = (id: string): SupportMessageInput => {
  const found = responseVar().supportMessages?.find(supportMessage => supportMessage.blockId === id);

  return (
    found || {
      name: '',
      message: '',
      blockId: id,
    }
  );
};

export const updateSupportMessage = (id: string, newMessage: Partial<SupportMessageInput>): void => {
  const response = responseVar();

  const message = { ...findSupportMessage(id), ...newMessage };
  const supportMessages = (response.supportMessages || []).filter(previous => id !== previous.blockId).concat(message);

  responseVar({ ...response, supportMessages });
};

export const updateTip = (tip: number, arbitraryTip = false): void => {
  const response = responseVar();
  responseVar({
    ...response,
    order: {
      ...response.order,
      totalCents: Math.round(multiplyAndRound(response.order.subtotalCents, 1 + tip)),
      tip: {
        id: response.order.tip.id,
        amountCents: Math.round(multiplyAndRound(response.order.subtotalCents, tip)),
        percentage: tip,
      },
    },
    metadata: {
      ...response.metadata,
      arbitraryTip: arbitraryTip,
    },
  });
};

export const clearResponse = () => {
  updateResponse({ ...defaultResponse, id: undefined });
};

export const updateResponse = (partialResponse: Partial<Response>): void => {
  const response = responseVar();
  responseVar({
    ...response,
    ...partialResponse,
  });
};

export const updateOrder = (order: Order): void => {
  const response = responseVar();
  responseVar({
    ...response,
    order: {
      ...response.order,
      id: order.id,
      tip: {
        ...response.order.tip,
        id: order.tip?.id,
      },
      totalCents: order.totalCents,
      subtotalCents: order.subtotalCents,
      metadata: order.metadata,
    },
  });
};

function updateOrderItems(response: Response, orderItems: OrderItem[]) {
  const reducer = (acc: number, curr: OrderItem) => acc + (curr.amount || 0);

  const subtotalCents = orderItems.reduce(reducer, 0);

  let tip = response.order.tip;

  if (response.metadata?.arbitraryTip) {
    tip = {
      ...tip,
      percentage: ((response.order.tip.amountCents / subtotalCents) * 100) / 100,
    };
  } else {
    tip = {
      ...tip,
      amountCents: Math.round(multiplyAndRound(subtotalCents, response.order.tip.percentage)),
      percentage: response.order.tip.percentage,
    };
  }

  responseVar({
    ...response,
    order: {
      ...response.order,
      subtotalCents: Math.round(subtotalCents),
      totalCents: response.metadata?.arbitraryTip
        ? subtotalCents + tip.amountCents
        : Math.round(multiplyAndRound(subtotalCents, 1 + tip.percentage)),
      orderItems,
      tip,
    },
  });
}

function newOrderItem(attrs: Partial<OrderItem> = {}): OrderItem {
  return {
    ...defaultOrderItem,
    ...attrs,
  } as OrderItem;
}

function multiplyAndRound(value: number, factor: number): number {
  return Math.round((value * factor + Number.EPSILON) * 100) / 100;
}
