import React, { FC } from 'react';
import { numberCentsFormatter, numberToCentsFormatter } from 'utils';
import { DatesFields, validateDates } from 'components/Editor/AddDatesModal';
import InputNumber from 'components/Input/Number';
import { DatesGoalProps } from './types';

const DatesGoal: FC<DatesGoalProps> = ({ handleOnFieldChange, startDate, endDate, goal }) => {
  const validDates = validateDates(startDate, endDate);

  const handleGoal = (value: string) => {
    const target = numberToCentsFormatter(value);
    handleOnFieldChange(
      'goal',
      target === 0
        ? null
        : {
            target,
            collectedAmount: goal?.collectedAmount || 0,
            submissionsCount: goal?.submissionsCount || 0,
          },
    );
  };

  return (
    <div>
      <h1>When should we kick things off?</h1>
      <p className="subtitle-small neutral">Don’t worry, you can modify these later!</p>
      <div className="dates-fields-container">
        <DatesFields
          startDate={startDate}
          endDate={endDate}
          setStartDate={date => handleOnFieldChange('startDate', date)}
          setEndDate={date => handleOnFieldChange('endDate', date)}
          showError={!validDates}
        />
        <InputNumber
          placeholder="$30,000"
          onValueChange={target => handleGoal(target.value)}
          value={goal?.target ? numberCentsFormatter(goal?.target) : ''}
          name="goal"
          thousandSeparator
          allowNegative={false}
          label="Goal"
        />
      </div>
    </div>
  );
};

export default DatesGoal;
