import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import { tipOptions } from 'defaults/response';
import { updateTip } from 'api/data/response';
import { inIframe } from 'utils';
import Modal from 'components/Modal';
import InputNumber from 'components/Input/Number';
import checkIcon from 'assets/check.svg';
import { Order } from 'api/data/response/types';
import './style.scss';

interface TipModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  order: Order;
  saveResponse?: () => void;
}

const TipModal: FC<TipModalProps> = ({ showModal, setShowModal, order, saveResponse }: TipModalProps) => {
  const [tipSelected, setTipSelected] = useState(order.tip.percentage);
  const [otherTip, setOtherTip] = useState(0);

  const mounted = useRef(true);
  const tipRef = useRef<HTMLInputElement>(null);

  const handleTipChange = () => {
    const tip = tipSelected ? tipSelected : order.subtotalCents > 0 ? otherTip / order.subtotalCents : 0;
    const isAnArbitraryTip = tipSelected === 0;

    updateTip(tip, isAnArbitraryTip);

    if (saveResponse) saveResponse();

    setShowModal(false);
  };

  const handleCancel = () => {
    setTipSelected(order.tip.percentage);
    setOtherTip(0);
    setShowModal(false);
  };

  useEffect(() => {
    if (tipSelected === 0 && tipRef && tipRef.current) {
      tipRef.current.focus();
    }
  }, [tipSelected]);

  useEffect(() => {
    if (mounted.current) {
      mounted.current = false;
      const hasTip = tipOptions.some(item => item.value === order.tip.percentage);
      if (hasTip) return;
      setTipSelected(0);
      setOtherTip(order.tip.amountCents);
    }
  }, [order.tip]);

  return (
    <Modal
      header="Update Omella tip"
      handleOnCancel={handleCancel}
      handleOnConfirm={handleTipChange}
      confirmlabel="Update"
      visible={showModal}
      className={clsx({ 'in-iframe': inIframe() })}
      fullScreenMobile={!inIframe()}>
      <ul className="modal-tip">
        {tipOptions.map(item => (
          <li key={item.value}>
            <input
              type="radio"
              id={item.label}
              name="tip-percentage"
              className="hide"
              onChange={() => setTipSelected(item.value)}
              checked={item.value === tipSelected}
            />
            <label htmlFor={item.label} className="row space-between">
              {`$${((order.subtotalCents * item.value) / 100).toFixed(2)} (${item.label})`}
              <img src={checkIcon} alt="selected item" />
            </label>
          </li>
        ))}
        <li>
          <input
            type="radio"
            id="other"
            name="tip-percentage"
            className="hide"
            checked={tipSelected === 0}
            onChange={() => setTipSelected(0)}
          />
          <label htmlFor="other" className="row space-between align-center other-tip">
            <span>
              Other
              {tipSelected === 0 && (
                <InputNumber
                  value={otherTip ? (otherTip / 100).toFixed(2) : 0}
                  onValueChange={target => {
                    setOtherTip(Math.round(parseFloat(target.value || '0') * 100));
                  }}
                  thousandSeparator
                  decimalScale={2}
                  name="other-tip"
                  aria-label="other amount tip"
                  allowNegative={false}
                  getInputRef={tipRef}
                />
              )}
            </span>
            <img src={checkIcon} alt="selected item" />
          </label>
        </li>
      </ul>
    </Modal>
  );
};

export default TipModal;
