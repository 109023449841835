import { v4 as uuidv4 } from 'uuid';
import { defaultPaymentOption, defaultPaymentVariant } from 'defaults/page';
import { DonationBlock, Variant as DonationVariant } from 'components/DonationBlock/types';
import { GoalInput } from 'components/GoalBar/types';
import { GroupBlock } from 'components/GroupBlock/types';
import {
  PaymentOption as PaymentOptionType,
  Inventory,
  PaymentBlock as PaymentBlockType,
  Variant as PaymentVariant,
} from 'components/PaymentBlock/types';
import { Choice, QuestionBlock } from 'components/QuestionBlock/types';
import { SupporterFeedBlock } from 'components/SupporterFeedBlock/types';
import { BlockState } from './types';

export type PaymentValue =
  | string
  | number
  | boolean
  | string[]
  | undefined
  | PaymentOptionType[]
  | Partial<Inventory>
  | GoalInput
  | null;

export interface PaymentDonationBlockProps {
  setGoal: (block: BlockState) => void;
}
export interface BlockComponentProps {
  updateBlock: (block: BlockState, groupIdx?: number) => void;
  handleDelete: (groupIdx?: number) => void;
  disableOutsideClick?: boolean;
}

const copyPaymentOrDonationBlocks = (
  block: PaymentBlockType | DonationBlock,
): [PaymentBlockType | DonationBlock, PaymentVariant[] | DonationVariant[]] => {
  const paymentBlock = { ...block };
  const variants = paymentBlock.variants || [];

  return [paymentBlock, variants];
};

const copyQuestionBlock = (
  data: QuestionBlock | GroupBlock | SupporterFeedBlock,
): [QuestionBlock | GroupBlock | SupporterFeedBlock, Choice[]] => {
  const questionBlock = { ...data };
  let choices: Choice[] = [];

  if (questionBlock.type !== 'SUPPORTER_FEED') choices = (questionBlock as QuestionBlock)?.choices || [];

  return [questionBlock, choices];
};

const mapOrder = (item: (Choice | PaymentOptionType | PaymentVariant | DonationVariant)[]) => {
  return item.map((item, i) => ({ ...item, order: i }));
};

export const updatePaymentBlock = (
  data: PaymentBlockType | DonationBlock,
  value: PaymentValue,
  field: string,
  variantIdx?: number,
) => {
  const [paymentBlock, variants] = copyPaymentOrDonationBlocks(data);

  if (variantIdx !== undefined) {
    let fieldValue = value;
    if (field === 'amountCents' && value) {
      fieldValue = parseInt(value as string);
    }
    variants[variantIdx] = { ...variants[variantIdx], [field]: fieldValue } as PaymentVariant;
    paymentBlock.variants = variants as PaymentVariant[];
  } else {
    if (field === 'image') {
      const [image, imageUrl] = value as string[];
      return { ...paymentBlock, image, imageUrl };
    } else {
      return { ...paymentBlock, [field]: value };
    }
  }
  return paymentBlock;
};

export const deleteBlock = (blocks: BlockState[], index: number, groupIndex?: number) => {
  const newBlocks = [...blocks];

  if (groupIndex !== undefined) {
    const group = newBlocks[index] as GroupBlock;
    group.blocks.splice(groupIndex, 1);
    newBlocks[index] = group;
    if (group.blocks.length === 0) {
      newBlocks.splice(index, 1);
    }
  } else {
    newBlocks.splice(index, 1);
  }

  return newBlocks;
};

export const addPaymentVariant = (data: PaymentBlockType | DonationBlock, type: 'DONATION' | 'PAYMENT') => {
  const [paymentBlock, variants] = copyPaymentOrDonationBlocks(data);

  const newVariant: PaymentVariant | DonationVariant = {
    ...defaultPaymentVariant(type),
    order: type === 'DONATION' ? variants.length - 1 : variants.length,
  };

  variants.splice(newVariant.order, 0, newVariant as PaymentVariant);

  return {
    ...paymentBlock,
    variants: mapOrder(variants),
  } as PaymentBlockType;
};

export const removeVariant = (data: PaymentBlockType | DonationBlock, variantIdx: number) => {
  const [paymentBlock, variants] = copyPaymentOrDonationBlocks(data);

  variants.splice(variantIdx, 1);

  return {
    ...paymentBlock,
    variants,
  } as PaymentBlockType;
};

export const addPaymentOption = (data: PaymentBlockType, variantIndex: number) => {
  const [paymentBlock, variants] = copyPaymentOrDonationBlocks(data);

  const variant = variants[variantIndex] as PaymentVariant;

  const newPaymentOption: PaymentOptionType = {
    ...defaultPaymentOption(),
    order: variant.paymentOptions.length,
  };

  variant.paymentOptions.splice(newPaymentOption.order, 0, newPaymentOption);

  (variants[variantIndex] as PaymentVariant).paymentOptions = mapOrder(variant.paymentOptions) as PaymentOptionType[];
  return {
    ...paymentBlock,
    variants,
  } as PaymentBlockType;
};

export const removePaymentOption = (data: PaymentBlockType, variantIndex: number, paymentOptionIndex: number) => {
  const [paymentBlock, variants] = copyPaymentOrDonationBlocks(data);
  const variant = variants[variantIndex] as PaymentVariant;

  variant.paymentOptions.splice(paymentOptionIndex, 1);
  variants[variantIndex] = variant;

  return {
    ...paymentBlock,
    variants,
  } as PaymentBlockType;
};

export const addChoice = (data: QuestionBlock | GroupBlock) => {
  const [questionBlock, choices] = copyQuestionBlock(data);

  const newChoice = {
    id: uuidv4(),
    order: choices.length,
    text: '',
  };
  choices.push(newChoice);

  const newQuestionBlock = {
    ...questionBlock,
    choices: mapOrder(choices),
  } as QuestionBlock;
  return newQuestionBlock;
};

export const removeChoice = (data: QuestionBlock | GroupBlock, choiceIdx: number) => {
  const [questionBlock, choices] = copyQuestionBlock(data);

  choices.splice(choiceIdx, 1);

  const newQuestionBlock = {
    ...questionBlock,
    choices,
  } as QuestionBlock;

  return newQuestionBlock;
};

export const updateGroupBlock = (data: GroupBlock, questionBlock: QuestionBlock, groupIdx?: number) => {
  const groupBlock = { ...data };

  if (groupIdx !== undefined && groupBlock) {
    groupBlock.blocks[groupIdx] = questionBlock;
  }
  return groupBlock;
};

export const updateQuestionBlock = (
  data: QuestionBlock | SupporterFeedBlock,
  value: string | number | boolean | string[],
  field: string,
  choiceIdx?: number,
) => {
  const [questionBlock, choices] = copyQuestionBlock(data);

  if (choiceIdx !== undefined) {
    choices[choiceIdx] = { ...choices[choiceIdx], [field]: value } as Choice;
    (questionBlock as QuestionBlock).choices = choices;
    return questionBlock;
  }

  return { ...questionBlock, [field]: value };
};
