import React, { FC } from 'react';
import Message from 'components/Message';
import IconButton from 'components/Button/IconButton';
import ErrorIcon from 'assets/error.svg';
import './style.scss';

interface ErrorMessageProps {
  message: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({ message }) => {
  return (
    <Message showMessage={true} setShowMessage={() => null}>
      <div className="error-message-container">
        <img className="warning-icon" src={ErrorIcon} alt="error-icon" />
        <span className="content">{message}</span>
        <IconButton icon="reload" onClick={() => window.location.reload()}>
          Reload
        </IconButton>
      </div>
    </Message>
  );
};

export default ErrorMessage;
