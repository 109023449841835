import { BlockState } from 'api/data/pages/blocks/types';

const generateOrder = (currentBlockIndex: number, blocks: BlockState[]): number => {
  if (blocks.length === 0) {
    return 1;
  }

  if (currentBlockIndex === -1) {
    const currentBlockOrder = blocks[0].order;
    return currentBlockOrder / 2.0;
  }

  if (currentBlockIndex < blocks.length - 1) {
    const currentBlockOrder = blocks[currentBlockIndex].order;
    const nextBlockOrder = blocks[currentBlockIndex + 1].order;
    return (currentBlockOrder + nextBlockOrder) / 2.0;
  }

  return blocks[currentBlockIndex].order + 1.0;
};

export default generateOrder;
