import React, { ReactNode } from 'react';
import { CSSTransition } from 'react-transition-group';
import useToggle from 'hooks/useToggle';
import ICONS from 'components/Icons';
import './style.scss';

interface AccordionProps {
  title: string;
  icon?: ReactNode;
  children: ReactNode;
}

const Accordion = ({ title, icon, children }: AccordionProps) => {
  const [toggle, setToggle] = useToggle(false);

  const toggleAccordion = () => {
    setToggle();
  };

  return (
    <div
      className="accordion"
      tabIndex={0}
      aria-label="show extra content"
      role="button"
      onClick={toggleAccordion}
      onKeyDown={toggleAccordion}>
      <div className="accordion-title-container row space-between align-center">
        <div className="row align-center">
          {icon}
          <span className="subtitle-xx-small">{title}</span>
        </div>
        <div className="icon">{toggle ? ICONS['chevron_up'] : ICONS['chevron_down']}</div>
      </div>
      <CSSTransition in={toggle} timeout={400} classNames="accordion-content" unmountOnExit>
        <div className="accordion-content">{children}</div>
      </CSSTransition>
    </div>
  );
};

export default Accordion;
