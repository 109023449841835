import React, { ReactNode } from 'react';
import { useLocation } from 'wouter';
import { currencyFormatter } from 'utils';
import UserRolePerspective from '~/contexts/UserRolePerspective';
import ICONS from 'components/Icons';
import PaymentPlanTooltip from 'components/PaymentBlock/PaymentPlanTooltip';
import Tag, { TagType } from '~/components/Tag';
import Tooltip from 'components/Tooltip';
import { Subscription } from 'api/data/subscription/types';
import { TransactionByUser } from 'api/data/transaction/types';
import './style.scss';

interface RowProps {
  data?: Subscription | TransactionByUser;
  hasPaymentPlan: boolean;
  date: string;
  status: { tagType: TagType; value: string };
  recurring?: string;
  location?: string;
  children?: ReactNode;
  actions?: ReactNode;
}

const Row = ({ data, hasPaymentPlan, date, status, recurring, location, children, actions }: RowProps) => {
  const [, setLocation] = useLocation();

  const transaction = data as TransactionByUser;
  const transactionAvailableAmount = transaction?.availableAmountCents + transaction?.availableFeeAmountCents;
  const isRefund = !!transaction?.lastRefundAt;

  return (
    <UserRolePerspective.Consumer>
      {userRolePerspective => {
        const isPayerView = userRolePerspective === 'payer';

        let amountCents = isRefund ? transaction?.availableAmountCents : data?.netAmountCents;
        if (isPayerView) {
          amountCents = isRefund ? transactionAvailableAmount : data?.totalAmountCents;
        }

        const refundStatusMessage = `${transaction.availableAmountCents === 0 ? 'Refunded' : 'Partially refunded'} ${
          isPayerView
            ? currencyFormatter(transaction.refundedAmountCents + transaction.feeRefundedAmountCents)
            : currencyFormatter(transaction.refundedAmountCents)
        }`;

        const statusMessage = isRefund ? refundStatusMessage : status.value;

        return (
          <div className="member-profile-row">
            <div
              className="info"
              role="presentation"
              onClick={() => location && setLocation(`${location}/${data?.id || ''}`)}>
              <div className="left-side-info">
                <p>
                  {isPayerView && !!data?.organization?.name ? `${data.organization.name} - ` : ''}
                  <span className="lighter-text">{data?.page?.title}</span>
                </p>
                <p>
                  {date} {status && <Tag type={status.tagType} label={statusMessage} />}
                </p>
              </div>
              <div className="right-side-info">
                <div className="amount-recurring-content">
                  {!!recurring &&
                    (hasPaymentPlan ? (
                      <div className="secondary-text">
                        <PaymentPlanTooltip />
                        Payment plan
                      </div>
                    ) : (
                      <Tooltip title={recurring} color="dark" className="secondary-text lower-case">
                        {ICONS['recurring']} {recurring}
                      </Tooltip>
                    ))}
                  <span className="total-amount">{currencyFormatter(amountCents)}</span>
                </div>
                {actions}
              </div>
            </div>
            {children}
          </div>
        );
      }}
    </UserRolePerspective.Consumer>
  );
};

export default Row;
