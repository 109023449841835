import React, { FC, useState, isValidElement, cloneElement, Children, ReactNode, useEffect } from 'react';
import ICONS from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import './style.scss';

interface ViewsProps {
  activeKey: string;
  icon?: string;
  addNewView: () => void;
  children: ReactNode;
}

const Views: FC<ViewsProps> = ({ activeKey, icon, addNewView, children }) => {
  const [viewActive, setViewActive] = useState(activeKey);

  useEffect(() => {
    setViewActive(activeKey);
  }, [activeKey]);

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      const props = { viewActive: viewActive };
      return cloneElement(child, props);
    }
  });

  return (
    <div className="views">
      {icon && ICONS[icon]}
      <div className="views-items">{childrenWithProps}</div>
      <IconButton icon="plus_new" onClick={addNewView} />
    </div>
  );
};

export default Views;
