import React, { FC, useEffect, useRef } from 'react';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { useQuery } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import { centsFormatter } from 'utils';
import ICONS from 'components/Icons';
import SafeHtmlRenderer from '../SafeHtmlRenderer';
import { SupportMessage as SupportMessageType, SupportMessagesData } from './types';
import supporterFeedImage from 'assets/supporter-feed.svg';
import './style.scss';

const SupporterList: FC<{
  blockId?: string;
  participantId?: string;
  readOnly?: boolean;
  isEditing?: boolean;
}> = ({ blockId, participantId, readOnly, isEditing }) => {
  const notAfter = useRef(new Date().toISOString());
  const { data, fetchMore, refetch } = useQuery<SupportMessagesData>(ResponseQuery.GetSupportMessages, {
    variables: { blockId, participantId: participantId, limit: 3, offset: 0, notAfter: notAfter.current },
    skip: !blockId,
  });
  const responseMessages = data?.supportMessages.messages;
  const messagesLength = responseMessages?.length || 0;
  const defaultMessage = { name: 'Supporter Name', message: 'Message will appear here', amountCents: '0' };
  const supportersMessages = readOnly ? (Array(3).fill(defaultMessage) as SupportMessageType[]) : responseMessages;
  const medals = ['\u{1F947}', '\u{1F948}', '\u{1F949}'];

  useEffect(() => {
    if (participantId) {
      void refetch();
    }
  }, [participantId, refetch]);

  return (
    <div className={clsx('supporter-list', { 'read-only': readOnly && !isEditing })}>
      {readOnly && !isEditing && (
        <div className="editor-message">{ICONS['supporter_feed']} Supporters will appear in the live page</div>
      )}
      {!readOnly && messagesLength === 0 && (
        <div className="empty-list">
          <img src={supporterFeedImage} alt="Supporter list empty" />
          <p>Be the first to support our cause</p>
        </div>
      )}
      {supportersMessages?.map((item, idx) => (
        <div key={idx}>
          <span>{medals[idx]}</span>
          <div className="name-message">
            <p className="name">{item.name || 'Anonymous'}</p>
            {item.message?.startsWith('<p>') ? <SafeHtmlRenderer content={item.message} /> : <p>{item.message}</p>}

            <p className="text-disabled">{dayjs(item.insertedAt).fromNow()}</p>
          </div>
          <div className="amount-cents">
            {item.amountCents > 0 ? '$' + centsFormatter(item.amountCents.toString()) : ''}
          </div>
        </div>
      ))}
      <footer>
        {(data?.supportMessages.totalCount || 0) > messagesLength && (
          <button
            className="button-cancel"
            onClick={() => void fetchMore({ variables: { limit: 5, offset: messagesLength } })}>
            Load more
          </button>
        )}
      </footer>
    </div>
  );
};

export default SupporterList;
