import React, { FC, useState } from 'react';
import { useQuery } from '@apollo/client';
import useActiveTab from 'hooks/useActiveTab';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import * as RequestQuery from 'graphql/request.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import showPlural from '~/utils/showPlural';
import LoggedPageContainer from 'components/LoggedPageContainer';
import PageResponses from '../PageResponses';
import RequestsTable from '~/components/RequestsTable';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs';
import { OrganizationRequests } from 'api/data/request/types';
import { RequestsResponses } from 'api/data/response/types';
import './style.scss';

const MyRequests: FC = () => {
  const [tabContentKey, setTabContentKey] = useState('requests');
  const { currentOrg } = useCurrentOrganization();
  const { data: requestsData, loading: requestLoading } = useQuery<OrganizationRequests>(
    RequestQuery.OrganizationRequests,
    {
      fetchPolicy: 'network-only',
    },
  );
  const { data: responsesData } = useQuery<RequestsResponses>(ResponseQuery.RequestsResponses, {
    fetchPolicy: 'cache-and-network',
  });

  useActiveTab(setTabContentKey, requestsData?.organizationRequests, responsesData?.requestsResponses?.responses);

  const requests = requestsData?.organizationRequests || [];
  const submissions = responsesData?.requestsResponses?.responses || [];

  return (
    <LoggedPageContainer
      className="my-requests"
      headerChildren={
        <div className="row second-header">
          <h1>Requests</h1>
        </div>
      }
      organizationStatus={currentOrg?.status}
      fixedHeaderContent={
        <div className="action-container">
          <Tabs activeKey={tabContentKey}>
            <Tab
              label={`${requests.length} request${showPlural(requests.length)}`}
              tabKey="requests"
              onClick={setTabContentKey}></Tab>
            <Tab
              label={`${submissions.length || 0} submission${showPlural(submissions.length)}`}
              tabKey="submissions"
              onClick={setTabContentKey}></Tab>
          </Tabs>
        </div>
      }>
      {tabContentKey === 'submissions' ? (
        <PageResponses
          responses={submissions}
          totalCollectedCents={responsesData?.requestsResponses.totalCollectedCents}
          showPageTitle
        />
      ) : (
        <RequestsTable requests={requests} isOrgRequests loading={requestLoading} />
      )}
    </LoggedPageContainer>
  );
};

export default MyRequests;
