import { default as queryString } from 'query-string';

const extractQueryStringFromParams = (paramName: string) => {
  const queryParams = queryString.extract(window.location.search);
  const searchParams: { [key: string]: string } = {};

  queryParams.split('&').forEach(param => {
    const [key, value] = param.split('=');
    searchParams[key] = value ? decodeURIComponent(value) : '';
  });

  return searchParams[paramName] || searchParams[paramName.charAt(0).toUpperCase() + paramName.slice(1)] || '';
};

export default extractQueryStringFromParams;
