import { InMemoryCache, Reference } from '@apollo/client';
import { convertFromRaw, EditorState, CompositeDecorator, DraftDecorator, RawDraftContentState } from 'draft-js';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import MultiDecorator from '../components/RichTextEditor/CustomEditor/Editor/MultiDecorator';
import { SupportMessages } from '~/components/SupporterFeedBlock/types';

function getPluginDecorator() {
  let decorators: (CompositeDecorator | DraftDecorator)[] = [];
  let plugin;

  const linkify = createLinkifyPlugin({ target: '_blank' });
  for (plugin of [linkify]) {
    if (plugin.decorators !== null && plugin.decorators !== undefined) {
      decorators = decorators.concat(plugin.decorators);
    }
  }
  return new MultiDecorator([new CompositeDecorator(decorators as DraftDecorator[])]);
}

const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    TextBlock: {
      fields: {
        data: {
          read(data = '') {
            return EditorState.createWithContent(
              convertFromRaw(JSON.parse(data as string) as RawDraftContentState),
              getPluginDecorator(),
            );
          },
        },
      },
    },
    Query: {
      fields: {
        supportMessages: {
          keyArgs: false,
          merge(existing: SupportMessages, incoming: SupportMessages) {
            const initial = existing?.messages || [];
            const merged = initial.concat(incoming.messages);
            return {
              ...existing,
              ...incoming,
              messages: merged,
            };
          },
        },
        participants: {
          read(existing: Reference[] = [], { readField }) {
            return existing.filter((participantRef: Reference) => {
              const deletedAt = readField<string | null>('deletedAt', participantRef);
              return !deletedAt;
            });
          },
        },
      },
    },
  },
});

export default cache;
