import React, { FC, useState } from 'react';
import Input from 'components/Input';
import ModalComponent from 'components/Modal';
import { ModalViewProps } from '../types';
import './style.scss';

const Modal: FC<ModalViewProps> = ({ handleOnSaveView, loadingSaveView, showModal, setShowModal }) => {
  const [viewName, setViewName] = useState('');

  const handleOnInputChange = (value: string) => {
    setViewName(value);
  };

  const saveView = async () => {
    await handleOnSaveView(viewName);
    setViewName('');
  };

  return (
    <ModalComponent
      header="New view"
      headerIcon="reports"
      handleOnCancel={() => {
        setShowModal(false);
        setViewName('');
      }}
      handleOnConfirm={() => void saveView()}
      confirmlabel={loadingSaveView ? 'Loading...' : 'New view'}
      denyLabel="Not now"
      visible={showModal}
      className="new-view-modal"
      confirmButtonProps={{ disabled: loadingSaveView }}>
      <div>
        <Input
          placeholder="E.g. Annual Fundraising"
          onChange={({ target }) => handleOnInputChange(target.value)}
          name="E.g. Annual Fundraising"
          value={viewName}
        />
      </div>
    </ModalComponent>
  );
};

export default Modal;
