import React, { FC } from 'react';
import oLogo from 'assets/o_logo.svg';
import './style.scss';

const PageWithLogoLine: FC = ({ children }) => {
  return (
    <section className="request-link page-with-logo-line">
      <header className="main">
        <div className="row"></div>
      </header>
      <div className="omella-logo-line">
        <img src={oLogo} alt="Omella logo" />
      </div>
      <div className="content">{children}</div>
    </section>
  );
};

export default PageWithLogoLine;
