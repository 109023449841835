import React, { FC, ReactNode, RefObject } from 'react';
import clsx from 'clsx';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import './style.scss';

export interface FilterProps {
  buttonName: string;
  filterName: string;
  refOutside: RefObject<HTMLElement>;
  handleOnApply: () => void;
  handleOnClear: () => void;
  outsideClick: () => void;
  children: ReactNode;
  itemsFiltered?: string;
}

const Filter: FC<FilterProps> = ({
  buttonName,
  filterName,
  refOutside,
  handleOnClear,
  handleOnApply,
  outsideClick,
  itemsFiltered,
  children,
}) => {
  return (
    <DropDown
      outsideRef={refOutside}
      openedIcon="arrow_up"
      closedIcon="arrow_down"
      iconSide="RIGHT"
      responsive
      className={clsx('dropdown-filter', { 'items-filtered': itemsFiltered })}
      buttonChildren={
        <p className="button-text">
          {buttonName}
          {itemsFiltered && <span className="ellipsis-text">{itemsFiltered}</span>}
        </p>
      }
      onBlur={outsideClick}>
      <p className="name">{filterName}</p>
      <div className="options">{children}</div>
      <button className="button-clear button-size-sm action" onClick={() => handleOnClear()}>
        Clear
      </button>
      <DropDownItem className="action apply" onClick={() => handleOnApply()}>
        Apply
      </DropDownItem>
    </DropDown>
  );
};

export default Filter;
