import { useCombobox, useMultipleSelection } from 'downshift';
import React, { FC, useEffect, useMemo, useState } from 'react';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import Badge from 'components/Badge';
import ICONS from 'components/Icons';
import IconButton from 'components/Button/IconButton';
import Input from 'components/Input';
import Tooltip from 'components/Tooltip';
import { SpaceRoles } from 'api/data/user/types';
import { SpacesType } from './InviteMembers';

export type SpaceMembershipsType = {
  id: string;
  name: string;
  role: SpaceRoles;
  membershipId?: string;
};

interface SpaceMembershipsProps {
  onChange: (space: SpacesType) => void;
  onRemove: (id: string) => void;
  spaceMemberships?: SpaceMembershipsType[];
}

const SpaceMemberships: FC<SpaceMembershipsProps> = ({ onChange, onRemove, spaceMemberships }) => {
  const [inputValue, setInputValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<SpaceMembershipsType[]>([]);
  const { currentOrg } = useCurrentOrganization({ fetchPolicy: 'cache-only' });

  useEffect(() => {
    setSelectedItems(spaceMemberships || []);
  }, [spaceMemberships]);

  const items = useMemo(() => {
    const lowerCasedInputValue = inputValue.toLowerCase();

    const filteredSpaces = currentOrg?.spaces.filter(
      space =>
        !selectedItems.some(item => item.id === space.id) && space.name.toLowerCase().includes(lowerCasedInputValue),
    );

    return filteredSpaces?.map(space => ({ id: space.id, name: space.name, role: 'STAFF' })) as SpaceMembershipsType[];
  }, [inputValue, currentOrg?.spaces, selectedItems]);

  const { getSelectedItemProps, getDropdownProps, removeSelectedItem } = useMultipleSelection({
    selectedItems,
    onStateChange({ selectedItems, type }) {
      if (type === useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem) {
        setSelectedItems(selectedItems || []);
      }
    },
  });
  const { isOpen, getToggleButtonProps, getMenuProps, getInputProps, getItemProps } = useCombobox({
    items,
    itemToString(item) {
      return item ? item.name : '';
    },
    selectedItem: null,
    inputValue,
    stateReducer(_, actionAndChanges) {
      const { changes, type } = actionAndChanges;

      if (type === useCombobox.stateChangeTypes.ItemClick) {
        return {
          ...changes,
          isOpen: true,
        };
      }
      return changes;
    },
    onStateChange({ inputValue, type, selectedItem }) {
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
          if (selectedItem) {
            setSelectedItems([...selectedItems, selectedItem]);
            setInputValue('');
            onChange({ id: selectedItem.id, role: 'STAFF' });
          }
          break;
        case useCombobox.stateChangeTypes.InputChange:
          setInputValue(inputValue || '');
          break;
        default:
          break;
      }
    },
  });

  const { ref, ...inputProps } = getInputProps(getDropdownProps({ preventKeyAction: isOpen }));

  return (
    <div className="selector space-memberships">
      <div className="selected-items">
        {selectedItems.map((selectedItem, index) => {
          return (
            <span key={`selected-item-${index}`} {...getSelectedItemProps({ selectedItem, index })}>
              {selectedItem.role === 'OWNER' ? (
                <Tooltip title="Unable to remove this person from this space" color="dark">
                  <Badge size="small" /> {selectedItem.name}
                </Tooltip>
              ) : (
                <>
                  {selectedItem.name}
                  <IconButton
                    icon="close"
                    onClick={e => {
                      e.stopPropagation();
                      removeSelectedItem(selectedItem);

                      if (selectedItem.membershipId) {
                        return onRemove(selectedItem.membershipId);
                      }
                      onRemove(selectedItem.id);
                    }}
                  />
                </>
              )}
            </span>
          );
        })}
        <div className="spaces-input-container">
          <Input
            {...inputProps}
            name="select-spaces"
            placeholder="Select spaces"
            aria-label="input-spaces"
            inputRef={ref}
          />
          <button className="icon-button" {...getToggleButtonProps()}>
            {ICONS['arrow_down']}
          </button>
        </div>
      </div>
      <ul {...getMenuProps()}>
        {isOpen && (
          <>
            <li className="spaces-dropdown-description">Select a Space</li>
            {items.map((item, index) => (
              <li key={item.id} {...getItemProps({ item, index })}>
                <span className="size-xxxs space-name">{item.name}</span>
              </li>
            ))}
          </>
        )}
      </ul>
    </div>
  );
};

export default SpaceMemberships;
