import React, { FC, ReactNode, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import ICONS from 'components/Icons';
import './style.scss';

export type MessageType = 'succeeded' | 'failed';

interface MessageProps {
  className?: string;
  children: ReactNode;
  showMessage: boolean;
  setShowMessage: (visible: boolean) => void;
  timeout?: number;
  type?: MessageType;
}

const Message: FC<MessageProps> = ({ className = '', showMessage, setShowMessage, children, timeout = 500, type }) => {
  const timeoutRef = useRef<NodeJS.Timeout | undefined>();

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }

    if (showMessage && timeout > 0) {
      timeoutRef.current = setTimeout(() => {
        setShowMessage(false);
      }, 6000);
    }
  }, [setShowMessage, showMessage, timeout]);

  return (
    <CSSTransition in={showMessage} timeout={timeout} classNames="animation" unmountOnExit>
      <div
        className={clsx(
          'message',
          { 'succeeded-message': type === 'succeeded', 'failed-message': type === 'failed' },
          className,
        )}>
        {type === 'succeeded' && ICONS['check_circle']}
        {type === 'failed' && ICONS['cancel']}
        {children}
      </div>
    </CSSTransition>
  );
};

export default Message;
