import React, { FC } from 'react';

type EmptyColumnsProps = {
  length: number;
};

const EmptyColumns: FC<EmptyColumnsProps> = ({ length }) => (
  <>
    {Array.from({ length }).map((_, index) => (
      <td key={index}></td>
    ))}
  </>
);

export default EmptyColumns;
