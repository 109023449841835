import React, { FC, useRef, useState } from 'react';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import uploadFile from 'api/uploadClient';
import { Organization } from 'api/data/user/types';
import UploadImageWrapper from 'components/UploadImage';
import pageCover from 'assets/page_cover.png';
import pageLogo from 'assets/page_logo.png';
import './style.scss';

interface UploadImagesProps {
  handleSaveOrganization: (changes: Partial<Organization>) => void;
  loading: boolean;
}

const UploadImages: FC<UploadImagesProps> = ({ handleSaveOrganization }) => {
  const { currentOrg } = useCurrentOrganization();
  const [imageUploading, setImageUploading] = useState('');
  const logoInput = useRef<HTMLInputElement>(null);
  const coverInput = useRef<HTMLInputElement>(null);
  const refImages = useRef<HTMLDivElement>(null);

  const handleOnUploadImage = async (file: File, attr: string) => {
    const uploadResponse = await uploadFile(file, 'IMAGE', attr);
    handleSaveOrganization({ ...currentOrg, [attr]: uploadResponse.file_handle });
  };

  const removeImage = (attr: string) => {
    handleSaveOrganization({ ...currentOrg, [attr]: '' });
  };

  const coverPictureUrl = currentOrg?.coverPictureUrl;
  const logoUrl = currentOrg?.logoUrl;

  return (
    <div className="img-header" ref={refImages}>
      <UploadImageWrapper
        image={{
          name: 'coverPicture',
          label: 'cover',
          file: coverPictureUrl || pageCover,
          ref: coverInput,
          imageIsEmpty: !coverPictureUrl,
        }}
        imageUploading={imageUploading}
        handleOnRemoveImage={removeImage}
        handleOnUploadImage={handleOnUploadImage}
        setImageUploading={setImageUploading}
      />
      <UploadImageWrapper
        image={{ name: 'logo', label: 'logo', file: logoUrl || pageLogo, ref: logoInput, imageIsEmpty: !logoUrl }}
        imageUploading={imageUploading}
        handleOnRemoveImage={removeImage}
        handleOnUploadImage={handleOnUploadImage}
        setImageUploading={setImageUploading}
      />
    </div>
  );
};

export default UploadImages;
