import React, { FC, useRef } from 'react';
import { Link } from 'wouter';
import useUserSession from '~/hooks/useUserSession';
import ICONS from '../Icons';
import LogoLink from './LogoLink';
import ProfileMenu from './ProfileMenu';
import { SubmissionType } from '~/pages/SubmissionDetails/Details/SuccessCard';
import Tooltip from '../Tooltip';

interface SimpleHeaderProps {
  submissionType: SubmissionType;
}

const SimpleHeader: FC<SimpleHeaderProps> = ({ submissionType }) => {
  const { data, loading } = useUserSession();
  const refOutside = useRef<HTMLElement>(null);

  return (
    <header className="main simple-header">
      <div className="row space-between">
        <div className="logo">
          <LogoLink />
        </div>
        {!submissionType && (
          <div className="row align-center">
            {data?.session?.currentUser ? (
              <div className="menu">
                <ProfileMenu data={data} refOutside={refOutside} />
              </div>
            ) : (
              !loading && <Link href={`/login${window.location.search}`}>Log in</Link>
            )}
            <div className="help">
              <Tooltip title="Help" color="dark" aria-label="help">
                <a href="https://www.omella.com/help" target="_blank" rel="noreferrer" aria-label="help">
                  {ICONS['help']}
                </a>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
    </header>
  );
};

export default SimpleHeader;
