import React from 'react';
import { isColumnVisible } from './isColumnVisible';
import { currencyFormatter, dateFormat, dateTimeFormat } from '~/utils';
import RegisterResponseActivity from './ComponentAccessors/RegisterResponseActivity';
import { ColumnConfig, ObjectConfig, TableConfig } from 'components/ReportTable/types';
import { TableConfigType, ConfigurationType } from '../types';
import { OrderItem, ResponseActivity, ResponseReportRecord, SubmissionQuestion } from '~/api/data/response/types';

const convertToResponse = (data: TableConfigType) => data as ResponseReportRecord;
const convertToOrderItem = (data: TableConfigType) => data as OrderItem;

const buildGroupedColumns = (
  viewConfig: ConfigurationType,
  questions?: SubmissionQuestion[],
  refetchSubmissionsResponse?: () => Promise<void>,
) => {
  const questionsPerPage = questions || [];

  const activityColumns = (page: SubmissionQuestion) => {
    return (
      page.response_activity_types?.map(activityType => ({
        label: activityType.name,
        accessor: (data: TableConfigType) => {
          const matchTypeId = (activity: ResponseActivity) => activity.responseActivityType.id === activityType.id;
          const activity = convertToResponse(data)?.responseActivities?.find(matchTypeId);

          return dateTimeFormat(activity?.insertedAt) || '';
        },
        componentAccessor: (data: TableConfigType) => {
          const matchTypeId = (activity: ResponseActivity) => activity.responseActivityType.id === activityType.id;
          const activity = convertToResponse(data)?.responseActivities?.find(matchTypeId);
          const responseId = convertToResponse(data).id;
          const activityName = activityType.name;

          if (!activity) {
            return (
              <RegisterResponseActivity
                className="button-link secondary button-size-sm"
                responseId={responseId}
                activityTypeId={activityType.id}
                activityName={activityName}
                afterRegisterCallback={refetchSubmissionsResponse}
              />
            );
          }
        },
        fieldName: activityType.name,
        id: activityType.id,
        show: isColumnVisible(viewConfig.query.columns, activityType.id),
        isGroupedRow: false,
        groupBlockId: null,
        groupedAccessor: () => '',
        isSortable: false,
      })) || []
    );
  };

  const groupedColumns = questionsPerPage.map(page => ({
    id: page.page_id,
    label: page.page_title,
    show: isColumnVisible(viewConfig.query.columns, page.page_id),
    columns: page.questions
      .map(question => ({
        label: question.question_prompt,
        accessor: (data: TableConfigType) => {
          const singleAnswers = convertToResponse(data)?.answers?.single || {};
          return singleAnswers[question.block_id] || '';
        },
        groupedAccessor: (data: ObjectConfig) => {
          return data[question.block_id] || '';
        },
        fieldName: question.block_id,
        isGroupedRow: !!question.group_block_id,
        groupBlockId: question.group_block_id,
        id: question.block_id,
        show: isColumnVisible(viewConfig.query.columns, question.block_id),
        isSortable: true,
      }))
      .concat(activityColumns(page)),
  }));

  return groupedColumns;
};

const fixedColumns: (viewConfig: ConfigurationType) => Array<ColumnConfig<TableConfigType>> = viewConfig => {
  return [
    {
      label: 'Space',
      accessor: (data: TableConfigType) => convertToResponse(data).page?.space.name || '',
      fieldName: 'space',
      id: '1',
      show: isColumnVisible(viewConfig.query.columns, 'space'),
      isSortable: true,
    },
    {
      label: 'Page',
      accessor: (data: TableConfigType) => convertToResponse(data).page?.title || '',
      fieldName: 'page',
      id: '2',
      show: isColumnVisible(viewConfig.query.columns, 'page'),
      isSortable: true,
    },
    {
      label: 'Name',
      accessor: (data: TableConfigType) => convertToResponse(data).user?.fullName || '',
      fieldName: 'name',
      id: '3',
      show: isColumnVisible(viewConfig.query.columns, 'name'),
      isSortable: true,
    },
    {
      label: 'Email',
      accessor: (data: TableConfigType) => convertToResponse(data).user?.email || '',
      fieldName: 'email',
      id: '4',
      show: isColumnVisible(viewConfig.query.columns, 'email'),
      isSortable: true,
    },
    {
      label: 'Participant Name',
      accessor: (data: TableConfigType) => convertToResponse(data).participantMembership?.fullName || '',
      fieldName: 'participant_name',
      id: '5',
      show: isColumnVisible(viewConfig.query.columns, 'participant_name'),
      isSortable: true,
    },
    {
      label: 'Participant Email',
      accessor: (data: TableConfigType) => convertToResponse(data).participantMembership?.user.email || '',
      fieldName: 'participant_email',
      id: '6',
      show: isColumnVisible(viewConfig.query.columns, 'participant_email'),
      isSortable: true,
    },
    {
      label: 'Created at',
      accessor: (data: TableConfigType) => dateFormat(convertToResponse(data).insertedAt) || '',
      fieldName: 'inserted_at',
      id: '7',
      show: isColumnVisible(viewConfig.query.columns, 'inserted_at'),
      isSortable: true,
    },
    {
      label: 'Items',
      accessor: (data: TableConfigType) => convertToOrderItem(data).blockTitle || '',
      fieldName: 'block_title',
      id: '8',
      show: isColumnVisible(viewConfig.query.columns, 'block_title'),
      isSortable: true,
    },
    {
      label: 'Choice',
      accessor: (data: TableConfigType) => convertToOrderItem(data).variantTitle || '',
      isSubData: true,
      fieldName: 'variant_title',
      id: '9',
      show: isColumnVisible(viewConfig.query.columns, 'variant_title'),
      isSortable: true,
    },
    {
      label: 'Order qty',
      accessor: (data: TableConfigType) => {
        const resp = convertToResponse(data);
        const respQuantity =
          resp.order?.orderItems
            .map(item => item.quantity || 0)
            .reduce((a, b) => a + b, 0)
            .toString() || '';
        return respQuantity || convertToOrderItem(data).quantity?.toString() || '';
      },
      isSubData: true,
      fieldName: 'variant_quantity',
      id: '10',
      show: isColumnVisible(viewConfig.query.columns, 'variant_quantity'),
      type: 'number',
      isSortable: true,
    },
    {
      label: 'Order total qty',
      fieldName: 'order_quantity',
      id: '11',
      show: false,
      type: 'number',
      isSortable: true,
    },
    {
      label: 'Order Sub Total',
      accessor: (data: TableConfigType) => {
        const subTotal = convertToResponse(data).order?.subtotalCents || convertToOrderItem(data)?.amount;
        return subTotal !== undefined && subTotal !== null ? currencyFormatter(subTotal) : '';
      },
      fieldName: 'subtotal',
      id: '12',
      show: isColumnVisible(viewConfig.query.columns, 'subtotal'),
      type: 'number',
      isSortable: true,
    },
    {
      label: 'Refunded qty',
      accessor: (data: TableConfigType) => {
        const orderItem = convertToOrderItem(data);
        if (orderItem.__typename === 'OrderItem') return (orderItem.refundedQuantity || 0).toString();

        const res = convertToResponse(data);
        return (
          res.order?.orderItems
            .map(item => item.refundedQuantity || 0)
            .reduce((a, b) => a + b, 0)
            .toString() || ''
        );
      },
      isSubData: true,
      fieldName: 'refunded_qty',
      id: '13',
      show: isColumnVisible(viewConfig.query.columns, 'refunded_qty'),
      type: 'number',
      isSortable: false,
    },
    {
      label: 'Refunded amount',
      accessor: (data: TableConfigType) => {
        const oi = convertToOrderItem(data);
        if (oi.__typename === 'OrderItem') return currencyFormatter(oi.refundedAmount || 0);

        const res = convertToResponse(data);
        const amount = res.order?.orderItems.map(item => item.refundedAmount || 0).reduce((a, b) => a + b, 0);
        return currencyFormatter(amount);
      },
      isSubData: true,
      fieldName: 'refunded_amount',
      id: '14',
      show: isColumnVisible(viewConfig.query.columns, 'refunded_amount'),
      type: 'number',
      isSortable: false,
    },
    {
      label: 'Net qty',
      accessor: (data: TableConfigType) => {
        const oi = convertToOrderItem(data);
        if (oi.__typename === 'OrderItem') return ((oi.quantity || 0) - (oi.refundedQuantity || 0)).toString();

        const res = convertToResponse(data);
        return res.order?.orderItems
          .map(item => (item.quantity || 0) - (item.refundedQuantity || 0))
          .reduce((a, b) => a + b, 0)
          .toString();
      },
      fieldName: 'net_qty',
      id: '15',
      show: isColumnVisible(viewConfig.query.columns, 'net_qty'),
      type: 'number',
      isSortable: false,
    },
    {
      label: 'Net Total',
      accessor: (data: TableConfigType) => {
        const netAmountCents = convertToResponse(data).netAmountCents;
        return netAmountCents !== undefined ? currencyFormatter(netAmountCents) : '';
      },
      fieldName: 'total',
      id: '16',
      show: isColumnVisible(viewConfig.query.columns, 'total'),
      type: 'number',
      isSortable: true,
    },
    {
      label: 'Payment Method',
      accessor: (data: TableConfigType) => {
        const paymentMethodTypes = {
          offline: 'Cash or Check',
          card: 'Card',
          ach: 'ACH',
        };

        const paymentMethod = convertToResponse(data).paymentMethod;

        return paymentMethodTypes[paymentMethod as keyof typeof paymentMethodTypes];
      },
      fieldName: 'payment_method',
      id: '17',
      show: isColumnVisible(viewConfig.query.columns, 'payment_method'),
      type: 'text',
    },
  ];
};

export const buildSubmissionsReportTableConfig = (
  viewConfig: ConfigurationType,
  questions?: SubmissionQuestion[],
  onRowClicked?: (data: TableConfigType) => void,
  refetchSubmissionsResponse?: () => Promise<void>,
): TableConfig<TableConfigType> => {
  return {
    ungroupedColumns: fixedColumns(viewConfig),
    groupedColumns: buildGroupedColumns(viewConfig, questions, refetchSubmissionsResponse),
    getFixedSubData: (data: TableConfigType) => {
      const groupedData = convertToResponse(data).answers.grouped;

      const groups = groupedData?.map(group => Object.values(group)[0].map(answer => answer.answers)) || [];
      const maxLength = groups.reduce((max, answers) => Math.max(max, answers.length), 0);

      const result = new Array(maxLength).fill({}) as Array<ObjectConfig>;

      groups.forEach(answers => {
        new Array(maxLength).fill(0).map((_, index) => {
          result[index] = { ...result[index], ...answers[index] };
        });
      });

      return result;
    },
    getSubData: (data: TableConfigType) => {
      const visibleColumns = viewConfig.query.columns;
      const columnsWithSubData =
        isColumnVisible(visibleColumns, 'block_title') ||
        isColumnVisible(visibleColumns, 'variant_title') ||
        isColumnVisible(visibleColumns, 'variant_quantity');
      if (columnsWithSubData) return convertToResponse(data).order.orderItems;

      return [];
    },
    onRowClicked: onRowClicked,
  };
};

// extracts all fields defined in tableConfig from response (used for searching in all fields)
export const extractSearchableDataFromResponse = (
  tableConfig: TableConfig<TableConfigType>,
  response: ResponseReportRecord,
) => {
  const columns = ['name', 'email'];

  const basicColumns = tableConfig.ungroupedColumns
    .filter(column => columns.includes(column.fieldName))
    .map(column => {
      return column.accessor?.(response) || '';
    });

  const subDataColumns = ['block_title', 'variant_title'];

  const subDataFields = tableConfig
    .getSubData(response)
    .map(subData => {
      return tableConfig.ungroupedColumns
        .filter(column => subDataColumns.includes(column.fieldName))
        .map(column => {
          return column.accessor?.(subData) || '';
        });
    })
    .flat();

  const singleAnswers =
    (tableConfig.groupedColumns &&
      tableConfig.groupedColumns[0]?.columns.map(column => {
        return column.accessor?.(response) || '';
      })) ||
    [];

  const fixedSubData = tableConfig.getFixedSubData(response);
  const groupedAnswers =
    (tableConfig.groupedColumns &&
      tableConfig.groupedColumns[0]?.columns
        .map(column => {
          return fixedSubData.map(data => column.groupedAccessor?.(data) || '');
        })
        .flat()) ||
    [];

  return basicColumns.concat(subDataFields).concat(singleAnswers).concat(groupedAnswers);
};
