import React, { FC } from 'react';
import clsx from 'clsx';
import useToggle from 'hooks/useToggle';
import ICONS from 'components/Icons';
import { DropDownListItemProps, DropDownListProps } from './types';
import './style.scss';

const DropDownList: FC<DropDownListProps> = ({ className, label, progressIcon, children }) => {
  const [toggle, setToggle] = useToggle(false);

  const handleOnMouseEvent = () => setToggle();

  return (
    <div
      className={clsx('row align-center dropdown-list', className)}
      onMouseEnter={handleOnMouseEvent}
      onMouseLeave={handleOnMouseEvent}>
      {label} {progressIcon}
      {toggle && <ul>{children}</ul>}
    </div>
  );
};

export const DropDownListItem: FC<DropDownListItemProps> = ({ className, icon, children }) => {
  return (
    <li className={clsx('row align-center subtitle-xx-small', className)}>
      {ICONS[icon]} {children}
    </li>
  );
};

export default DropDownList;
