import React, { FC, ReactNode, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import './style.scss';

type TooltipProps = {
  title: string;
  children?: ReactNode;
  multiline?: boolean;
  color?: string;
  className?: string;
  ariaLabel?: string;
};

type TooltipEllipsisProps = {
  title: string;
  className?: string;
  elementMaxWidth?: number;
  children?: ReactNode;
};

type RichTooltipProps = {
  title: ReactNode;
  children: ReactNode;
  ariaLabel?: string;
};

const Tooltip: FC<TooltipProps> = ({
  title,
  children,
  multiline = false,
  color,
  className,
  ariaLabel,
}: TooltipProps) => {
  const classNames = clsx('tooltip', color, className, {
    multiline: multiline,
  });

  if (title === '') return <div className={classNames}>{children}</div>;

  return (
    <div data-tooltip={title} data-tooltip-persistent className={classNames} role="tooltip" aria-label={ariaLabel}>
      {children}
    </div>
  );
};

const TooltipEllipsis: FC<TooltipEllipsisProps> = ({
  title,
  className,
  elementMaxWidth = 0,
  children,
}: TooltipEllipsisProps) => {
  const titleRef = useRef<HTMLSpanElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    if (titleRef && titleRef.current) {
      const element = titleRef.current;

      if (element.scrollWidth > element.clientWidth || (elementMaxWidth > 0 && element.clientWidth > elementMaxWidth)) {
        setShowTooltip(true);
      }
    }
  }, [titleRef, elementMaxWidth]);

  return showTooltip ? (
    <Tooltip title={title} color="dark">
      <span className={clsx('ellipsis-text', className)} ref={titleRef}>
        {children || title}
      </span>
    </Tooltip>
  ) : (
    <span className={clsx('ellipsis-text', className)} ref={titleRef}>
      {children || title}
    </span>
  );
};

const RichTooltip: FC<RichTooltipProps> = ({ title, ariaLabel, children }) => {
  return (
    <div className="tooltip rich-content" role="tooltip" aria-label={ariaLabel}>
      {children}
      <div className="tooltip-content">{title}</div>
    </div>
  );
};

export default Tooltip;
export { TooltipEllipsis, RichTooltip };
