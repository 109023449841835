import dayjs from 'dayjs';
import { Subscription } from '~/api/data/subscription/types';
import { Transaction } from '~/api/data/transaction/types';

const subscriptionDatesByStatus = (subscription?: Subscription, lastTransaction?: Transaction) => {
  if (subscription?.canceledAt && subscription.status === 'CANCELED') {
    return `Canceled on: ${dayjs(subscription.canceledAt).format('MMM D, YYYY')}`;
  }

  if (subscription?.status === 'FINISHED' && lastTransaction) {
    return `Finished on: ${dayjs(lastTransaction.processedAt).format('MMM D, YYYY')}`;
  }

  return `Next charge: ${dayjs(subscription?.nextChargeAt).format('MMM D, YYYY')}`;
};

export default subscriptionDatesByStatus;
