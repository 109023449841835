import React, { FC } from 'react';
import { removeNonDigits } from 'utils';
import Input from '../Input';

type ZipInputProps = {
  handleResponseUser: (value: string, field: string) => void;
  handleOnBlur: () => void;
  zipcode?: string;
};

const ZipInput: FC<ZipInputProps> = ({ handleResponseUser, handleOnBlur, zipcode }) => {
  return (
    <Input
      label="Zip"
      placeholder="Zip"
      onChange={({ target }) => handleResponseUser(removeNonDigits(target.value, 5), 'zipcode')}
      value={zipcode}
      name="zip-code"
      type="text"
      className="zip-input"
      rules={{
        hasError: !!zipcode && zipcode?.length < 5,
      }}
      onBlur={handleOnBlur}
    />
  );
};

export default ZipInput;
