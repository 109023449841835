import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import { removeOrderItem, removeOrderItemByVariant } from 'api/data/response';
import Modal from 'components/Modal';
import { OrderItem } from 'api/data/response/types';

interface QuantityModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  orderItem: OrderItem;
  isRequired: boolean;
  isTheLastChargeBlock: boolean;
  setShowItemRemovedMessage: (showItemRemovedMessage: boolean) => void;
  saveResponse?: () => void;
}

type ModalInfoType = {
  message: string;
  confirmLabel: string;
  callback: () => void;
};

const removeRequiredPaymentBlock = (title: string): { message: string; confirmLabel: string } => ({
  message: `${title} is required. Would you like to remove it and update your selection?`,
  confirmLabel: 'Remove and update selection',
});

const removeLastPaymentBlock = {
  message: 'If you remove this item, you will be taken back to the previous page to submit the form without payment.',
  confirmLabel: 'Remove and submit without payment',
};

const removeNotLastNotRequiredPaymentBlock = {
  message: 'Are you sure you want to remove this item?',
  confirmLabel: 'Remove',
};

const QuantityModal: FC<QuantityModalProps> = ({
  showModal,
  setShowModal,
  isRequired,
  isTheLastChargeBlock,
  setShowItemRemovedMessage,
  saveResponse,
  orderItem,
}) => {
  const [location, setLocation] = useLocation();
  const [modalInfo, setModalInfo] = useState<ModalInfoType>();

  useEffect(() => {
    let info: ModalInfoType;

    const removeItemAndGoBack = (id: string) => {
      removeOrderItem(orderItem.blockId);
      setLocation(location.replace('/checkout', `#${id}`));
    };

    if (isRequired) {
      info = {
        ...removeRequiredPaymentBlock(orderItem.block.title || orderItem.blockTitle || 'Block'),
        callback: () => removeItemAndGoBack(`block-${orderItem.blockId}`),
      };
    } else if (isTheLastChargeBlock) {
      info = {
        ...removeLastPaymentBlock,
        callback: () => removeItemAndGoBack('payer-page-submit-form'),
      };
    } else {
      info = {
        ...removeNotLastNotRequiredPaymentBlock,
        callback: () => {
          removeOrderItemByVariant(orderItem.variant.id);
          setShowItemRemovedMessage(true);
        },
      };
    }

    setModalInfo(info);
  }, [isRequired, isTheLastChargeBlock, location, orderItem, setLocation, setShowItemRemovedMessage]);

  const handleRemoveItem = () => {
    modalInfo?.callback();
    setShowModal(false);
    if (saveResponse) saveResponse();
  };

  return (
    <Modal
      header="Remove item"
      handleOnCancel={() => setShowModal(false)}
      handleOnConfirm={handleRemoveItem}
      confirmlabel={modalInfo?.confirmLabel}
      denyLabel="Cancel"
      visible={showModal}>
      <span>{modalInfo?.message}</span>
    </Modal>
  );
};

export default QuantityModal;
