import React, { FC, useState } from 'react';
import { extractQueryStringFromParams } from 'utils';
import Email from './Email';
import Organization from './Organization';
import Password from './Password';
import PageWithLogoLine from 'components/PageWithLogoLine';

const Signup: FC = () => {
  const [email, setEmail] = useState(extractQueryStringFromParams('email'));
  const [step, setStep] = useState('EMAIL');

  const stepComponent: {
    [key: string]: JSX.Element;
  } = {
    EMAIL: <Email setStep={setStep} email={email} setEmail={setEmail} />,
    PASSWORD: <Password setStep={setStep} userEmail={email} />,
    ORGANIZATION: <Organization />,
  };

  return <PageWithLogoLine>{stepComponent[step]}</PageWithLogoLine>;
};

export default Signup;
