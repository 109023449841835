import React, { FC, useState, useRef, useEffect } from 'react';
import { toPng, toSvg, toBlob } from 'html-to-image';
import QRCodeStyling from 'qr-code-styling';
import qrCodeDefault from 'defaults/qrCode';
import ICONS from 'components/Icons';
import Message from 'components/Message';
import cellphoneIcon from 'assets/cellphone.svg';
import './style.scss';

interface QrCodeProps {
  imageName?: string;
  titleUrl: string;
  title: string;
  validation: boolean;
  qrCodeUrl: string;
}

const qrCode = new QRCodeStyling({
  ...qrCodeDefault,
});

const QrCode: FC<QrCodeProps> = ({ imageName, titleUrl, title, validation, qrCodeUrl }) => {
  const [copying, setCopying] = useState(false);
  const [showCopyMessage, setShowCopyMessage] = useState(false);
  const qrCodeRef = useRef<HTMLDivElement>(null);
  const qrCodeBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (qrCodeRef.current && validation) {
      qrCode.update({
        data: `${qrCodeUrl}?utm_medium=qr&utm_source=share_page`,
      });
      qrCode.append(qrCodeRef.current);
    }

    setShowCopyMessage(false);
  }, [qrCodeRef, validation, qrCodeUrl]);

  const copyImageToClipboard = () => {
    if (qrCodeBoxRef.current) {
      setCopying(true);
      void toBlob(qrCodeBoxRef.current, { cacheBust: true }).then(async blob => {
        if (blob) {
          await navigator.clipboard.write([
            new ClipboardItem({
              'image/png': new Promise(resolve => resolve(blob)),
            }),
          ]);
          setShowCopyMessage(true);
          setCopying(false);
        }
      });
    }
  };

  const downloadImageCallback = (dataUrl: string, extension: string) => {
    const link = document.createElement('a');
    const fileName = imageName || 'qr-code';
    link.download = `${fileName}.${extension}`;
    link.href = dataUrl;
    link.click();
  };

  const handleDownloadPNG = () => {
    if (qrCodeBoxRef.current) {
      void toPng(qrCodeBoxRef.current, { cacheBust: true }).then(dataUrl => downloadImageCallback(dataUrl, 'png'));
    }
  };

  const handleDownloadSVG = () => {
    if (qrCodeBoxRef.current) {
      void toSvg(qrCodeBoxRef.current, { cacheBust: true }).then(dataUrl => downloadImageCallback(dataUrl, 'svg'));
    }
  };

  return (
    <div className="row qr-code-container">
      <div ref={qrCodeBoxRef} className="row align-center">
        <div className="scan-container">
          <img src={cellphoneIcon} alt="cellphone icon" />
          <span>Scan me</span>
        </div>
        <div ref={qrCodeRef} />
        <p className="paragraph-x-small title">{title}</p>
        <p className="paragraph-x-small">{titleUrl}</p>
      </div>
      <div className="download-options">
        <button type="button" onClick={copyImageToClipboard} className="button-link" disabled={copying}>
          {ICONS['copy']}
          {copying ? 'Copying' : 'Copy'}
        </button>
        <Message
          className="row align-center justify-center default-message"
          showMessage={showCopyMessage}
          setShowMessage={setShowCopyMessage}>
          Image copied!
        </Message>
        <button type="button" onClick={handleDownloadPNG} className="button-link download">
          {ICONS['print']}
          Print
        </button>
        <button type="button" onClick={handleDownloadSVG} className="button-link download">
          {ICONS['computer']}
          Digital
        </button>
      </div>
    </div>
  );
};

export default QrCode;
