import React, { FC, useState } from 'react';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import { isSpaceNameTaken as validateSpaceName } from 'utils';
import Input from 'components/Input';
import { Space } from '~/api/data/user/types';
import './style.scss';

type SpaceNameProps = {
  space: Space;
  submitButtonText?: string;
  onSubmit: (space: Space) => Promise<void>;
};

const SpaceName: FC<SpaceNameProps> = ({ space: { name, ...space }, onSubmit, submitButtonText = 'Save' }) => {
  const [spaceName, setSpaceName] = useState(name);

  const { spaces } = useSpacesFromCurrentOrg();

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    void onSubmit({ ...space, name: spaceName });
  };

  const isSpaceNameTaken = validateSpaceName(
    spaces.filter(({ id }) => id !== space.id),
    spaceName,
  );

  const invalidSpaceName = !spaceName || isSpaceNameTaken;

  return (
    <form className="space-name-container" onSubmit={handleSubmit}>
      <h1>What is the name of this space?</h1>
      <p className="title-description">
        A space is where you organize pages, permissions and members. Create a space for each club, group, team, topic,
        department or just use it to stay organized.
      </p>
      <Input
        name="space-name"
        type="text"
        placeholder="Space name"
        value={spaceName}
        onChange={({ target }) => {
          setSpaceName(target.value);
        }}
        onBlur={({ target }) => setSpaceName(target.value.trim())}
        rules={{
          required: true,
          hasError: invalidSpaceName,
          errorMessage: isSpaceNameTaken ? 'This space name is already taken' : 'This is required',
        }}
      />

      <div className="submit-container">
        <button disabled={invalidSpaceName} type="submit" className="button-size-ml">
          {submitButtonText}
        </button>
      </div>
    </form>
  );
};

export default SpaceName;
