import React, { FC } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import useUserSession from 'hooks/useUserSession';
import PageWithLogoLine from 'components/PageWithLogoLine';
import ShareByEmail from '../ShareByEmail';

const InviteSupporters: FC<RouteComponentProps> = ({ params }) => {
  const { id: pageSlug, participantSlug } = params;
  const [, setLocation] = useLocation();
  const { loading } = useUserSession({ fetchPolicy: 'network-only' });

  const redirectToParticipantPage = () => {
    setLocation(`/${params.id}/p/${params.participantSlug}`);
  };

  if (loading) return <>Loading...</>;

  return (
    <PageWithLogoLine>
      <div>
        <h3 className="heading-large">✉️ Send 20 emails</h3>
        <ShareByEmail
          pageSlug={pageSlug}
          participantSlug={participantSlug}
          buttonText={{ action: 'Send', description: 'and continue' }}
          onInvite={redirectToParticipantPage}
          laterButtonAction={redirectToParticipantPage}
        />
      </div>
    </PageWithLogoLine>
  );
};

export default InviteSupporters;
