import { Status } from '~/api/data/subscription/types';
import { TagType } from '~/components/Tag';

export const statusTranslator: Record<Status, string> = {
  INCOMPLETE: 'Incomplete',
  ACTIVE: 'Active',
  PAST_DUE: 'Past due',
  CANCELED: 'Canceled',
  FINISHED: 'Finished',
};

type StatusType = {
  [key in Status]: TagType;
};

export const mapStatusClass: StatusType = {
  INCOMPLETE: 'pending',
  ACTIVE: 'positive',
  PAST_DUE: 'neutral',
  CANCELED: 'neutral',
  FINISHED: 'neutral',
};
