const downloadFile = (filename: string, content: string): void => {
  const element = document.createElement('a');
  const file = new Blob([content], { type: 'text/csv' });
  element.href = URL.createObjectURL(file);
  element.download = filename;
  element.id = 'download-file';
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export default downloadFile;
