import React, { useContext, useRef, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'wouter';
import { hasPaymentPlanInstallments, subscriptionDatesByStatus, subscriptionEnded } from 'utils';
import { getLastTransaction } from 'api/data/subscription';
import { mapStatusClass, statusTranslator } from 'defaults/subscription';
import UserRolePerspective from '~/contexts/UserRolePerspective';
import BlankState from '~/components/BlankState';
import CancelSubscriptionModal, { showCancelationButton } from 'components/CancelSubscriptionModal';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import ICONS from 'components/Icons';
import PayerRequestedModal from 'components/RefundModal/PayerRequestedModal';
import PaymentMethod from './PaymentMethod';
import ReviewRequestModal from 'components/RefundModal/ReviewRequestModal';
import Row from './Row';
import { Subscription } from 'api/data/subscription/types';
import { TransactionByUser } from 'api/data/transaction/types';
import './style.scss';

interface SubscriptionsProps {
  subscriptions?: Subscription[] | undefined;
  onSubscriptionsChanged: () => Promise<unknown>;
  transactionsByUser?: TransactionByUser[];
  userId?: string;
  currentOrganization?: string;
}

const Subscriptions = ({ subscriptions, onSubscriptionsChanged, transactionsByUser, userId }: SubscriptionsProps) => {
  const [location] = useLocation();
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const [selectedSubscription, setSelectedSubscription] = useState<Subscription>();
  const [showPaymentMethodModal, setShowPaymentMethodModal] = useState(false);
  const refOutside = useRef<HTMLElement>(null);

  if (subscriptions?.length === 0)
    return <BlankState title="No Subscriptions" message="Subscriptions will appear here." />;

  return (
    <>
      <div
        className={clsx('subscription-member-profile', {
          'payer-view': isPayerView,
        })}>
        {subscriptions?.map(subscription => {
          const hasPaymentPlan = subscription.orderItems.some(orderItem =>
            hasPaymentPlanInstallments(orderItem.variant.paymentPlanInstallments),
          );

          const lastTransaction = getLastTransaction(subscription.id, transactionsByUser) as TransactionByUser;

          return (
            <Row
              key={subscription.id}
              data={subscription}
              hasPaymentPlan={hasPaymentPlan}
              location={`${location}/subscription`}
              date={subscriptionDatesByStatus(subscription, lastTransaction)}
              status={{
                tagType: mapStatusClass[subscription?.status],
                value: statusTranslator[subscription?.status],
              }}
              recurring={subscription?.recurring}
              actions={
                <>
                  {subscription?.hasPendingPayerRequest && isPayerView && (
                    <PayerRequestedModal
                      requestedDate={subscription?.pendingPayerRequest?.requestedAt}
                      organization={subscription?.organization}
                      requestType="Cancelation"
                    />
                  )}
                  <CancelSubscriptionModal
                    subscription={subscription}
                    userId={userId}
                    showButton={showCancelationButton(subscription)}
                    lastTransaction={lastTransaction}
                  />
                  <ReviewRequestModal
                    pageTitle={subscription.page?.title}
                    requesterName={subscription?.user?.fullName}
                    amountCents={subscription?.pendingPayerRequest?.amountCents}
                    showButton={!!subscription?.hasPendingPayerRequest && !isPayerView}
                    status={subscription?.lastRespondedPayerRequest?.status}
                    userId={userId}
                    subscriptionId={subscription?.id}
                    recurring={subscription?.recurring}
                    transactionId={subscription?.pendingPayerRequest?.transactionId}
                    cancelationReason={subscription?.pendingPayerRequest?.cancelationReason}
                  />
                </>
              }>
              {isPayerView && !subscriptionEnded(subscription) && (
                <DropDown outsideRef={refOutside} openedIcon="menu" closedIcon="menu_open">
                  <DropDownItem
                    onClick={() => {
                      setSelectedSubscription(subscription);
                      setShowPaymentMethodModal(true);
                    }}>
                    {ICONS['wallet']} Change payment method
                  </DropDownItem>
                </DropDown>
              )}
            </Row>
          );
        })}
      </div>
      <PaymentMethod
        subscription={selectedSubscription}
        visible={showPaymentMethodModal}
        setShowModal={setShowPaymentMethodModal}
        onClose={() => setShowPaymentMethodModal(false)}
        onPaymentMethodChanged={onSubscriptionsChanged}
      />
    </>
  );
};

export default Subscriptions;
