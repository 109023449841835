import React, { FC } from 'react';
import Input from 'components/Input';
import { SpaceProps } from './types';

const Naming: FC<SpaceProps> = ({ spaceData, setSpaceData, invalidSpaceName, setNextStep }) => {
  return (
    <div>
      <h1>What is the name of this space?</h1>
      <p>
        A space is where you organize pages, permissions and members. Create a space for each club, group, team,
        department, function or just use it to stay organized.
      </p>
      <Input
        name="space-name"
        type="text"
        placeholder="Space name"
        value={spaceData.name}
        onChange={({ target }) => {
          setSpaceData({ ...spaceData, name: target.value });
        }}
        rules={{
          hasError: invalidSpaceName,
          errorMessage: 'This space name is already taken',
        }}
        onKeyDown={({ key }) => {
          if ((key === 'Enter' || key === 'NumpadEnter') && spaceData.name && !invalidSpaceName) {
            setNextStep();
          }
        }}
      />
    </div>
  );
};

export default Naming;
