import excel from 'assets/extensions/excel.svg';
import image from 'assets/extensions/image.svg';
import link from 'assets/extensions/link.svg';
import music from 'assets/extensions/music.svg';
import pdf from 'assets/extensions/pdf.svg';
import ppt from 'assets/extensions/ppt.svg';
import text from 'assets/extensions/text.svg';
import undefined from 'assets/extensions/undefined.svg';
import video from 'assets/extensions/video.svg';
import word from 'assets/extensions/word.svg';
import zip from 'assets/extensions/zip.svg';

export type Icons =
  | 'excel'
  | 'image'
  | 'link'
  | 'music'
  | 'pdf'
  | 'ppt'
  | 'text'
  | 'undefined'
  | 'video'
  | 'word'
  | 'zip';

type IconsProps = {
  [key in Icons]: string;
};

const EXTENSIONS_ICONS: IconsProps = {
  excel,
  image,
  link,
  music,
  pdf,
  ppt,
  text,
  undefined,
  video,
  word,
  zip,
};

export default EXTENSIONS_ICONS;
