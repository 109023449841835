import React, { FC, useRef } from 'react';
import { RouteComponentProps } from 'wouter';
import { useEditor } from 'hooks/useEditor';
import Editor from 'components/Editor';
import Header from 'components/Editor/Header';
import RequestHeader from 'pages/Request/RequestHeader';
import { PageDataDraftBlock } from 'api/data/pages/types';

type PageParams = {
  id: string;
  requestId?: string;
};

const Request: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [page, setPage, isLoading, savePageError] = useEditor(params.id, params.requestId);
  const refOutside = useRef<HTMLElement>(null);
  const requestId = params.requestId || '';

  const handleConfigChanges = (changes: Partial<PageDataDraftBlock>) => {
    setPage({ ...page, ...changes });
  };

  return (
    <>
      <Editor page={page} setPage={setPage} requestId={requestId} savePageError={savePageError}>
        <Header isLoading={isLoading} title={page.title} refOutside={refOutside} />
        <RequestHeader
          requestId={requestId}
          page={page}
          fullName={page.fullName}
          email={page.email}
          handleSettingsChange={handleConfigChanges}
        />
      </Editor>
    </>
  );
};

export default Request;
