import { Item, SlideDirection } from './types';

class Circular<T> {
  constructor(
    private arr: T[],
    private currentIndex: number,
  ) {}

  next(): T {
    const i = this.currentIndex;
    const arr = this.arr;
    this.currentIndex = i < arr.length - 1 ? i + 1 : 0;
    return this.current();
  }

  prev(): T {
    const i = this.currentIndex;
    const arr = this.arr;
    this.currentIndex = i > 0 && i < arr.length ? i - 1 : arr.length - 1;
    return this.current();
  }

  current(): T {
    return this.arr[this.currentIndex];
  }
}

export const rotateItems = (
  items: Item[],
  showingItems: Item[],
  start: number,
  show: number,
  slide: number,
  direction: SlideDirection,
): Item[] => {
  const circular = new Circular(items, start);
  const newItems: Item[] = Array.from(showingItems);

  switch (direction) {
    case SlideDirection.Left:
      for (let i = slide; i >= 0; i--) {
        if (slide - i < 0 || !newItems[i - slide]) {
          newItems.unshift(circular.current());
        }
        circular.prev();
      }
      break;
    case SlideDirection.Right:
      for (let i = 0; i < show + slide; i++) {
        if (!newItems[2 * slide + i]) {
          newItems.push(circular.current());
        }
        circular.next();
      }
      break;
  }

  return newItems;
};

export const getTransformAmount = (width: number, slideCount: number, direction: SlideDirection): number => {
  return direction * width * slideCount;
};

export const getCurrent = (current: number, slide: number, length: number, direction: SlideDirection) => {
  const slideTo = current - direction * slide;
  if (slideTo < 0) {
    return length + slideTo;
  } else if (length <= slideTo) {
    return slideTo - length;
  }

  return slideTo;
};

interface GetShowArrowProps {
  itemCount: number;
  itemsToShow: number;
  infinite: boolean;
  current: number;
  hideArrows: boolean;
}

export const getShowArrow = (props: GetShowArrowProps): { left: boolean; right: boolean } => {
  const { itemCount, itemsToShow, infinite, current, hideArrows = false } = props;

  if (hideArrows) {
    return {
      left: false,
      right: false,
    };
  }

  const hasMoreItems = itemCount > itemsToShow;

  if (infinite) {
    return {
      left: hasMoreItems,
      right: hasMoreItems,
    };
  }

  return {
    left: hasMoreItems && current !== 0,
    right: hasMoreItems && current + itemsToShow < itemCount,
  };
};

export const cleanItems = (showingItems: Item[], slide: number, direction: SlideDirection): Item[] => {
  if (direction === SlideDirection.Left) {
    return showingItems.slice(0, -1 * slide);
  }
  return showingItems.slice(slide);
};

export const initItems = (items: Item[], slide: number, infinite: boolean): Item[] => {
  if (!infinite) {
    return items;
  }

  const newArray = Array.from(items);
  const circular = new Circular(items, 0);
  for (let i = 0; i < slide; i++) {
    newArray.unshift(circular.prev());
  }

  return newArray;
};
