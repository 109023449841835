import React, { FC } from 'react';
import { Redirect, RouteComponentProps } from 'wouter';
import { useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import { PageView } from '~/api/data/pages/types';

const RedirectToMyPage: FC<RouteComponentProps<{ id: string }>> = ({ params: { id } }) => {
  const { data: pageData, loading } = useQuery<PageView>(PageQuery.GetPageSpace, {
    variables: { id },
  });

  if (loading || !pageData || !id) return <div>Loading...</div>;

  return <Redirect to={`/${pageData.page.space?.slug}/pages/${id}/edit`} />;
};

export default RedirectToMyPage;
