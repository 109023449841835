import React, { FC, useRef } from 'react';
import { useLocation } from 'wouter';
import { useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { isProduction } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useNewPage from 'hooks/useNewPage';
import useUserSession from 'hooks/useUserSession';
import { Carousel } from 'components/Carousel';
import IconButton from 'components/Button/IconButton';
import ProtectedComponent from 'components/ProtectedComponent';
import SpaceHeader from 'components/SpaceHeader';
import Tooltip from 'components/Tooltip';
import { TemplatesData } from './types';
import './style.scss';

const Templates: FC = () => {
  const { data } = useUserSession();
  const { data: templatesData } = useQuery<TemplatesData>(PageQuery.GetTemplates);
  const [createPage, loadingPage] = useNewPage(data?.session);
  const templateRef = useRef<HTMLUListElement>(null);
  const [, setLocation] = useLocation();
  const { currentOrg } = useCurrentOrganization();

  const copyFromTemplate = (templateId?: string) => {
    void createPage(templateId);
  };

  const createTeamFundraiser = async () => {
    const page = await createPage(undefined, false);
    setLocation(`/${currentSpaceSlug() || ''}/pages/${page?.slug}/team/enable?fromTemplate=true`);
  };

  const redirectToTemplatesDashboard = () => {
    const newLocation = `https://${isProduction() ? 'omella' : 'staging.omella'}.com/templates?utm_source=dashboard`;
    window.location.assign(newLocation);
  };

  const templates = templatesData?.templates && templatesData.templates.length > 0 ? templatesData.templates : [];

  const commonProps = {
    className: 'ellipsis-text template-button template-button-with-color',
    disabled: loadingPage,
  };
  const templateComponents = [
    <Tooltip title="Start from scratch" color="dark" key="blank-page">
      <li className="blank-page">
        <IconButton
          icon="plus_new"
          className="template-button"
          onClick={() => copyFromTemplate()}
          disabled={loadingPage}>
          Blank page
        </IconButton>
      </li>
    </Tooltip>,
  ];

  const teamFrundraiserComponent = (
    <li key="team-fundraiser-template">
      <button {...commonProps} onClick={() => void createTeamFundraiser()} style={{ borderTopColor: '#C7C1FF' }}>
        ✨ Team Fundraiser
      </button>
    </li>
  );

  const moreComponent = (
    <li key="more-templates">
      <button {...commonProps} onClick={redirectToTemplatesDashboard} style={{ borderTopColor: '#74CFFF' }}>
        More...
      </button>
    </li>
  );

  const TEMPLATE_WIDTH = 176;
  const itemsToShow = Math.floor(
    templateRef.current?.clientWidth ? templateRef.current.clientWidth / TEMPLATE_WIDTH : 1,
  );

  return (
    <div className="templates">
      <SpaceHeader />
      <h2 className="template-title">Create from a template</h2>
      <ul className="templates-list" ref={templateRef}>
        {templates.length === 0 ? (
          <>
            {templateComponents}
            {teamFrundraiserComponent}
            {moreComponent}
          </>
        ) : (
          <Carousel show={itemsToShow} slide={itemsToShow}>
            {templateComponents.concat(
              templates.map((template, index) => (
                <div key={template.id} className="row-inline">
                  <Tooltip title={template.description} color="dark">
                    <li>
                      <ProtectedComponent
                        action="EDIT_TEMPLATES"
                        currentUserOrganizationRole={currentOrg?.currentUserRole}>
                        <IconButton
                          icon="edit"
                          className="edit-template"
                          onClick={() => setLocation(`/${template.page.space?.slug}/pages/${template.page.slug}/edit`)}
                        />
                      </ProtectedComponent>
                      <button
                        {...commonProps}
                        onClick={() => copyFromTemplate(template.id)}
                        style={{ borderTopColor: `#${template.color}` }}>
                        {template.page.title}
                      </button>
                    </li>
                  </Tooltip>
                  {index === 0 && teamFrundraiserComponent}
                </div>
              )),
              moreComponent,
            )}
          </Carousel>
        )}
      </ul>
    </div>
  );
};

export default Templates;
