import React, { FC } from 'react';
import Banner from 'components/Banner';
import EditorComponent from 'components/Editor';
import { EditorProps } from './types';

const Editor: FC<EditorProps> = ({ page, setPage }) => {
  return (
    <EditorComponent page={page} setPage={setPage}>
      <Banner>
        🎉 Looking good! Everything below is editable; once you’re happy, press “Finish” to add participants.
      </Banner>
    </EditorComponent>
  );
};

export default Editor;
