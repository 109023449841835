import React, { FC, useMemo, useRef } from 'react';
import Editor from './CustomEditor';
import { EditorProps, EditorState } from 'draft-js';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import createLinkifyPlugin from '@draft-js-plugins/linkify';
import createVideoPlugin from '@omella/draft-js-plugins-video';
import { StyleButtons } from 'components/RichTextEditor/Plugin';
import { useVideo, VideoDropdown, VideoMenu } from './Plugin/AddVideo';
import { customStyleMap, myBlockStyleFn, getCustomKeyBinding } from './utils';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/linkify/lib/plugin.css';
import 'components/RichTextEditor/Plugin/style.scss';
import './style.scss';

interface RichTextEditorProps extends EditorProps {
  onChange: (editorState: EditorState) => void;
  handleEmbedVideo?: (editorState: EditorState) => void;
  handleDelete?: () => void;
}

const RichTextEditor: FC<RichTextEditorProps> = props => {
  const [plugins, InlineToolbar] = useMemo(() => {
    const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });
    const inlineToolbarPlugin = createInlineToolbarPlugin({
      theme: {
        toolbarStyles: {
          toolbar: 'inline-toolbar',
        },
        buttonStyles: {
          button: 'inline-toolbar-button',
          buttonWrapper: 'inline-toolbar-button-wrapper',
          active: 'inline-toolbar-button-active',
        },
      },
    });
    const videoPlugin = createVideoPlugin();
    return [[inlineToolbarPlugin, linkifyPlugin, videoPlugin], inlineToolbarPlugin.InlineToolbar];
  }, []);

  const editor = useRef<Editor | null>(null);

  const { handlePastedText, showEmbedMenu, handleEmbedVideo, setShowEmbedMenu, videoUrl, hasVideo } = useVideo(
    props.editorState,
    props.handleEmbedVideo,
  );

  return (
    <div className="block text" data-testid="rich-text-editor">
      <Editor
        {...props}
        keyBindingFn={command => getCustomKeyBinding(command, props.editorState)}
        plugins={plugins}
        customStyleMap={customStyleMap}
        blockStyleFn={myBlockStyleFn}
        ariaLabel="draft-editor"
        ref={element => {
          editor.current = element;
        }}
        handlePastedText={handlePastedText}
      />
      <VideoDropdown
        showEmbedMenu={showEmbedMenu}
        handleEmbedVideo={handleEmbedVideo}
        setShowEmbedMenu={setShowEmbedMenu}
        editorRef={editor}
        videoUrl={videoUrl}
      />
      {!props.readOnly && hasVideo() && <VideoMenu handleDelete={props.handleDelete} editorState={props.editorState} />}
      <InlineToolbar>
        {externalProps => (
          <>
            {StyleButtons.map((StyleButton, i) => (
              <div key={i} className="inline-toolbar-button-wrapper">
                <StyleButton {...externalProps} />
              </div>
            ))}
          </>
        )}
      </InlineToolbar>
    </div>
  );
};

export default RichTextEditor;
