import { Variant } from 'components/PaymentBlock/types';

const filterDuplicated = <T>(variant: T, index: number, self: T[]): boolean => {
  const _variant = JSON.stringify({ ...variant, id: '', order: 0 });

  return (
    index ===
    self.findIndex(obj => {
      return JSON.stringify({ ...obj, id: '', order: 0 }) === _variant;
    })
  );
};

export const filterPaymentVariantDuplicated = (variant: Variant, index: number, self: Variant[]): boolean => {
  const _variant = canonicalVariantString(variant);

  return (
    index ===
    self.findIndex(obj => {
      return canonicalVariantString(obj) === _variant;
    })
  );
};

const canonicalVariantString = (obj: Variant) => {
  return JSON.stringify({
    ...obj,
    paymentOptions: obj.paymentOptions.filter(filterDuplicated).map(item => ({ ...item, id: '', order: 0 })),
    id: '',
    order: 0,
    inventory: { ...obj.inventory, id: '' },
  });
};

export default filterDuplicated;
