import React, { FC, useRef } from 'react';
import { useLocation, Link } from 'wouter';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import Avatar from '~/components/Avatar';
import DropDown from '~/components/Menu/DropDown';
import DropDownItem from '~/components/Menu/DropDownItem';
import IconButton from '../Button/IconButton';
import ICONS from '~/components/Icons';
import ProtectedComponent from '~/components/ProtectedComponent';
import './style.scss';

type SpaceHeaderProps = {
  renderSpaceSettings?: boolean;
};

const paths = [
  {
    url: '/space-settings/general',
    label: 'Settings',
  },
  {
    url: '/space-settings/members',
    label: 'Settings',
  },
  {
    url: '/pages',
    label: 'Pages',
  },
];

const SpaceHeader: FC<SpaceHeaderProps> = ({ renderSpaceSettings = true }) => {
  const [location, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug();
  const { currentSpace } = useSpacesFromCurrentOrg();
  const { currentOrg } = useCurrentOrganization();
  const settingsRef = useRef<HTMLElement>(null);

  const redirectTo = (path: string) => spaceSlug && setLocation(`/${spaceSlug}${path}`);
  const knownPath = paths.find(({ url }) => `/${spaceSlug || ''}${url}` === location);

  const spacePagesLink = `/${currentSpace?.slug}/pages`;
  return (
    <>
      {!!currentOrg && !!currentSpace && (
        <div className="space-info-container">
          <div className="space-title">
            <Avatar text={currentSpace.name} size="small" />
            <Link href={spacePagesLink}>
              <h3>{currentSpace.name}</h3>
            </Link>
            {ICONS['arrow_right']}
            {knownPath && <h3>{knownPath.label}</h3>}
          </div>
          {renderSpaceSettings ? (
            <ProtectedComponent
              action="SPACE_SETTINGS"
              currentUserOrganizationRole={currentOrg.currentUserRole}
              currentUserSpaceRole={currentSpace.currentUserRole}>
              <DropDown outsideRef={settingsRef} openedIcon="menu_open" closedIcon="menu_open">
                <DropDownItem icon="settings" onClick={() => redirectTo('/space-settings/general')}>
                  Settings
                </DropDownItem>
                <DropDownItem icon="add_new_member" onClick={() => redirectTo('/space-settings/members')}>
                  Add people
                </DropDownItem>
              </DropDown>
            </ProtectedComponent>
          ) : (
            <IconButton icon="close" onClick={() => setLocation(spacePagesLink)} />
          )}
        </div>
      )}
    </>
  );
};

export default SpaceHeader;
