import React, { FC, useState, ChangeEvent, ReactNode, SyntheticEvent } from 'react';
import { checkEmails, emailList, removeEmptyLines, showPlural } from 'utils';
import TextArea from 'components/Input/TextArea';
import Message from 'components/Message';
import './style.scss';

type TextAreaEmailProps = {
  buttonText: { action: string; description: string; showEmailQty?: boolean };
  laterButtonAction?: () => void;
  disabled?: boolean;
  label?: string;
  loading: boolean;
  onClick: (emails: string[], hasInvalidEmails: boolean) => Promise<void>;
  children?: ReactNode;
};

const TextAreaEmail: FC<TextAreaEmailProps> = ({
  buttonText,
  laterButtonAction,
  disabled,
  label = 'To:',
  loading,
  onClick,
  children,
}) => {
  const [emails, setEmails] = useState('');
  const [checkedEmails, setCheckedEmails] = useState({
    validEmails: '',
    invalidEmails: '',
  });
  const [showMessage, setShowMessage] = useState(false);

  const resetCheckedEmails = () => setCheckedEmails({ validEmails: '', invalidEmails: '' });

  const handleOnChange = ({ target }: ChangeEvent<HTMLTextAreaElement>) => {
    resetCheckedEmails();
    setEmails(target.value);
  };

  const handleOnClick = async () => {
    await onClick(emailList(checkedEmails.validEmails), !!checkedEmails.invalidEmails);

    if (checkedEmails.invalidEmails) {
      setEmails(checkedEmails.invalidEmails);
      setShowMessage(true);
    } else {
      setEmails('');
    }

    resetCheckedEmails();
  };

  const emailQty = emailList(emails).length;

  const buttonAction = `${buttonText.action}${loading ? 'ing' : ''}`;
  const buttonDescription = buttonText.showEmailQty
    ? `${emailQty > 1 ? emailQty : ''} ${buttonText.description}${showPlural(emailQty)}`
    : buttonText.description;

  return (
    <form
      className="text-area-email-container"
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        void handleOnClick();
      }}>
      <Message type="succeeded" showMessage={showMessage} setShowMessage={setShowMessage}>
        Valid emails were sent successfully! Please, check the invalid emails below.
      </Message>
      <TextArea
        className="text-area-email"
        classNameLabel="subtitle-x-small"
        label={label}
        placeholder="Separate different emails with a comma, space or new line (ex: sam@email.com, jon@email.com)"
        name="emails"
        value={emails}
        onChange={handleOnChange}
        onBlur={() => {
          setEmails(removeEmptyLines(emails));
          setCheckedEmails(checkEmails(emails));
        }}
        rules={{
          hasError: !!checkedEmails.invalidEmails,
          errorMessage: 'Invalid email',
        }}
        hint={checkedEmails.invalidEmails ? '' : `${emailQty} email${showPlural(emailQty)} added`}
      />
      {children}
      <button type="submit" className="button-full-screen-mobile" disabled={loading || !emails || disabled}>
        {buttonAction} {buttonDescription}
      </button>
      {laterButtonAction && (
        <button type="button" className="tertiary button-full-screen-mobile" onClick={laterButtonAction}>
          Later
        </button>
      )}
    </form>
  );
};

export default TextAreaEmail;
