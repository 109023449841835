import React, { FC, useState } from 'react';
import { Link } from 'wouter';
import { useMutation, useLazyQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import ChooseSpaceModal from 'components/ChooseSpaceModal';
import IconButton from 'components/Button/IconButton';
import Message from 'components/Message';
import { PagesData, CopyPageData } from 'api/data/pages/types';

export interface CopyPageModalData {
  pageId: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
}

const CopyPageModal: FC<CopyPageModalData> = ({ pageId, showModal, setShowModal }) => {
  const [refetch] = useLazyQuery<PagesData>(PageQuery.PagesQuery);
  const [copyPage, { data: copyPageData }] = useMutation<CopyPageData>(PageQuery.CopyPage);
  const [showCopiedPageMessage, setShowCopiedPageMessage] = useState(false);

  const handleCopyPage = async (spaceSlug: string) => {
    const { data: newPage } = await copyPage({
      variables: { pageId: pageId, currentSpace: spaceSlug },
    });

    if (newPage) {
      setShowCopiedPageMessage(true);
      void refetch();
    }
  };

  return (
    <>
      <Message
        className="page-message page-copied"
        setShowMessage={setShowCopiedPageMessage}
        showMessage={showCopiedPageMessage}
        type="succeeded"
        timeout={0}>
        Page copied succesfully
        <Link
          href={`/${copyPageData?.copyPage.space?.slug}/pages/${copyPageData?.copyPage?.slug || ''}/edit`}
          onClick={() => setShowCopiedPageMessage(false)}>
          Go to page
        </Link>
        <IconButton icon="close" onClick={() => setShowCopiedPageMessage(false)}></IconButton>
      </Message>
      {showModal && (
        <ChooseSpaceModal
          onSpaceChosen={spaceId => void handleCopyPage(spaceId)}
          showModal={showModal}
          setShowModal={setShowModal}
          header="Copy page to..."
          confirmButtonLabel="Copy"
        />
      )}
    </>
  );
};

export default CopyPageModal;
