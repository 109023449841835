import React, { FC, useCallback } from 'react';
import { Item } from './types';

export const ItemProvider: FC<ItemProviderProps> = ({
  widthCallBack,
  show,
  transform,
  transition,
  items,
}: ItemProviderProps) => {
  const ref = useCallback(
    (node: HTMLElement | null) => {
      if (node !== null) {
        const calculated = node.getBoundingClientRect().width / show;
        widthCallBack(calculated);
      }
    },
    [show, widthCallBack],
  );

  return (
    <div ref={ref} className="item-provider">
      <div
        className="item-tracker"
        style={{
          transform: `translateX(${transform}px)`,
          transition: `transform ${transition}s ease 0s`,
          width: 176 * items.length,
        }}>
        {items.map((item, i) => (
          <div key={i} className="item-container">
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

interface ItemProviderProps {
  items: Item[];
  show: number;
  slide: number;
  widthCallBack: (width: number) => void;
  transition: number;
  transform: number;
  responsive: boolean;
  infinite: boolean;
}
