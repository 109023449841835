import { useQuery } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { chooseManagedSpace } from 'utils';
import { GetOrganization } from 'api/data/user/types';
import useUserSession from './useUserSession';

export default function useFirstOrganization() {
  const { data: userSession } = useUserSession();
  const firstSpace = chooseManagedSpace(userSession?.session.managedOrganizations);

  const { data, loading } = useQuery<GetOrganization>(OrganizationQuery.GetOrganization, {
    variables: { currentSpace: firstSpace?.slug },
    skip: !firstSpace?.slug,
  });

  return { firstOrganizationData: data?.organization, loading };
}
