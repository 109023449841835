import React, { MouseEvent } from 'react';
import { RichUtils } from 'draft-js';
import {
  DraftJsStyleButtonType,
  DraftJsStyleButtonProps,
  CreateInlineStyleButtonProp,
} from 'components/RichTextEditor/Plugin/types';
import clsx from 'clsx';

export default function createInlineStyleButton({
  style,
  component,
  testId,
}: CreateInlineStyleButtonProp): DraftJsStyleButtonType {
  return function InlineStyleButton(props: DraftJsStyleButtonProps) {
    const toggleStyle = (event: MouseEvent): void => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleInlineStyle(props.getEditorState(), style));
    };

    const preventBubblingUp = (event: MouseEvent): void => {
      event.preventDefault();
    };

    const styleIsActive = (): boolean =>
      props.getEditorState && props.getEditorState().getCurrentInlineStyle().has(style);

    const { theme } = props;
    const className = styleIsActive() ? clsx(theme.button, theme.active) : theme.button;

    return (
      <button
        className={className}
        onClick={toggleStyle}
        onMouseDown={preventBubblingUp}
        type="button"
        data-testid={testId}>
        {component}
      </button>
    );
  };
}
