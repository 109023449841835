import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import giftAnimationData from 'assets/lottie/gift.json';

const NAME = 'gift';
const Gift = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      const animation = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        animationData: giftAnimationData,
        loop: true,
        autoplay: false,
        name: NAME,
      });

      return () => {
        animation.destroy(NAME);
      };
    }
  }, [animationContainer]);

  return (
    <div
      className="gift"
      data-testid={NAME}
      ref={animationContainer}
      onMouseEnter={() => lottie.play(NAME)}
      onMouseLeave={() => lottie.stop(NAME)}></div>
  );
};

export default Gift;
