import { useEffect } from 'react';
import { useLocation } from 'wouter';
import { getCookie, isProduction, setCookie } from '~/utils';
import useCurrentOrganization from './useCurrentOrganization';

function useCioIdentify(): void {
  const [location] = useLocation();
  const { currentOrg } = useCurrentOrganization();

  resetCIOIfEnvironmentChanged();

  useEffect(() => {
    if (location && _cio && _cio.page && currentOrg) {
      _cio.page(location, { organization_id: currentOrg.id });
    }
  }, [location, currentOrg]);
}

function resetCIOIfEnvironmentChanged(): void {
  const currentEnvironment = isProduction() ? 'production' : 'staging';
  const previousEnvironment = getCookie('previous_environment');

  if (previousEnvironment && previousEnvironment !== currentEnvironment)
    typeof _cio.reset === 'function' && _cio.reset();

  setCookie('previous_environment', currentEnvironment, 7, '.omella.com');
}

export default useCioIdentify;
