import React, { FC, useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import { currencyFormatter } from 'utils';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import ErrorModal from 'components/ErrorModal';
import LoggedPageContainer from 'components/LoggedPageContainer';
import ShareButton from 'components/ShareButton';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import Templates from 'components/Templates';
import PageCard from './PageCard';
import arrowDownIcon from 'assets/arrow_down_order.svg';
import { PagesData } from 'api/data/pages/types';
import './style.scss';

const MyPages: FC = () => {
  const [tabContentKey, setTabContentKey] = useState('active');
  const { currentOrg } = useCurrentOrganization();
  const { data: pageData, refetch } = useQuery<PagesData>(PageQuery.PagesQuery);

  const spaceSlug = currentSpaceSlug();

  useEffect(() => {
    void refetch();
  }, [spaceSlug, refetch]);

  const pagesFiltered = pageData?.pagesQuery?.pages?.filter(page =>
    tabContentKey === 'active' ? page.status === 'active' : page.status === 'archived',
  );
  const totalCollectedCents = pagesFiltered?.reduce((prev, curr) => prev + curr.totalCollectedCents, 0);
  const responsesCount = pagesFiltered?.reduce((prev, curr) => prev + curr.responsesCount, 0);

  return (
    <LoggedPageContainer
      className="my-pages"
      additionalHeaderComponents={<Templates />}
      organizationStatus={currentOrg?.status}
      fixedHeaderContent={
        <>
          <Tabs activeKey={tabContentKey}>
            <Tab
              label={`${pageData?.pagesQuery?.activeCount || 0} pages`}
              tabKey="active"
              onClick={setTabContentKey}></Tab>
            <Tab
              label={`${pageData?.pagesQuery?.archivedCount || 0} archived`}
              tabKey="archived"
              onClick={setTabContentKey}></Tab>
          </Tabs>
          <div className="header-list">
            <span>Page name</span>
            <span>
              Last update <img src={arrowDownIcon} alt="arrow down" />
            </span>
            <span className="number-column">
              Responses <span>{responsesCount}</span>
            </span>
            <span className="number-column">
              Lifetime <span>{currencyFormatter(totalCollectedCents)}</span>
            </span>
            <span className="number-column"></span>
          </div>
        </>
      }>
      <ShareButton organizationId={currentOrg?.id || ''} showButton={false} />
      <ErrorModal />
      <div className="page-list">
        {pagesFiltered?.map(page => {
          return <PageCard data={page} key={page.id} />;
        })}
      </div>
    </LoggedPageContainer>
  );
};

export default MyPages;
