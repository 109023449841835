import React, { FC } from 'react';
import clsx from 'clsx';
import './style.scss';

export interface TabProps {
  label: string;
  tabKey: string;
  onClick: (tabKey: string) => void;
  tabActive?: string;
  suffix?: JSX.Element;
}

const Tab: FC<TabProps> = ({ tabKey, onClick, label, tabActive, suffix }) => {
  return (
    <button
      className={clsx('button-clear tab-button', { active: tabKey === tabActive })}
      onClick={() => {
        onClick(tabKey);
      }}>
      {label}
      {suffix}
    </button>
  );
};

export default Tab;
