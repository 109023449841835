import dayjs, { Dayjs } from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { PageDataDraftBlock } from '~/api/data/pages/types';

dayjs.extend(isSameOrAfter);

const truncatedDateWithoutHour = (date: Date) => dayjs(date).format('YYYY-MM-DD');

export const hasPageStarted = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.startDate) return true;

  return dayjs(currentDate.toDate()).isSameOrAfter(dayjs(page.startDate));
};

export const hasPageEnded = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.endDate) return false;

  return dayjs(currentDate.toDate()).isAfter(dayjs(page.endDate));
};

export const isPageActive = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  return hasPageStarted(currentDate, page) && !hasPageEnded(currentDate, page);
};

export const daysUntilPageStarts = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.startDate) return undefined;

  const truncatedStartDate = truncatedDateWithoutHour(page.startDate);
  const truncatedCurrentDate = truncatedDateWithoutHour(currentDate.toDate());

  return dayjs(truncatedStartDate).diff(truncatedCurrentDate, 'day');
};

export const daysUntilPageEnds = (currentDate: Dayjs, page: PageDataDraftBlock) => {
  if (!page.endDate) return undefined;

  const truncatedEndDate = truncatedDateWithoutHour(page.endDate);
  const truncatedCurrentDate = truncatedDateWithoutHour(currentDate.toDate());

  return dayjs(truncatedEndDate).diff(truncatedCurrentDate, 'day');
};
