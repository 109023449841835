import { useContext } from 'react';
import { useQuery } from '@apollo/client';
import * as TransactionQuery from 'graphql/transaction.graphql';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import UserRolePerspective from 'contexts/UserRolePerspective';
import { TransactionsByUser, TransactionsByUserAndOrg } from 'api/data/transaction/types';

export default function useTransactions(userId?: string) {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const {
    data: transactionsByUser,
    loading: loadigByUser,
    refetch: refetchByUser,
  } = useQuery<TransactionsByUser>(TransactionQuery.GetTransactionsFromCurrentUser, {
    skip: !isPayerView,
  });

  const {
    data: transactionsByUserAndOrg,
    loading: loadigByOrg,
    refetch: refetchByUserAndOrg,
  } = useQuery<TransactionsByUserAndOrg>(TransactionQuery.GetTransactionsByUserIdAndOrg, {
    variables: { userId, currentSpace: currentSpaceSlug() },
    fetchPolicy: 'network-only',
    skip: isPayerView,
  });

  return {
    transactions: transactionsByUser?.transactionsFromCurrentUser || transactionsByUserAndOrg?.transactionsByUserAndOrg,
    loading: loadigByUser || loadigByOrg,
    refetch: refetchByUser || refetchByUserAndOrg,
  };
}
