import React, { useState, useEffect, useContext, ReactNode } from 'react';
import { useLocation } from 'wouter';
import { ApolloError, useMutation, InternalRefetchQueriesInclude } from '@apollo/client';
import { default as queryString } from 'query-string';
import * as ResponseQuery from 'graphql/response.graphql';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';
import { apolloErrorFormatter, currencyFormatter } from 'utils';
import UserRolePerspective from 'contexts/UserRolePerspective';
import useToggle from 'hooks/useToggle';
import Message from 'components/Message';
import Modal from 'components/Modal';
import OrderItemDetails from 'components/OrderItem';
import PlusMinusCounter from 'components/PlusMinusCounter';
import { RefundTooltip } from './';
import SideModal from 'components/SideModal';
import SideModalHeader from 'components/SideModal/Header';
import Textarea from 'components/Input/TextArea';
import { PayerRequest, PayerRequestStatus, Transaction } from 'api/data/transaction/types';

type RequestType = 'SUBSCRIPTION' | 'REFUND';
type RefundLabelsType = {
  header: string;
  buttonText: string;
  approveMessage: (hasRefund: boolean) => string;
  rejectMessage: (hasRefund: boolean) => string;
  content: (
    requesterName: string,
    pageTitle?: string,
    amountCents?: number,
    recurring?: string,
    hasRefund?: boolean,
    cancelationReason?: string,
  ) => ReactNode;
  approveOption: (hasRefund: boolean, amountCents?: number) => string;
  reviewPerItemContent: (pendingRequest: PayerRequest) => ReactNode;
};

const requestLabels: {
  [key in RequestType]: RefundLabelsType;
} = {
  SUBSCRIPTION: {
    header: 'Review cancelation request',
    buttonText: 'Review request',
    reviewPerItemContent: () => null,
    content: (requesterName, pageTitle, _, recurring, hasRefund, cancelationReason) => (
      <p>
        {requesterName} has requested <b>cancelation {hasRefund && 'and a refund'}</b> for the &quot;{pageTitle}&quot;{' '}
        {recurring} subscription{cancelationReason && `, with the reason: "${cancelationReason}"`}.
      </p>
    ),
    approveOption: (hasRefund, amountCents) =>
      `Approve cancelation ${hasRefund ? ` and refund ${currencyFormatter(amountCents)}` : ''}`,
    approveMessage: hasRefund =>
      ` has been notified that his cancelation ${hasRefund ? 'and refund' : ''} has been issued.`,
    rejectMessage: hasRefund =>
      ` has been notified that his cancelation ${hasRefund ? 'and refund' : ''} request has been rejected.`,
  },
  REFUND: {
    header: 'Review refund request',
    buttonText: 'Review request',
    reviewPerItemContent: pendingRequest => (
      <>
        <p>Once initiated, refunds take 5-10 days to appear on the payer statement.</p>
        {pendingRequest?.requestedOrderItems?.map(item => {
          const { orderItem } = item;
          const quantity = orderItem.quantity || 0;
          const selectedAmount = orderItem.unitPriceCents || 0 * quantity;

          return (
            <OrderItemDetails
              key={orderItem.id}
              orderItem={{ ...orderItem, amount: selectedAmount }}
              showRecurring={false}>
              <div className="row align-center gap-s">
                <PlusMinusCounter quantity={item.quantity} disabled className="secondary" />/ {orderItem.quantity}
              </div>
            </OrderItemDetails>
          );
        })}
        <div className="horizontal-line" />
        <div className="row space-between total">
          <p className="subtitle-small">Total</p>
          <span className="subtitle-small">{currencyFormatter(pendingRequest?.amountCents)}</span>
        </div>
      </>
    ),
    content: (requesterName, pageTitle, amountCents) => (
      <p>
        {requesterName} has requested a {currencyFormatter(amountCents)} refund for &quot;{pageTitle}&quot;
      </p>
    ),
    approveMessage: () => ' has been issued a refund and emailed.',
    rejectMessage: () => ' has been informed that his request was rejected.',
    approveOption: () => 'Approve and refund',
  },
};

type ReviewRequestModalProps = {
  showButton: boolean;
  pageTitle?: string;
  requesterName?: string;
  transaction?: Transaction;
  transactionId?: string;
  amountCents?: number;
  status?: PayerRequestStatus;
  userId?: string;
  responseId?: string;
  pageId?: string;
  subscriptionId?: string;
  recurring?: string;
  cancelationReason?: string;
};

const ReviewRequestModal = ({
  pageTitle,
  requesterName = '',
  transaction,
  amountCents,
  transactionId,
  showButton,
  status,
  responseId,
  userId,
  pageId,
  subscriptionId,
  recurring,
  cancelationReason,
}: ReviewRequestModalProps) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const [reviewRequestError, setReviewRequestError] = useState('');
  const [location, setLocation] = useLocation();
  const [isSettingsOpen, setToggleSettings] = useToggle();
  const [showRespondedModal, setShowRespondedModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [reviewRequest, setReviewRequest] = useState({ action: 'approve', rejectionReason: '' });
  const [hasRefundAndSubscription] = useState(!!amountCents && !!subscriptionId);

  const refetchQueries = () => {
    const queries: InternalRefetchQueriesInclude = [];

    if (subscriptionId) {
      queries.push(
        responseId
          ? { query: SubscriptionQuery.GetSubscriptions, variables: { id: responseId } }
          : { query: SubscriptionQuery.GetSubscriptionsByUserAndOrg, variables: { userId } },
      );
    }

    if (transactionId) {
      queries.push(
        {
          query: isPayerView
            ? TransactionQuery.GetTransactionsFromCurrentUser
            : TransactionQuery.GetTransactionsByUserIdAndOrg,
          variables: { userId },
        },
        pageId
          ? { query: ResponseQuery.PageResponses, variables: { pageId } }
          : { query: ResponseQuery.RequestsResponses },
      );
      if (responseId) queries.push({ query: TransactionQuery.GetTransactions, variables: { id: responseId } });
    }

    return queries;
  };

  const [processPayerRequest, { loading }] = useMutation(TransactionQuery.ProcessPayerRequest, {
    refetchQueries: refetchQueries(),
  });

  const requestType = subscriptionId ? 'SUBSCRIPTION' : 'REFUND';

  useEffect(() => {
    const refundRequestFor = queryString.parse(window.location.search).refundRequestFor;
    const cancelationRequestFor = queryString.parse(window.location.search).cancelationRequestFor;
    if (
      (transactionId && refundRequestFor === transactionId) ||
      (subscriptionId && cancelationRequestFor === subscriptionId)
    ) {
      if (showButton) {
        setToggleSettings(true);
      } else if (status && status !== 'PENDING') {
        setShowRespondedModal(true);
      }
      setLocation(location);
    }
  }, [location, setLocation, setToggleSettings, transactionId, status, showButton, subscriptionId]);

  const handleSubmit = async () => {
    setReviewRequestError('');

    try {
      await processPayerRequest({ variables: { transactionId, subscriptionId, ...reviewRequest } });
      setShowMessage(true);
      setToggleSettings(false);
    } catch (error) {
      setReviewRequestError(apolloErrorFormatter(error as ApolloError));
    }
  };

  return (
    <>
      {showButton && (
        <button
          className="button-link secondary button-size-sm"
          type="button"
          onClick={event => {
            event.stopPropagation();
            setToggleSettings(true);
          }}>
          {requestLabels[requestType].buttonText}
        </button>
      )}
      <SideModal
        header={<SideModalHeader title={requestLabels[requestType].header} onClose={() => setToggleSettings(false)} />}
        isOpen={isSettingsOpen}
        toggle={setToggleSettings}>
        <div className="common-review-refund-modal review-request-modal">
          {transaction?.pendingPayerRequest &&
            requestLabels[requestType].reviewPerItemContent(transaction?.pendingPayerRequest)}

          {requestLabels[requestType].content(
            requesterName,
            pageTitle,
            amountCents,
            recurring?.toLowerCase(),
            hasRefundAndSubscription,
            cancelationReason,
          )}
          <div className="row align-center gap-ms request-choice">
            <input
              type="radio"
              id="request-approve"
              name="review-request"
              checked={reviewRequest.action === 'approve'}
              onChange={() => setReviewRequest({ ...reviewRequest, action: 'approve' })}
            />
            <label htmlFor="request-approve">
              {requestLabels[requestType].approveOption(hasRefundAndSubscription, amountCents)}{' '}
              {hasRefundAndSubscription && <RefundTooltip />}
            </label>
          </div>
          <div className="row align-center gap-ms request-choice">
            <input
              id="request-reject"
              type="radio"
              name="review-request"
              checked={reviewRequest.action === 'reject'}
              onChange={() => setReviewRequest({ ...reviewRequest, action: 'reject' })}
            />
            <label htmlFor="request-reject">Reject</label>
          </div>
          {reviewRequest.action === 'reject' && (
            <Textarea
              name="reject-reason"
              onChange={({ target }) => setReviewRequest({ ...reviewRequest, rejectionReason: target.value })}
              value={reviewRequest.rejectionReason}
              label="Add comment"
              rules={{ required: true }}
            />
          )}
          {!!reviewRequestError && <p className="error-message">{reviewRequestError}</p>}
          <button
            className="primary full-screen"
            disabled={loading || (reviewRequest.action === 'reject' && !reviewRequest.rejectionReason.trim())}
            onClick={() => void void handleSubmit()}>
            {loading ? 'Processing' : 'Submit'}
          </button>
        </div>
      </SideModal>
      <Modal
        header={requestLabels[requestType].header}
        headerIcon="money_back"
        handleOnConfirm={() => setShowRespondedModal(false)}
        handleOnCancel={() => setShowRespondedModal(false)}
        confirmlabel="Ok"
        visible={showRespondedModal}
        className="review-request-modal">
        <p className="paragraph-x-small">
          This {requestType === 'SUBSCRIPTION' ? 'cancelation' : 'refund'} request has already been{' '}
          {status?.toLowerCase()}.
        </p>
      </Modal>
      <Message type="succeeded" showMessage={showMessage} setShowMessage={setShowMessage}>
        {requesterName}
        {reviewRequest.action === 'approve'
          ? requestLabels[requestType].approveMessage(hasRefundAndSubscription)
          : requestLabels[requestType].rejectMessage(hasRefundAndSubscription)}
      </Message>
    </>
  );
};

export default ReviewRequestModal;
