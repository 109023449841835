import React, { FC, ReactNode, useContext } from 'react';
import clsx from 'clsx';
import { Link } from 'wouter';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import UserRolePerspective from 'contexts/UserRolePerspective';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import { checkUserRole } from '../ProtectedComponent';
import './style.scss';

interface UserLinkProps {
  fullName: string;
  nameTag?: ReactNode;
  separator?: ReactNode;
  email: string;
  userId: string;
}

const UserLink: FC<UserLinkProps> = ({ fullName, nameTag, email, userId, separator = <br /> }) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';

  const spaceSlug = currentSpaceSlug() || '';

  let userLink = '/member';
  if (spaceSlug && isPayerView) {
    userLink = `/${spaceSlug}/my-profile`;
  } else if (spaceSlug && !isPayerView) {
    userLink = `/${spaceSlug}/member/${userId}`;
  }

  return (
    <p className="user-link">
      <ConditionalLink className="breakword-anywhere username" link={userLink} isPayerView={isPayerView}>
        {fullName}
      </ConditionalLink>
      {nameTag && nameTag}
      {separator}
      <span>
        <ConditionalLink className="breakword-anywhere email" link={userLink} isPayerView={isPayerView}>
          {email}
        </ConditionalLink>
      </span>
    </p>
  );
};

const ConditionalLink = ({
  className,
  link,
  isPayerView,
  children,
}: {
  className: string;
  link: string;
  isPayerView: boolean;
  children: React.ReactNode;
}) => {
  const { currentOrg } = useCurrentOrganization();
  const { currentSpace } = useSpacesFromCurrentOrg();

  if (checkUserRole('PAYER_PROFILE', currentOrg?.currentUserRole, currentSpace?.currentUserRole) || isPayerView) {
    return (
      <Link className={className} href={link}>
        {children}
      </Link>
    );
  }

  return <span className={clsx('user-info', className)}>{children}</span>;
};

export default UserLink;
