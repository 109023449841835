import { NumberFormatValues } from 'react-number-format';

const MIN_VAL = 1;

const isValidPaymentAmount = (inputObj: NumberFormatValues): boolean => {
  const { floatValue } = inputObj;

  return floatValue === undefined || floatValue >= MIN_VAL;
};

export default isValidPaymentAmount;
