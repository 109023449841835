import React, { FC, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'wouter';
import { default as queryString } from 'query-string';
import * as AuthQuery from 'graphql/auth.graphql';
import { chooseManagedSpace } from '~/utils';
import useUserSession from 'hooks/useUserSession';
import { updateUserCache } from 'api/data/user';
import { LoginQuery } from 'api/data/user/types';

const TokenLogin: FC = () => {
  const { data: sessionData, loading, error } = useUserSession();
  const [userLogin] = useMutation<LoginQuery>(AuthQuery.Login, {
    update: updateUserCache,
  });
  const [, setLocation] = useLocation();

  useEffect(() => {
    const token = queryString.parse(window.location.search).token;
    const redirectUrl = queryString.parse(window.location.search).redirectUrl as string;
    if (!token) setLocation('/');

    const sessionQueryReturned = sessionData || error;
    if (!loading && token && sessionQueryReturned) {
      userLogin({ variables: { token: token } })
        .then(({ data }) => {
          if (data?.login?.currentUser) {
            if (redirectUrl) {
              setLocation(window.atob(redirectUrl));
            } else {
              const space = chooseManagedSpace(data?.login.managedOrganizations);
              const path = space ? `/${space.slug}/pages` : '/member';
              setLocation(path);
            }
          }
        })
        .catch(() => {
          setLocation('/request-login-token?invalid-link');
        });
    }
  }, [setLocation, userLogin, sessionData, loading, error]);

  return <div>Loading...</div>;
};

export default TokenLogin;
