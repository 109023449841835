import React, { FC } from 'react';
import './style.scss';

type PayerViewProps = {
  blockType: string;
  imageUrl?: string;
};

const PayerView: FC<PayerViewProps> = ({ blockType, imageUrl }) => {
  if (imageUrl) {
    return (
      <div className="block-image-payer-view">
        <img src={imageUrl} alt={`${blockType} block`} />
      </div>
    );
  }

  return null;
};

export default PayerView;
