export const passwordRules = [
  {
    rule: /[a-z]/,
    message: 'Have at least one lower case character',
  },
  {
    rule: /[A-Z]/,
    message: 'Have at least one capital letter',
  },
  {
    rule: /[0-9]/,
    message: 'Have at least one number',
  },
  {
    rule: /^.{8,}$/,
    message: 'Be at least 8 characters',
  },
  {
    rule: /^\S+$/,
    message: 'Cannot start or end with a blank space',
  },
];

export const regexCheckPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[^\s|\s$].{7}/;

type ErrorMessage = {
  message: string[];
};

export const errorParser = (error: string) => {
  const errorCleaned = error.replace('%', '').replace('encrypted_password:', '"message":');
  const errorParsed = JSON.parse(errorCleaned) as ErrorMessage;
  return errorParsed.message[0] || 'Password criteria not met';
};

export const participantNameDefaultLength = 25;
