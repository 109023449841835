import React, { FC } from 'react';
import ICONS from '../Icons';
import './style.scss';

export type TagType = 'negative' | 'neutral' | 'new' | 'pending' | 'positive' | 'warning';
export type TagSize = 'small' | 'medium' | 'large';

interface TagProps {
  label: string;
  type: TagType;
  size?: TagSize;
  icon?: string;
  capitalize?: boolean;
}

const Tag: FC<TagProps> = ({ label, type, size = 'medium', icon = '', capitalize = true }) => {
  return (
    <span className={`tag ${type} ${size} ${capitalize ? 'first-capitalize' : ''}`}>
      {ICONS[icon]} {label}
    </span>
  );
};

export default Tag;
