import React, { FC, useEffect } from 'react';
import lottie from 'lottie-web';
import Confetti from 'components/Animation/Confetti';
import CopyButton from 'components/Button/CopyButton';
import Input from '../Input';
import Modal from 'components/Modal';
import referralImg from 'assets/referral_image.png';
import './style.scss';

interface RefundModalProps {
  showModal: boolean;
  setShowModal: (show: boolean) => void;
  userEmail: string;
}

const ReferModal: FC<RefundModalProps> = ({ showModal, setShowModal, userEmail }) => {
  const referralLink = `https://omella.com/r?r=${window.btoa(userEmail)}`;

  useEffect(() => {
    if (showModal) {
      lottie.play('confetti');
      _cio.track('viewed_refer_modal');
    }
  }, [showModal]);

  const handleTrackEvent = () => {
    _cio.track('clicked_copy_refer_modal');
  };

  return (
    <>
      <Modal
        header="Earn $50"
        headerIcon="gift"
        visible={showModal}
        handleOnCancel={() => setShowModal(false)}
        className="refer-modal"
        fullScreenMobile>
        <div>
          {showModal && <Confetti />}
          <div className="refer-content">
            <img src={referralImg} alt="Referral Earn $50" />
            <b>Spread the word about Omella</b>
            <p>Both you and your referral will each earn $50 once they collect their first $1000 on Omella.</p>
            <div className="copy-container">
              <Input value={referralLink} name="referral" readOnly />
              <CopyButton link={referralLink} copyCallback={handleTrackEvent} />
            </div>
          </div>
          <div className="horizontal-line"></div>
          <a href="https://omella.com/blog/omella-referral-program" target="_blank" rel="noreferrer">
            <b>Learn more</b>
          </a>
        </div>
      </Modal>
    </>
  );
};

export default ReferModal;
