import React, { FC } from 'react';
import { findAnswer } from 'api/data/response';
import { Answer } from 'api/data/response/types';
import { QuestionBlock } from './types';

export interface LongAnswerProps {
  data: QuestionBlock;
  placeholder: string;
  readOnly?: boolean;
  groupedAnswer?: Answer;
  handleAnswerChange?: (value: string) => void;
}

const LongAnswer: FC<LongAnswerProps> = ({ data, readOnly, placeholder, groupedAnswer, handleAnswerChange }) => {
  const answer = groupedAnswer ? groupedAnswer : findAnswer(data.id, 'LONG_ANSWER');

  return (
    <textarea
      className="input-answer"
      aria-label="long answer"
      disabled={readOnly}
      maxLength={5000}
      placeholder={placeholder}
      value={answer.value}
      onChange={({ target }) => handleAnswerChange && handleAnswerChange(target.value.trimStart())}
    />
  );
};

export default LongAnswer;
