import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useQuery } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import Header from 'components/Header';
import NewPasswordForm from './NewPasswordForm';
import { PageParams, PasswordRecoveryResponse } from './types';

const NewPassword: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const { id, token } = params;
  const [, setLocation] = useLocation();

  const { data, loading: loadingPasswordRecovery } = useQuery<PasswordRecoveryResponse>(AuthQuery.PasswordRecovery, {
    variables: { id, token },
  });

  const outsideRef = useRef<HTMLElement>(null);

  useEffect(() => {
    if (data && !data?.passwordRecoveryValidation && !loadingPasswordRecovery) {
      setLocation('/password-recover?invalid-link');
    }
  }, [data, loadingPasswordRecovery, setLocation]);

  if (loadingPasswordRecovery) return <div>Loading... </div>;
  return (
    <section ref={outsideRef} className="request-link password-recover">
      <Header refOutside={outsideRef} showSideBar={false} />
      <div className="content">
        <h1>Choose a new password</h1>
        <NewPasswordForm params={params} buttonLabel="Submit" />
      </div>
    </section>
  );
};

export default NewPassword;
