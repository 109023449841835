import React, { FC, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useLocation } from 'wouter';
import * as AuthQuery from 'graphql/auth.graphql';
import { validateEmail } from 'utils';
import Email from '~/components/Input/Email';
import Header from 'components/Header';

const LoginTokenRequest: FC = () => {
  const [currentLocation] = useLocation();
  const [email, setEmail] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [requestToken, { loading }] = useMutation(AuthQuery.RequestLoginToken);
  const outsideRef = useRef<HTMLElement>(null);

  const handleRequestToken = () => {
    const redirectUrl = window.btoa(
      currentLocation === '/request-login-token'
        ? window.location.href.replace('/request-login-token', '/member')
        : window.location.href,
    );

    void requestToken({
      variables: {
        email: email,
        redirectUrl,
      },
    }).then(() => setEmailSent(true));
  };

  return (
    <section ref={outsideRef} className="request-link">
      <Header refOutside={outsideRef} showSideBar={false} />
      <div className="content">
        {emailSent ? (
          <div>
            <h1>Please check your email to login</h1>
            <p>{`If an account exists for ${email}, you will get an email with instructions to login.`}</p>
            <p>If not, please check your spam folder.</p>
          </div>
        ) : (
          <>
            <div>
              {location.search === '?invalid-link' ? (
                <>
                  <h1>The link you tried to access has expired or is invalid.</h1>
                  <h2>Please enter your email below to receive a new link.</h2>
                </>
              ) : (
                <h1>Enter your email to request a login link</h1>
              )}
              <Email
                value={email}
                onChange={({ target }) => setEmail(target.value)}
                id="email-field"
                name="input email"
                onBlur={({ target }) => setEmail(target.value)}
              />
            </div>
            <div className="footer">
              <button disabled={!validateEmail(email)} onClick={handleRequestToken}>
                {loading ? 'Loading...' : 'Request link'}
              </button>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default LoginTokenRequest;
