import React, { FC } from 'react';
import { useSelect } from 'downshift';
import ICONS from 'components/Icons';
import { MapType } from './index';
import './style.scss';

const mapRoleText: MapType = {
  OWNER: 'Organization Owner',
  ADMIN: 'Organization Admin',
  SPACE: 'Space Staff',
};

type OrganizationRoleProps = {
  value: string;
  onChange: (role: string) => void;
};

const organizationRoles = [
  {
    id: 'OWNER',
    name: 'Organization Owner',
    description: 'Can access all spaces, settings and payouts.',
  },
  {
    id: 'ADMIN',
    name: 'Organization Admin',
    description: 'Can access all spaces and settings.',
  },
  { id: 'SPACE', name: 'Space Staff', description: 'Can only access the spaces they are members of.' },
];

const OrganizationRole: FC<OrganizationRoleProps> = ({ value, onChange }) => {
  const { isOpen, getToggleButtonProps, getMenuProps, getItemProps } = useSelect({
    items: organizationRoles,
    itemToString(item) {
      return item ? item.id : '';
    },
    selectedItem: organizationRoles.find(item => item.id === value),
    onSelectedItemChange: ({ selectedItem }) => onChange(selectedItem?.id || ''),
  });

  return (
    <div className="selector organization-role">
      <span {...getToggleButtonProps()} className="role-name">
        {mapRoleText[value]}
        {ICONS['arrow_down']}
      </span>
      <ul {...getMenuProps()}>
        {isOpen &&
          organizationRoles.map((item, index) => (
            <li key={item.id} {...getItemProps({ item, index })}>
              <div>
                <span className="name">{item.name}</span>
                <span className="description">{item.description}</span>
              </div>
              <div>{ICONS['check']}</div>
            </li>
          ))}
      </ul>
    </div>
  );
};

export default OrganizationRole;
