import React, { FC, useState, useRef, isValidElement, cloneElement, Children } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import useOutsideClick from 'hooks/useOutsideClick';
import IconButton from '../Button/IconButton';
import { DropDownProps } from './types';
import './style.scss';

const DropDown: FC<DropDownProps> = ({
  openedIcon = 'menu_open',
  closedIcon = 'menu',
  children,
  outsideRef,
  className,
  buttonChildren,
  classNameOverlay,
  iconSide = 'LEFT',
  dynamicPosition = false,
  responsive = false,
  disabled = false,
  iconButtonLabel = '',
  onClick,
  onBlur,
  fixedChildren,
  htmlId,
}: DropDownProps) => {
  const [menuActive, setMenuActive] = useState(false);
  const [menuUpward, setMenuUpward] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const classNames = clsx('dropdown', { 'menu-active': menuActive, responsive: responsive }, className);
  const classNamesOverlay = clsx('dropdown-overlay', classNameOverlay, { responsive: responsive });

  const childrenWithProps = Children.map(children, child => {
    if (isValidElement(child)) {
      const props = { setMenuActive };
      return cloneElement(child, props);
    }
  });

  const menuItems = (
    <CSSTransition in={menuActive} timeout={550} classNames="animation" unmountOnExit>
      <>
        <ul className={clsx({ upward: menuUpward })}>{childrenWithProps}</ul>
        {fixedChildren && <ul className="fixed-children">{fixedChildren(() => setMenuActive(!menuActive))}</ul>}
        <div
          role="presentation"
          className={classNamesOverlay}
          onClick={() => {
            setMenuActive(!menuActive);
          }}
        />
      </>
    </CSSTransition>
  );

  useOutsideClick(menuRef, outsideRef, () => {
    if (menuActive) {
      setMenuActive(false);
      if (onBlur) onBlur();
    }
  });

  const handleOnClick = () => {
    if (dynamicPosition && !menuActive) {
      const body = document.documentElement || document.body.parentNode;
      const scrollTop = (body && typeof body.scrollTop === 'number' ? body : document.body).scrollTop;
      const topOffset = menuRef?.current?.offsetTop || 0;
      const relativeOffset = topOffset - scrollTop;
      const windowHeight = window.innerHeight / 2 + 220;

      setMenuUpward(relativeOffset > windowHeight);
    }

    if (onClick) onClick();
    setMenuActive(!menuActive);
  };

  return (
    <div className={classNames} ref={menuRef}>
      <IconButton
        htmlId={htmlId}
        label={iconButtonLabel}
        icon={menuActive ? openedIcon : closedIcon}
        onClick={handleOnClick}
        iconSide={iconSide}
        disabled={disabled}>
        {buttonChildren}
      </IconButton>
      {menuItems}
    </div>
  );
};

export default DropDown;
