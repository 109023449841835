import React from 'react';
import ICONS from 'components/Icons';

type HeaderProps = { onClose: () => void; title: string };

const Header = ({ onClose, title }: HeaderProps) => {
  return (
    <>
      <div className="side-modal-header">
        <button className="button-link close-button" aria-label="close button" onClick={onClose}>
          {ICONS['double_arrow']}
        </button>
        <h2>{title}</h2>
      </div>
      <div className="horizontal-line" />
    </>
  );
};

export default Header;
