import React, { FC, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import { defaultResponse } from 'defaults/response';
import { apolloErrorFormatter } from 'utils';
import { termsPrivacyDescription } from 'components/ACH';
import ICONS from 'components/Icons';
import Message from 'components/Message';
import Modal from 'components/Modal';
import PaymentMethodOptions, { PaymentMethod as PaymentMethodType } from 'components/PaymentMethodOptions';
import TermsPrivacy from 'components/TermsPrivacy';
import { PaymentMethodType as PaymentMethodTypes, ProcessPaymentData } from 'api/data/payment/types';
import { Subscription } from 'api/data/subscription/types';
import bankIcon from 'assets/bank.svg';
import cardIcon from 'assets/card.svg';
import './style.scss';

interface PaymentMethodProps {
  visible: boolean;
  setShowModal: (visible: boolean) => void;
  subscription?: Subscription;
  onClose: () => void;
  onPaymentMethodChanged?: () => Promise<unknown>;
}

const PaymentMethod: FC<PaymentMethodProps> = ({
  subscription,
  visible,
  setShowModal,
  onClose,
  onPaymentMethodChanged,
}) => {
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethodType>();
  const [showMessage, setShowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updateSubscriptionsPaymentMethod] = useMutation(SubscriptionQuery.UpdateSubscriptionsPaymentMethod);

  const updatePaymentMethod = async (processPaymentData: ProcessPaymentData) => {
    const subscriptionIds = [subscription?.id];

    await updateSubscriptionsPaymentMethod({
      variables: {
        input: {
          subscriptionIds,
          paymentParams: {
            paymentMethodId: processPaymentData.paymentMethodId,
            paymentMethodType: processPaymentData.paymentMethodType.toLowerCase(),
          },
        },
      },
    })
      .then(async () => {
        setShowMessage(true);
        setShowModal(false);
        onPaymentMethodChanged && (await onPaymentMethodChanged());
      })
      .catch(error => {
        throw { message: apolloErrorFormatter(error as ApolloError) };
      });
  };

  const currentInfo =
    subscription?.paymentMethodType === PaymentMethodTypes.ACH
      ? { name: 'ach', secondaryLabel: 'Free', icon: bankIcon }
      : { name: 'card', icon: cardIcon };

  return (
    <>
      <Message className="payment-method-changed" showMessage={showMessage} setShowMessage={setShowMessage}>
        Payment method changed
      </Message>
      <Modal
        visible={visible}
        handleOnCancel={() => {
          setPaymentMethod(undefined);
          onClose();
        }}
        className="payment-method-modal"
        header="Change payment method"
        headerIcon="money_blue"
        fullScreenMobile>
        <div className="modal-content-options">
          <span>Current</span>
          <div className="current-payment-content block">
            <label htmlFor={`payment-method-${currentInfo.name}`}>
              <img src={currentInfo.icon} alt={currentInfo.name} />
              <span className="last-4">
                {subscription?.paymentMethodType === PaymentMethodTypes.ACH ? (
                  <> {`${subscription?.bankName?.toUpperCase() || ''} •••• ${subscription?.last4 || ''} `}</>
                ) : (
                  <>
                    {ICONS[subscription?.cardBrand?.toLocaleLowerCase() || '']}
                    {`•••• ${subscription?.last4 || ''} `}
                  </>
                )}
              </span>
            </label>
            <div className="radio-button">
              <span>{currentInfo.secondaryLabel}</span>
              <input
                id="payment-method-current"
                type="radio"
                name="payment"
                checked={!paymentMethod}
                onChange={() => setPaymentMethod(undefined)}
              />
            </div>
          </div>
          <span className="add-new-card-label">Add new</span>
          <PaymentMethodOptions
            onSubmit={updatePaymentMethod}
            buttonText="Save"
            paymentError={{ title: 'Failed to change payment method' }}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            setLoading={setLoading}
            loading={loading}
            provider={subscription?.organization?.provider}
            disableInputEmail
            response={{
              ...defaultResponse,
              id: subscription?.responseId,
              user: {
                id: subscription?.user?.id,
                email: subscription?.user?.email || '',
                fullName: '',
                zipcode: '',
              },
            }}
          />
          <TermsPrivacy type="SUBMIT" additionalDescription={paymentMethod === 'ach' ? termsPrivacyDescription : ''} />
        </div>
      </Modal>
    </>
  );
};

export default PaymentMethod;
