import { useQuery, QueryHookOptions } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { GetOrganization } from 'api/data/user/types';

export default function useCurrentOrganization(options?: QueryHookOptions) {
  const spaceSlug = currentSpaceSlug();
  const result = useQuery<GetOrganization>(OrganizationQuery.GetOrganization, {
    variables: { currentSpace: spaceSlug },
    skip: !spaceSlug,
    ...options,
  });
  return { ...result, currentOrg: result.data?.organization || result.previousData?.organization };
}
