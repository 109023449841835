import React from 'react';
import { useMutation } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';

type RegisterResponseActivityProps = {
  responseId: string;
  activityTypeId: string;
  activityName: string;
  afterRegisterCallback?: () => Promise<void>;
  className?: string;
};

const RegisterResponseActivity = ({
  responseId,
  activityTypeId,
  activityName,
  afterRegisterCallback,
  className,
}: RegisterResponseActivityProps) => {
  const [registerActivity] = useMutation(ResponseQuery.RegisterResponseActivity);
  return (
    <button
      className={className}
      onClick={() => {
        void registerActivity({
          variables: {
            responseId: responseId,
            responseActivityTypeId: activityTypeId,
          },
        });

        afterRegisterCallback && void afterRegisterCallback();
      }}>
      {activityName}
    </button>
  );
};

export default RegisterResponseActivity;
