import React, { FC, useState, useEffect } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { getSaveOrganizationInput } from 'utils';
import useCurrentOrganization from '~/hooks/useCurrentOrganization';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Message from 'components/Message';
import Receipt from '~/components/Receipt';
import Tabs from 'components/Tabs';
import Tab from 'components/Tabs/Tab';
import GeneralSettings from './General';
import OrganizationMembers from './OrganizationMembers';
import PageSettings from './PageSettings';
import { Organization } from 'api/data/user/types';
import './style.scss';

export interface SettingsProps {
  handleSaveOrg: (changes: Partial<Organization>) => void;
  loading: boolean;
}

const tabs = ['general', 'page', 'members', 'receipt'];

type PageParams = {
  currentOrganization?: string;
  tab?: string;
};

const Settings: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [location, setLocation] = useLocation();
  const { currentOrg } = useCurrentOrganization();
  const [tabContentKey, setTabContentKey] = useState('general');
  const [saveOrganization, { loading }] = useMutation(OrganizationQuery.SaveOrganization);
  const [showMessage, setShowMessage] = useState(false);

  const spaceSlug = currentSpaceSlug() || '';

  const handleSaveOrganization = (changes: Partial<Organization>) => {
    const newOrganizationInfo = getSaveOrganizationInput(changes, currentOrg);

    void saveOrganization({
      variables: { input: newOrganizationInfo },
      optimisticResponse: {
        saveOrganization: { ...currentOrg, ...changes },
      },
    }).then(() => setShowMessage(true));
  };

  const settingsProps = {
    handleSaveOrg: handleSaveOrganization,
    loading,
  };

  const content: {
    [key: string]: JSX.Element;
  } = {
    general: <GeneralSettings {...settingsProps} />,
    page: <PageSettings {...settingsProps} />,
    members: <OrganizationMembers />,
    receipt: (
      <Receipt
        onSave={changes => handleSaveOrganization(changes as Partial<Organization>)}
        loading={loading}
        subject={currentOrg}
        subjectType="organization"
      />
    ),
  };

  useEffect(() => {
    if (params.tab && tabs.includes(params.tab)) {
      setTabContentKey(params.tab);
      setLocation(`/${spaceSlug}/settings`);
    }
  }, [setTabContentKey, location, setLocation, params.tab, spaceSlug]);

  return (
    <>
      <Message className="settings-changed" showMessage={showMessage} setShowMessage={setShowMessage}>
        Organization Info has been updated.
      </Message>
      <LoggedPageContainer
        className="settings-page"
        headerChildren={
          <div className="row second-header">
            <h1>{currentOrg?.name} Settings</h1>
            <p>Manage your organization</p>
          </div>
        }
        organizationStatus={currentOrg?.status}
        fixedHeaderContent={
          <div>
            <Tabs activeKey={tabContentKey}>
              <Tab label="General" tabKey="general" onClick={setTabContentKey}></Tab>
              <Tab label="Page" tabKey="page" onClick={setTabContentKey}></Tab>
              <Tab label="Members" tabKey="members" onClick={setTabContentKey}></Tab>
              <Tab label="Receipt" tabKey="receipt" onClick={setTabContentKey}></Tab>
            </Tabs>
          </div>
        }>
        {content[tabContentKey]}
      </LoggedPageContainer>
    </>
  );
};

export default Settings;
