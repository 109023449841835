import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';
import confettiAnimationData from 'assets/lottie/confetti.json';
import './style.scss';

const NAME = 'confetti';
const Confetti = () => {
  const animationContainer = useRef(null);

  useEffect(() => {
    if (animationContainer.current) {
      const animation = lottie.loadAnimation({
        container: animationContainer.current,
        renderer: 'svg',
        animationData: confettiAnimationData,
        loop: false,
        autoplay: false,
        name: NAME,
      });

      return () => {
        animation.destroy(NAME);
      };
    }
  }, [animationContainer]);

  return <div className="confetti-animation" ref={animationContainer}></div>;
};

export default Confetti;
