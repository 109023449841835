import React, { FC } from 'react';
import { removeOrderItemByVariant, removeOrderItemsByParentBlock, updateOrderItemByVariant } from 'api/data/response';
import PlusMinusCounter from '../PlusMinusCounter';
import { OrderItem } from 'api/data/response/types';

interface VariantQuantityProps {
  variantId: string;
  allowAnyAmount: boolean;
  orderItem: OrderItem;
  isRequired: boolean;
  maxQuantity?: number | null;
  availableQuantity?: number | null;
  onQuantityChangedToZero?: () => void;
  saveResponse?: () => void;
  replaceHandleAddMinusQuantity?: () => void;
  disabled?: boolean;
  plusMinusClassName?: string;
}

const VariantQuantity: FC<VariantQuantityProps> = ({
  variantId,
  allowAnyAmount,
  maxQuantity,
  availableQuantity,
  orderItem,
  isRequired,
  onQuantityChangedToZero,
  saveResponse,
  disabled = false,
  plusMinusClassName,
}) => {
  const handleAddMinusQuantity = (quantity: number) => {
    let newQuantity = quantity;

    if (onQuantityChangedToZero && quantity < 1) {
      onQuantityChangedToZero();
      return;
    }

    if (isRequired && quantity < 1) {
      newQuantity = 1;
    } else if (quantity === 0) {
      removeOrderItemByVariant(orderItem.variant.id);
      removeOrderItemsByParentBlock(orderItem.blockId);
      return;
    }

    const newOrderItem = {
      recurring: orderItem.recurring,
      amount: (orderItem.variant.amount || 0) * newQuantity,
      unitPriceCents: orderItem.variant.amount,
      variant: {
        ...orderItem.variant,
        quantity: newQuantity,
      },
    };

    updateOrderItemByVariant(orderItem.blockId, variantId, newOrderItem);

    if (saveResponse) saveResponse();
  };

  const maxAllowed = Math.min(availableQuantity || Infinity, maxQuantity || Infinity);

  const allowChangeQuantity = maxAllowed > 1;

  if (orderItem.variant.id === variantId && !allowAnyAmount && allowChangeQuantity)
    return (
      <PlusMinusCounter
        quantity={orderItem.variant.quantity}
        maxAllowed={maxAllowed}
        handleAddMinus={handleAddMinusQuantity}
        disabled={disabled}
        className={plusMinusClassName}
      />
    );

  return null;
};

export default VariantQuantity;
