import { ApolloClient, FetchResult, NormalizedCacheObject } from '@apollo/client';
import * as PaymentMutation from 'graphql/payment.graphql';
import * as ResponseQuery from 'graphql/response.graphql';
import { PaymentMethodType, ProcessPayment, SetupIntent, SetupIntentInput } from './types';

interface ProcessPaymentInput {
  responseId: string;
  customerId?: string;
  paymentMethodId?: string | null;
  paymentMethodType?: PaymentMethodType;
  financialConnectionsAccountId?: string | null;
  error: {
    type?: string;
    code?: string;
    message?: string;
  } | null;
}

const processPaymentMutation = async (
  processPaymentInput: ProcessPaymentInput,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<FetchResult<ProcessPayment>> => {
  return await client.mutate({
    mutation: PaymentMutation.ProcessPayment,
    variables: { input: processPaymentInput },
    refetchQueries: [{ query: ResponseQuery.GetResponse, variables: { id: processPaymentInput.responseId } }],
  });
};

const createSetupIntentMutation = async (
  setupIntentInput: SetupIntentInput,
  client: ApolloClient<NormalizedCacheObject>,
): Promise<FetchResult<SetupIntent>> => {
  return await client.mutate({
    mutation: PaymentMutation.CreateSetupIntent,
    variables: { input: setupIntentInput },
  });
};

export { createSetupIntentMutation, processPaymentMutation };
