import { makeVar } from '@apollo/client';
import { v4 as uuidv4 } from 'uuid';
import dayjs from 'dayjs';
import { RequestStatus, Requests } from 'api/data/request/types';

const generateDueDateDefault = () => dayjs().add(7, 'day').toDate();

const generateDefaultRequest = () => ({
  id: uuidv4(),
  fullName: '',
  email: '',
  status: 'UNSAVED' as RequestStatus,
  dueDate: generateDueDateDefault(),
});

export const generateRemindBeforeDueDate = (dueDate: Date) => dayjs(dueDate).subtract(3, 'day').format('YYYY-MM-DD');
export const generateRemindAfterDueDate = (dueDate: Date) => dayjs(dueDate).add(3, 'day').format('YYYY-MM-DD');

export const requestsVar = makeVar<Requests>([]);

export const updateRequests = (target: string, value: string | Date, index: number) => {
  const newRequestList = [...requestsVar()];
  const newRequest = {
    ...newRequestList[index],
    [target]: value,
  };

  if (target === 'dueDate' && !value) {
    newRequest.dueDate = generateDueDateDefault();
  }

  newRequestList[index] = newRequest;
  requestsVar(newRequestList);
};

export const deleteRequest = (index: number) => {
  const newRequestList = [...requestsVar()];
  newRequestList.splice(index, 1);
  requestsVar(newRequestList);
};

export const deleteRequestById = (id: string) => {
  const newRequestList = requestsVar().filter(request => request.id !== id);

  if (newRequestList.length === 0) {
    newRequestList.push(generateDefaultRequest());
  }
  requestsVar(newRequestList);
};

export const addRequest = () => {
  if (requestsVar().length === 0) {
    requestsVar([generateDefaultRequest()]);
  } else {
    const newRequestList = [...requestsVar()];
    newRequestList.push({ ...generateDefaultRequest(), dueDate: newRequestList[newRequestList.length - 1].dueDate });
    requestsVar(newRequestList);
  }
};

export const addRequests = (requests: Requests = []) => {
  const list = requestsVar();
  const filtered = requests.filter(request => list.findIndex(item => item.id === request.id) === -1);
  requestsVar([...list, ...filtered]);
};

export const resetRequests = () => {
  requestsVar([generateDefaultRequest()]);
};

export const clearRequests = () => {
  requestsVar([]);
};
