import React, { FC } from 'react';
import clsx from 'clsx';
import Tooltip from '../Tooltip';
import eyeIcon from 'assets/eye.svg';

interface EyeButtonProps {
  url: string;
  desktopOnly: boolean;
}

const EyeButton: FC<EyeButtonProps> = ({ url, desktopOnly }) => {
  return (
    <Tooltip title="Open in new tab" color="dark">
      <a href={url} target="_blank" rel="noreferrer" className={clsx({ 'desktop-only': desktopOnly })}>
        <img src={eyeIcon} alt="open payer page in a new tab" />
      </a>
    </Tooltip>
  );
};

export default EyeButton;
