import React, { FC } from 'react';
import { TeamFields } from 'components/PageSettings/TeamSettings';
import { SetupProps } from './types';

const Setup: FC<SetupProps> = ({ handleOnFieldChange, p2pReferToMeAs, p2pOrganizationName, p2pProgramName }) => {
  return (
    <div>
      <h1>Let’s set up your team fundraiser!</h1>
      <p className="subtitle-small neutral">Just a few quick questions before we add participants.</p>
      <TeamFields
        handleOnFieldChange={handleOnFieldChange}
        p2pReferToMeAs={p2pReferToMeAs || ''}
        p2pOrganizationName={p2pOrganizationName || ''}
        p2pProgramName={p2pProgramName || ''}
      />
    </div>
  );
};

export default Setup;
