import React, { FC, useState } from 'react';
import clsx from 'clsx';
import { updateAnswer, findGroupAnswers } from 'api/data/response';
import PayerView from '../QuestionBlock/PayerView';
import Tag from '../Tag';
import { Answer, Choice } from 'api/data/response/types';
import { GroupBlockPayerViewProps } from './types';
import './style.scss';

const GroupBlockPayerView: FC<GroupBlockPayerViewProps> = ({
  readOnly,
  data,
  isRequired,
}: GroupBlockPayerViewProps) => {
  const [groupedAnswers, setGroupedAnswers] = useState<Answer[][]>(Object.values(findGroupAnswers(data.id)));
  const [hasMissingAnswer, setHasMissingAnswer] = useState(false);
  const generateAnswers = () => {
    return data.blocks
      .filter(b => b.type === 'QUESTION')
      .map(question => {
        return {
          value: '',
          group: { id: data.id },
          groupKey: groupedAnswers.length || 0,
          question: {
            id: question.id,
            title: question.questionPrompt,
            type: question.questionType,
          },
        } as Answer;
      });
  };

  const [answers, setAnswers] = useState(generateAnswers());

  const saveAnswer = (value: string, index: number, choice?: Choice) => {
    const newAnswers = [...answers];
    newAnswers[index] = {
      ...newAnswers[index],
      value,
      choice: choice,
    };

    setAnswers(newAnswers);
  };

  const refreshAnswers = () => {
    let missingAnswer = false;
    data.blocks
      .filter(b => b.type === 'QUESTION' && b.required)
      .forEach(block => {
        if (!answers.find(answer => answer.question.id === block.id && !!answer.value)) {
          missingAnswer = true;
        }
      });

    setHasMissingAnswer(missingAnswer);
    if (missingAnswer) return;

    const group = { id: data.id };

    answers.forEach(answer => {
      if (answer.value) {
        answer.group = group;
        answer.groupKey = groupedAnswers.length;
        updateAnswer(answer.question.id, answer, group);
      }
    });

    const newGrouped = [...groupedAnswers];
    newGrouped.push(answers);
    setGroupedAnswers(newGrouped);
    setAnswers(generateAnswers());
  };

  const clear = () => {
    setAnswers(generateAnswers());
  };

  return (
    <div id={`block-${data.id}`} className="group payer-view">
      <div
        className={clsx('block question preview', {
          'read-only': readOnly,
          'required-block': isRequired && !groupedAnswers.length,
        })}>
        {data.blocks.map((block, index) => (
          <PayerView
            data={block}
            group={{ id: data.id }}
            key={block.id}
            readOnly={readOnly}
            groupedAnswer={answers[index]}
            saveGroupedAnswer={(value, choice) => saveAnswer(value, index, choice)}
            isRequired={(isRequired && !groupedAnswers.length) || (hasMissingAnswer && !answers[index].value)}
          />
        ))}
        <div className="group-button-container">
          <button className="button-link" onClick={clear}>
            Clear
          </button>
          <button className="add-button" onClick={refreshAnswers}>
            Add
          </button>
        </div>
      </div>
      <div className="answers-container">
        {groupedAnswers.map((item, idx) => {
          return (
            <Tag
              key={`grouped_answer_${data.id}_${idx}`}
              type="neutral"
              size="large"
              label={item.map(answer => (answer.value ? answer.value : answer.choice?.title || '...')).join(' • ')}
              capitalize={false}
            />
          );
        })}
      </div>
    </div>
  );
};

export default GroupBlockPayerView;
