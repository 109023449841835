import React, { FC } from 'react';
import { RouteComponentProps } from 'wouter';
import { useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import Header from 'pages/MyPage/Header';
import ShareByLink from '~/components/ShareByLink';
import { PageView } from 'api/data/pages/types';
import './style.scss';

const SharePayerPage: FC<RouteComponentProps> = ({ params }) => {
  const { id: pageSlug } = params;

  const { data: pageData, loading } = useQuery<PageView>(PageQuery.GetPageSummary, {
    variables: { id: params.id },
  });

  if (loading || !pageData?.page) {
    return <div>Loading...</div>;
  }

  return (
    <main className="page-default share-payer-page">
      <Header title="Share Page" linkBack={`/${pageSlug}`} showLogo={false} />
      <div className="page-content max-width-desktop">
        <ShareByLink params={pageSlug || ''} shareType="payerPage" title={pageData?.page.title.trim() || ''} />
      </div>
    </main>
  );
};

export default SharePayerPage;
