import { useMutation, FetchResult, MutationFunctionOptions } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';

type RefundByType = 'ORGANIZATION' | 'PAYER';
type RefundLabelsType = {
  header: string;
  buttonText: string;
  successfulMessage: string;
  content: string;
  variableLabelInput: string;
};

const refundLabels: {
  [key in RefundByType]: RefundLabelsType;
} = {
  PAYER: {
    header: 'Request Refund',
    buttonText: 'Request Refund',
    content: 'Once initiated, refunds take 5-10 days to appear on your statement.',
    successfulMessage: 'Your refund request has been sent.',
    variableLabelInput: 'requestedOrderItems',
  },
  ORGANIZATION: {
    header: 'Refund Payment',
    buttonText: 'Refund',
    content: 'Once initiated, refunds take 5-10 days to appear on the payer statement.',
    successfulMessage: 'A refund has been issued and the payer has been notified via email.',
    variableLabelInput: 'refundedOrderItems',
  },
};

type UseRefundResponse = [(param: MutationFunctionOptions) => Promise<FetchResult<unknown>>, boolean, RefundLabelsType];

function useRefundValues(
  refundBy: RefundByType,
  userId?: string,
  pageId?: string,
  responseId?: string,
): UseRefundResponse {
  const isPayerView = refundBy === 'PAYER';

  const [createRefund, { loading: loadingCreateRefund }] = useMutation(TransactionQuery.CreateRefund, {
    refetchQueries: responseId
      ? [
          pageId
            ? { query: ResponseQuery.PageResponses, variables: { pageId } }
            : { query: ResponseQuery.RequestsResponses },
          { query: TransactionQuery.GetTransactions, variables: { id: responseId } },
        ]
      : [],
  });
  const [createPayerRequest, { loading: loadingCreateRequest }] = useMutation(TransactionQuery.CreatePayerRequest, {
    refetchQueries: [
      {
        query: isPayerView
          ? TransactionQuery.GetTransactionsFromCurrentUser
          : TransactionQuery.GetTransactionsByUserIdAndOrg,
        variables: { userId },
      },
    ],
  });

  if (refundBy === 'ORGANIZATION') return [createRefund, loadingCreateRefund, refundLabels[refundBy]];

  return [createPayerRequest, loadingCreateRequest, refundLabels[refundBy]];
}

export default useRefundValues;
