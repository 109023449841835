import React, { FC, useState, useEffect, KeyboardEvent } from 'react';
import { Link, useLocation } from 'wouter';
import { useLazyQuery } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import useUserSession from 'hooks/useUserSession';
import useFirstOrganization from 'hooks/useFirstOrganization';
import { extractQueryStringFromParams, validateEmail } from 'utils';
import Email from '~/components/Input/Email';
import { CheckUserType } from 'api/data/user/types';

type SignupEmailProps = {
  setStep: (step: string) => void;
  email: string;
  setEmail: (email: string) => void;
};

const SignupEmail: FC<SignupEmailProps> = ({ setStep, email, setEmail }) => {
  const [, setLocation] = useLocation();
  const { data: userData, loading: loadingUser } = useUserSession();
  const { firstOrganizationData, loading: loadingFirstOrg } = useFirstOrganization();
  const [userExists, setUserExists] = useState(false);
  const [checkUser, { loading, data }] = useLazyQuery<CheckUserType>(AuthQuery.CheckUser);
  const spaceSlug = currentSpaceSlug() || firstOrganizationData?.spaces[0]?.slug || '';

  useEffect(() => {
    if (data) {
      if (data.checkUserExists) {
        setUserExists(true);
      } else {
        setStep('PASSWORD');
      }
    }
  }, [data, setStep]);

  useEffect(() => {
    if (extractQueryStringFromParams('redirectTo') === 'editor') {
      setStep('PASSWORD');
    }
  }, [setStep]);

  useEffect(() => {
    if (userData?.session.currentUser && !loadingFirstOrg) {
      if (spaceSlug) setLocation(`/${spaceSlug}/pages`);
      else setStep('ORGANIZATION');
    }
  }, [userData, loadingFirstOrg, spaceSlug, setLocation, setStep]);

  if (loadingUser || loadingFirstOrg) return null;

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === ' ') {
      // without this, if user types a space in the middle of the field
      // we fires the change event, replace the whole value, and the cursor
      // goes to the end. It's a weird UX
      event.preventDefault();
    }
  };

  const title =
    extractQueryStringFromParams('redirectTo') === 'editor'
      ? 'Your page has been saved! Create an account or log in to view it!'
      : 'Payments, forms and signatures that save you time and money.';

  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        void checkUser({
          variables: {
            email,
            tracking: {
              url: window.location.href,
              userAgent: window.navigator.userAgent,
              referrer: document.referrer,
            },
          },
        });
      }}
      aria-label="validate account form">
      <p className="title">{title}</p>
      <div>
        <Email
          onChange={({ target }) => setEmail(target.value)}
          value={email}
          name="input email"
          rules={{
            errorMessage: 'Email is invalid or already taken.',
            hasError: userExists,
          }}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
          onKeyDown={handleKeyDown}
          onBlur={({ target }) => setEmail(target.value)}
        />
        <div className="footer">
          <button disabled={!validateEmail(email) || loading}>{loading ? 'Loading...' : 'Get started'}</button>
          <p>
            Already have an account? <Link href={`/login${window.location.search}`}>Log in</Link>
          </p>
        </div>
      </div>
    </form>
  );
};

export default SignupEmail;
