import React, { FC, useEffect, useRef } from 'react';
import { useLocation } from 'wouter';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import useNewPage from 'hooks/useNewPage';
import useUserSession from 'hooks/useUserSession';
import { chooseManagedSpace } from 'utils';
import Error from 'components/Error';

const NewPage: FC = () => {
  const [, setLocation] = useLocation();
  const { data, loading } = useUserSession();
  const [createPage, loadingPage, error] = useNewPage(data?.session);
  const mounted = useRef(true);
  const spaceSlug = chooseManagedSpace(data?.session.managedOrganizations);

  if (spaceSlug) currentSpaceSlug(spaceSlug.slug);
  useEffect(() => {
    if (mounted.current && !loading) {
      if (data?.session.currentUser && spaceSlug) {
        void createPage();
      } else {
        setLocation('/editor/new-page');
      }
      mounted.current = false;
    }
  }, [createPage, mounted, data?.session, spaceSlug, setLocation, loading]);

  if (loadingPage) return <span>Loading...</span>;

  return error ? <Error code="DEFAULT" /> : null;
};

export default NewPage;
