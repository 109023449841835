import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import { NumericFormat } from 'react-number-format';
import { isValidAmount, numberCentsFormatter, numberToCentsFormatter } from 'utils';
import { updatePaymentBlock, PaymentValue, addPaymentVariant, removeVariant } from 'api/data/pages/blocks';
import useOutsideClick from 'hooks/useOutsideClick';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import DropDownItemDivider from 'components/Menu/DropDownItemDivider';
import InputNumber from '../Input/Number';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import ImageInBlock from 'components/ImageInBlock';
import Input from 'components/Input/LabelFloating';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import DonationPayerView from './PayerView';
import RecurringModal from './RecurringModal';
import { DonationBlock, DonationBlockProps } from './types';
import './style.scss';

const Donation: FC<DonationBlockProps> = ({ data, isFocused, refOutside, setGoal, updateBlock, handleDelete }) => {
  const [focusItem, setFocusItem] = useState<number>();
  const [isEditing, setIsEditing] = useState<boolean>(isFocused);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRecurringModal, setShowRecurringModal] = useState(false);
  const [focusElement, setFocusElement] = useState<string>();

  const mounted = useRef(true);
  const donationRef = useRef<HTMLElement>(null);
  const elementsRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>({});

  useEffect(() => {
    if (mounted.current) {
      mounted.current = false;
      return;
    }

    if (!mounted.current && data.variants) {
      const variantId = data.variants[data.variants.length - 2]?.id;
      const variantRefName = `donation_variant_${variantId}`;
      if (elementsRefs && elementsRefs.current[variantRefName]) {
        elementsRefs?.current[variantRefName]?.focus();
      }
    }
  }, [data.variants, elementsRefs]);

  useEffect(() => {
    if (isEditing && focusElement && elementsRefs && elementsRefs.current[focusElement]) {
      elementsRefs?.current[focusElement]?.focus();
    }
  }, [isEditing, focusElement, elementsRefs]);

  useOutsideClick(donationRef, refOutside, () => {
    if (isEditing) {
      setIsEditing(false);
    }
  });

  const handleOnChange = (value: PaymentValue, field: string, variantIdx?: number) => {
    const updatedBlock = updatePaymentBlock(data, value, field, variantIdx);
    if (field === 'goal') {
      setGoal(updatedBlock);
    } else {
      updateBlock(updatedBlock);
    }
  };

  const handleGoal = (block: Partial<DonationBlock>, value: string) => {
    const amountCents = numberToCentsFormatter(value);

    if (block.goal) {
      handleOnChange({ ...block.goal, amountCents }, 'goal');
    } else {
      handleOnChange({ id: uuidv4(), amountCents }, 'goal');
    }
  };

  const handleAddVariant = () => {
    const block = addPaymentVariant(data, 'DONATION');
    updateBlock(block);
  };

  const handleRemoveVariant = (variantIdx: number) => {
    const block = removeVariant(data, variantIdx);
    updateBlock(block);
  };
  return (
    <section
      className="block-container"
      role="presentation"
      ref={donationRef}
      onClick={ev => {
        ev.stopPropagation();
        const target = ev.target as HTMLElement;

        if (!isEditing) {
          setIsEditing(true);
          setFocusElement(target.id);
        }
      }}>
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={handleDelete}
        confirmlabel="Delete"
        denyLabel="Cancel"
        visible={showDeleteModal}
        header=" Delete this Donation Block?"
      />
      <RecurringModal
        setShowModal={setShowRecurringModal}
        showModal={showRecurringModal}
        handleOnConfirm={recurring => handleOnChange(recurring, 'recurring')}
        recurring={data.recurring}
      />
      {isEditing ? (
        <div className="block donation">
          <div className="block-header row space-between">
            <div className="row align-center">
              {ICONS['heart_money']}
              <span className="size-xxs">Donation</span>
            </div>
            <div className="row align-center">
              <ToggleSwitch
                label="Required"
                toggleValue={data.required}
                handleOnChange={() => handleOnChange(!data.required, 'required')}
              />
              <span className="vertical-line"></span>
              <DropDown outsideRef={donationRef}>
                <DropDownItem icon="recurring" onClick={() => setShowRecurringModal(true)}>
                  Recurring
                </DropDownItem>
                <DropDownItemDivider />
                <DropDownItem icon="delete" onClick={() => setShowDeleteModal(true)}>
                  Delete
                </DropDownItem>
              </DropDown>
            </div>
          </div>
          <div className="block-content">
            <ImageInBlock imageUrl={data.imageUrl} handleOnChange={handleOnChange}>
              <Input
                label="What are you collecting money for?"
                placeholder="i.e, Donation, Fundraiser, Charity"
                secondLabel="i.e, Donation, Fundraiser, Charity"
                onChange={({ target }) => handleOnChange(target.value, 'description')}
                value={data.description || ''}
                className="block-title"
                name="donation"
                innerRef={element => (elementsRefs.current[`donation_description_${data.id}`] = element)}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!data.description}
              />
            </ImageInBlock>
            <InputNumber
              placeholder="Type your goal"
              onValueChange={target => handleGoal(data, target.value)}
              value={
                data.goal?.amountCents !== null && data.goal?.amountCents !== undefined
                  ? numberCentsFormatter(data.goal?.amountCents)
                  : ''
              }
              name="goal"
              thousandSeparator
              allowNegative={false}
            />
            <div className="row variant-container">
              {data.variants.map((item, i) => {
                const classNamesInput = clsx({ 'focus-item': focusItem === i || item.label });
                const variantBox = clsx('row align-center variant-box', {
                  'focus-item': focusItem === i,
                });

                if (item.allowAnyAmount) {
                  return (
                    <div key={item.id} className="row align-center variant-box">
                      <p>Other</p>
                      <div className="fixed-label">$</div>
                    </div>
                  );
                }
                return (
                  <div key={item.id} className={variantBox}>
                    <IconButton icon="close" onClick={() => handleRemoveVariant(i)} />
                    <span className="variant-label-box">
                      <NumericFormat
                        value={`${
                          item.amountCents !== null ? `$${numberCentsFormatter(item.amountCents as number)}` : ''
                        }`}
                        onValueChange={target => {
                          handleOnChange((parseFloat(target.value) * 100).toFixed(), 'amountCents', i);
                        }}
                        thousandSeparator
                        decimalScale={2}
                        placeholder="$0"
                        onFocus={() => setFocusItem(i)}
                        onBlur={() => setFocusItem(undefined)}
                        isAllowed={isValidAmount}
                        getInputRef={(element: HTMLInputElement) =>
                          (elementsRefs.current[`donation_variant_${item.id}`] = element)
                        }
                      />
                    </span>
                    <textarea
                      maxLength={70}
                      wrap="hard"
                      value={item.label || ''}
                      onChange={({ target }) => handleOnChange(target.value, 'label', i)}
                      onKeyPress={e => {
                        if (e.code === 'Enter' || e.code === 'NumpadEnter') e.preventDefault();
                      }}
                      onFocus={() => setFocusItem(i)}
                      onBlur={() => setFocusItem(undefined)}
                      placeholder={`Title ${focusItem === i ? '(optional)' : ''}`}
                      data-testid="variant-label"
                      className={`variant-label ${classNamesInput}`}
                      ref={element => (elementsRefs.current[`donation_variant_text_${item.id}`] = element)}></textarea>
                  </div>
                );
              })}
            </div>
            <div className="choice-btn-container">
              <button className="button-link underline" onClick={handleAddVariant}>
                Add choice
              </button>
            </div>
          </div>
        </div>
      ) : (
        <DonationPayerView data={data} readOnly />
      )}
    </section>
  );
};

export default Donation;
