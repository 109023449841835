import React, { FC } from 'react';
import clsx from 'clsx';
import IconButton from '../Button/IconButton';
import { DropDownItemProps } from './types';
import './style.scss';

const DropDownItem: FC<DropDownItemProps> = ({
  children,
  icon,
  onClick,
  className,
  disabled = false,
  setMenuActive,
  isSelected = false,
}: DropDownItemProps) => {
  const classNames = clsx('menu-item', { selected: isSelected }, className);

  return (
    <li className={classNames}>
      <IconButton
        icon={icon || ''}
        onClick={event => {
          if (setMenuActive) setMenuActive(false);
          onClick && onClick(event);
        }}
        disabled={disabled}>
        {children}
      </IconButton>
    </li>
  );
};

export default DropDownItem;
