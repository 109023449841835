import { useMutation, FetchResult, MutationFunctionOptions } from '@apollo/client';
import * as ResponseQuery from 'graphql/response.graphql';
import * as SubscriptionQuery from 'graphql/subscription.graphql';
import * as TransactionQuery from 'graphql/transaction.graphql';

type CancelationByType = 'ORGANIZATION' | 'PAYER';
type LabelsType = {
  header: string;
  buttonText: string;
  buttonConfirmation: string;
  successfulMessage: (refundLastTransaction: boolean) => string;
  content: (recurring: string, date: string, orgName: string, showLastCharge?: boolean) => string;
  refund: string;
};

const labels: {
  [key in CancelationByType]: LabelsType;
} = {
  PAYER: {
    header: 'Request cancelation',
    buttonText: 'Request cancelation',
    buttonConfirmation: 'Send request',
    content: (recurring, date, orgName) =>
      `The last charge for this ${recurring} subscription was ${date}. If you would like to cancel, please provide ${orgName} with a reason so that they may review your request. `,
    successfulMessage: refundLastTransaction =>
      `Your cancelation${refundLastTransaction ? ' and refund' : ''} request has been sent.`,
    refund: 'Please also refund the last payment from',
  },
  ORGANIZATION: {
    header: 'Cancel subscription',
    buttonText: 'Cancel subscription',
    buttonConfirmation: 'Cancel subscription',
    content: (recurring, date, _, showLastCharge) => {
      const cancelationText = 'Are you sure you want to cancel it? This action cannot be undone.';
      if (!showLastCharge) return cancelationText;
      return `The last charge for this ${recurring} subscription was ${date}. ` + cancelationText;
    },
    successfulMessage: refundLastTransaction =>
      `A cancelation${
        refundLastTransaction ? ' and refund' : ''
      } has been issued and the payer has been notified via email.`,
    refund: 'Refund the last payment from',
  },
};

type UseRefundResponse = [(param: MutationFunctionOptions) => Promise<FetchResult<unknown>>, boolean, LabelsType];

function useCancelSubscriptionValues(
  refundBy: CancelationByType,
  userId?: string,
  pageId?: string,
  responseId?: string,
): UseRefundResponse {
  const isPayerView = refundBy === 'PAYER';

  const [cancelSubscription, { loading: cancelSubscriptionLoading }] = useMutation(
    SubscriptionQuery.CancelSubscription,
    {
      refetchQueries: responseId
        ? [
            pageId
              ? { query: ResponseQuery.PageResponses, variables: { pageId } }
              : { query: ResponseQuery.RequestsResponses },
            { query: SubscriptionQuery.GetSubscriptions, variables: { id: responseId } },
          ]
        : [{ query: SubscriptionQuery.GetSubscriptionsByUserAndOrg, variables: { userId } }],
    },
  );
  const [createPayerRequest, { loading: loadingCreateRequest }] = useMutation(TransactionQuery.CreatePayerRequest, {
    refetchQueries: [
      {
        query: isPayerView
          ? SubscriptionQuery.GetSubscriptionsFromCurrentUser
          : SubscriptionQuery.GetSubscriptionsByUserAndOrg,
        variables: { userId },
      },
    ],
  });

  if (refundBy === 'ORGANIZATION') return [cancelSubscription, cancelSubscriptionLoading, labels[refundBy]];

  return [createPayerRequest, loadingCreateRequest, labels[refundBy]];
}

export default useCancelSubscriptionValues;
