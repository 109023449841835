import { FC, useEffect } from 'react';
import { useLocation } from 'wouter';
import { chooseManagedSpace } from '~/utils';
import useUserSession from '~/hooks/useUserSession';

const PagesRedirects: FC = () => {
  const [, setLocation] = useLocation();
  const { data, loading } = useUserSession();

  useEffect(() => {
    const firstSpace = chooseManagedSpace(data?.session.managedOrganizations);
    if (!loading && firstSpace) {
      setLocation(`/${firstSpace?.slug || ''}/pages`);
    }
  }, [data, loading, setLocation]);

  return null;
};

export default PagesRedirects;
