import React, { FC, useEffect, useRef } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { chooseManagedSpace } from 'utils';
import useUserSession from 'hooks/useUserSession';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import Header from 'components/Header';
import ErrorComponent from 'components/Error';
import './style.scss';

export type PageParams = { code: string };

const Error: FC<RouteComponentProps<PageParams>> = ({ params }: RouteComponentProps<PageParams>) => {
  const { data, loading: loadingSession } = useUserSession();
  const [location, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug() || '';

  const refOutside = useRef<HTMLElement>(null);

  useEffect(() => {
    if (!spaceSlug && data && !loadingSession) {
      const firstSpace = chooseManagedSpace(data?.session.managedOrganizations);
      if (firstSpace?.slug) setLocation(`/${firstSpace?.slug || ''}${location}`);
    }
  }, [spaceSlug, data, loadingSession, location, setLocation]);

  if (loadingSession) return <div>Loading...</div>;

  return (
    <section className="error-main">
      <Header refOutside={refOutside} />
      <div className="row justify-center">
        <ErrorComponent code={params.code?.toLocaleUpperCase()} />
      </div>
    </section>
  );
};

export default Error;
