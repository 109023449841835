import React, { FC, useState, useEffect, useCallback } from 'react';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import { recurringOptionsArray } from 'defaults/page';

export interface RecurringModalProps {
  recurring: string[];
  showModal: boolean;
  handleOnConfirm: (recurring: string[]) => void;
  setShowModal: (show: boolean) => void;
}

const RecurringModal: FC<RecurringModalProps> = ({
  setShowModal,
  showModal,
  handleOnConfirm,
  recurring,
}: RecurringModalProps) => {
  const [data, setData] = useState(recurringOptionsArray);

  const setRecurring = useCallback(() => {
    const newData = recurringOptionsArray.map(item => {
      return { ...item, value: recurring.some(recur => recur === item.id) };
    });
    setData(newData);
  }, [setData, recurring]);

  useEffect(() => {
    setRecurring();
  }, [setRecurring]);

  const handleOnChange = (newValue: boolean, index: number) => {
    const activeRecurring = data.filter(item => item.value);
    if (activeRecurring.length > 1 || newValue) {
      const newData = [...data];
      newData[index] = { ...newData[index], value: newValue };
      setData(newData);
    }
  };

  const handleSave = () => {
    const recurring = data.filter(item => item.value).map(recur => recur.id);
    handleOnConfirm(recurring);
    setShowModal(false);
  };

  const handleOnCancel = () => {
    setRecurring();
    setShowModal(false);
  };

  return (
    <Modal
      handleOnCancel={handleOnCancel}
      handleOnConfirm={handleSave}
      header="Recurring"
      headerIcon="recurring"
      confirmlabel="Save"
      denyLabel="Cancel"
      visible={showModal}
      fullScreenMobile>
      <div className="donation-modal-body">
        <span className="header-description">At least one option must be selected.</span>
        {data.map((item, i) => (
          <ToggleSwitch
            key={item.label}
            label={item.label}
            toggleValue={item.value}
            handleOnChange={() => handleOnChange(!item.value, i)}
          />
        ))}
      </div>
    </Modal>
  );
};

export default RecurringModal;
