import { useEffect, RefObject } from 'react';

function useOutsideClick(
  refInside: RefObject<HTMLElement>,
  refOutside: RefObject<HTMLElement> | null | undefined,
  action: (event?: Event) => void,
): void {
  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (refInside.current && event && !refInside.current.contains(event.target as Node)) {
        action(event);
      }
    }

    const copyRef = refOutside?.current || document;

    copyRef?.addEventListener('click', handleClickOutside);
    return () => {
      copyRef?.removeEventListener('click', handleClickOutside);
    };
  }, [refInside, refOutside, action]);
}

export default useOutsideClick;
