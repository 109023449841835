import React, { FC, useState } from 'react';
import { currencyFormatter } from 'utils';
import useInfiniteScroll from 'hooks/useInfiniteScroll';
import BlankState from 'components/BlankState';
import ResponseRow from './ResponseRow';
import arrowDownIcon from 'assets/arrow_down_order.svg';
import { Page, PageResponse } from 'api/data/response/types';
import './style.scss';

export type PageResponsesProps = {
  responses: PageResponse[];
  totalCollectedCents?: number;
  page?: Page;
  showPageTitle?: boolean;
};

const PageResponses: FC<PageResponsesProps> = ({ responses, totalCollectedCents, page, showPageTitle }) => {
  const [responseId, setResponseId] = useState<string>();
  const [quantity, setQuantity] = useState<number>(100);

  const loadMoreItems = () => {
    setQuantity(prevQuantity => prevQuantity + 100);
  };

  useInfiniteScroll(loadMoreItems, quantity < responses.length);

  return (
    <div className="submission-list">
      {responses.length === 0 ? (
        <BlankState title="No submissions" message={'Data will appear here.'} />
      ) : (
        <table>
          <thead>
            <tr>
              <th>Respondent</th>
              {showPageTitle && <th>Page</th>}
              <th className="short-column">
                Last update <img src={arrowDownIcon} alt="arrow down" />
              </th>
              <th className="number-column">
                Total <span>{currencyFormatter(totalCollectedCents || page?.totalCollectedCents)}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {responses.slice(0, quantity).map(response => (
              <ResponseRow
                key={response.id}
                data={response}
                page={page}
                setResponseId={setResponseId}
                showDetail={responseId === response.id}
                showPageTitle={showPageTitle}
              />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PageResponses;
