import { useEffect } from 'react';

type ClickAwayHandler = (event: MouseEvent) => void;

const useClickAway = (ref: React.RefObject<HTMLElement>, onClickAway: ClickAwayHandler) => {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickAway(event);
      }
    };

    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [ref, onClickAway]);
};

export default useClickAway;
