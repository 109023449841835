import React, { FC, useRef, ReactNode } from 'react';
import clsx from 'clsx';
import { isKYCd } from 'utils';
import Header from 'components/Header';
import { OrganizationStatus } from 'api/data/user/types';

type LoggedPageContainerProps = {
  children: ReactNode;
  className: string;
  fixedHeaderContent?: ReactNode;
  organizationStatus?: OrganizationStatus;
  additionalHeaderComponents?: ReactNode;
  header?: ReactNode;
  headerChildren?: ReactNode;
  headerClassName?: string;
  showSideBar?: boolean;
};

const LoggedPageContainer: FC<LoggedPageContainerProps> = ({
  children,
  className,
  fixedHeaderContent,
  organizationStatus,
  additionalHeaderComponents,
  header,
  headerChildren,
  headerClassName,
  showSideBar,
}) => {
  const refOutside = useRef<HTMLElement>(null);
  return (
    <main className={clsx('page-default', className)}>
      {header || (
        <Header refOutside={refOutside} showSideBar={showSideBar}>
          {headerChildren}
        </Header>
      )}
      {additionalHeaderComponents}
      <div className="page-content">
        {fixedHeaderContent && (
          <div
            className={clsx('fixed-header', headerClassName, {
              'banner-space': !!organizationStatus && !isKYCd(organizationStatus),
            })}>
            {fixedHeaderContent}
          </div>
        )}
        {children}
      </div>
    </main>
  );
};

export default LoggedPageContainer;
