import React, { FC } from 'react';
import Alert from '~/components/Alert';
import Modal from '~/components/Modal';
import { SpaceMembership, User } from '~/api/data/user/types';

type TransferOwnershipModalProps = {
  currentUser: User;
  currentOwner: User;
  newOwner: SpaceMembership;
  onPromoteToOwner: (newOwner: SpaceMembership) => void;
  setShowModal: (show: boolean) => void;
  showModal: boolean;
};

const TransferOwnershipModal: FC<TransferOwnershipModalProps> = ({
  currentUser,
  currentOwner,
  newOwner,
  onPromoteToOwner,
  setShowModal,
  showModal,
}) => {
  const {
    status,
    user: { fullName },
  } = newOwner;

  return (
    <Modal
      header="Make this person the space owner?"
      headerIcon="transfer"
      handleOnCancel={() => setShowModal(false)}
      handleOnConfirm={() => {
        onPromoteToOwner(newOwner);
        setShowModal(false);
      }}
      confirmlabel="Transfer ownership"
      denyLabel="Not now"
      visible={showModal}
      className="transfer-ownership-modal">
      {status === 'ACTIVE' && (
        <>
          <p>
            Space owners have access to space settings and are responsible for handling refund and cancelation requests.
          </p>
          <strong>Are you sure you want to replace the existing space owner with {fullName}?</strong>
        </>
      )}

      {status === 'PENDING' && (
        <>
          <p>
            Space owners have access to space settings and are responsible for handling refund and cancelation requests.
            Are you sure you want to transfer ownership to {fullName}?
          </p>
          <Alert
            type="new"
            icon="warning_solid"
            content={`${
              currentOwner.id === currentUser?.id ? "You'll" : `${currentOwner.fullName} will`
            } be the owner until this person accepts ownership`}
          />
        </>
      )}
    </Modal>
  );
};

export default TransferOwnershipModal;
