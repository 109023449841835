import React, { FC, useEffect, useRef } from 'react';
import { PaymentRequest, Stripe } from '@stripe/stripe-js';

interface CustomPaymentRequestButtonProps {
  paymentRequest: PaymentRequest;
  stripe: Stripe;
}

const CustomPaymentRequestButton: FC<CustomPaymentRequestButtonProps> = ({ paymentRequest, stripe }) => {
  const buttonContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const elements = stripe.elements();
    const buttonElement = elements.create('paymentRequestButton', { paymentRequest });
    paymentRequest
      .canMakePayment()
      .then(result => {
        if (!result || !buttonContainer.current) {
          return;
        }
        buttonElement.mount(buttonContainer.current);
      })
      .catch(() => null);
    return () => buttonElement && buttonElement.destroy();
  }, [buttonContainer, stripe, paymentRequest]);

  return <div ref={buttonContainer} className="payment-request-button" />;
};

export default CustomPaymentRequestButton;
