import React, { FC, useRef, useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, ApolloError } from '@apollo/client';
import clsx from 'clsx';
import { isKYCd } from 'utils';
import * as AuthQuery from 'graphql/auth.graphql';
import * as OrganizationQuery from 'graphql/organization.graphql';
import * as MemberQuery from 'graphql/member.graphql';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useSpacesFromCurrentOrg from 'hooks/useSpacesFromCurrentOrg';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import DropDownItemDivider from 'components/Menu/DropDownItemDivider';
import InviteMembers, { SpacesType } from './InviteMembers';
import Message from 'components/Message';
import Modal from 'components/Modal';
import OrganizationRole from './OrganizationRole';
import SpaceMemberships, { SpaceMembershipsType } from './SpaceMemberships';
import Tag from 'components/Tag';
import { OrganizationMembership, Membership } from 'api/data/user/types';
import { MessageType } from 'components/Message';
export type MapType = Record<string, string>;

const ERROR_MESSAGE: MapType = {
  MEMBER_IS_A_SPACE_OWNER: 'Space owners cannot be removed from the organization.',
  CANNOT_REMOVE_LAST_OWNER: 'This member is the last active owner of the organization. Cannot be removed.',
  CANNOT_UPDATE_ROLE_OF_LAST_OWNER: 'This member is the last active owner of the organization. Cannot be updated.',
};

const OrganizationMembers: FC = () => {
  const { data, refetch } = useQuery<OrganizationMembership>(OrganizationQuery.GetMembershipsFromOrganization, {
    fetchPolicy: 'cache-and-network',
  });
  const { currentOrg } = useCurrentOrganization();
  const { spaces, loading: loadingSpaces } = useSpacesFromCurrentOrg();
  const [removeError, setRemoveError] = useState('');
  const [inviteMembersMutation] = useMutation(OrganizationQuery.InviteMembers, {
    refetchQueries: [{ query: OrganizationQuery.GetOrganization }],
  });
  const [removeMember, { loading: loadingRemoveMember }] = useMutation(OrganizationQuery.RemoveMembership, {
    refetchQueries: [{ query: OrganizationQuery.GetOrganization }],
  });
  const [resendMembershipInvite, { loading: loadingResendInvite }] = useMutation(
    OrganizationQuery.ResendMembershipInvite,
  );
  const [forgotPassword, { loading: forgotPasswordLoading }] = useMutation(AuthQuery.ForgotPassword);
  const [updateRole] = useMutation(MemberQuery.UpdateRole);
  const settingsRef = useRef<HTMLElement>(null);
  const [toastContent, setToastContent] = useState({
    showMessage: false,
    message: '',
    type: 'succeeded' as MessageType | undefined,
  });
  const [showInviteMembers, setShowInviteMembers] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState({ showModal: false, memberEmail: '' });
  const [removeMemberModal, setRemoveMemberModal] = useState<{ showModal: boolean; member?: Membership }>({
    showModal: false,
    member: undefined,
  });

  const organizationMembers = useMemo(() => {
    return (data?.organization.memberships || []).filter(membership => membership.status !== 'INACTIVE');
  }, [data?.organization.memberships]);

  useEffect(() => {
    return function cleanup() {
      setToastContent({ message: '', showMessage: false, type: undefined });
    };
  }, []);

  const inactivateMember = async (memberId?: string) => {
    try {
      await removeMember({ variables: { id: removeMemberModal.member?.id || memberId, type: 'organization' } });
      setRemoveMemberModal({ showModal: false, member: undefined });
      setToastContent({
        showMessage: true,
        message: 'Member has been removed!',
        type: 'succeeded',
      });
      void refetch();
    } catch (error) {
      if (error) setRemoveError((error as ApolloError)?.message);
    }
  };

  const resendInvite = async (memberId: string) => {
    await resendMembershipInvite({ variables: { membershipId: memberId } });
    setToastContent({ showMessage: true, message: 'The invitation has been resent!', type: 'succeeded' });
  };

  const handleResetPassword = async () => {
    await forgotPassword({ variables: { email: resetPasswordModal.memberEmail } });
    setResetPasswordModal({ showModal: false, memberEmail: '' });
    setToastContent({ showMessage: true, message: 'Password reset email successfully sent', type: 'succeeded' });
  };

  const spaceMemberships = useMemo(() => {
    const result: { [key: string]: SpaceMembershipsType[] } = {};

    spaces?.forEach(space => {
      space.memberships?.forEach(member => {
        if (!result[member.user.id]) result[member.user.id] = [];
        result[member.user.id].push({
          id: space.id,
          name: space.name,
          role: member.role,
          membershipId: member.id,
        });
      });
    });

    return result;
  }, [spaces]);

  const updateMemberRole = async (role: string, membership: Membership) => {
    try {
      await updateRole({
        variables: { membership: { role, id: membership.id } },
        optimisticResponse: {
          updateMemberRole: {
            ...membership,
            role,
          },
        },
      });
    } catch (err) {
      if (err) {
        setToastContent({ message: ERROR_MESSAGE[(err as ApolloError)?.message], type: 'failed', showMessage: true });
      }
    }
  };

  const inviteMembership = async (space: SpacesType, member: Membership) => {
    await inviteMembersMutation({
      variables: {
        invites: {
          email: member.user.email,
          fullName: member.user.fullName,
          role: member.role,
          spaces: [space],
        },
      },
    });
  };

  const removeMembership = async (spaceMembershipId: string) => {
    await removeMember({ variables: { id: spaceMembershipId, type: 'space' } });
    void refetch();
  };

  return (
    <>
      <InviteMembers show={showInviteMembers} closeInviteMembers={() => setShowInviteMembers(false)} />
      <Message
        type={toastContent.type}
        className="settings-message"
        showMessage={toastContent.showMessage}
        setShowMessage={visible =>
          setToastContent({ ...toastContent, showMessage: visible, message: '', type: undefined })
        }>
        {toastContent.message}
      </Message>
      <Modal
        handleOnCancel={() => setResetPasswordModal({ showModal: false, memberEmail: '' })}
        handleOnConfirm={() => void handleResetPassword()}
        confirmlabel={forgotPasswordLoading ? 'Sending...' : 'Send password reset email'}
        denyLabel="Not now"
        header="Send Password Reset Email"
        headerIcon="warning"
        className="reset-password-modal"
        visible={resetPasswordModal.showModal}
        confirmButtonProps={{ disabled: forgotPasswordLoading }}>
        Would you like us to email this person a link that will allow them to reset their password?
      </Modal>
      <Modal
        handleOnCancel={() => {
          setRemoveMemberModal({ showModal: false, member: undefined });
          setRemoveError('');
        }}
        handleOnConfirm={() => void inactivateMember()}
        confirmlabel={loadingRemoveMember ? 'Removing...' : 'Remove'}
        denyLabel="Not now"
        header="Remove this person?"
        headerIcon="delete"
        className="remove-member-modal"
        visible={removeMemberModal.showModal}
        confirmButtonProps={{ disabled: loadingRemoveMember }}>
        Are you sure you want remove {removeMemberModal.member?.user.fullName} from your organization?
        {!!removeError && !!ERROR_MESSAGE[removeError] && <p className="error-message">{ERROR_MESSAGE[removeError]}</p>}
      </Modal>
      <div className="title-header-container">
        <b>
          {organizationMembers?.length} {organizationMembers?.length > 1 ? 'people' : 'person'}
        </b>
        {!showInviteMembers && (
          <button
            className={clsx('button-size-m', {
              'banner-space': !isKYCd(currentOrg?.status),
            })}
            onClick={() => setShowInviteMembers(true)}>
            Add
          </button>
        )}
      </div>
      <div className="table organization-member">
        <header>
          <div className="col">Person</div>
          <div className="col">Role</div>
          <div className="col memberships">Space Memberships</div>
          <div className="col dropdown"></div>
        </header>
        <div className="table-body">
          {organizationMembers?.map(member => {
            const statusIsPending = member.status === 'PENDING';
            return (
              <div key={member.id} className="table-row">
                <div className="col full-name">
                  <div>
                    <strong>{member.user.fullName}</strong>
                    {statusIsPending && <Tag label="Invited" type="pending" size="small" />}
                  </div>
                  <span>{member.user.email}</span>
                </div>
                <div className="col role">
                  <OrganizationRole
                    value={member.role}
                    onChange={role => {
                      void updateMemberRole(role, member);
                    }}
                  />
                </div>
                <div className="col memberships">
                  {!loadingSpaces && (
                    <SpaceMemberships
                      spaceMemberships={spaceMemberships[member.user.id]}
                      onChange={space => void inviteMembership(space, member)}
                      onRemove={spaceMembershipId => void removeMembership(spaceMembershipId)}
                    />
                  )}
                </div>
                <div className="col dropdown">
                  {member.status !== 'INACTIVE' && (
                    <DropDown outsideRef={settingsRef} openedIcon="menu" closedIcon="menu_open">
                      {statusIsPending && (
                        <DropDownItem
                          icon="resend"
                          onClick={() => void resendInvite(member.id)}
                          disabled={loadingResendInvite}>
                          Resend Invite
                        </DropDownItem>
                      )}
                      {member.status === 'ACTIVE' && (
                        <DropDownItem
                          icon="envelope"
                          onClick={() => setResetPasswordModal({ showModal: true, memberEmail: member.user.email })}>
                          Send password reset
                        </DropDownItem>
                      )}
                      <DropDownItemDivider />
                      <DropDownItem
                        icon={statusIsPending ? 'cancel' : 'delete'}
                        onClick={() =>
                          statusIsPending
                            ? void inactivateMember(member.id)
                            : setRemoveMemberModal({ showModal: true, member })
                        }
                        disabled={loadingRemoveMember}>
                        {statusIsPending ? 'Cancel Invitation' : 'Remove'}
                      </DropDownItem>
                    </DropDown>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OrganizationMembers;
