import React, { FC } from 'react';
import Meld from './Meld';
import Stripe from './Stripe';
import { ACHProps } from './types';
import './style.scss';

export const termsPrivacyDescription =
  'Omella verifies your bank account details and accesses your balances to make sure you have sufficient funds before initiating an ACH transaction.';

const ACH: FC<ACHProps> = ({ provider, ...props }) => {
  if (provider === 'stripe') return <Stripe {...props} />;

  return <Meld {...props} />;
};

export default ACH;
