import React, { FC } from 'react';
import failureCard from 'assets/failure_card.svg';

const PaymentError: FC<{ title?: string; errorMessage: string }> = ({ title = 'Failed to pay', errorMessage }) => {
  return (
    <div className="failure-message">
      <img src={failureCard} alt="Failure card" />
      <div>
        <h6>{title} </h6>
        <span>{errorMessage}</span>
      </div>
    </div>
  );
};

export default PaymentError;
