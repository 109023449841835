import React, { FC } from 'react';
import { useLocation } from 'wouter';
import teamEmptyState from 'assets/team-empty-state.svg';
import './style.scss';

type TeamEmptyStateProps = {
  p2pEnabled?: boolean;
};

const TeamEmptyState: FC<TeamEmptyStateProps> = ({ p2pEnabled }) => {
  const [, setLocation] = useLocation();

  const addParticipant = () => {
    const location = `team/${p2pEnabled ? 'invite' : 'enable'}`;
    setLocation(location);
  };

  return (
    <div className="team-empty-content">
      <img src={teamEmptyState} alt="Supporter list empty" />
      <p className="subtitle-small">Win as a Team</p>
      <p className="paragraph-xxx-small">
        Also known as <strong>Team</strong> or <strong>Peer-to-Peer Fundraising</strong>, add participants to raise more
        and generate friendly competition with member pages that showcase their goals and personal stories.
      </p>
      <button className="button primary" onClick={() => addParticipant()}>
        Add Participants
      </button>
    </div>
  );
};

export default TeamEmptyState;
