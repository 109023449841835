import React, { useState, FC, useRef, useCallback } from 'react';
import { ItemProvider } from './Item';
import { rotateItems, getTransformAmount, getCurrent, initItems, getShowArrow, cleanItems } from './helpers';
import { defaultProps } from './defaultProps';
import { CarouselProps, SlideDirection } from './types';
import arrowLeft from 'assets/carousel_left.svg';
import arrowRight from 'assets/carousel_right.svg';
import './style.scss';

interface ArrowProps {
  onClick?: () => void;
  direction: string;
}

const Arrow: FC<ArrowProps> = ({ onClick, direction }: ArrowProps) => (
  <button className={`arrow-control ${direction}`} onClick={onClick} data-direction={direction}>
    <img src={direction === 'left' ? arrowLeft : arrowRight} alt={`arrow ${direction}`} />
  </button>
);

export const Carousel: FC<CarouselProps> = (userProps: CarouselProps) => {
  const props: Required<CarouselProps> = { ...defaultProps, ...userProps };
  const initialItems = initItems(props.children, props.slide, props.infinite);
  const [items, setItems] = useState(initialItems);
  const itemsRef = useRef(initialItems);
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(0);
  const [animation, setAnimation] = useState({
    transform: 0,
    transition: 0,
    isSliding: false,
  });
  const [current, setCurrent] = useState(0);
  const [showArrow, setShowArrow] = useState(
    getShowArrow({
      itemCount: props.children.length,
      itemsToShow: props.show,
      infinite: props.infinite,
      current,
      hideArrows: props.hideArrows,
    }),
  );
  const isPaginating = useRef(false);

  const slide = (direction: SlideDirection, target?: number) => {
    if (
      animation.isSliding ||
      (direction === SlideDirection.Right && !showArrow.right) ||
      (direction === SlideDirection.Left && !showArrow.left)
    ) {
      return;
    }

    const elements = props.children;

    const next = getCurrent(current, props.slide, elements.length, direction);

    const slideAmount = typeof target === 'number' ? target - current : -1 * direction;

    const rotated = props.infinite ? rotateItems(elements, items, next, props.show, props.slide, direction) : items;

    if (props.infinite && direction === SlideDirection.Right) {
      setItems(rotated);
      itemsRef.current = rotated;
    }

    setAnimation({
      transform: animation.transform + Math.abs(slideAmount) * getTransformAmount(width, props.slide, direction),
      transition: props.transition,
      isSliding: true,
    });

    setCurrent(typeof target === 'number' ? target : next);
    setShowArrow(
      getShowArrow({
        itemCount: elements.length,
        itemsToShow: props.show,
        infinite: props.infinite,
        current: next,
        hideArrows: props.hideArrows,
      }),
    );

    setTimeout(() => {
      if (props.infinite) {
        const cleanedItems = cleanItems(
          direction === SlideDirection.Right ? itemsRef.current : rotated,
          props.slide,
          direction,
        );

        setItems(cleanedItems);
        itemsRef.current = cleanedItems;
      }
      setAnimation({
        transform: props.infinite
          ? getTransformAmount(width, props.slide, SlideDirection.Right)
          : animation.transform + getTransformAmount(width, props.slide, direction),
        transition: 0,
        isSliding: false,
      });
    }, props.transition * 1_0_0_0);
    isPaginating.current = false;
  };

  const widthCallBack = useCallback(
    (calculatedWidth: number) => {
      setWidth(calculatedWidth);
      setAnimation({
        transform: props.infinite ? getTransformAmount(calculatedWidth, props.slide, SlideDirection.Right) : 0,
        transition: 0,
        isSliding: false,
      });
    },
    [props.infinite, props.slide],
  );

  const onLeftArrowClick = () => {
    slide(SlideDirection.Left);
  };

  const onRightArrowClick = () => {
    slide(SlideDirection.Right);
  };

  return (
    <div className={`carousel-base ${props.className}`} ref={containerRef}>
      {showArrow.left && <Arrow direction="left" onClick={onLeftArrowClick} />}
      <ItemProvider
        {...props}
        transition={animation.transition}
        items={itemsRef.current}
        transform={animation.transform}
        widthCallBack={widthCallBack}
      />
      {showArrow.right && <Arrow direction="right" onClick={onRightArrowClick} />}
    </div>
  );
};
