import React, { FC, useState, useContext } from 'react';
import clsx from 'clsx';
import { ApolloError, OperationVariables } from '@apollo/client';
import dayjs from 'dayjs';
import UserRolePerspective from 'contexts/UserRolePerspective';
import { availableAmountCents } from 'api/data/transaction';
import { apolloErrorFormatter, currencyFormatter, subscriptionEnded } from 'utils';
import useCancelSubscriptionValues from './useCancelSubscriptionValues';
import useRefundValues from 'components/RefundModal/useRefundValues';
import useUserSession from 'hooks/useUserSession';
import Message from 'components/Message';
import Modal from 'components/Modal';
import { RefundTooltip, showRefund } from 'components/RefundModal';
import TextArea from '../Input/TextArea';
import { Transaction } from 'api/data/transaction/types';
import { Subscription } from 'api/data/subscription/types';
import './style.scss';

interface CancelSubscriptionModalProps {
  showButton: boolean;
  subscription?: Subscription;
  pageId?: string;
  responseId?: string;
  lastTransaction?: Transaction;
  userId?: string;
}

const CancelSubscriptionModal: FC<CancelSubscriptionModalProps> = ({
  subscription,
  lastTransaction,
  pageId,
  responseId,
  showButton,
  userId,
}) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const { data } = useUserSession();
  const [showModal, setShowModal] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [refundLastTransaction, setRefundLastTransaction] = useState(false);
  const [requestOrCancelSubscriptions, cancelSubscriptionLoading, labels] = useCancelSubscriptionValues(
    isPayerView ? 'PAYER' : 'ORGANIZATION',
    userId,
    pageId,
    responseId,
  );
  const [refund, refundLoading] = useRefundValues('ORGANIZATION', data?.session.currentUser?.id, pageId, responseId);
  const [refundError, setRefundError] = useState('');
  const [cancelationReason, setCancelationReason] = useState('');

  const transactionAvailableAmount = availableAmountCents(lastTransaction);

  const handleCancelSubscription = async () => {
    setRefundError('');

    try {
      let variables: OperationVariables = { id: subscription?.id };

      if (isPayerView)
        variables = {
          payerRequest: {
            cancelationReason,
            subscriptionId: subscription?.id,
            requestingUserId: isPayerView ? data?.session.currentUser?.id : undefined,
            amountCents: refundLastTransaction ? transactionAvailableAmount : 0,
            transactionId: refundLastTransaction ? lastTransaction?.id : undefined,
          },
        };

      await requestOrCancelSubscriptions({ variables });

      if (refundLastTransaction && !isPayerView)
        await refund({
          variables: {
            refund: {
              transactionId: lastTransaction?.id,
              amountCents: transactionAvailableAmount,
            },
          },
        });
      setShowMessage(true);
      setShowModal(false);
    } catch (error) {
      setRefundError(apolloErrorFormatter(error as ApolloError));
    }
  };

  const onLoading = cancelSubscriptionLoading || refundLoading;
  return (
    <>
      {showButton && (
        <button
          className="button-link secondary button-size-sm"
          onClick={event => {
            event.stopPropagation();
            setShowModal(true);
          }}>
          {labels.buttonText}
        </button>
      )}
      <Message
        type="succeeded"
        className={clsx('subscription-canceled', { refund: refundLastTransaction && !isPayerView })}
        showMessage={showMessage}
        setShowMessage={setShowMessage}>
        {labels.successfulMessage(refundLastTransaction)}
      </Message>
      <Modal
        header={labels.header}
        headerIcon="calendar_cancel"
        handleOnCancel={() => setShowModal(false)}
        handleOnConfirm={() => void handleCancelSubscription()}
        confirmlabel={onLoading ? 'Processing...' : labels.buttonConfirmation}
        denyLabel="Not now"
        visible={showModal}
        className="cancel-subscription-modal"
        confirmButtonProps={{ disabled: onLoading || (isPayerView && !cancelationReason) }}
        fullScreenMobile>
        <div>
          <p>
            {labels.content(
              subscription?.recurring?.toLocaleLowerCase() || '',
              dayjs(lastTransaction?.processedAt).format('MMM DD, YY'),
              subscription?.organization?.name || '',
              lastTransaction?.status === 'SUCCEEDED',
            )}
          </p>
          {isPayerView && (
            <TextArea
              label="Reason"
              name="cancelation-reason"
              onChange={({ target }) => setCancelationReason(target.value)}
              value={cancelationReason}
              rules={{ required: true }}
            />
          )}
          {showRefund(
            lastTransaction?.status?.toLowerCase() || '',
            transactionAvailableAmount,
            lastTransaction?.processedAt,
          ) && (
            <div className="refund">
              <input
                type="checkbox"
                id="refund"
                checked={refundLastTransaction}
                onChange={({ target }) => setRefundLastTransaction(target.checked)}
              />
              <label htmlFor="refund">
                {labels.refund} {dayjs(lastTransaction?.processedAt).format('MMM DD')} of{' '}
                {currencyFormatter(transactionAvailableAmount)}
                {!isPayerView && <RefundTooltip />}
              </label>
            </div>
          )}
          <p className="error-message">{refundError}</p>
        </div>
      </Modal>
    </>
  );
};

export default CancelSubscriptionModal;

export const showCancelationButton = (subscription: Subscription) => {
  return !subscriptionEnded(subscription) && !subscription.hasPendingPayerRequest;
};
