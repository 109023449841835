import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import Appsignal from '@appsignal/javascript';
import { ErrorBoundary } from '@appsignal/react';
import generateClient from './api/client';
import { APP_CONFIG } from './api/config';
import App from 'App';
import 'react-datepicker/dist/react-datepicker.css';
import 'index.scss';

window.Appsignal = new Appsignal({
  key: APP_CONFIG.APP_SIGNAL,
});

const Wrapper = () => (
  <ErrorBoundary instance={window.Appsignal}>
    <React.StrictMode>
      <ApolloProvider client={generateClient(APP_CONFIG.ENDPOINT, APP_CONFIG.WEB_SOCKET)}>
        <App />
      </ApolloProvider>
    </React.StrictMode>
  </ErrorBoundary>
);

ReactDOM.render(<Wrapper />, document.getElementById('root'));
