import React, { FC } from 'react';
import clsx from 'clsx';
import './style.scss';

interface AvatarProps {
  text: string;
  className?: string;
  size?: 'small' | 'normal';
}

const Avatar: FC<AvatarProps> = ({ text, className, size = 'normal' }) => {
  return <span className={clsx('avatar', size, className)}>{getInitials(text)}</span>;
};

const getInitials = (text: string) => {
  const words = text.trim().split(' ');

  if (words.length === 1) return words[0].substring(0, 2).toUpperCase();

  const initials = words.map(word => word[0]?.toUpperCase()).join('');

  return initials.substring(0, 2);
};

export default Avatar;
