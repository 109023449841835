import React, { FC, useState, useRef, useEffect } from 'react';
import clsx from 'clsx';
import useOutsideClick from 'hooks/useOutsideClick';
import { addChoice, removeChoice, updateQuestionBlock } from 'api/data/pages/blocks';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import Modal from 'components/Modal';
import ToggleSwitch from 'components/ToggleSwitch';
import ChoicesBlock from './ChoicesBlock';
import FileUpload from './FileUpload';
import LongAnswer from './LongAnswer';
import QuestionPayerView from './PayerView';
import ShortAnswer from './ShortAnswer';
import Signature from './Signature';
import questionConfig from './questionConfig';
import { QuestionBlockMainProps } from './types';
import './style.scss';

const Question: FC<QuestionBlockMainProps> = ({
  data,
  isFocused,
  refOutside,
  group,
  handleDelete,
  updateBlock,
  disableOutsideClick = false,
}) => {
  const [isEditing, setIsEditing] = useState<boolean>(!!isFocused);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [focusElement, setFocusElement] = useState<string>();
  const isSignatureBlock = data.questionType === 'SIGNATURE';

  const questionRef = useRef<HTMLElement>(null);
  const elementsRefs = useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});

  useEffect(() => {
    if (isEditing && focusElement && elementsRefs && elementsRefs.current[focusElement]) {
      elementsRefs?.current[focusElement]?.focus();
    }
  }, [isEditing, focusElement, elementsRefs]);

  useOutsideClick(questionRef, refOutside, () => {
    if (isEditing && !disableOutsideClick) setIsEditing(false);
  });

  const handleAddChoice = () => {
    const block = addChoice(data);
    updateBlock(block);
  };

  const handleRemoveChoice = (choiceIdx: number) => {
    const block = removeChoice(data, choiceIdx);
    updateBlock(block);
  };

  const handleOnChange = (value: string | number | boolean | string[], field: string, choiceIdx?: number) => {
    const block = updateQuestionBlock(data, value, field, choiceIdx);
    updateBlock(block);
  };

  const blockComponents = {
    FILE_UPLOAD: <FileUpload data={data} placeholder="Recipient will upload a file here" readOnly />,
    SHORT_ANSWER: <ShortAnswer data={data} placeholder="Recipient will type their answer here" readOnly />,
    LONG_ANSWER: <LongAnswer data={data} placeholder="Recipient will type their answer here" readOnly />,
    SINGLE_CHOICE: (
      <ChoicesBlock
        data={data}
        handleOnChange={handleOnChange}
        addChoice={handleAddChoice}
        removeChoice={handleRemoveChoice}
        focusElement={focusElement}
      />
    ),
    MULTIPLE_CHOICE: (
      <ChoicesBlock
        data={data}
        handleOnChange={handleOnChange}
        addChoice={handleAddChoice}
        removeChoice={handleRemoveChoice}
        focusElement={focusElement}
      />
    ),
    SIGNATURE: <Signature data={data} placeholder="John Hancock" readOnly />,
  };

  return (
    <section
      className={clsx({ 'block-container': !group?.id })}
      role="presentation"
      ref={questionRef}
      onClick={ev => {
        ev.stopPropagation();
        const target = ev.target as HTMLElement;

        if (!isEditing) {
          setIsEditing(true);
          setFocusElement(target.getAttribute('data-id') || '');
        }
      }}>
      <Modal
        handleOnCancel={() => setShowDeleteModal(false)}
        handleOnConfirm={handleDelete}
        confirmlabel="Delete"
        denyLabel="Cancel"
        visible={showDeleteModal}
        header={`Delete this ${questionConfig[data.questionType]?.name} Block?`}
      />
      {isEditing ? (
        <div className={clsx('block question', { inner: !!group?.id })}>
          <div className="block-header row space-between">
            <div className="row align-center">
              <img src={questionConfig[data.questionType]?.icon} alt={questionConfig[data.questionType]?.title} />
              <span className="size-xxs">{questionConfig[data.questionType]?.title}</span>
            </div>
            <div className="row align-center">
              <ToggleSwitch
                label="Required"
                toggleValue={data.required}
                handleOnChange={() => handleOnChange(!data.required, 'required')}
              />
              <span className="vertical-line"></span>
              <DropDown outsideRef={questionRef}>
                <DropDownItem icon="delete" onClick={() => setShowDeleteModal(true)}>
                  Delete
                </DropDownItem>
              </DropDown>
            </div>
          </div>
          <div className="block-content">
            <div className="default-question edit-view">
              <input
                placeholder={isSignatureBlock ? 'By signing below…' : 'Type your question here.'}
                onChange={({ target }) => handleOnChange(target.value, target.name)}
                value={data.questionPrompt || ''}
                className="block-title"
                name="questionPrompt"
                type="text"
                aria-label="question"
                ref={element => (elementsRefs.current[`question_title_${data.id}`] = element)}
                maxLength={isSignatureBlock ? 1000 : 255}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!data.questionPrompt}
              />
              {!isSignatureBlock && (
                <input
                  placeholder="Description (optional)"
                  onChange={({ target }) => handleOnChange(target.value, target.name)}
                  value={data.description || ''}
                  className="description"
                  name="description"
                  type="text"
                  aria-label="description"
                  ref={element => (elementsRefs.current[`question_description_${data.id}`] = element)}
                  maxLength={1000}
                />
              )}
            </div>
            {blockComponents[data.questionType]}
          </div>
        </div>
      ) : (
        <QuestionPayerView data={data} group={group} readOnly />
      )}
    </section>
  );
};

export default Question;
