import React, { FC, useState, useEffect, useCallback } from 'react';
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { CanMakePaymentResult, PaymentRequest, PaymentRequestPaymentMethodEvent, Stripe } from '@stripe/stripe-js';
import { processPayment } from 'api/data/payment';
import CustomPaymentRequestButton from './CustomPaymentRequestButton';
import { PaymentMethodType } from 'api/data/payment/types';
import { ProviderProps } from '../types';

const StripeWallet: FC<ProviderProps> = ({
  paymentMethod,
  pageTitle,
  handleOnChange,
  stripe,
  paymentProcessing,
  setWalletType,
  redirectAfterPayment,
  response,
  children,
}) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [loading, setLoading] = useState<boolean>(false);
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null);

  const handleSubmit = useCallback(
    async (event: PaymentRequestPaymentMethodEvent) => {
      try {
        setLoading(true);
        paymentProcessing(true);
        const newResponse = {
          ...response,
          user: {
            email: event.payerEmail || '',
            zipcode: event.shippingAddress?.postalCode || '',
            fullName: event.payerName || '',
          },
        };

        const { succeeded } = await processPayment(
          PaymentMethodType.WALLET,
          stripe || null,
          client,
          newResponse,
          event,
        );

        event.complete('success');
        redirectAfterPayment(succeeded, newResponse.id || '');
      } catch {
        setLoading(false);
        paymentProcessing(false);
        event.complete('fail');
      }
    },
    [stripe, client, response, paymentProcessing, redirectAfterPayment],
  );

  useEffect(() => {
    const setWallet = (result: CanMakePaymentResult) => {
      if (result.applePay) {
        setWalletType('apple');
        return;
      }

      if (result.googlePay) {
        setWalletType('google');
        return;
      }

      if (result.link) {
        setWalletType('link');
        return;
      }
    };

    if (paymentMethod === 'wallet' && stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: pageTitle,
          amount: response.order.totalCents,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment()
        .then(result => {
          if (result) {
            pr.on('paymentmethod', async ev => {
              await handleSubmit(ev);
            });
            setPaymentRequest(pr);
            setWallet(result);
          } else {
            handleOnChange('card');
          }
        })
        .catch(() => null);
    }
  }, [stripe, response.order.totalCents, pageTitle, handleOnChange, handleSubmit, paymentMethod, setWalletType]);

  const walletButton = (paymentRequest: PaymentRequest, stripe: Stripe, loading: boolean) => {
    return loading ? (
      <div className="payment-request-button">
        <button disabled className="processing-button">
          Processing...
        </button>
      </div>
    ) : (
      <CustomPaymentRequestButton paymentRequest={paymentRequest} stripe={stripe} />
    );
  };

  if (paymentRequest && stripe) {
    return (
      <div className="block">
        {children}
        {paymentMethod === 'wallet' && walletButton(paymentRequest, stripe, loading)}
      </div>
    );
  }

  return null;
};

export default StripeWallet;
