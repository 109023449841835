import React, { FC, useRef, useState } from 'react';
import uploadFile from 'api/uploadClient';
import UploadImage from 'components/UploadImage';
import { SpaceProps } from './types';
import pageCover from 'assets/page_cover.png';
import pageLogo from 'assets/page_logo.png';

const Branding: FC<SpaceProps> = ({ spaceData, setSpaceData }) => {
  const [imageUploading, setImageUploading] = useState('');

  const coverInput = useRef<HTMLInputElement>(null);
  const logoInput = useRef<HTMLInputElement>(null);

  const handleOnRemoveImage = (attr: string) => {
    setSpaceData({ ...spaceData, [attr]: '', [`${attr}Url`]: '' });
  };

  const handleOnUploadImage = async (file: File, attr: string) => {
    const { url, file_handle } = await uploadFile(file, 'IMAGE', attr);
    setSpaceData({ ...spaceData, [attr]: file_handle, [`${attr}Url`]: url });
  };

  return (
    <div className="branding">
      <h1>Branding</h1>
      <p>
        The default logo and cover image below will apply to all newly created pages within this space. The recommended
        size for the logo is 180 x 180 and for the cover is 1440 x 312.
      </p>
      <div className="img-header">
        <UploadImage
          image={{
            name: 'coverPicture',
            label: 'cover',
            file: spaceData.coverPictureUrl || pageCover,
            ref: coverInput,
            imageIsEmpty: !spaceData.coverPicture,
          }}
          imageUploading={imageUploading}
          handleOnRemoveImage={handleOnRemoveImage}
          handleOnUploadImage={handleOnUploadImage}
          setImageUploading={setImageUploading}
        />
        <UploadImage
          image={{
            name: 'logo',
            label: 'logo',
            file: spaceData.logoUrl || pageLogo,
            ref: logoInput,
            imageIsEmpty: !spaceData.logo,
          }}
          imageUploading={imageUploading}
          handleOnRemoveImage={handleOnRemoveImage}
          handleOnUploadImage={handleOnUploadImage}
          setImageUploading={setImageUploading}
        />
      </div>
    </div>
  );
};

export default Branding;
