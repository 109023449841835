import React, { FC } from 'react';
import clsx from 'clsx';
import { default as ReactDatePicker, ReactDatePickerProps } from 'react-datepicker';
import Wrapper, { WrapperProps } from './Wrapper';

type DatePickerProps = {
  pickerClass?: string;
} & ReactDatePickerProps &
  WrapperProps;

const DatePicker: FC<DatePickerProps> = ({
  name,
  label,
  className,
  hint,
  rules,
  labelTooltip,
  pickerClass,
  ...datePickerProps
}) => {
  return (
    <Wrapper
      label={label}
      name={name}
      className={clsx('date-picker', className)}
      hint={hint}
      rules={rules}
      labelTooltip={labelTooltip}>
      <ReactDatePicker {...datePickerProps} className={pickerClass} />
    </Wrapper>
  );
};

export default DatePicker;
