import React, { ChangeEventHandler, ReactElement, useRef, useEffect, KeyboardEventHandler } from 'react';

interface Props {
  className?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onKeyDown?: KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  ariaLabel?: string;
  value?: string | number | readonly string[];
  autoFocus?: boolean;
  disabled?: boolean;
}

const ResizableTextArea = ({
  className,
  placeholder,
  ariaLabel,
  onChange,
  onKeyDown,
  value,
  autoFocus,
  disabled,
}: Props): ReactElement => {
  const textAreaEl = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textAreaEl && textAreaEl.current) {
      const el = textAreaEl.current;
      el.style.removeProperty('height');
      el.style.height = `${el.scrollHeight + 2}px`;

      if (autoFocus) textAreaEl.current.focus();
    }
  }, [autoFocus, value]);

  return (
    <textarea
      ref={textAreaEl}
      className={className}
      rows={1}
      aria-label={ariaLabel}
      value={value}
      maxLength={255}
      placeholder={placeholder}
      onChange={onChange}
      onKeyDown={onKeyDown}
      disabled={disabled}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={autoFocus}></textarea>
  );
};

export default ResizableTextArea;
