import React, { FC, useState } from 'react';
import { RouteComponentProps, useLocation } from 'wouter';
import { useQuery } from '@apollo/client';
import clsx from 'clsx';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { isKYCd } from 'utils';
import useUserSession from '~/hooks/useUserSession';
import * as PageQuery from 'graphql/page.graphql';
import LoggedPageContainer from 'components/LoggedPageContainer';
import Header from 'pages/MyPage/Header';
import AddByEmail from './AddByEmail';
import AddByQrCode from './AddByQrCode';
import ICONS from 'components/Icons';
import ShareByLink from 'components/ShareByLink';
import { PageView } from 'api/data/pages/types';
import './style.scss';

export type PageParams = { id: string };

const InviteParticipant: FC<RouteComponentProps<PageParams>> = ({ params }) => {
  const [, setLocation] = useLocation();
  const [section, setSection] = useState<string>('index');

  const { data: pageData, loading: pageLoading } = useQuery<PageView>(PageQuery.GetPage, {
    variables: { id: params.id },
    skip: !params.id,
  });

  const { data: userData } = useUserSession();

  if (!pageData || pageLoading) return null;

  const { page } = pageData;
  const spaceSlug = currentSpaceSlug() || '';

  const p2pTitle = `${page?.p2pOrganizationName || page?.organization?.name} ${page?.p2pProgramName || ''}`.trim();

  const content: {
    [key: string]: JSX.Element;
  } = {
    index: (
      <>
        <button className="button-size-m card row space-between" onClick={() => setSection('email')}>
          <span className="row align-center">{ICONS['envelope']} Email</span>
          {ICONS['arrow_right']}
        </button>
        <button className="button-size-m card row space-between" onClick={() => setSection('link')}>
          <span className="row align-center">{ICONS['link']} Link</span>
          {ICONS['arrow_right']}
        </button>
        <button className="button-size-m card row space-between" onClick={() => setSection('qrCode')}>
          <span className="row align-center">{ICONS['qr_code']} QR Code</span>
          {ICONS['arrow_right']}
        </button>
      </>
    ),
    email: (
      <AddByEmail
        pageId={page.id}
        location={`/${spaceSlug}/pages/${page.slug}/team`}
        referToMeAs={page.p2pReferToMeAs}
      />
    ),
    link: (
      <ShareByLink
        params={`${page.slug}/p/signup`}
        shareType="addParticipant"
        title={p2pTitle}
        p2pReferToMeAs={page.p2pReferToMeAs || userData?.session.currentUser?.fullName || ''}
      />
    ),
    qrCode: <AddByQrCode slug={page.slug} title={page.title} />,
  };

  const title: {
    [key: string]: string;
  } = {
    email: 'Add by Email',
    index: 'Add Participants',
    link: 'Add by link',
    qrCode: 'Add by QR Code',
  };

  const goBack = () => {
    if (section === 'index') {
      setLocation(`/${spaceSlug}/pages/${page.slug}/team`);
      return;
    }

    setSection('index');
  };

  return (
    <LoggedPageContainer
      className="my-page-default invite-participant"
      showSideBar={false}
      header={<Header title={title[section]} linkBack="" onClickBack={goBack} showLogo={false} />}>
      <div
        className={clsx('max-width-desktop', {
          'banner-space': !!page?.organization?.status && !isKYCd(page.organization.status),
        })}>
        {content[section]}
      </div>
    </LoggedPageContainer>
  );
};

export default InviteParticipant;
