import React, { FC, RefObject } from 'react';
import clsx from 'clsx';
import { useLocation, Link } from 'wouter';
import Avatar from 'components/Avatar';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from '../Menu/DropDownItem';
import DropDownItemDivider from '../Menu/DropDownItemDivider';
import ICONS from '../Icons';
import ProtectedComponent, { checkUserRole } from '~/components/ProtectedComponent';
import { currentSpaceSlug } from '~/api/currentSpaceMiddleware';
import { Organization, SessionQuery } from 'api/data/user/types';

interface OrganizationMenuProps {
  data: SessionQuery;
  organization: Organization | undefined;
  refOutside: RefObject<HTMLElement>;
}

const OrganizationMenu: FC<OrganizationMenuProps> = ({ data, organization, refOutside }) => {
  const [, setLocation] = useLocation();
  const spaceSlug = currentSpaceSlug() || '';

  const changeOrganization = (organization: Organization) => {
    setLocation(`/${organization.firstSpace?.slug}/pages`);
  };

  return (
    <DropDown
      outsideRef={refOutside}
      openedIcon="arrow_down_filled"
      closedIcon="arrow_down_filled"
      iconSide="RIGHT"
      responsive
      className="organization-menu"
      buttonChildren={
        <>
          <Avatar text={organization?.name || ''} />
          <span className="ellipsis-text menu-name">{organization?.name}</span>
        </>
      }>
      {!!organization && (
        <DropDownItem icon="" onClick={() => null} className="current-organization">
          <div className="organization-info">
            <Avatar text={organization?.name || ''} />
            <span
              className={clsx('organization-name', {
                'not-allowed': !checkUserRole('ORGANIZATION_SETTINGS', organization.currentUserRole),
              })}>
              {organization.name}
              <ProtectedComponent
                action="ORGANIZATION_SETTINGS"
                currentUserOrganizationRole={organization.currentUserRole}>
                <div className="organization-actions">
                  <Link to={`/${spaceSlug}/settings/general`} className="icon-button">
                    {ICONS['gear_outline']}Settings
                  </Link>
                  <span className="vertical-line"></span>
                  <Link to={`/${spaceSlug}/settings/members`} className="icon-button">
                    {ICONS['add_user']} Invite team members
                  </Link>
                </div>
              </ProtectedComponent>
            </span>
          </div>
        </DropDownItem>
      )}
      <DropDownItemDivider />
      {data.session.managedOrganizations
        ?.filter(org => org.id !== organization?.id)
        .map(org => (
          <DropDownItem icon="" onClick={() => void changeOrganization(org)} key={org.id}>
            <Avatar text={org.name} />
            <span className="item-text">{org.name}</span>
          </DropDownItem>
        ))}
    </DropDown>
  );
};

export default OrganizationMenu;
