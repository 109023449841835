import React, { FC } from 'react';
import { RouteComponentProps } from 'wouter';
import clsx from 'clsx';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import SimpleHeader from 'components/Header/SimpleHeader';
import { extractQueryStringFromParams } from '~/utils';
import { SubmissionType } from './Details/SuccessCard';
import SubmissionDetailsTabs from './Tabs';
import './style.scss';

export type SubmissionsDetailsProps = {
  id: string;
  source?: string;
};

const SubmissionDetails: FC<RouteComponentProps<SubmissionsDetailsProps>> = ({ params }) => {
  const { currentOrg } = useCurrentOrganization();
  const submissionType = extractQueryStringFromParams('type');
  const participantSlug = extractQueryStringFromParams('participant');
  const source = extractQueryStringFromParams('source');

  return (
    <main className={clsx('page-default', 'submission-details', { 'payer-receipt': !currentOrg })}>
      <SimpleHeader submissionType={submissionType as SubmissionType} />
      <div className="page-content">
        <SubmissionDetailsTabs
          responseId={params.id}
          autoTrackActivities={source === 'qrcode'}
          submissionType={submissionType as SubmissionType}
          participantSlug={participantSlug}
        />
      </div>
    </main>
  );
};

export default SubmissionDetails;
