import React, { useState } from 'react';
import { useParams } from 'wouter';
import { useMutation } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import Badge from '../Badge';
import Message from '../Message';
import Modal from '../Modal';
import PricingSettingsContent from '../PricingSettings/PriceSettingsContent';
import Receipt from '../Receipt';
import SideModal from '../SideModal';
import SideModalHeader from '../SideModal/Header';
import Tabs from '../Tabs';
import Tab from '../Tabs/Tab';
import TeamSettings from './TeamSettings';
import { PageDataDraftBlock } from '~/api/data/pages/types';
import { ResponseActivityType, SaveResponseActivityType } from '~/api/data/response/types';
import './style.scss';

interface PageSettingsProps {
  page: PageDataDraftBlock;
  isSettingsOpen: boolean;
  toggleSettings: (isOpen?: boolean) => void;
  handleConfigChanges: (changes: Partial<PageDataDraftBlock>) => void;
}

export default function PageSettings({ page, isSettingsOpen, toggleSettings, handleConfigChanges }: PageSettingsProps) {
  const [tabContentKey, setTabContentKey] = useState('pricing');
  const [message, setMessage] = useState('');
  const [showMessage, setShowMessage] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState<Partial<PageDataDraftBlock>>();
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const { requestId } = useParams();

  const [saveResponseActivityType] = useMutation<SaveResponseActivityType>(PageQuery.SaveResponseActivityType, {
    refetchQueries: [
      {
        query: PageQuery.GetPageSettings,
        variables: { id: page.id },
      },
    ],
  });

  const saveActionButton = (activityTypeList?: Partial<ResponseActivityType>[]) => {
    const filteredActivityType = activityTypeList?.filter(actType => {
      const unchanged = page.responseActivityTypes?.find(
        resActType => JSON.stringify(resActType) === JSON.stringify(actType),
      );
      return !unchanged;
    });

    filteredActivityType?.forEach(aType => {
      void saveResponseActivityType({
        variables: {
          id: aType?.id,
          pageId: page.id,
          name: aType?.name,
          public: aType?.public,
          deletedAt: aType?.deletedAt,
          currentSpace: page.space?.slug,
          autoTracking: aType?.autoTracking,
        },
      });
    });
  };

  const onConfigChanges = (changes: Partial<PageDataDraftBlock>) => {
    const { responseActivityTypes, ...configChanges } = changes;
    saveActionButton(responseActivityTypes);
    handleConfigChanges(configChanges);

    toggleSettings(false);
    setShowUnsavedChangesModal(false);
    setUnsavedChanges(undefined);
    setShowMessage(true);
  };

  const content: {
    [key: string]: JSX.Element;
  } = {
    pricing: (
      <div className="price-settings">
        <PricingSettingsContent
          page={page}
          onSave={changes => {
            setMessage('Pricing settings updated!');
            onConfigChanges(changes);
          }}
          showCashOrCheck
        />
      </div>
    ),
    receipt: (
      <Receipt
        subject={page}
        subjectType="page"
        onSave={changes => {
          setMessage('Settings updated!');
          onConfigChanges(changes as Partial<PageDataDraftBlock>);
        }}
        loading={false}
        unsavedChanges={unsavedChanges}
        setUnsavedChanges={setUnsavedChanges}
      />
    ),
    team: (
      <TeamSettings
        page={page}
        onChange={changes => {
          setMessage('Team settings updated!');
          onConfigChanges(changes);
        }}
      />
    ),
  };

  return (
    <>
      <Message className="receipt-changed" showMessage={showMessage} setShowMessage={setShowMessage}>
        {message}
      </Message>
      <Modal
        handleOnCancel={() => {
          setShowUnsavedChangesModal(false);
          setUnsavedChanges(undefined);
          toggleSettings();
        }}
        handleOnClose={() => setShowUnsavedChangesModal(false)}
        handleOnConfirm={() => {
          setMessage('Settings updated!');
          unsavedChanges && onConfigChanges(unsavedChanges);
        }}
        confirmlabel="Save changes"
        denyLabel="Leave without saving"
        visible={showUnsavedChangesModal}
        header="Unsaved Changes"
        headerIcon="save">
        <p>Are you sure you want to leave this page and discard your changes?</p>
      </Modal>
      <SideModal
        isOpen={isSettingsOpen}
        toggle={toggleSettings}
        header={
          <SideModalHeader
            title="Settings"
            onClose={() => (unsavedChanges ? setShowUnsavedChangesModal(true) : toggleSettings())}
          />
        }
        closeOnOutsideClick={tabContentKey !== 'receipt'}>
        <div className="page-settings-menu">
          <Tabs activeKey={tabContentKey}>
            <Tab label="Pricing" tabKey="pricing" onClick={setTabContentKey} />
            <Tab label="After Submission" tabKey="receipt" onClick={setTabContentKey} />
            {!requestId && (
              <Tab label="Team" tabKey="team" onClick={setTabContentKey} suffix={<Badge label="Beta" />} />
            )}
          </Tabs>
        </div>
        <div className="settings-container">{content[tabContentKey]}</div>
      </SideModal>
    </>
  );
}
