import React, { FC, SyntheticEvent, useState } from 'react';
import { numberCentsFormatter, numberToCentsFormatter } from 'utils';
import TeamEmptyState from 'components/Team/EmptyState';
import InputNumber from 'components/Input/Number';
import Tab from 'components/Tabs/Tab';
import Tabs from '../Tabs';
import Text from '../Input';
import TextArea from 'components/Input/TextArea';
import ToggleSwitch from 'components/ToggleSwitch';
import { TeamSettings as TeamSettingsType } from 'api/data/pages/types';
import './style.scss';

type TeamSettingsProps = {
  page: {
    id: string;
  } & TeamSettingsType;
  onChange: (attrs: TeamSettingsType) => void;
};

type TeamFieldsProps = {
  handleOnFieldChange: (field: string, value: string) => void;
} & TeamSettingsType;

export const TeamFields: FC<TeamFieldsProps> = ({
  p2pReferToMeAs,
  p2pOrganizationName,
  p2pProgramName,
  handleOnFieldChange,
}) => {
  const [touched, setTouched] = useState(false);

  return (
    <>
      <Text
        name="p2pReferToMeAs"
        value={p2pReferToMeAs}
        label="Participants refer to me as"
        rules={{ required: true, hasError: !p2pReferToMeAs?.trim() && touched }}
        placeholder="e.g. Coach Kopf"
        onChange={({ target }) => handleOnFieldChange(target.name, target.value)}
        onBlur={() => setTouched(true)}
      />
      <Text
        name="p2pOrganizationName"
        value={p2pOrganizationName}
        label="Organization name"
        rules={{ required: true, hasError: !p2pOrganizationName?.trim() && touched }}
        placeholder="e.g. Niles West High School"
        onChange={({ target }) => handleOnFieldChange(target.name, target.value)}
        onBlur={() => setTouched(true)}
      />
      <Text
        name="p2pProgramName"
        value={p2pProgramName}
        label="Program name"
        placeholder="e.g. Varsity Football"
        onChange={({ target }) => handleOnFieldChange(target.name, target.value)}
      />
    </>
  );
};

const TeamTab: FC<TeamSettingsProps> = ({ page, onChange }) => {
  const pageTeamSettings = {
    p2pOrganizationName: page.p2pOrganizationName || '',
    p2pProgramName: page.p2pProgramName || '',
    p2pReferToMeAs: page.p2pReferToMeAs || '',
  };

  const [teamSettings, setTeamSettings] = useState<TeamSettingsType>(pageTeamSettings);

  const handleOnFieldChange = (field: string, value: string) => {
    setTeamSettings({
      ...teamSettings,
      [field]: value,
    });
  };

  const handleOnReset = () => {
    setTeamSettings(pageTeamSettings);
  };

  return (
    <form className="team-settings-form">
      <TeamFields handleOnFieldChange={handleOnFieldChange} {...teamSettings} />
      <footer className="row justify-end gap-ms">
        <button type="button" className="button-outline" onClick={handleOnReset}>
          Not now
        </button>
        <button
          className="primary"
          type="submit"
          disabled={!teamSettings.p2pReferToMeAs?.trim() || !teamSettings.p2pOrganizationName?.trim()}
          onClick={event => {
            event.preventDefault();
            onChange(teamSettings);
          }}>
          Save
        </button>
      </footer>
    </form>
  );
};

const ParticipantTab: FC<TeamSettingsProps> = ({ page, onChange }) => {
  const pageParticipantSettings = {
    defaultParticipantGoalCents: page.defaultParticipantGoalCents || 0,
    collectBeyondParticipantGoal: page.collectBeyondParticipantGoal || false,
    defaultParticipantMessage: page.defaultParticipantMessage || '',
    publicParticipantsGoals: page.publicParticipantsGoals,
  };

  const [participantSettings, setParticipantSettings] = useState<TeamSettingsType>(pageParticipantSettings);
  const [touched, setTouched] = useState(false);

  const handleOnFieldChange = (field: string, value: number | boolean | string) => {
    setParticipantSettings({
      ...participantSettings,
      [field]: value,
    });
  };

  const handleOnReset = () => {
    setParticipantSettings(pageParticipantSettings);
  };

  const goalPerParticipantIsEmpty =
    participantSettings.publicParticipantsGoals && !participantSettings.defaultParticipantGoalCents;

  return (
    <form
      aria-label="team settings form"
      className="team-settings-form"
      onSubmit={(event: SyntheticEvent) => {
        event.preventDefault();
        onChange(participantSettings);
      }}>
      <TextArea
        label="Default donor message"
        labelTooltip="Each participant can customize their donor message, which is used on their individual fundraising page and in donor communication."
        ariaLabelTooltip="default-donor-message-info"
        name="donor-message"
        onChange={({ target }) => handleOnFieldChange('defaultParticipantMessage', target.value)}
        value={participantSettings.defaultParticipantMessage || ''}
        rules={{ required: true, hasError: !participantSettings.defaultParticipantMessage?.trim() && touched }}
        onBlur={() => setTouched(true)}
      />
      <div className="row space-between">
        <span className="subtitle-x-small">
          Show how much each participant raised on their public page with the following goal:
        </span>
        <ToggleSwitch
          ariaLabel="public participant goal"
          label={participantSettings.publicParticipantsGoals ? 'On' : 'Off'}
          labelPosition="after"
          toggleValue={participantSettings.publicParticipantsGoals || false}
          handleOnChange={() =>
            handleOnFieldChange('publicParticipantsGoals', !participantSettings.publicParticipantsGoals)
          }
        />
      </div>
      <InputNumber
        disabled={!participantSettings.publicParticipantsGoals}
        ariaLabel="goal-per-participant"
        onValueChange={target => {
          handleOnFieldChange('defaultParticipantGoalCents', numberToCentsFormatter(target.value));
        }}
        value={
          participantSettings.defaultParticipantGoalCents
            ? numberCentsFormatter(participantSettings.defaultParticipantGoalCents)
            : ''
        }
        className="goal-per-participant"
        name="goal-per-participant"
        thousandSeparator
        allowNegative={false}
        rules={{
          required: true,
          hasError: goalPerParticipantIsEmpty && touched,
        }}
        onBlur={() => setTouched(true)}
      />
      <div className="row space-between">
        <span className="subtitle-x-small">Raise beyond goal</span>
        <ToggleSwitch
          disabled={!participantSettings.publicParticipantsGoals}
          ariaLabel="raise-beyond-goal"
          label={participantSettings.collectBeyondParticipantGoal ? 'On' : 'Off'}
          labelPosition="after"
          toggleValue={participantSettings.collectBeyondParticipantGoal || false}
          handleOnChange={() =>
            handleOnFieldChange('collectBeyondParticipantGoal', !participantSettings.collectBeyondParticipantGoal)
          }
        />
      </div>
      <p className="paragraph-x-small">
        {participantSettings.collectBeyondParticipantGoal
          ? 'There is no limit to what each participant can raise!'
          : 'Participants cannot raise more than their goal.'}
      </p>
      <footer className="row justify-end gap-ms">
        <button type="button" className="button-outline" onClick={handleOnReset}>
          Not now
        </button>
        <button
          className="primary"
          disabled={!participantSettings.defaultParticipantMessage?.trim() || goalPerParticipantIsEmpty}>
          Save
        </button>
      </footer>
    </form>
  );
};

const TeamSettings: FC<TeamSettingsProps> = ({ page, onChange }) => {
  const [tabContentKey, setTabContentKey] = useState('team');

  const content: {
    [key: string]: JSX.Element;
  } = {
    team: <TeamTab page={page} onChange={onChange} />,
    participant: <ParticipantTab page={page} onChange={onChange} />,
  };
  if (page.p2pEnabled)
    return (
      <>
        <Tabs activeKey={tabContentKey}>
          <Tab label="Team Settings" tabKey="team" onClick={setTabContentKey} />
          <Tab label="Participant Settings" tabKey="participant" onClick={setTabContentKey} />
        </Tabs>
        {content[tabContentKey]}
      </>
    );

  return <TeamEmptyState />;
};

export default TeamSettings;
