import React, { useContext } from 'react';
import dayjs from 'dayjs';
import { getTransactionRecurring } from 'utils';
import UserRolePerspective from 'contexts/UserRolePerspective';
import { mapStatusClass } from 'defaults/transaction';
import BlankState from 'components/BlankState';
import RefundModal from 'components/RefundModal';
import PayerRequestedModal from 'components/RefundModal/PayerRequestedModal';
import ReviewRequestModal from 'components/RefundModal/ReviewRequestModal';
import Row from '../Row';
import { TransactionByUser } from 'api/data/transaction/types';
import './style.scss';

interface TransactionsProps {
  transactionsByUser?: TransactionByUser[];
  userId?: string;
  refundTransactions?: TransactionByUser[];
}

const Transactions = ({ transactionsByUser, userId, refundTransactions }: TransactionsProps) => {
  const isPayerView = useContext(UserRolePerspective) === 'payer';
  const transactionDate = (transaction: TransactionByUser) => {
    return transaction?.status === 'PENDING' ? transaction?.pendingAt : transaction?.processedAt;
  };

  if (transactionsByUser?.length === 0) return <BlankState title="No Payments" message="Payments will appear here." />;

  return (
    <div className="transactions-list">
      {transactionsByUser?.map(transaction => {
        const recurringInfo = getTransactionRecurring(transaction);
        const isRefund = !!transaction.lastRefundAt;

        return (
          <Row
            key={transaction.id}
            data={transaction}
            hasPaymentPlan={recurringInfo?.hasPaymentPlan || false}
            date={dayjs(transactionDate(transaction)).format('MMM D, YYYY')}
            status={{
              tagType: mapStatusClass[isRefund ? 'CANCELED' : transaction?.status],
              value: transaction?.status?.toLocaleLowerCase(),
            }}
            recurring={recurringInfo?.showRecurring ? recurringInfo?.recurring : undefined}
            actions={
              <>
                {transaction.hasPendingPayerRequest && isPayerView && (
                  <PayerRequestedModal
                    requestedDate={transaction?.pendingPayerRequest?.requestedAt}
                    organization={transaction?.organization}
                    requestType="Refund"
                  />
                )}
                <ReviewRequestModal
                  pageTitle={transaction?.page?.title}
                  requesterName={transaction?.user?.fullName}
                  amountCents={transaction?.pendingPayerRequest?.amountCents}
                  transactionId={transaction?.id}
                  showButton={!!transaction?.hasPendingPayerRequest && !isPayerView}
                  status={transaction?.lastRespondedPayerRequest?.status}
                  userId={userId}
                  subscriptionId={transaction?.pendingPayerRequest?.subscriptionId}
                  cancelationReason={transaction.pendingPayerRequest?.cancelationReason}
                  transaction={transaction}
                />
                <RefundModal
                  showButton={!transaction?.hasPendingPayerRequest}
                  transaction={transaction}
                  userId={userId}
                  refundTransactions={refundTransactions}
                />
              </>
            }
          />
        );
      })}
    </div>
  );
};

export default Transactions;
