import React, { FC, useEffect, useState } from 'react';
import { useLocation } from 'wouter';
import Modal from '../Modal';
import './style.scss';

const ErrorModal: FC = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    if (window.location.hash === '#error') {
      setModalOpen(true);
      setLocation(location.replace('#error', ''));
    }
  }, [location, setLocation]);

  return (
    <>
      <Modal visible={modalOpen} handleOnCancel={() => setModalOpen(false)} className="error-modal">
        <span className="headline">OOPS!</span>
        <h2>Something went wrong</h2>
        <p>But we&apos;re on it! We have notified our engineers. Please refresh the page and try again</p>
        <div className="action">
          <a
            className="button button-link"
            href={`https://www.omella.com/contact-us?email=email@omella.com&referrer=${window.location.origin}${location}#error`}
            target="_blank"
            rel="noreferrer"
            onClick={() => setModalOpen(false)}>
            Contact us
          </a>
          <button className="button-outline" onClick={() => setModalOpen(false)}>
            Ok
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ErrorModal;
