import { hasPaymentPlanInstallments } from 'utils';
import { TransactionByUser } from 'api/data/transaction/types';

const getTransactionRecurring = (
  transaction: TransactionByUser,
): { showRecurring: boolean; hasPaymentPlan: boolean; recurring: string } | undefined => {
  const recurrings: { [key: string]: string } = {};
  let hasPaymentPlan = false;
  transaction.invoice.orderItems.forEach(item => {
    if (item.recurring) {
      const recurr = item.recurring.toLocaleLowerCase();
      recurrings[recurr] = recurr;
    }

    if (hasPaymentPlanInstallments(item.variant.paymentPlanInstallments)) {
      hasPaymentPlan = true;
    }
  });

  const qtyRecurrings = Object.keys(recurrings).length;
  const showRecurring = qtyRecurrings > 1 || (qtyRecurrings === 1 && !recurrings.once);

  return showRecurring ? { showRecurring, hasPaymentPlan, recurring: Object.values(recurrings).join(', ') } : undefined;
};

export default getTransactionRecurring;
