import React, { FC } from 'react';
import OrderItemDetails from 'components/OrderItem';
import PlusMinusCounter from 'components/PlusMinusCounter';
import { OrderItem } from 'api/data/response/types';

interface OrderItemDetailsProps {
  orderItem: OrderItem;
  refundQtyByOrderItem: { [key: string]: number };
  maxAllowed?: number;
  setRefundQtyByOrderItem: (refundQtyByOrderItem: { [key: string]: number }) => void;
  qtyMaxAllowed: number;
}

const RefundOrderItemDetail: FC<OrderItemDetailsProps> = ({
  orderItem,
  refundQtyByOrderItem,
  setRefundQtyByOrderItem,
  qtyMaxAllowed,
}: OrderItemDetailsProps) => {
  const handleAddMinusQuantity = (quantity: number) => {
    const orderItemId = orderItem.id as string;
    setRefundQtyByOrderItem({ ...refundQtyByOrderItem, [orderItemId]: ~~quantity });
  };

  return (
    <OrderItemDetails key={orderItem.id} orderItem={orderItem} showRecurring={false}>
      <div className="row align-center gap-s">
        <PlusMinusCounter
          quantity={refundQtyByOrderItem[orderItem.id as string] || 0}
          maxAllowed={qtyMaxAllowed}
          handleAddMinus={handleAddMinusQuantity}
          disabled={qtyMaxAllowed === 0}
          className="secondary"
        />
        / {qtyMaxAllowed}
      </div>
    </OrderItemDetails>
  );
};

export default RefundOrderItemDetail;
