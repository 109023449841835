import React, { FC } from 'react';
import clsx from 'clsx';
import ICONS from '../Icons';
import './style.scss';

type Item = {
  title: string;
};

type StepsProps = {
  items: Item[];
  current: number;
};

const Steps: FC<StepsProps> = ({ items, current }) => {
  return (
    <div className="steps">
      {items.map((item, index) => {
        return (
          <div key={item.title || index} className={clsx('step-item', { 'step-active': index <= current })}>
            {index > 0 && <div className="horizontal-line"></div>}
            <div>
              {index < current ? ICONS['check_circle'] : <span className="step-icon">{index + 1}</span>}
              {!!item.title && <span className="step-title">{item.title}</span>}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Steps;
