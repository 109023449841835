import React, { FC, useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import {
  addPaymentOption,
  addPaymentVariant,
  deleteBlock,
  removePaymentOption,
  removeVariant,
  updatePaymentBlock,
  PaymentValue,
} from 'api/data/pages/blocks';
import { defaultMaxLengthTitle } from 'defaults/page';
import useOutsideClick from 'hooks/useOutsideClick';
import { numberCentsFormatter, numberToCentsFormatter } from 'utils';
import CustomFrequencyModal from './CustomFrequencyModal';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import ImageInBlock from 'components/ImageInBlock';
import Input from 'components/Input/LabelFloating';
import InputNumber from '../Input/Number';
import InventoryModal from './InventoryModal';
import Modal from 'components/Modal';
import SideMenu from 'components/Editor/SideMenu';
import ToggleSwitch from 'components/ToggleSwitch';
import Tooltip from '../Tooltip';
import PaymentBlockPayerView from './PayerView';
import PaymentOption from './PaymentOption';
import Question from '../QuestionBlock';
import {
  PaymentBlockProps,
  PaymentFrequency,
  PaymentOption as PaymentOptionType,
  PaymentBlock as PaymentBlockType,
} from './types';
import { BlockState } from 'api/data/pages/blocks/types';
import './style.scss';

const PaymentBlock: FC<PaymentBlockProps> = ({
  data,
  isFocused,
  refOutside,
  isRequest,
  setGoal,
  updateBlock,
  handleDelete,
  disableOutsideClick = false,
}: PaymentBlockProps) => {
  const [showDelete, setShowDelete] = useState(false);
  const [frequencyModal, setFrequencyModal] = useState<PaymentFrequency>({
    show: false,
    variantIndex: 0,
    paymentOptionIndex: 0,
    recurring: 'ONCE',
    amountCents: 0,
    paymentPlanInstallments: -1,
  });
  const [inventoryModal, setInventoryModal] = useState({
    show: false,
    maxQuantity: 0,
    variantIndex: 0,
    id: '',
  });
  const [isEditing, setIsEditing] = useState<boolean>(isFocused);
  const [focusElement, setFocusElement] = useState<string>();

  const paymentRef = useRef<HTMLElement>(null);
  const elementsRefs = useRef<{
    [key: string]: HTMLInputElement | null;
  }>({});

  useOutsideClick(paymentRef, refOutside, () => {
    if (isEditing && !disableOutsideClick) {
      setIsEditing(false);
    }
  });

  const handleOnChange = (value: PaymentValue, field: string, variantIdx?: number) => {
    const block = updatePaymentBlock(data, value, field, variantIdx);
    if (field === 'goal') {
      setGoal(block);
    } else {
      updateBlock(block);
    }
  };

  useEffect(() => {
    if (isEditing && focusElement && elementsRefs && elementsRefs.current[focusElement]) {
      elementsRefs?.current[focusElement]?.focus();
    }
  }, [isEditing, focusElement, elementsRefs]);

  const handleOnChangePaymentOption = (
    paymentOption: Partial<PaymentOptionType>,
    variantIndex: number,
    optionIndex: number,
  ) => {
    const newPaymentOptions = [...data.variants[variantIndex].paymentOptions];
    newPaymentOptions[optionIndex] = { ...newPaymentOptions[optionIndex], ...paymentOption } as PaymentOptionType;

    handleOnChange(newPaymentOptions, 'paymentOptions', variantIndex);
  };

  const addGoal = () => {
    if (data.goal) handleOnChange(null, 'goal');
    else handleOnChange({ id: uuidv4(), amountCents: null }, 'goal');
  };

  const handleGoal = (value: string) => {
    handleOnChange({ id: data.goal?.id, amountCents: numberToCentsFormatter(value) }, 'goal');
  };

  const handleAddPaymentOption = (variantIdx: number) => {
    const block = addPaymentOption(data, variantIdx);
    updateBlock(block);
  };

  const handleRemoveVariant = (variantIdx: number) => {
    const block = removeVariant(data, variantIdx);
    updateBlock(block);
  };

  const handleRemovePaymentOption = (variantIdx: number, paymentOptionIdx: number) => {
    const block = removePaymentOption(data, variantIdx, paymentOptionIdx);
    updateBlock(block);
  };

  const handleAddPaymentVariant = () => {
    const block = addPaymentVariant(data, 'PAYMENT');
    updateBlock(block);
  };

  const copyNestedBlocks = (variantIndex: number): [PaymentBlockType, PaymentBlockType[]] => {
    const copyData = { ...data };
    const blocksCopy = copyData.variants[variantIndex].blocks as PaymentBlockType[];

    return [copyData, blocksCopy];
  };

  const updateBlockNested = (block: BlockState, variantIndex: number, blockIdx: number) => {
    const [copyData, blocksCopy] = copyNestedBlocks(variantIndex);
    if (blocksCopy) {
      blocksCopy[blockIdx] = block as PaymentBlockType;
      copyData.variants[variantIndex].blocks = blocksCopy;
    }

    return copyData;
  };

  const handleChangeNested = (block: BlockState, variantIndex: number, blockId: number) => {
    const copyData = updateBlockNested(block, variantIndex, blockId);
    updateBlock(copyData);
  };

  const handleDeleteNested = (variantIndex: number, blockId: number) => {
    const [copyData, blocksCopy] = copyNestedBlocks(variantIndex);
    if (blocksCopy) {
      const newBlocks = deleteBlock(blocksCopy, blockId) as PaymentBlockType[];
      copyData.variants[variantIndex].blocks = newBlocks;
      updateBlock(copyData);
    }
  };

  const handleGoalNested = (block: BlockState, variantIndex: number, blockId: number) => {
    const copyData = updateBlockNested(block, variantIndex, blockId);
    setGoal(copyData);
  };

  const addBlocks = (blocks: BlockState[], variantIndex: number) => {
    const copyData = { ...data };
    copyData.variants[variantIndex].blocks = blocks.map(item => ({
      ...item,
      parentVariantId: copyData.variants[variantIndex].id,
    })) as PaymentBlockType[];
    updateBlock(copyData);
  };

  return (
    <section
      className="block-container"
      role="presentation"
      ref={paymentRef}
      onClick={ev => {
        ev.stopPropagation();
        const target = ev.target as HTMLElement;

        if (!isEditing) {
          setIsEditing(true);
          setFocusElement(target.id);
        }
      }}>
      <Modal
        handleOnCancel={() => {
          setShowDelete(false);
        }}
        handleOnConfirm={handleDelete}
        confirmlabel="Delete"
        denyLabel="Cancel"
        visible={showDelete}
        header="Delete this Payment Block?"
      />
      {isEditing ? (
        <div className="block payment">
          <div className="block-header row space-between">
            <div className="row align-center">
              {ICONS['money']}
              <span className="size-xxs">Payment</span>
            </div>
            <div className="row align-center">
              <ToggleSwitch
                label="Required"
                toggleValue={data.required}
                handleOnChange={() => handleOnChange(!data.required, 'required')}
              />
              <span className="vertical-line"></span>
              <DropDown outsideRef={paymentRef}>
                <DropDownItem
                  icon=""
                  onClick={event => {
                    event.preventDefault();
                    handleOnChange(!data.multipleChoice, 'multipleChoice');
                  }}>
                  <ToggleSwitch
                    label="Allow more than one choice to be selected"
                    toggleValue={data.multipleChoice}
                    labelPosition="after"
                  />
                </DropDownItem>
                <DropDownItem icon="target" onClick={() => addGoal()}>
                  {data.goal ? 'Remove' : 'Add'} goal
                </DropDownItem>
                <DropDownItem icon="delete" onClick={() => setShowDelete(true)}>
                  Delete
                </DropDownItem>
              </DropDown>
            </div>
          </div>
          <div className="block-content">
            <ImageInBlock imageUrl={data.imageUrl} handleOnChange={(...params) => handleOnChange(...params)}>
              <Input
                label="What are you collecting money for?"
                placeholder="i.e., Dues, Ticket Sales, Merchandise"
                secondLabel="i.e., Dues, Ticket Sales, Merchandise"
                onChange={({ target }) => handleOnChange(target.value, 'description')}
                value={data.description || ''}
                className="block-title"
                name={`payment_${data.id}`}
                innerRef={element => (elementsRefs.current[`payment_${data.id}`] = element)}
                // eslint-disable-next-line jsx-a11y/no-autofocus
                autoFocus={!data.description}
              />
            </ImageInBlock>
            {!!data.goal?.id && (
              <InputNumber
                placeholder="Type your goal"
                onValueChange={target => handleGoal(target.value)}
                value={data.goal?.amountCents ? numberCentsFormatter(data.goal?.amountCents) : ''}
                name="goal"
                thousandSeparator
                allowNegative={false}
              />
            )}
            {data.variants.map((item, variantIndex) => {
              const hasOneVariant = data.variants.length === 1;

              return (
                <div key={item.id} className="variant-container">
                  <div className="container group-child align-center variant-actions">
                    {!data.parentVariantId && (
                      <SideMenu
                        blocks={item.blocks || []}
                        blockIndex={-1}
                        setFocusedElement={() => null}
                        setBlocks={blocks => addBlocks(blocks, variantIndex)}
                        outsideRef={paymentRef}
                        isNested
                      />
                    )}
                    <input
                      type="text"
                      value={item.label || ''}
                      onChange={({ target }) => handleOnChange(target.value, 'label', variantIndex)}
                      placeholder="Title (optional)"
                      className={clsx('default variant-title', { 'has-parent': !!data.parentVariantId })}
                      data-testid="variant-label"
                      ref={element => (elementsRefs.current[`payment_variant_text_${item.id}`] = element)}
                      maxLength={defaultMaxLengthTitle}
                    />
                    <div>
                      <Tooltip color="dark" title="Add a payment option">
                        <IconButton icon="plus_circle" onClick={() => handleAddPaymentOption(variantIndex)} />
                      </Tooltip>
                      <DropDown outsideRef={paymentRef} className="variant-options">
                        <DropDownItem
                          icon="box_inventory"
                          onClick={() =>
                            setInventoryModal({
                              show: true,
                              maxQuantity: item.maxQuantity || 0,
                              variantIndex: variantIndex,
                              id: item.inventory?.id,
                            })
                          }>
                          <span className="variant-action-item">Quantity settings</span>
                          <span className="help">Track inventory and set limits</span>
                        </DropDownItem>
                        <DropDownItem icon="plus_circle" onClick={() => handleAddPaymentOption(variantIndex)}>
                          <span className="variant-action-item">Add payment option</span>
                          <span className="help">Offer plans and installments!</span>
                        </DropDownItem>
                        <DropDownItem
                          icon="delete"
                          className={clsx({ disabled: hasOneVariant })}
                          onClick={() => {
                            if (!hasOneVariant) {
                              handleRemoveVariant(variantIndex);
                            }
                          }}>
                          <span className="variant-action-item">Remove</span>
                          <span className="help">Remove this choice</span>
                        </DropDownItem>
                      </DropDown>
                    </div>
                  </div>
                  <div className="row variant-box">
                    {item.paymentOptions?.map((option, optionIndex) => {
                      return (
                        <PaymentOption
                          key={option.id}
                          paymentOption={option}
                          handleOnChange={partialPaymentOption =>
                            handleOnChangePaymentOption(partialPaymentOption, variantIndex, optionIndex)
                          }
                          elementsRefs={elementsRefs}
                          paymentRef={paymentRef}
                          setFrequencyModal={() =>
                            setFrequencyModal({
                              variantIndex,
                              paymentOptionIndex: optionIndex,
                              recurring: option.recurring || 'ONCE',
                              amountCents: option.amountCents || 0,
                              paymentPlanInstallments: option.paymentPlanInstallments || -1,
                              show: true,
                            })
                          }
                          deleteEnabled={item.paymentOptions.length > 1}
                          removePaymentOption={() => handleRemovePaymentOption(variantIndex, optionIndex)}
                        />
                      );
                    })}
                  </div>
                  {item.blocks?.map((block, blockIdx) => {
                    return (
                      <section key={block.id} className={`container group-child nested`}>
                        <SideMenu
                          blocks={item.blocks || []}
                          blockIndex={blockIdx}
                          setFocusedElement={() => null}
                          setBlocks={blocks => addBlocks(blocks, variantIndex)}
                          outsideRef={paymentRef}
                          isNested
                        />
                        {block.type === 'PAYMENT' ? (
                          <PaymentBlock
                            data={block}
                            isFocused
                            isRequest={isRequest}
                            updateBlock={block => handleChangeNested(block, variantIndex, blockIdx)}
                            handleDelete={() => handleDeleteNested(variantIndex, blockIdx)}
                            setGoal={block => handleGoalNested(block, variantIndex, blockIdx)}
                            refOutside={refOutside}
                            disableOutsideClick
                          />
                        ) : (
                          <Question
                            isFocused
                            data={block}
                            refOutside={refOutside}
                            handleDelete={() => handleDeleteNested(variantIndex, blockIdx)}
                            updateBlock={block => handleChangeNested(block, variantIndex, blockIdx)}
                            disableOutsideClick
                          />
                        )}
                      </section>
                    );
                  })}
                </div>
              );
            })}
            {frequencyModal.show && (
              <CustomFrequencyModal
                handleOnChange={paymentOption =>
                  handleOnChangePaymentOption(
                    paymentOption,
                    frequencyModal.variantIndex,
                    frequencyModal.paymentOptionIndex,
                  )
                }
                setShowModal={(show: boolean) => setFrequencyModal({ ...frequencyModal, show })}
                paymentRecurring={frequencyModal.recurring}
                variantAmount={frequencyModal.amountCents}
                variantInstallments={frequencyModal.paymentPlanInstallments || -1}
              />
            )}
            {inventoryModal.show && (
              <InventoryModal
                handleOnChange={(...params) => handleOnChange(...params, inventoryModal.variantIndex)}
                setShowModal={(show: boolean) => setInventoryModal({ ...inventoryModal, show })}
                maxQuantity={inventoryModal.maxQuantity}
                id={inventoryModal.id}
                isRequest={isRequest}
              />
            )}
            <div className="choice-btn-container">
              <IconButton className="" icon="plus_circle" onClick={handleAddPaymentVariant}>
                Add choice
              </IconButton>
            </div>
          </div>
        </div>
      ) : (
        <PaymentBlockPayerView data={data} readOnly />
      )}
    </section>
  );
};

export default PaymentBlock;
