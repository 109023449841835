import React, { FC } from 'react';
import './style.scss';

interface TermsPrivacyProps {
  type: 'SUBMIT' | 'PAYMENT' | 'SIGNUP';
  additionalDescription?: string;
}

const termsText: {
  [key: string]: string;
} = {
  SUBMIT: 'submitting',
  PAYMENT: 'paying',
  SIGNUP: 'signing up',
};

const TermsPrivacy: FC<TermsPrivacyProps> = ({ type, additionalDescription }) => {
  return (
    <p className="terms-privacy">
      By {termsText[type]}, you agree to our
      <a href="https://www.omella.com/terms-of-service" target="_blank" rel="noreferrer">
        <strong> Terms of Service </strong>
      </a>
      and
      <a href="https://www.omella.com/privacy-policy" target="_blank" rel="noreferrer">
        <strong> Privacy Policy</strong>
      </a>
      . {additionalDescription}
    </p>
  );
};

export default TermsPrivacy;
