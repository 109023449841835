import React, { FC, ReactNode } from 'react';
import { Link } from 'wouter';
import { isKYCd } from 'utils';
import useCurrentOrganization from 'hooks/useCurrentOrganization';
import useFirstOrganization from 'hooks/useFirstOrganization';
import ICONS from '~/components/Icons';
import KYCBanner from 'components/KYCBanner';
import LogoLink from 'components/Header/LogoLink';
import { TooltipEllipsis } from 'components/Tooltip';
import './style.scss';

interface HeaderProps {
  title: string;
  linkBack: string;
  onClickBack?: () => void;
  tabs?: ReactNode;
  actions?: ReactNode;
  additionalActions?: ReactNode;
  tabsOnMobile?: ReactNode;
  showLogo?: boolean;
}

const Header: FC<HeaderProps> = ({
  title,
  linkBack,
  onClickBack,
  tabs,
  actions,
  additionalActions,
  tabsOnMobile,
  showLogo = true,
}) => {
  const { currentOrg, loading: orgLoading } = useCurrentOrganization();
  const { firstOrganizationData } = useFirstOrganization();

  const organization = currentOrg || (!orgLoading && firstOrganizationData);

  return (
    <header className="main">
      {!!organization && !isKYCd(organization?.status) && <KYCBanner currentOrganization={organization} />}
      <div className="row space-between second-header">
        <div className="page-title">
          <div className="page-actions">
            {(linkBack || onClickBack) && (
              <Link aria-label="Back" onClick={onClickBack} href={linkBack} className="link-back subtitle-xx-small">
                {ICONS['arrow_left']} {!showLogo && 'Back'}
              </Link>
            )}
            {showLogo && (
              <div className="logo">
                <LogoLink />
              </div>
            )}
            <div className="row justify-center title-container">
              <TooltipEllipsis title={title} className="tooltip-title" />
            </div>
          </div>
        </div>
        {tabs}
        {actions}
      </div>
      {additionalActions}
      {tabsOnMobile}
    </header>
  );
};

export default Header;
