/* eslint-disable react/no-children-prop */
import React, { MouseEvent } from 'react';
import { RichUtils } from 'draft-js';
import {
  DraftJsStyleButtonType,
  DraftJsStyleButtonProps,
  CreateBlockStyleButtonProps,
} from 'components/RichTextEditor/Plugin/types';
import clsx from 'clsx';

export default function createBlockStyleButton({
  blockType,
  component,
  testId,
}: CreateBlockStyleButtonProps): DraftJsStyleButtonType {
  return function BlockStyleButton(props: DraftJsStyleButtonProps) {
    const toggleStyle = (event: MouseEvent): void => {
      event.preventDefault();
      props.setEditorState(RichUtils.toggleBlockType(props.getEditorState(), blockType));
    };

    const preventBubblingUp = (event: MouseEvent): void => {
      event.preventDefault();
    };

    const blockTypeIsActive = (): boolean => {
      if (!props.getEditorState()) {
        return false;
      }

      const editorState = props.getEditorState();
      const type = editorState.getCurrentContent().getBlockForKey(editorState.getSelection().getStartKey()).getType();
      return type === blockType;
    };

    const { theme } = props;
    const className = blockTypeIsActive() ? clsx(theme.button, theme.active) : theme.button;

    return (
      <button
        className={className}
        onClick={toggleStyle}
        onMouseDown={preventBubblingUp}
        type="button"
        data-testid={testId}>
        {component}
      </button>
    );
  };
}
