import React, { FC, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { NumericFormat } from 'react-number-format';
import * as PageQuery from 'graphql/page.graphql';
import ICONS from 'components/Icons';
import Modal from 'components/Modal';
import Tooltip from 'components/Tooltip';
import { Inventory, InventoryData } from '../types';
import './style.scss';

interface InventoryModalProps {
  handleOnChange: (value: string | number | Partial<Inventory>, field: string) => void;
  setShowModal: (show: boolean) => void;
  maxQuantity: number;
  id: string;
  isRequest: boolean;
}

const InventoryModal: FC<InventoryModalProps> = ({
  setShowModal,
  handleOnChange,
  isRequest,
  maxQuantity,
  id,
}: InventoryModalProps) => {
  const { data, error, loading } = useQuery<InventoryData>(PageQuery.GetInventory, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  const [saveInventory, { loading: saveLoading }] = useMutation(PageQuery.SaveInventory);
  const [inventory, setInventory] = useState({
    id,
    hasLimit: maxQuantity > 0,
    maxQuantity: maxQuantity || 1,
    unlimited: true,
    availableQuantity: 0,
  });

  const disabledMaxQtyText = 'This cannot be changed because inventory is tracked system-wide.';

  useEffect(() => {
    if (!loading && !error && data && data.inventory) {
      setInventory(prevInventory => {
        const newInventory = {
          id: data.inventory.id,
          unlimited: data.inventory.unlimited || false,
          availableQuantity: data.inventory.availableQuantity || 0,
        };
        return {
          ...prevInventory,
          ...newInventory,
        };
      });
    }
  }, [loading, error, data]);

  const handleSave = async () => {
    const newInventory = updateInventory();
    await saveInventory({ variables: { inventory: newInventory } });
    handleOnChange(inventory.hasLimit ? inventory.maxQuantity : 0, 'maxQuantity');
    setShowModal(false);
  };

  const handleOnChangeInventory = (value: boolean | number, field: string) => {
    const newInventory = { ...inventory, [field]: value };

    if (field === 'hasLimit' && !value) {
      newInventory.maxQuantity = 1;
    }

    setInventory(newInventory);
  };

  const updateInventory = () => {
    const newInventory = {
      unlimited: inventory.unlimited,
      availableQuantity: inventory.unlimited ? 0 : inventory.availableQuantity,
      id: inventory.id,
    };
    handleOnChange(newInventory, 'inventory');

    return newInventory;
  };

  const maxQuantityCheckbox = () => {
    return (
      <input
        disabled={isRequest}
        type="checkbox"
        name="unlimited"
        checked={!inventory.unlimited}
        onChange={({ target }) => handleOnChangeInventory(!target.checked, 'unlimited')}
      />
    );
  };

  const availableQuantityInput = () => {
    return (
      <NumericFormat
        value={inventory.availableQuantity}
        onValueChange={target => handleOnChangeInventory(parseInt(target.value), 'availableQuantity')}
        thousandSeparator
        placeholder="E.g. 100"
        className="default"
        disabled={isRequest}
        allowNegative={false}
      />
    );
  };

  return (
    <Modal
      handleOnCancel={() => {
        updateInventory();
        setShowModal(false);
      }}
      handleOnConfirm={() => void handleSave()}
      header="Quantity settings"
      headerIcon="box_inventory"
      confirmlabel={saveLoading ? 'Saving...' : 'Save change'}
      confirmButtonProps={{ disabled: saveLoading }}
      denyLabel="Cancel"
      visible
      className="inventory-modal"
      fullScreenMobile>
      <div className="modal-body">
        {loading ? (
          <span>Loading...</span>
        ) : (
          <div className="quantity-box">
            <label>
              {isRequest ? (
                <Tooltip className="disabled-qty-tooltip" color="dark" title={disabledMaxQtyText}>
                  {maxQuantityCheckbox()}
                </Tooltip>
              ) : (
                maxQuantityCheckbox()
              )}
              Set maximum quantity
              <Tooltip
                color="dark"
                title="Specify how many items you have available for purchase. We will automatically track your inventory so you do not sell more than you have.">
                {ICONS['solid_help']}
              </Tooltip>
            </label>
            {!inventory.unlimited && (
              <>
                <p>Available quantity for purchase:</p>
                {isRequest ? (
                  <Tooltip className="disabled-qty-tooltip number-input" color="dark" title={disabledMaxQtyText}>
                    {availableQuantityInput()}
                  </Tooltip>
                ) : (
                  availableQuantityInput()
                )}
              </>
            )}
          </div>
        )}
        <div className="quantity-box max-qty">
          <input
            type="checkbox"
            name="maxQuantity"
            id="maxQuantity"
            checked={inventory.hasLimit}
            onChange={({ target }) => handleOnChangeInventory(target.checked, 'hasLimit')}
          />
          Limit to
          <NumericFormat
            value={inventory.maxQuantity}
            onValueChange={target => handleOnChangeInventory(parseInt(target.value), 'maxQuantity')}
            thousandSeparator
            className="default"
            disabled={!inventory.hasLimit}
            allowNegative={false}
          />
          per submission
          <Tooltip color="dark" title="Limit the number of items that someone can purchase per submission.">
            {ICONS['solid_help']}
          </Tooltip>
        </div>
      </div>
    </Modal>
  );
};

export default InventoryModal;
