import React, { FC, useState } from 'react';
import { RouteComponentProps } from 'wouter';
import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import * as PageQuery from 'graphql/page.graphql';
import { isPageActive } from 'utils/pageAvailability';
import { isProduction } from 'utils';
import Header from 'pages/MyPage/Header';
import LoggedPageContainer from 'components/LoggedPageContainer';
import ShareByEmail from '../ShareByEmail';
import ShareByLink from 'components/ShareByLink';
import Tab from 'components/Tabs/Tab';
import Tabs from 'components/Tabs';
import { PageView } from 'api/data/pages/types';
import './style.scss';

const ShareParticipantPage: FC<RouteComponentProps> = ({ params }) => {
  const { id: pageSlug, participantSlug } = params;
  const { data: participantPage } = useQuery<PageView>(PageQuery.GetPageParticipant, {
    variables: { id: pageSlug, participantSlug },
    fetchPolicy: 'network-only',
  });
  const [tabContentKey, setTabContentKey] = useState('email');
  const currentDate = dayjs();
  const showLinkTab = !!participantPage?.page && isPageActive(currentDate, participantPage.page);

  const pageURL = participantSlug ? `${pageSlug}/p/${participantSlug}` : pageSlug || '';

  const participantMessage = (participantPage?.page?.defaultParticipantMessage || '')
    .replace(/<p>(.*?)<\/p>/g, '$1%0D%0A')
    .replace(/<br\s*\/?>/g, '')
    .replace(/\n/g, '%0D%0A');

  const message = `Hey!%0D%0A%0D%0A${participantMessage}%0D%0APlease consider donating and sharing this link:%0D%0A%0D%0A`;

  const p2pTitle =
    `${participantPage?.page?.p2pOrganizationName || participantPage?.page?.organization?.name} ${participantPage?.page?.p2pProgramName || ''}`.trim();

  const participantName = participantPage?.page?.participant?.fullName
    ? `${participantPage?.page?.participant?.fullName}'s`
    : '';

  const mailSubject = `${participantName} ${p2pTitle} fundraiser on Omella`.trim();

  const subdomain = isProduction() ? 'p2p' : 'p2p-staging';

  const emailBcc = `do-not-remove%2B${participantSlug}@${subdomain}.omella.com`;

  const tabContent: {
    [key: string]: JSX.Element;
  } = {
    email: <ShareByEmail pageSlug={pageSlug} participantSlug={participantSlug} />,
    link: (
      <ShareByLink
        shareType="participantPage"
        params={pageURL}
        title={mailSubject}
        message={message}
        emailBcc={emailBcc}
      />
    ),
  };

  const backParticipantParam = participantSlug ? `/p/${participantSlug}` : '';

  return (
    <LoggedPageContainer
      className="share-participant"
      header={<Header title="Share Page" linkBack={`/${pageSlug}${backParticipantParam}`} showLogo={false} />}>
      <Tabs className="row justify-center" activeKey={tabContentKey}>
        <Tab label="Email" tabKey="email" onClick={setTabContentKey} />
        {showLinkTab && <Tab label="Link" tabKey="link" onClick={setTabContentKey} />}
      </Tabs>
      <div className="max-width-desktop">{tabContent[tabContentKey]}</div>
    </LoggedPageContainer>
  );
};

export default ShareParticipantPage;
