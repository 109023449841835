import React, { FC, useEffect, Dispatch, SetStateAction } from 'react';
import axios from 'axios';
import { ApolloClient, NormalizedCacheObject, useApolloClient } from '@apollo/client';
import { APP_CONFIG } from 'api/config';
import { createSetupIntent, saveResponseAndProcessPayment } from 'api/data/payment';
import { applePayAvailable, mountButton } from '../ApplePay';
import { Response } from 'api/data/response/types';
import { PaymentMethodType, PaymentErrorType } from 'api/data/payment/types';
import './style.scss';

interface ApplePayProps {
  setErrorMessage: (message: string) => void;
  redirectAfterPayment: (succeeded: boolean, responseId: string) => void;
  paymentProcessing: Dispatch<SetStateAction<boolean>>;
  setWalletType: Dispatch<SetStateAction<string>>;
  response: Response;
}

type CheckoutToken = {
  token: string;
};

const APPLE_PAY_ELEMENT_ID = 'apple-pay';

const ApplePay: FC<ApplePayProps> = ({
  paymentProcessing,
  redirectAfterPayment,
  setErrorMessage,
  setWalletType,
  response,
}) => {
  const client = useApolloClient() as ApolloClient<NormalizedCacheObject>;

  useEffect(() => {
    const performPayment = async (details: ApplePayJS.ApplePayPayment, callback: (succeeded: boolean) => void) => {
      const newResponse = {
        ...response,
        user: {
          email: details.shippingContact?.emailAddress || '',
          zipcode: details.shippingContact?.postalCode || '',
          fullName: `${details.shippingContact?.givenName || ''} ${details.shippingContact?.familyName || ''}`,
        },
      };

      const param = {
        type: 'applepay',
        token_data: details.token.paymentData as unknown,
      };

      try {
        const checkoutResponse = await axios.post<CheckoutToken>(APP_CONFIG.CHECKOUT_ENDPOINT, param, {
          headers: { Authorization: `Bearer ${APP_CONFIG.CHECKOUT}` },
        });

        const { paymentMethodId } = await createSetupIntent(
          {
            email: details.shippingContact?.emailAddress || '',
            responseId: newResponse.id || '',
            token: checkoutResponse.data.token,
          },
          client,
        );

        const processPaymentData = {
          paymentMethodId,
          paymentMethodType: PaymentMethodType.CARD,
          response: newResponse,
        };

        const { succeeded } = await saveResponseAndProcessPayment(client, newResponse, processPaymentData);

        callback(true);
        redirectAfterPayment(succeeded, newResponse.id || '');
      } catch (error) {
        if (typeof error === 'string') setErrorMessage(error);
        if ((error as PaymentErrorType)?.message) setErrorMessage((error as PaymentErrorType).message);
        paymentProcessing(false);
        callback(false);
      }
    };

    const total = {
      label: response.page?.title || '',
      amount: (response.order.totalCents / 100).toFixed(2),
    };

    if (applePayAvailable()) {
      void mountButton(APPLE_PAY_ELEMENT_ID, performPayment, total);
    } else {
      setWalletType('google');
    }
  }, [response, paymentProcessing, client, redirectAfterPayment, setErrorMessage, setWalletType]);

  return <div className="apple-pay-container" id={APPLE_PAY_ELEMENT_ID}></div>;
};

export default ApplePay;
