import React, { FC, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import { showPlural } from 'utils';
import useParticipantLoggedIn from 'hooks/useParticipantLoggedIn';
import Accordion from 'components/Accordion';
import ICONS from 'components/Icons';
import Message from 'components/Message';
import TextAreaEmail from 'components/TextAreaEmail';
import { PageView, SupporterInvitation, SupporterInvitationData } from 'api/data/pages/types';
import './style.scss';

const whoShouldInvite = [
  {
    title: '❤️ Family',
    list: ['Parents or guardians', 'Grandparents', 'Uncles, aunts and cousins', 'Adult siblings'],
  },
  {
    title: '🏘️ Community',
    list: ['Neighbors', 'Parent’s coworkers', 'Family friends', 'Alumni'],
  },
  {
    title: '🌐 Organizations',
    list: ['Religious leader', 'Scout leader or counselor', 'Family doctor or dentist', 'Local business'],
  },
];

type ShareByEmailProps = {
  pageSlug?: string;
  participantSlug?: string;
  buttonText?: { action: string; description: string };
  onInvite?: () => void;
  laterButtonAction?: () => void;
};

const ShareByEmail: FC<ShareByEmailProps> = ({
  pageSlug,
  participantSlug,
  buttonText,
  onInvite,
  laterButtonAction,
}) => {
  const { participant } = useParticipantLoggedIn({ id: pageSlug, participantSlug });
  const supporterInvitationsVariables = { pageId: pageSlug, participantId: participant?.id };
  const { data: supporterInvitationsData } = useQuery<SupporterInvitationData>(PageQuery.GetSupporterInvitations, {
    variables: supporterInvitationsVariables,
    fetchPolicy: 'network-only',
    skip: !participant?.id,
  });
  const { data: participantPage } = useQuery<PageView>(PageQuery.GetPageParticipant, {
    variables: { id: pageSlug, participantSlug },
    fetchPolicy: 'network-only',
  });
  const [sendSupporterInvites, { data: invitations, loading }] = useMutation<{
    sendSupporterInvites: SupporterInvitation[];
  }>(PageQuery.SendSupporterInvites, {
    refetchQueries: [{ query: PageQuery.GetSupporterInvitations, variables: supporterInvitationsVariables }],
  });
  const [showMessage, setShowMessage] = useState(false);

  const handleOnClick = async (emails: string[], hasInvalidEmails: boolean) => {
    await sendSupporterInvites({
      variables: { emails, participantId: participantPage?.page.participant?.id, pageId: pageSlug },
    });

    if (!hasInvalidEmails) {
      onInvite && onInvite();
      setShowMessage(true);
    }
  };

  const supporterInvitations = supporterInvitationsData?.supporterInvitations || [];

  return (
    <div className="share-by-email">
      <Message type="succeeded" showMessage={showMessage} setShowMessage={setShowMessage}>
        {invitations?.sendSupporterInvites.length} invitation{showPlural(invitations?.sendSupporterInvites.length || 0)}{' '}
        sent successfully
      </Message>
      <TextAreaEmail
        label="Email Addresses"
        loading={loading}
        onClick={handleOnClick}
        buttonText={buttonText || { action: 'Send', description: 'invite', showEmailQty: true }}
        laterButtonAction={laterButtonAction}
      />
      {supporterInvitations.length > 0 && (
        <Accordion
          title={`${supporterInvitations.length} email${showPlural(supporterInvitations.length)} sent!`}
          icon={ICONS['emoji_user']}>
          <div className="invite-box">
            {supporterInvitations.map(({ id, email }) => (
              <p key={id} className="paragraph-xx-small">
                {email}
              </p>
            ))}
          </div>
        </Accordion>
      )}
      <Accordion title="Who should I invite?" icon={ICONS['emoji_lamp']}>
        {whoShouldInvite.map(({ title, list }, index) => (
          <div key={index} className="invite-box">
            <p className="subtitle-xx-small">{title}</p>
            {list.map((item, index) => (
              <p key={index} className="paragraph-xx-small">
                {item}
              </p>
            ))}
          </div>
        ))}
      </Accordion>
    </div>
  );
};

export default ShareByEmail;
