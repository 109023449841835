import { useEffect, useCallback } from 'react';

const useInfiniteScroll = (loadMore: () => void, hasMore: boolean) => {
  const handleScroll = useCallback(() => {
    if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 && hasMore) {
      loadMore();
    }
  }, [loadMore, hasMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
};

export default useInfiniteScroll;
