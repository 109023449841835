import React, { FC } from 'react';
import clsx from 'clsx';
import { removeSpaces } from '~/utils';
import Input, { TextProps } from '../Input';

const Email: FC<TextProps> = ({ value, onBlur, onChange, className, ...props }) => {
  return (
    <Input
      value={value}
      label="Email"
      placeholder="email@email.com"
      {...props}
      type="email"
      className={clsx('email-input', className)}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChange && onChange(event)}
      onBlur={(event: React.FocusEvent<HTMLInputElement>) => {
        if (onBlur) {
          onBlur({
            ...event,
            target: {
              ...event.target,
              value: removeSpaces(event.target.value),
            },
          });
        }
      }}
    />
  );
};

export default Email;
