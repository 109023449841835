import React, { FC, useRef, useState } from 'react';
import { Link } from 'wouter';
import dayjs from 'dayjs';
import { currentSpaceSlug } from 'api/currentSpaceMiddleware';
import { currencyFormatter } from 'utils';
import CopyPageModal from 'components/CopyPageModal';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import Tag from 'components/Tag';
import { TooltipEllipsis } from 'components/Tooltip';
import { PageCardData } from 'api/data/pages/types';
import './style.scss';

const PageCard: FC<PageCardData> = ({ data }: PageCardData) => {
  const [showModal, setShowModal] = useState(false);

  const spaceSlug = currentSpaceSlug() || '';

  const modalRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className="page-card-row">
        <Link
          className="page-card"
          href={`/${spaceSlug}/pages/${data.slug}/${data.status === 'archived' ? 'activity' : 'edit'}`}>
          <div>
            <span>
              <TooltipEllipsis title={data.title} className="card-title" />
              {data.isTemplate && <Tag type="neutral" label="Template" />}
              {!data.isTemplate && dayjs().diff(dayjs(data.createdAt), 'day') < 8 && <Tag type="new" label="New" />}
            </span>
            <span className="secondary-text">
              created {dayjs(data.createdAt).format('MMM DD, YY')} • <span>{data.status}</span>
            </span>
          </div>
          <span>{dayjs(data.updatedAt).fromNow()}</span>
          <span className="number-column">{data.responsesCount}</span>
          <span className="number-column">{currencyFormatter(data.totalCollectedCents)}</span>
        </Link>
        <CopyPageModal pageId={data.id} showModal={showModal} setShowModal={setShowModal} />
        <DropDown outsideRef={modalRef}>
          <DropDownItem onClick={() => void setShowModal(true)} icon="copy">
            Copy to...
          </DropDownItem>
        </DropDown>
      </div>
    </>
  );
};

export default PageCard;
