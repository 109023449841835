import React, { FC } from 'react';
import DOMPurify from 'dompurify';
import OrganizationReceipt from './OrganizationReceipt';
import PageReceipt from './PageReceipt';
import { Organization } from '~/api/data/user/types';
import { PageDataDraftBlock } from '~/api/data/pages/types';

interface ReceiptProps {
  onSave: (changes: Partial<Organization> | Partial<PageDataDraftBlock>) => void;
  subject?: Organization | PageDataDraftBlock;
  subjectType: 'organization' | 'page';
  loading: boolean;
  unsavedChanges?: Partial<PageDataDraftBlock>;
  setUnsavedChanges?: (unsavedChanges?: Partial<PageDataDraftBlock>) => void;
}

const Receipt: FC<ReceiptProps> = ({
  onSave,
  loading,
  subject,
  subjectType = 'organization',
  unsavedChanges,
  setUnsavedChanges,
}) => {
  const handleOnSave = (customBody: string, customFooter: string, extraProps?: Partial<PageDataDraftBlock>) => {
    onSave({
      customBodyReceiptContent: DOMPurify.sanitize(customBody),
      customFooterReceiptContent: DOMPurify.sanitize(customFooter),
      ...extraProps,
    });
  };

  return subjectType === 'organization' ? (
    <OrganizationReceipt loading={loading} organization={subject as Organization} onSave={handleOnSave} />
  ) : (
    <PageReceipt
      loading={loading}
      page={subject as PageDataDraftBlock}
      onSave={handleOnSave}
      unsavedChanges={unsavedChanges}
      setUnsavedChanges={setUnsavedChanges}
    />
  );
};

export default Receipt;
