import { ApolloCache, FetchResult } from '@apollo/client';
import * as AuthQuery from 'graphql/auth.graphql';
import { CreateParticipantQuery, LoginQuery } from 'api/data/user/types';

export const updateUserCache = (
  cache: ApolloCache<LoginQuery | CreateParticipantQuery>,
  mutationResult: Omit<
    FetchResult<LoginQuery | CreateParticipantQuery, Record<string, []>, Record<string, []>>,
    'context'
  >,
): void => {
  cache.writeQuery({
    query: AuthQuery.Session,
    data: {
      session: {
        ...((mutationResult.data as LoginQuery)?.login ||
          (mutationResult.data as CreateParticipantQuery)?.createParticipant),
      },
    },
  });
};
