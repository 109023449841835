import React, { FC, useRef, useState } from 'react';
import DOMPurify from 'dompurify';
import { useLocation } from 'wouter';
import { useMutation } from '@apollo/client';
import * as PageQuery from 'graphql/page.graphql';
import useUserSession from 'hooks/useUserSession';
import RichTextInput, { RichTextInputMethods } from '~/components/RichTextInput';
import TextAreaEmail from 'components/TextAreaEmail';

type AddByEmailProps = {
  pageId: string;
  location: string;
  referToMeAs?: string;
};

const AddByEmail: FC<AddByEmailProps> = ({ pageId, location, referToMeAs }) => {
  const [sendParticipantInvites, { loading }] = useMutation(PageQuery.SendParticipantInvites);
  const [, setLocation] = useLocation();
  const { data: userSession } = useUserSession();

  const [message, setMessage] = useState(
    `<p>Hi there,\n\nWe’re using Omella to run our team fundraiser! Please click the button below to join (takes 30 seconds).\n\n${referToMeAs || userSession?.session.currentUser?.fullName}</p>`,
  );

  const richTextInputRef = useRef<RichTextInputMethods>(null);

  const handleOnClick = async (emails: string[], hasInvalidEmails: boolean) => {
    await sendParticipantInvites({ variables: { emails, message: DOMPurify.sanitize(message), pageId } });
    if (!hasInvalidEmails) setLocation(location);
  };

  return (
    <TextAreaEmail
      loading={loading}
      onClick={handleOnClick}
      buttonText={{ action: 'Add', description: 'Participant', showEmailQty: true }}
      disabled={!message.trim()}>
      <RichTextInput
        ref={richTextInputRef}
        className="email-message-input"
        id="message"
        parseToHTMLCallback={(htmlFragment: string) => setMessage(htmlFragment)}
        initialValue={message}
        name="message"
        label="Email Message:"
      />
    </TextAreaEmail>
  );
};

export default AddByEmail;
