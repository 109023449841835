import { useMemo } from 'react';
import { Filter } from 'bad-words';
import { useQuery } from '@apollo/client';
import * as BlackListQuery from 'graphql/blacklist.graphql';

interface BlackListType {
  blackListActiveTerms: { term: string; __typename: 'BlackList' }[];
}

const useProfanityChecker = (value: string) => {
  const { data } = useQuery<BlackListType>(BlackListQuery.GetBlackListActiveTerms, {
    fetchPolicy: 'cache-and-network',
  });

  const filter = useMemo(() => {
    const filterInstance = new Filter();

    data?.blackListActiveTerms.forEach(item => {
      filterInstance.addWords(item.term);
    });

    return filterInstance;
  }, [data]);

  const isProfane = filter.isProfane(value);

  return { isProfane, errorMessage: isProfane ? 'Please enter an appropriate name' : '' };
};

export default useProfanityChecker;
