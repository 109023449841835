import React, { FC } from 'react';
import './style.scss';

interface ToggleSwitchProps {
  handleOnChange?: () => void;
  toggleValue: boolean;
  label?: string;
  labelPosition?: 'before' | 'after';
  disabled?: boolean;
  ariaLabel?: string;
}

const ToggleSwitch: FC<ToggleSwitchProps> = ({
  handleOnChange,
  toggleValue,
  label,
  labelPosition = 'before',
  disabled,
  ariaLabel = 'switch-toggle',
}) => {
  return (
    <div className="row align-center space-between toggle-switch">
      {labelPosition === 'before' && !!label && <span className="size-xxs switch-label-before">{label}</span>}
      <label className="switch">
        <input
          type="checkbox"
          checked={toggleValue}
          onClick={handleOnChange}
          aria-label={ariaLabel}
          disabled={disabled}
          className="switch"
          onChange={() => null}
        />
        <span className="slider round"></span>
      </label>
      {labelPosition === 'after' && !!label && <span className="size-xxs switch-label-after">{label}</span>}
    </div>
  );
};

export default ToggleSwitch;
