import React, { FC } from 'react';
import QuestionBlockView from 'components/QuestionBlock/PayerView';
import PaymentBlockPayerView from '../PaymentBlock/PayerView';
import { BlockState } from 'api/data/pages/blocks/types';
import { Variant } from '../PaymentBlock/types';
import './style.scss';

interface NestedBlocksProps {
  blocks: BlockState[];
  parent: Variant;
  isRequired?: boolean;
  usedPasscodes?: string[];
  updateUsedPasscodes?: (blockId: string, passcode: string[]) => void;
  pageId?: string;
}

const NestedBlocks: FC<NestedBlocksProps> = ({
  blocks,
  parent,
  isRequired,
  usedPasscodes,
  updateUsedPasscodes,
  pageId,
}) => {
  return (
    <div className="nested-blocks-container">
      {blocks.map(block => {
        if (block.type === 'QUESTION') {
          return (
            <QuestionBlockView
              key={block.id}
              data={block}
              parent={parent}
              isRequired={isRequired}
              usedPasscodes={usedPasscodes}
              updateUsedPasscodes={updateUsedPasscodes}
              pageId={pageId}
            />
          );
        }
        if (block.type === 'PAYMENT') {
          return <PaymentBlockPayerView key={block.id} data={block} parent={parent} isRequired={isRequired} />;
        }
      })}
    </div>
  );
};

export default NestedBlocks;
