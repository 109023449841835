import { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import useDebounce from 'hooks/useDebounce';
import * as PageQuery from 'graphql/page.graphql';
import { buildBlockItem, generateEditorState, mapBlocks, mapPage } from 'api/data/pages';
import { emptyPage } from '~/defaults/page';
import { UseEditorReturn, PageDataDraftBlock, PageView } from '../api/data/pages/types';
import { BlockStateRaw } from 'api/data/pages/blocks/types';

const DEBOUNCE_DELAY = 1000;

export function useEditor(
  pageId: string,
  requestId?: string,
  shouldSave: boolean = true,
  delay: number = DEBOUNCE_DELAY,
): UseEditorReturn {
  const { data, error, loading } = useQuery<PageView>(requestId ? PageQuery.GetPage : PageQuery.GetPageEditor, {
    variables: { id: pageId, requestId: requestId },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
    skip: !pageId,
  });
  const defaultPageData = emptyPage(pageId);
  const [page, setPage] = useState<PageDataDraftBlock>(defaultPageData);
  const [updatePage, { loading: savePageLoading, error: savePageError }] = useMutation(PageQuery.SavePage);
  const debouncedPage = useDebounce<PageDataDraftBlock>(page, delay);

  useEffect(() => {
    if (!loading && !error && data) {
      if (data.page.blocks.length > 0) {
        const newEditorBlocks = data.page.blocks.map(item => buildBlockItem(item));
        setPage({ ...data.page, blocks: newEditorBlocks });
      } else {
        setPage({ ...data.page, blocks: [generateEditorState()] });
      }
    }
  }, [error, loading, data]);

  useEffect(() => {
    if (data && debouncedPage && debouncedPage.title !== '' && !error && !loading && shouldSave) {
      let newEditorBlocks: BlockStateRaw[] = [];

      if (debouncedPage.blocks.length > 0) {
        newEditorBlocks = debouncedPage.blocks.map(mapBlocks);
      }
      const pageData = {
        ...mapPage(debouncedPage),
        id: debouncedPage.id,
        blocks: newEditorBlocks,
        requestId: requestId,
      };

      if (pageData.title !== '') void updatePage({ variables: { page: pageData } });
    }
  }, [debouncedPage, error, loading, updatePage, data, requestId, shouldSave]);

  return [page, setPage, savePageLoading, savePageError];
}
