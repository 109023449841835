import React, { FC, RefObject, useEffect, useMemo, useRef, useState, useCallback, ChangeEvent } from 'react';
import { useQuery } from '@apollo/client';
import { useCombobox } from 'downshift';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { validateEmail } from '~/utils';
import useSpacesFromCurrentOrg from '~/hooks/useSpacesFromCurrentOrg';
import useUserSession from '~/hooks/useUserSession';
import Accordion from 'components/Accordion';
import Avatar from 'components/Avatar';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import DropDownItemDivider from 'components/Menu/DropDownItemDivider';
import ICONS from 'components/Icons';
import Input from 'components/Input';
import Tag from 'components/Tag';
import TransferOwnershipModal from './TransferOwnershipModal';
import { Membership, OrganizationMembership, SpaceMembership } from '~/api/data/user/types';
import './style.scss';

type SpaceMembersProps = {
  onSubmit: (member: { name: string; email: string }) => Promise<void>;
  onRemove: (member: SpaceMembership) => Promise<void>;
  onPromoteToOwner: (member: SpaceMembership) => Promise<void>;
  onCancelPendingMembership: (member: SpaceMembership) => Promise<void>;
};

type ActionInputProps = {
  onRemove: (member: SpaceMembership) => void;
  onPromoteToOwner: (member: SpaceMembership) => void;
  onCancelPendingMembership: (member: SpaceMembership) => void;
  refOutside: RefObject<HTMLElement>;
  member: SpaceMembership;
};

type SpaceMemberInputProps = {
  organizationMembers: Membership[];
  value: string;
  onChange: (email: string) => void;
  isEmailInvalid: boolean;
  isAlreadyAMember: boolean;
};

const SpaceMembers: FC<SpaceMembersProps> = ({ onSubmit, onRemove, onPromoteToOwner, onCancelPendingMembership }) => {
  const { data: userSession } = useUserSession();
  const { currentSpace, loading: loadingCurrentSpace } = useSpacesFromCurrentOrg();
  const { data } = useQuery<OrganizationMembership>(OrganizationQuery.GetMembershipsFromOrganization, {
    fetchPolicy: 'network-only',
  });
  const [member, setMember] = useState({ name: '', email: '' });
  const [showModal, setShowModal] = useState(false);
  const [newOwner, setNewOwner] = useState<SpaceMembership>();

  // for now it's okay to grab the space members from the organization members.
  // In the future, we might want to have a separate query for space members and accept them as a property.
  const spaceMembers = useMemo(() => currentSpace?.memberships || [], [currentSpace]);

  const organizationMembers = useMemo(() => {
    return (data?.organization.memberships || []).filter(
      membership =>
        membership.status !== 'INACTIVE' &&
        !spaceMembers.find(spaceMember => spaceMember.user.email === membership.user.email),
    );
  }, [data?.organization.memberships, spaceMembers]);

  const organizationMemberAssociedToTheEmail = organizationMembers.find(
    membership => membership.user.email === member.email,
  );

  const isEmailInvalid = !!member.email && !validateEmail(member.email);

  const isAlreadyAMember = !!spaceMembers.find(spaceMember => spaceMember.user.email === member.email);

  const currentUser = userSession?.session?.currentUser;

  const currentOwner = spaceMembers.find(member => member.role === 'OWNER' && member.status === 'ACTIVE')?.user;

  const transferOwnershipModal = currentUser && newOwner && currentOwner;

  const resetFieldsIfEmailIsEmpty = (name: string) => {
    if (name !== '') {
      setMember({ name: '', email: '' });
    }
  };

  const disableNameInput = !!organizationMemberAssociedToTheEmail;

  const disableAddButton = !member.name.trim() || !member.email || isEmailInvalid || isAlreadyAMember;

  const refOutside = useRef<HTMLElement>(null);

  useEffect(() => {
    if (organizationMemberAssociedToTheEmail) {
      setMember({
        name: organizationMemberAssociedToTheEmail.user.fullName || '',
        email: organizationMemberAssociedToTheEmail.user.email,
      });
    }
  }, [organizationMemberAssociedToTheEmail]);

  return (
    <>
      <div className="space-members-container">
        <h1>Add people to {currentSpace?.name}</h1>
        <p className="title-description">
          These people can access {currentSpace?.name} pages, reports and will receive receipt emails.
        </p>
        <form className="add-space-member">
          <SpaceMemberInput
            organizationMembers={organizationMembers}
            value={member.email}
            onChange={email => {
              if (!email && disableNameInput) resetFieldsIfEmailIsEmpty(member.name);
              else setMember({ ...member, email });
            }}
            isEmailInvalid={isEmailInvalid}
            isAlreadyAMember={isAlreadyAMember}
          />
          <Input
            className="input"
            name="name"
            placeholder="Full name"
            type="text"
            value={member.name}
            disabled={disableNameInput}
            onChange={({ target }) => {
              setMember({ ...member, name: target.value });
            }}
            onBlur={({ target }) => setMember({ ...member, name: target.value.trim() })}
          />
          <button
            type="submit"
            className="button-size-ml"
            disabled={disableAddButton}
            onClick={() => {
              void onSubmit(member);
              setMember({ name: '', email: '' });
            }}>
            Add
          </button>
        </form>
        <div className="table">
          <h3 className="space-members-section-title">People with access</h3>
          <div className="table-body">
            {spaceMembers.map(member => (
              <div key={member.id} className="table-row">
                <div className="member-identifier">
                  <div className="member-full-name">
                    <span>{member.user.fullName}</span>

                    {member.status === 'PENDING' && member.role === 'OWNER' ? (
                      <Tag label="Pending ownership" type="neutral" size="small" />
                    ) : (
                      member.status === 'PENDING' && <Tag label="Invited" type="pending" size="small" />
                    )}
                  </div>

                  <span>{member.user.email}</span>
                </div>
                <ActionInput
                  refOutside={refOutside}
                  member={member}
                  onRemove={membership => void onRemove(membership)}
                  onPromoteToOwner={membership => {
                    setNewOwner(membership);
                    setShowModal(true);
                  }}
                  onCancelPendingMembership={membership => void onCancelPendingMembership(membership)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="general-access-container">
        <h3 className="space-members-section-title">General access</h3>
        {!loadingCurrentSpace && (
          <Accordion
            title={`${currentSpace?.name} Owners and Admin`}
            icon={<Avatar text={currentSpace?.name || ''} size="small" />}>
            <p>
              All organization owners and admin can access this space. Add them as members if you would like them to
              receive payment notifications.
            </p>
          </Accordion>
        )}
      </div>
      {transferOwnershipModal && (
        <TransferOwnershipModal
          currentUser={currentUser}
          currentOwner={currentOwner}
          newOwner={newOwner}
          onPromoteToOwner={newOwner => void onPromoteToOwner(newOwner)}
          setShowModal={setShowModal}
          showModal={showModal}
        />
      )}
    </>
  );
};

function getMembersFilter(inputValue?: string) {
  const lowerCasedInputValue = inputValue?.toLowerCase() || '';

  return function membersfilter(member: Membership) {
    return !inputValue || member.user.email.toLowerCase().startsWith(lowerCasedInputValue);
  };
}

const SpaceMemberInput: FC<SpaceMemberInputProps> = ({
  organizationMembers,
  value,
  onChange,
  isEmailInvalid,
  isAlreadyAMember,
}) => {
  const [members, setMembers] = useState(organizationMembers || []);
  const [onBlur, setOnBlur] = useState(false);

  useEffect(() => {
    setMembers(organizationMembers.filter(getMembersFilter(value)));
  }, [organizationMembers, value]);

  const { isOpen, getInputProps, getMenuProps, getItemProps } = useCombobox({
    onInputValueChange: ({ type, inputValue }) => {
      if (type !== useCombobox.stateChangeTypes.InputChange) {
        onChange(inputValue || '');
      }
    },
    items: members,
    inputValue: value,
    itemToString(item) {
      return item ? item.user.email : '';
    },
  });

  const handleNativeChangeEvent = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const value = event.target.value;
      onChange(value);
    },
    [onChange],
  );

  const inputProps = { ...getInputProps({ refKey: 'inputRef' }) };

  return (
    <div className="selector">
      <Input
        {...inputProps}
        name="email"
        placeholder="e.g. my@email.com"
        onFocus={() => setOnBlur(false)}
        onBlur={({ target }) => {
          onChange(target.value.replaceAll(' ', ''));
          setOnBlur(true);
        }}
        rules={{
          errorMessage: isEmailInvalid ? 'Please enter a valid email' : 'Is already a member',
          hasError: (isEmailInvalid && onBlur) || isAlreadyAMember,
        }}
        onChange={event => {
          inputProps.onChange && inputProps.onChange(event);
          handleNativeChangeEvent(event);
        }}
      />
      <ul {...getMenuProps()}>
        {isOpen &&
          members.map((item, index) => (
            <li key={item.id} {...getItemProps({ item, index })}>
              <div>
                <span className="name">{item.user.fullName}</span>
                <span className="description">{item.user.email}</span>
              </div>
            </li>
          ))}
      </ul>
    </div>
  );
};

const ActionInput: FC<ActionInputProps> = ({
  refOutside,
  onRemove,
  onPromoteToOwner,
  onCancelPendingMembership,
  member,
}) => {
  const roleDisplay: Record<string, string> = {
    OWNER: 'Space Owner',
    STAFF: 'Space Staff',
  };

  return (
    <DropDown
      iconButtonLabel="action-button"
      openedIcon=""
      closedIcon=""
      outsideRef={refOutside}
      disabled={member.role === 'OWNER' && member.status === 'ACTIVE'}
      buttonChildren={
        <div className="input-with-icon">
          <div className="icon">{ICONS['chevron_down']}</div>
          <input type="text" value={roleDisplay[member.role]} disabled />
        </div>
      }>
      <DropDownItem disabled>
        <div className="role-content">
          {roleDisplay[member.role]}
          {ICONS['check']}
        </div>
      </DropDownItem>
      <DropDownItemDivider />
      {member.status === 'PENDING' && member.role === 'OWNER' ? (
        <DropDownItem onClick={() => onCancelPendingMembership(member)}>Cancel ownership transfer</DropDownItem>
      ) : (
        <DropDownItem onClick={() => onPromoteToOwner(member)}>Promote to owner</DropDownItem>
      )}
      <DropDownItem onClick={() => onRemove(member)}>Remove</DropDownItem>
    </DropDown>
  );
};

export default SpaceMembers;
