import React, { useState } from 'react';
import dayjs from 'dayjs';
import Modal from 'components/Modal';
import { Organization } from 'api/data/user/types';

type PayerRequestedModalProps = {
  organization?: Organization;
  requestedDate?: string;
  requestType: 'Cancelation' | 'Refund';
};

const PayerRequestedModal = ({ organization, requestedDate, requestType }: PayerRequestedModalProps) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <button
        className="button-link secondary button-size-sm"
        type="button"
        onClick={ev => {
          ev.stopPropagation();
          setShowModal(true);
        }}>
        {requestType} Requested
      </button>
      <Modal
        header={`${requestType} Requested`}
        headerIcon="money_back"
        handleOnConfirm={() => setShowModal(false)}
        handleOnCancel={() => setShowModal(false)}
        confirmlabel="Okay"
        visible={showModal}
        className="refund-request-modal">
        <p>
          Your {requestType} request was sent to {organization?.name} {dayjs(requestedDate).fromNow()}. Please reach out
          to {organization?.contactName} at{' '}
          <a href={`mailto:${organization?.contactEmail ? organization.contactEmail : 'contact@omella.com'}`}>
            {organization?.contactEmail}
          </a>{' '}
          with questions or for an update.
        </p>
      </Modal>
    </>
  );
};

export default PayerRequestedModal;
