import React, { FC, useState, useEffect } from 'react';
import { useLocation } from 'wouter';
import { default as queryString } from 'query-string';
import Message from 'components/Message';

const PageAddedMessage: FC = () => {
  const [showCopiedPageMessage, setShowCopiedPageMessage] = useState(false);
  const [location, setLocation] = useLocation();

  useEffect(() => {
    const pageAdded = queryString.parse(window.location.search).pageAdded;
    if (pageAdded) {
      setShowCopiedPageMessage(true);
    }

    return function cleanup() {
      setShowCopiedPageMessage(false);
    };
  }, [setShowCopiedPageMessage]);

  useEffect(() => {
    if (showCopiedPageMessage) setLocation(location);
  }, [setLocation, location, showCopiedPageMessage]);

  return (
    <Message
      className="page-added"
      setShowMessage={setShowCopiedPageMessage}
      showMessage={showCopiedPageMessage}
      type="succeeded">
      Page added!
    </Message>
  );
};

export default PageAddedMessage;
