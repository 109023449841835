import { SubmissionDetail } from 'api/data/response/types';
import { Participant } from 'api/data/pages/types';

const participantAsSubmission = (participant: Partial<Participant>): SubmissionDetail => {
  return {
    title: 'Participant',
    answer: participant.user?.fullName || '',
    blockType: 'profile',
    isFile: false,
  };
};

export default participantAsSubmission;
