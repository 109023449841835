import React, { FC } from 'react';
import { findAnswer } from 'api/data/response';
import { QuestionBlock } from '../types';
import './style.scss';

export interface SignatureProps {
  data: QuestionBlock;
  placeholder: string;
  handleAnswerChange?: (value: string) => void;
  readOnly?: boolean;
}

const Signature: FC<SignatureProps> = ({ data, placeholder, handleAnswerChange, readOnly }: SignatureProps) => {
  const answer = findAnswer(data.id, 'SIGNATURE');

  return (
    <div className="signature-block">
      <span>x</span>
      <input
        className="input-answer signature cursive-text"
        type="text"
        disabled={readOnly}
        placeholder={placeholder}
        maxLength={1000}
        aria-label="signature"
        value={answer.value}
        onChange={({ target }) => handleAnswerChange && handleAnswerChange(target.value.trimStart())}
      />
    </div>
  );
};

export default Signature;
