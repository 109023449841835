import React, { FC, RefObject, MutableRefObject, useState } from 'react';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import { getRecurringName, hasPaymentPlanInstallments, isValidAmount, numberCentsFormatter } from 'utils';
import { recurringOptionsArray, RecurringValue } from 'defaults/page';
import DropDown from 'components/Menu/DropDown';
import DropDownItem from 'components/Menu/DropDownItem';
import IconButton from 'components/Button/IconButton';
import Tooltip from 'components/Tooltip';
import { PaymentOption as PaymentOptionType } from '../types';
import './style.scss';

type PaymentOptionProps = {
  paymentOption: PaymentOptionType;
  handleOnChange: (value: Partial<PaymentOptionType>) => void;
  paymentRef: RefObject<HTMLElement>;
  elementsRefs: MutableRefObject<{ [key: string]: HTMLInputElement | null }>;
  setFrequencyModal: () => void;
  deleteEnabled: boolean;
  removePaymentOption: () => void;
};
const PaymentOption: FC<PaymentOptionProps> = ({
  paymentOption,
  handleOnChange,
  paymentRef,
  elementsRefs,
  setFrequencyModal,
  deleteEnabled,
  removePaymentOption,
}) => {
  const hasInstallments = hasPaymentPlanInstallments(paymentOption.paymentPlanInstallments);
  const [focusItem, setFocusItem] = useState<string>();
  const handleOnChangeAmount = (value: string) => {
    let amount = undefined;
    if (value.trim() !== '') {
      amount = parseInt((parseFloat(value || '0') * 100).toFixed());
    }

    const paymentOption = {
      amountCents: amount,
      allowAnyAmount: amount === undefined,
    };
    handleOnChange(paymentOption);
  };

  const handleOnChangeRecurring = (recurring: RecurringValue) => {
    const paymentOption = {
      recurring: recurring,
      paymentPlanInstallments: -1,
    };

    handleOnChange(paymentOption);
  };

  const handleOnBlur = () => {
    if (paymentOption.amountCents === 0) {
      handleOnChangeRecurring('ONCE');
    }
    setFocusItem(undefined);
  };

  return (
    <div className="variant-payment-option">
      <div className="option-container">
        <input type="radio" disabled />
        <span>$</span>
        <NumericFormat
          className="input-amount"
          value={
            paymentOption.amountCents !== null && paymentOption.amountCents !== undefined
              ? numberCentsFormatter(paymentOption.amountCents)
              : ''
          }
          onValueChange={target => handleOnChangeAmount(target.value)}
          thousandSeparator
          decimalScale={2}
          placeholder="Amount"
          onFocus={
            /* istanbul ignore next */
            () => {
              setFocusItem(paymentOption.id);
            }
          }
          onBlur={() => handleOnBlur()}
          isAllowed={isValidAmount}
          getInputRef={(element: HTMLInputElement) =>
            (elementsRefs.current[`payment_variant_${paymentOption.id}`] = element)
          }
          maxLength={10}
        />
        <span className={`recurring-slash `}>/</span>
        <DropDown
          outsideRef={paymentRef}
          openedIcon="arrow_up"
          closedIcon="arrow_down"
          buttonChildren={
            <span>
              <span>{getRecurringName(paymentOption.recurring)}</span>
              {hasInstallments &&
                !!paymentOption.paymentPlanInstallments &&
                ` ending after ${paymentOption.paymentPlanInstallments} payments`}
            </span>
          }
          iconSide="RIGHT"
          onClick={() => {
            setFocusItem(paymentOption.id);
          }}
          onBlur={() => handleOnBlur()}>
          {recurringOptionsArray.map(recurring => (
            <DropDownItem
              key={recurring.id}
              onClick={() => {
                handleOnChangeRecurring(recurring.id);
                setFocusItem(undefined);
              }}
              isSelected={!hasInstallments && paymentOption.recurring === recurring.id}
              disabled={paymentOption.amountCents === 0 && recurring.id !== 'ONCE'}>
              {recurring.noun}
            </DropDownItem>
          ))}
          {hasInstallments && !!paymentOption.paymentPlanInstallments && (
            <DropDownItem isSelected={hasInstallments} onClick={setFrequencyModal}>
              {`${getRecurringName(paymentOption.recurring)} ending after ${
                paymentOption.paymentPlanInstallments
              } payments`}
            </DropDownItem>
          )}
          <DropDownItem onClick={setFrequencyModal} disabled={paymentOption.amountCents === 0}>
            Custom...
          </DropDownItem>
        </DropDown>
      </div>
      {deleteEnabled && (
        <Tooltip title="Delete">
          <IconButton
            icon="close"
            className={clsx('delete-button', { 'show-button': focusItem === paymentOption.id })}
            onClick={() => removePaymentOption()}
          />
        </Tooltip>
      )}
    </div>
  );
};

export default PaymentOption;
