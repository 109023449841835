import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(isToday);
dayjs.extend(isYesterday);

const dateFormat = (date: string | null = ''): string => {
  if (!date) return '';

  const minutesAgo = dayjs(dayjs()).diff(date, 'minute');

  if (minutesAgo <= 30) return 'Now';
  if (dayjs(date).isToday()) return 'Today';
  if (dayjs(date).isYesterday()) return 'Yesterday';

  return dayjs(date).format('MMM D, YYYY');
};

export default dateFormat;
