const centsFormatter = (value = '0'): number => {
  if (!value) return 0;
  return parseInt(value) / 100;
};

const numberCentsFormatter = (value: number): string | number => {
  const amount = value / 100;
  return amount.toLocaleString('en-US', { minimumFractionDigits: Number.isInteger(amount) ? 0 : 2 });
};

const numberToCentsFormatter = (value: string) => {
  if (value.trim() !== '') {
    return parseInt((parseFloat(value || '0') * 100).toFixed());
  }
  return 0;
};

export { centsFormatter, numberCentsFormatter, numberToCentsFormatter };
