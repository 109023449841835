import { CarouselProps } from './types';

export const defaultProps: Required<CarouselProps> = {
  children: [],
  show: 1,
  slide: 1,
  transition: 0.5,
  responsive: true,
  infinite: false,
  className: '',
  hideArrows: false,
};
