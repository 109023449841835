import React, { FC, useState, useEffect } from 'react';
import clsx from 'clsx';
import { useMutation } from '@apollo/client';
import * as OrganizationQuery from 'graphql/organization.graphql';
import { showPlural, validateEmail } from 'utils';
import IconButton from 'components/Button/IconButton';
import ICONS from 'components/Icons';
import MemberRow from './MemberRow';
import Message from 'components/Message';
import { OrganizationRoles, SpaceRoles, UserParams } from 'api/data/user/types';
import './style.scss';

type InviteMemberProps = {
  show: boolean;
  closeInviteMembers: () => void;
};

export type SpacesType = { id: string; role: SpaceRoles };

export interface UserInviteMember extends UserParams {
  role: OrganizationRoles;
  spaces?: SpacesType[];
}

const DEFAULT_MEMBER: UserInviteMember = {
  fullName: '',
  email: '',
  role: 'ADMIN',
};

const InviteMembers: FC<InviteMemberProps> = ({ closeInviteMembers, show }) => {
  const [inviteMembersMutation, { loading }] = useMutation(OrganizationQuery.InviteMembers, {
    refetchQueries: [
      { query: OrganizationQuery.GetOrganization },
      { query: OrganizationQuery.GetMembershipsFromOrganization },
    ],
  });
  const [inviteMembers, setInviteMembers] = useState<UserInviteMember[]>([DEFAULT_MEMBER]);
  const [invitationMessage, setInvitationMessage] = useState({ showMessage: false, invitationQty: 0 });
  const [hasInvalidInvitation, setHasInvalidInvitation] = useState(true);
  const [filledInvitations, setFilledInvitations] = useState<UserInviteMember[]>([]);

  const handleOnChange = (value: string, attr: string, index: number) => {
    const newMember = [...inviteMembers];
    newMember[index] = {
      ...newMember[index],
      [attr]: value,
    };
    setInviteMembers(newMember);
  };

  const handleOnAddSpaceMembership = (value: SpacesType, index: number) => {
    const newMember = [...inviteMembers];
    const newSpaces = newMember[index].spaces || [];

    newSpaces.push(value);

    newMember[index] = {
      ...newMember[index],
      spaces: newSpaces,
    };
    setInviteMembers(newMember);
  };

  const handleOnRemoveSpaceMembership = (value: string, index: number) => {
    const newMember = [...inviteMembers];
    const newSpaces = newMember[index].spaces || [];

    newMember[index] = {
      ...newMember[index],
      spaces: newSpaces.filter(space => space.id !== value),
    };
    setInviteMembers(newMember);
  };

  const addMember = () => {
    const newMember = [...inviteMembers, DEFAULT_MEMBER];
    setInviteMembers(newMember);
  };

  const removeMember = (index: number) => {
    const newMember = [...inviteMembers];
    newMember.splice(index, 1);
    setInviteMembers(newMember);
  };

  const closeAndResetInvitesMembers = () => {
    closeInviteMembers();
    setInviteMembers([DEFAULT_MEMBER]);
  };

  const handleInviteMembers = async () => {
    await inviteMembersMutation({
      variables: {
        invites: filledInvitations,
      },
    });

    setInvitationMessage({ showMessage: true, invitationQty: filledInvitations.length });
    closeAndResetInvitesMembers();
  };

  useEffect(() => {
    const hasInvalidInvitations = inviteMembers.some(
      member =>
        (member.email?.trim() || member.fullName?.trim()) && (!validateEmail(member.email) || !member.fullName?.trim()),
    );

    const filledInvitations = inviteMembers
      .filter(member => member.email || member.fullName)
      .map(member => {
        return {
          ...member,
          email: member.email.replaceAll(' ', ''),
        };
      });

    setHasInvalidInvitation(hasInvalidInvitations || filledInvitations.length === 0);
    setFilledInvitations(filledInvitations);
  }, [inviteMembers]);

  const inviteButtonText = inviteMembers.length > 1 ? `Invite ${inviteMembers.length} people` : 'Invite';

  return (
    <>
      <Message
        className="page-message invitation-message"
        showMessage={invitationMessage.showMessage}
        setShowMessage={visible => setInvitationMessage({ ...invitationMessage, showMessage: visible })}>
        {ICONS['check_circle']}
        Invitation{showPlural(invitationMessage.invitationQty)} sent!
      </Message>
      <div className={clsx('invite-members', { show: show })} data-testid="invite-members">
        <header>
          Add people
          <IconButton icon="close" onClick={() => closeAndResetInvitesMembers()} />
        </header>
        <div className="members-container">
          {inviteMembers.map((inviteMember, idx) => {
            return (
              <MemberRow
                key={idx}
                index={idx}
                {...inviteMember}
                handleOnChange={handleOnChange}
                removeMember={removeMember}
                loading={loading}
                handleOnAddSpaceMembership={handleOnAddSpaceMembership}
                handleOnRemoveSpaceMembership={handleOnRemoveSpaceMembership}
              />
            );
          })}
          <div className="horizontal-line"></div>
          <div className="button-container">
            <IconButton
              className="button-link button-size-m"
              icon="plus_new"
              onClick={() => addMember()}
              disabled={loading}>
              Add more
            </IconButton>
            <div>
              <button
                disabled={loading}
                className="button-outline button-size-m"
                onClick={() => closeAndResetInvitesMembers()}>
                Cancel
              </button>
              <button
                disabled={loading || hasInvalidInvitation}
                className="button-size-m"
                onClick={() => void handleInviteMembers()}>
                {loading ? 'Inviting...' : inviteButtonText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default InviteMembers;
